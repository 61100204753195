import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextNotary } from './Notary';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { NotaryService } from '../../../services/notary.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { NotaryModel } from '../../../models/notary.model';
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../../types/pagination.type';
import { modalShow } from '../../../types/modal.type';
import AppModalNotaryUpdate from '../../../components/modal/ModalNotaryUpdate';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';
import balance from '../../../assets/images/svg/balance.svg';

export interface ClientListPageProps {};

let errorResponse: Error, messageResponse: Message, notariesResponse: any, notaryResponse: any;

const ClientListPage: React.FunctionComponent<ClientListPageProps> = props => {
  const {setRoute} = UseOutletContextNotary()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [notaries, setNotaries] = useState<NotaryModel[] | undefined | null>(null)
  const [notary, setNotary] = useState<NotaryModel | undefined | null>(null)

  const loadNotaries = async () => {
    let totalPages: number = 1
    let totalByPage: number = 10
    let listPagesOfPagination: number[] = []

    await NotaryService.getNotaries().then( (response) => {
      if (response.status === 200) {
        notariesResponse = response.data

        if (notariesResponse.notaries.length % totalByPage === 0) {
          totalPages = Math.floor(notariesResponse.notaries.length / totalByPage)
        } else {
          totalPages = Math.floor(notariesResponse.notaries.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPagination.push(i)
        }

        setNotaries(notariesResponse.notaries)
        setPagination({...pagination, current: 1, total: totalByPage, pages: listPagesOfPagination, limits: {min: 0, max: totalByPage}})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitContactDelete = async (item: string) => {
    if (notaries) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisNotary,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await NotaryService.deleteNotary(item).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              let list = notaries.filter((notary) => (notary.id !== item))
              setNotaries(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingNotarieListReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
              
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitNotaryUpdate = async (item: string) => {
    setNotary(null)

    await NotaryService.getNotary(item).then( (response) => {
      if (response.status === 200) {
        notaryResponse = response.data
        setNotary(notaryResponse)
        modalShow('modal-notary-update')
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    }) 
  }

  const executePaginationSelect = (item: number) => {
    let minLimitNew: number = pagination.total * (item - 1)
    let maxLimitNew: number = pagination.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-button-previous')
    } else {
      pageLimitEnabled('pagination-button-previous')
    }

    if (item === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }else {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(item, elements)
    setPagination({...pagination, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationPrevious = () => {
    let currentNew: number = pagination.current - 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-button-previous')
    }

    if (currentNew < pagination.pages.length) {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationNext = () => {
    let currentNew: number = pagination.current + 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-button-previous')
    }

    if (currentNew === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  function rowClass(index: number): string {
    if (pagination.limits.min <= index && index < pagination.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.notaries, branch: lang.labels.notaryList}, browse: {target: lang.labels.addNotary, link: "/app/notary/create"}})
    loadNotaries()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { notaries 
        ?
        <div className="card">
          <div className="card-body">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed gy-5">
                  <thead>
                    <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase">
                      <th className="min-w-300px sorting">{lang.labels.notaries}</th>
                      <th className="w-250px sorting_disabled text-end">{lang.labels.actions}</th>
                    </tr>
                  </thead>
                  { notaries.length > 0
                    ?
                    <tbody className="text-gray-600 fs-6 fw-bold">
                      { notaries.map (( (item, index) => { return (
                        <tr key={index} className={`row-dashed bg-hover-light ${rowClass(index)}`} onClick={()=>{navigate(`/app/notary/view/${item.id}`)}}>
                          <td className="d-flex align-items-center ps-3">
                            <div className="symbol symbol-55px overflow-hidden me-5">
                              <img src={balance} alt="logo"/>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="fs-7 text-uppercase">{lang.labels.notary}</span>
                              <span className="fs-6 text-gray-900">{item.first_name} {item.last_name}</span>
                              <span className="fs-7 text-gray-700">{item.phone}</span>
                            </div>
                          </td>
                          <td className="text-end pe-1">
                            <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation(); submitNotaryUpdate(item.id);}}>{lang.labels.edit}</button>
                            <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation(); submitContactDelete(item.id);}}>{lang.labels.delete}</button>
                            <img className="w-20px" src={arrowNext} alt='Go'/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              { notaries.length > 0 && pagination.pages.length > 1 &&
                <div className="row">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers">
                      <ul className="pagination">
                        <li id="pagination-button-previous" className="paginate_button page-item previous disabled">
                          <button className="page-link" type="button" onClick={executePaginationPrevious}>
                            <i className="previous"></i>
                          </button>
                        </li>
                        { pagination.pages.map (( (item, index) => { return (
                          <li key={index} id={"pagination-button-" + item} className={`paginate_button page-item ${item === pagination.current && 'active' }`}>
                            <button className="page-link" type="button" onClick={() => executePaginationSelect(item)}>{item}</button>
                          </li>
                        )}))}
                        <li id="pagination-button-next" className="paginate_button page-item next">
                          <button className="page-link" type="button" onClick={executePaginationNext}>
                            <i className="next"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalNotaryUpdate notary={notary} notaries={notaries} setNotary={setNotary} setNotaries={setNotaries}></AppModalNotaryUpdate>
    </div>
  )
};

export default ClientListPage;
