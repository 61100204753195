import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';

import { UseOutletContextNotary } from './Notary';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { Error } from '../../../models/error.model';
import { NotaryService } from '../../../services/notary.service';
import { Message } from '../../../models/message.model';
import { getCode, getName, getNames } from '../../../scripts/countries.script';
import { inputValid, inputInvalid, restartInput } from '../../../types/legend.input.type';
import { getError, getMessage } from '../../../languages/context/response';
import { expressions } from '../../../scripts/regular.expressions.script';

interface ContactCreateProps {};

let errorResponse: Error, messageResponse: Message;

const ContactCreatePage: React.FunctionComponent<ContactCreateProps> = () => {
  const {setRoute} = UseOutletContextNotary()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: true})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitNotaryCreate = async () => {
    setLoadIndicator('on')

    if (firstName.valid && lastName.valid && street.valid && city.valid && zipCode.valid && city.valid && country.valid && email.valid && phone.valid && website.valid) {
      await NotaryService.postNotary(firstName.value, lastName.value, street.value, zipCode.value, city.value, country.value, phone.value, email.value, website.value).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.codeLanguage),
            text: lang.labels.updatingNotaryListReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }  

  const onCancel = async () => {
    Swal.fire({
      title: lang.labels.areYouSureYouWantToCancel,
      text: lang.labels.ifYouAcceptYouMayHaveToFillOutTheFormAgain,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: lang.labels.yes,
      cancelButtonText: lang.labels.noCancel,
      customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(-1)
        }
    })
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeCompanyCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const validateFirstName = () => {
    if (expressions.text.test(firstName.value)) {
      setFirstName({...firstName, valid: true})
      inputValid('input-first-name')
    } else {
      setFirstName({...firstName, valid: false})
      inputInvalid('input-first-name')
    }
  }

  const validateLastName = () => {
    if (expressions.text.test(lastName.value)) {
      setLastName({...lastName, valid: true})
      inputValid('input-last-name')
    } else {
      setLastName({...lastName, valid: false})
      inputInvalid('input-last-name')
    }
  }

  const validateStreet = () => {
    if (expressions.street.test(street.value)) {
      setStreet({...street, valid: true})
      inputValid('input-street')
    } else {
      setStreet({...street, valid: false})
      inputInvalid('input-street')
    }
  }

  const validateZipCode = () => {
    if (expressions.zip.test(zipCode.value)) {
      setZipCode({...zipCode, valid: true})
      inputValid('input-zip-code')
    } else {
      setZipCode({...zipCode, valid: false})
      inputInvalid('input-zip-code')
    }
  }

  const validateCity = () => {
    if (expressions.text.test(city.value)) {
      setCity({...city, valid: true})
      inputValid('input-city')
    } else {
      setCity({...city, valid: false})
      inputInvalid('input-city')
    }
  }

  const validatePhone = () => {
    if (expressions.phone.test(phone.value)) {
      setPhone({...phone, valid: true})
      inputValid('input-phone')
    } else {
      setPhone({...phone, valid: false})
      inputInvalid('input-phone')
    }
  }

  const validateEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email, valid: true})
      inputValid('input-email')
    } else {
      setEmail({...email, valid: false})
      inputInvalid('input-email')
    }
  }

  const validateWebsite = () => {
    if (website.value.length > 0) {
      if (expressions.website.test(website.value)) {
        setWebsite({...website, valid: true})
        inputValid('input-website')
      } else {
        setWebsite({...website, valid: false})
        inputInvalid('input-website')
      }
    } else if (website.value.length === 0) {
      setWebsite({...website, valid: true})
      restartInput('input-website')
    }
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.notaries, branch: lang.labels.createNotary}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="form">
      <div className="card card-flush mb-5">
        <div className="card-header">
          <div className="card-title">
            <h3 className="text-uppercase">{lang.labels.createANewNotary}</h3>
          </div>
        </div>
        <div className="card-body pt-0">
          <p className="fst-italic text-muted mb-5">
            {lang.labels.fieldsMarkedWithAnAsterisk}<span className="text-danger">(*)</span>{lang.labels.areRequired}
          </p>
          <div className="mb-5">
            <label className="form-label required">{lang.labels.firstName}</label>
            <input id="input-first-name" className="form-control form-control-solid" type="text" name="firstName" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
          </div>
          <div className="mb-5">
            <label className="form-label required">{lang.labels.lastName}</label>
            <input id="input-last-name" className="form-control form-control-solid" type="text" name="lastName" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
          </div>
          <div className="mb-5">
            <label className="form-label required">{lang.labels.street}</label>
            <input id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
          </div>
          <div className="row mb-0 mb-xl-5">
            <div className="mb-5 mb-xl-0 col-xl-4">
            <label className="form-label required">{lang.labels.postcode}</label>
              <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zipCode" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
            </div>
            <div className="mb-5 mb-xl-0 col-xl-8">
              <label className="form-label required">{lang.labels.city}</label>
              <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label required">{lang.labels.country}</label>
            <Dropdown>
              <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                <span className="selection">
                  <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                    <span className="select2-selection__rendered" role="textbox">
                      <span className="select2-selection__placeholder">
                        {country.valid && country.value.length > 0 ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                      </span>
                    </span>
                  </span>
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                <span className="select2-dropdown select2-dropdown--below">
                  <span className="select2-search select2-search--dropdown">
                    <input className="select2-search__field" type="text" name="country" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                  </span>
                  <span className="select2-results">
                    <ul className="select2-results__options" role="listbox">
                      { searchCountry.list.length > 0
                        ?
                        <>
                          { searchCountry.list.map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === country.value ? "true" : "false"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCompanyCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                            </li>
                          )}))}
                        </>
                        :
                        <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                      }
                    </ul>
                  </span>
                </span>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="mb-5">
            <label className="form-label required">{lang.labels.phone}</label>
            <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
          </div>
          <div className="mb-5">
            <label className="form-label required">{lang.labels.email}</label>
            <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
          </div>
          <div className="mb-0">
            <label className="form-label">Website</label>
            <input id="input-website" className="form-control form-control-solid" type="text" name="website" value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
          </div>
        </div>   
      </div>
      <div className="card card-flush p-3">
        <div className="d-flex justify-content-end">
          <div className="mb-xl-0 col-12 col-md-6  d-flex pe-6 ps-3">
            <button className="btn btn-primary w-100" type="button" data-kt-indicator={loadIndicator} onClick={submitNotaryCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary w-100 ms-3" type="reset" onClick={onCancel}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>    
      </div>
    </div>
  )
}

export default ContactCreatePage;
