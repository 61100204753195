import React, { Dispatch, SetStateAction, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { PurityModel } from '../../models/purity.model';
import { PurityService } from '../../services/purity.service';
import { listValueSortedAscending, listValueSortedDescending } from '../../scripts/order.asc.desc.list.script';
import { expressions } from '../../scripts/regular.expressions.script';

interface AppModalPurityCreateProps {
  orderAscDesc: string,
  purities: PurityModel[] | undefined | null,
  setPurities: Dispatch<SetStateAction<PurityModel[] | undefined | null>>,
};

const AppModalPurityCreate: React.FunctionComponent<AppModalPurityCreateProps> = ({orderAscDesc, purities, setPurities}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [valuePurity, setValuePurity] = useState({value: '', valid: false})

  const submitPurityCreate = async () => {
    setLoadIndicator('on')

    if (purities && valuePurity.valid) {
      await PurityService.postPurity(valuePurity.value).then( (response) => {
        if (response.status === 201) {
          let serviceResponse: PurityModel = response.data
          let list = (orderAscDesc === "asc") ? listValueSortedAscending([...purities, serviceResponse]) : listValueSortedDescending([...purities, serviceResponse])

          setPurities(list)

          Swal.fire({
            title: lang.labels.successfullyCreatedPurity,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalPurityCreate()
          })
        } else {
          let errorResponse: Error = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          }) 
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeValuePurity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setValuePurity({...valuePurity, value: event.target.value})
  }

  const validateValuePurity = () => {
    if (expressions.purity.test(valuePurity.value)) {
      setValuePurity({...valuePurity, valid: true})
      inputValid('modal-purity-create-input-value-purity')
    } else {
      setValuePurity({...valuePurity, valid: false})
      inputInvalid('modal-purity-create-input-value-purity')
    }
  }

  const executeHideModalPurityCreate = () => {
    modalHide('modal-purity-create')

    setTimeout( () => {
      restartInput('modal-purity-create-input-value-purity')
      setValuePurity({value: "", valid: false})
    }, 200 )
  }

  return (
    <div id="modal-purity-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-550px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.createPurity}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className='row fv-row'>
              <div className="d-flex justify-content-between">
                <label className="form-label">{lang.labels.value}</label>
                <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
              </div>
              <input id="modal-purity-create-input-value-purity" className="form-control form-control-solid" type="text" name="value-purity" value={valuePurity.value} onChange={handleChangeValuePurity} onKeyUp={validateValuePurity} onBlur={validateValuePurity} />
            </div>  
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitPurityCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalPurityCreate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalPurityCreate;
