import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';

import { UseOutletContextCommunication } from './Communication';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { CommunicationService } from '../../../services/communication.service';
import { Error } from '../../../models/error.model';
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../../types/pagination.type';
import { CommunicationModel } from '../../../models/communication.model';
import { clientSelectedDropDown } from '../../../scripts/selected.object.script';
import { dateFormat } from '../../../scripts/date.format.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';
import imageEmail from '../../../assets/images/svg/email-bg.svg';

export interface CommunicationListPageProps {};

let errorResponse: Error, communicationsResponse: any;

const CommunicationListPage: React.FunctionComponent<CommunicationListPageProps> = props => {
  const {setRoute, clients} = UseOutletContextCommunication()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [total, setTotal] = useState(0)
  const [filter, setFilter] = useState({client: ''})
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [communications, setCommunications] = useState<CommunicationModel[] | null>(null)
  const [search, setSearch] = useState({text: {value: '', valid: false}, list: [] as CommunicationModel[]})

  const loadCommunications = async () => {
    let totalPages: number = 1
    let totalByPage: number = 10
    let listPagesOfPagination: number[] = []

    await CommunicationService.getCommunications(filter.client).then( (response) => {
      if (response.status === 200) {
        communicationsResponse = response.data

        if (communicationsResponse.communications.length % totalByPage === 0) {
          totalPages = Math.floor(communicationsResponse.communications.length / totalByPage)
        } else {
          totalPages = Math.floor(communicationsResponse.communications.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPagination.push(i)
        }

        if (communicationsResponse.filter.client.length === 0) {
          setTotal(communicationsResponse.communications.length)
        }

        setCommunications(communicationsResponse.communications)
        setSearch({...search, list: communicationsResponse.communications})
        setPagination({...pagination, current: 1, total: totalByPage, pages: listPagesOfPagination, limits: {min: 0, max: totalByPage}})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let validity: boolean = false
    let list: CommunicationModel[] = []
    let totalPages: number = 10
    let listPagesOfPagination: number[] = []

    if (communications && text.length > 0) {
      for (let communication of communications) {
        let title: string = communication.title.toLowerCase()
        let client: string = `communicated to ${communication.client.last_name} ${communication.client.first_name}`.toLocaleLowerCase()
        let date: string = dateFormat(communication.created_at.slice(0, 10), lang.codeLanguage).toLocaleLowerCase() 
        if (title.indexOf(text.toLowerCase()) !== -1 || client.indexOf(text.toLowerCase()) !== -1 || date.indexOf(text.toLowerCase()) !== -1) {
          list.push(communication)
        }
      }
      validity = true
    } else if (communications! && text.length === 0) {
      validity = false
      list = communications
    }

    if (list.length % pagination.total === 0) {
      totalPages = Math.floor(list.length / pagination.total)
    } else {
      totalPages = Math.floor(list.length / pagination.total) + 1
    }

    for (let i = 1; i <= totalPages; i++) {
      listPagesOfPagination.push(i)
    }

    setSearch({...search, text: {valid: validity, value: text}, list: list})
    setPagination({...pagination, current: 1, total: pagination.total, pages: listPagesOfPagination, limits: {min: 0, max: pagination.total}})
  }

  const handleChangeClient = (item: string) => {
    setFilter({...filter, client: item})
    setSearch({...search, text: {valid: false, value: ''}, list: []})
  }

  const executePaginationSelect = (item: number) => {
    let minLimitNew: number = pagination.total * (item - 1)
    let maxLimitNew: number = pagination.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-button-previous')
    } else {
      pageLimitEnabled('pagination-button-previous')
    }

    if (item === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }else {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(item, elements)
    setPagination({...pagination, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationPrevious = () => {
    let currentNew: number = pagination.current - 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-button-previous')
    }

    if (currentNew < pagination.pages.length) {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationNext = () => {
    let currentNew: number = pagination.current + 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-button-previous')
    }

    if (currentNew === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  function rowClass(index: number): string {
    if (pagination.limits.min <= index && index < pagination.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  useEffect( () => {
    setCommunications(null)

    pagination.current = 0
    pagination.total = 0
    pagination.pages = []
    pagination.limits.min = 0
    pagination.limits.max = 0

    loadCommunications()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.client])

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.communications, branch: lang.labels.listCommunications}, browse: {target: lang.labels.addCommunication, link: "/app/communication/create"}})

    return () => setMounted(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { clients && communications
        ?
        <div className="card">
          <div className="card-header border-0">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px ps-14" type="text" placeholder={`${lang.labels.search}...`} maxLength={200} onChange={handleChangeSearch} />
              </div>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end">
              <Dropdown>
                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-250px p-0">
                  <span className="selection">
                    <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                      <span className="svg-icon svg-icon-1">
                        <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M3 7C3 6.44772 3.44772 6 4 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H4C3.44772 8 3 7.55228 3 7ZM6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12ZM9 17C9 16.4477 9.44772 16 10 16H14C14.5523 16 15 16.4477 15 17C15 17.5523 14.5523 18 14 18H10C9.44772 18 9 17.5523 9 17Z" fill="#bfc3c9"></path>
                        </svg>
                      </span>
                      <span className="select2-selection__rendered" role="textbox">
                        <span className="select2-selection__placeholder text-muted">
                          {filter.client.length > 0 ? `${clientSelectedDropDown(clients, filter.client)}` : lang.labels.filterBy}
                        </span>
                      </span>
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100 my-1">
                  <span className="select2-dropdown select2-dropdown--below">
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox">
                        <li className={`select2-results__option select2-results__option--selectable ${filter.client.length === 0 && "select2-results__option--selected"}`} role="option" aria-selected={filter.client.length === 0 ? "true" : "false"}>
                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeClient("")}>All ({total})</Dropdown.Item>
                        </li>
                        { clients.filter(item => item.count > 0).map(( (item, index) => { return (
                          <li key={index} className={`select2-results__option select2-results__option--selectable ${filter.client === item.id  && "select2-results__option--selected"}`} role="option" aria-selected={filter.client === item.id ? "true" : "false"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeClient(item.id)}>{item.last_name} {item.first_name} ({item.count})</Dropdown.Item>
                          </li>
                        )}))}
                      </ul>
                    </span>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed gy-5">
                  <thead>
                    <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase">
                      <th colSpan={2} className="min-w-300px sorting">{lang.labels.communications}</th>
                    </tr>
                  </thead>
                  { search.list.length > 0
                    ?
                    <tbody className="text-gray-600 fs-6 fw-bold">
                      { search.list.map (( (item, index) => { return (
                        <tr key={index} className={`row-dashed bg-hover-light ${rowClass(index)}`} onClick={()=>{navigate(`/app/communication/view/${item.client.id}/${item.id}`)}}>
                          <td className="d-flex align-items-center ps-3">
                            <span className="symbol symbol-55px overflow-hidden me-5">
                              <img src={imageEmail} alt="email"/>
                            </span>
                            <div className="d-flex flex-column">
                              <span className="fs-8 text-uppercase">{dateFormat(item.created_at, lang.codeLanguage)}</span>
                              <span className="fs-6 text-gray-900">{lang.labels.communicatedTo} {item.client.last_name} {item.client.first_name}</span>
                              <span className="fs-7 text-gray-700">{item.title}</span>
                            </div>
                          </td>
                          <td className="text-end pe-1">
                            <img className= "w-20px" src={arrowNext} alt="Go"/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              { search.list.length > 0 && pagination.pages.length > 1 &&
                <div className="row">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers">
                      <ul className="pagination">
                        <li id="pagination-button-previous" className="paginate_button page-item previous disabled">
                          <button className="page-link" type="button" onClick={executePaginationPrevious}>
                            <i className="previous"></i>
                          </button>
                        </li>
                        { pagination.pages.map (( (item, index) => { return (
                          <li key={index} id={"pagination-button-" + item} className={`paginate_button page-item ${item === pagination.current && 'active' }`}>
                            <button className="page-link" type="button" onClick={() => executePaginationSelect(item)}>{item}</button>
                          </li>
                        )}))}
                        <li id="pagination-button-next" className="paginate_button page-item next">
                          <button className="page-link" type="button" onClick={executePaginationNext}>
                            <i className="next"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default CommunicationListPage;
