import axios from 'axios';
import format from 'date-fns/format';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { CommunicationModel } from '../models/communication.model';
import { ClientModel } from '../models/client.model';
import { extractBase64 } from '../scripts/extract.base.64.script';
import { appToken } from '../scripts/app.token.script';

export class CommunicationService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getCommunications(id_client: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let clients: ClientModel[] | null = []

      let response: any | null = {
        filter: {
          client: id_client
        },
        communications: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/clients.data.json').then(resp => { clients = resp.data.clients }).catch(err => { alert(err) })
      await axios.get('../../../../../database/communications.data.json').then(resp => { response = resp.data }).catch(error => { alert(error) })

      if (response) {
        if (clients!.some(item => item.id === id_client)) {
          response.filter.client = `${clients.find(item => item.id === id_client)!.first_name} ${clients.find(item => item.id === id_client)!.last_name}`
          response.communications = response.communications.filter((item: CommunicationModel) => (item.client.id === id_client))
        } else {
          response.filter.client = id_client
        }

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/communications?client=${id_client}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async getClientCommunications(id_client: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        client_id: id_client,
        communications: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/communications.client.data.json').then(resp => { response = resp.data }).catch(error => { alert(error) })

      if (response.client_id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }
      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/communications`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getCommunication(id_client: string, id_communication: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: CommunicationModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/communication.data.json').then(resp => { response = resp.data.communication }).catch(error => { alert(error) })

      if (response!.id === id_communication && response!.client.id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/communications/${id_communication}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.communication
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postCommunication(clients: string[], title: string, message: string, files: any[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let communications: CommunicationModel[] | null = null
      let clientList: ClientModel[] | null = null
      let response: CommunicationModel[] | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/communications.data.json').then(resp => { communications = resp.data.communications }).catch(err => { alert(err) })
      await axios.get('../../../../../database/clients.data.json').then(resp => { clientList = resp.data.clients }).catch(error => { alert(error) })

      if (!communications!.some(item => item.title === title)) {
        let list: CommunicationModel[] = []
        let listFiles: string[] = []

        for (let file of files) {
          if (file) {
            if (typeof file === 'string') {
              listFiles.push(file)
            } else {
              extractBase64(file).then((doc: any) => { listFiles.push(doc.base) })
            }
          } else {
            listFiles.push('')
          }
        }

        for (let client of clientList!) {
          for (let idClient of clients) {
            if (client.id === idClient) {
              list.push({
                id: `newcommunication${client.id}`,
                files: files,
                title: title,
                message: message,
                created_at: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
                is_read: false,
                client: client!
              })
            }
          }
        }

        response = list

        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/communications`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('title', title)
      data.append('message', message)

      clients.forEach((item) => {
        data.append('clients_id', item)
      })

      files.forEach((item: any) => {
        data.append('documents', item)
      })

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.communications
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putCommunication(id_client: string, id_communication: string, title: string, message: string, files: any[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let communications: CommunicationModel[] | null = null
      let response: CommunicationModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/communications.data.json').then(resp => { communications = resp.data.communications }).catch(err => { alert(err) })
      await axios.get('../../../../../database/communication.data.json').then(resp => { response = resp.data.communication }).catch(error => { alert(error) })

      if (communications!.some(item => item.id === id_communication && item.client.id === id_client)) {
        let list: string[] = []

        for (let file of files) {
          if (file) {
            if (typeof file === 'string') {
              list.push(file)
            } else {
              extractBase64(file).then((doc: any) => { list.push(doc.base) })
            }
          } else {
            list.push('')
          }
        }

        response!.title = title
        response!.message = message
        response!.files = list

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/communications/${id_communication}`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('title', title)
      data.append('message', message)

      files.forEach((item: any) => {
        data.append('documents', item)
      })

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.communication
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteCommunication(id_client: string, id_communication: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let communications: CommunicationModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/communications.data.json').then(resp => { communications = resp.data.communications }).catch(err => { alert(err) })

      if (communications!.some(item => item.id === id_communication && item.client.id === id_client)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/communications/${id_communication}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }
}
