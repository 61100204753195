export function ibanFormat(iban: string): string {
  let value: string = ''

  if (iban.length > 0) {
    let array: string[] = iban.match(/.{1,4}/g) as string[]

    for (let i = 0; i < array.length; i++) {
      if (i < array.length - 1) {
        value += `${array[i]} `
      } else {
        value += `${array[i]}`
      }
    }
  }

  return value
}
