import { Language } from "../languages/context/language"

export function documentGeneralInformationTypeList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'commercial_register_extract', desc: lang.labels.commercialRegisterExtract },
    { code: 'debt_collection_office_extract', desc: lang.labels.debtCollectionOfficeExtract },
    { code: 'auditors_attestation', desc: lang.labels.auditorsAttestation },
    { code: 'finma', desc: lang.labels.finma },
    { code: 'professional_liability_insurance', desc: lang.labels.professionalLiabilityInsurance },
  ]
}

export function documentGeneralInformationTypeValue(code: string, lang: Language): string {
  let element = documentGeneralInformationTypeList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}