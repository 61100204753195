import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { InvestmentModel } from '../../../models/investment.model';
import { InvestmentService } from '../../../services/investment.service';
import { UseOutletContextInvestment } from './Investment';
import { moneyFormat } from '../../../scripts/money.format.script';
import { dateFormat, dateFormatAbbreviation } from '../../../scripts/date.format.script';
import { weightUnitFormat } from '../../../scripts/weight.units.script';
import { IntermediateModel } from '../../../models/intermediate.model';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';

export interface InvestmentViewPageProps {};

let errorResponse: Error, investmentResponse: InvestmentModel;

const InvestmentViewPage: React.FunctionComponent<InvestmentViewPageProps> = props => {
  const {setRoute, goldPrice} = UseOutletContextInvestment()
  const {lang} = StateLanguage()
  const param = useParams()

  const [mounted, setMounted] = useState(false)
  const [investment, setInvestment] = useState<InvestmentModel | undefined | null>(null)

  const loadInvestment = async (id_client: string, id_investment: string) => {
    await InvestmentService.getInvestment(id_client, id_investment).then( (response) => {
      if (response.status === 200) {
        investmentResponse = response.data
        setInvestment(investmentResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  function currentRatingCalculation(weight: number): string {
    return moneyFormat(weight * goldPrice)
  }

  function elapsedTimeCalculation(date: string): string {
    let dateString: string = (date.split(' ').length === 2) ? `${date.replace(' ', 'T')}` : (/GMT/.test(date)) ? `${date.split('GMT')[0].replace(' ', 'T').replace(' ', '')}${date.split('GMT')[1]}` : `${date.split(' ')[0]}T${date.split(' ')[1]}`
    let startDate = new Date(dateString)
    let currentDate = new Date()
    let difference = Math.abs(currentDate.getTime() - startDate.getTime())
    let day = 1000 * 60 * 60 * 24

    return Math.floor(difference / day).toLocaleString('fr-FR').replace(/\s/g, "'")
  }

  function remainingTimeCalculation(date: string): string {
    let dateString: string = (date.split(' ').length === 2) ? `${date.replace(' ', 'T')}` : (/GMT/.test(date)) ? `${date.split('GMT')[0].replace(' ', 'T').replace(' ', '')}${date.split('GMT')[1]}` : `${date.split(' ')[0]}T${date.split(' ')[1]}`
    let endDate = new Date(dateString)
    let currentDate = new Date()
    let difference = Math.abs(endDate.getTime() - currentDate.getTime())
    let day = 1000 * 60 * 60 * 24

    return Math.floor(difference / day).toLocaleString('fr-FR').replace(/\s/g, "'")
  }

  function intermediaryCommissionCalculation(intermediates: IntermediateModel[], amount: number): string {
    const total = intermediates.reduce((addition, intermediate) => {
      const year: number = intermediate.annual_commission.years ?? 1
      return addition + (amount * (intermediate.annual_commission.value / 100) * year)
    }, 0)

    return Math.floor(total / intermediates.length).toLocaleString('fr-FR').replace(/\s/g, "'")
  }

  useEffect( () => {
    if(param.id_client && param.id_investment) {
      loadInvestment(param.id_client, param.id_investment)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setRoute({main: false, path: {root: lang.labels.clients, branch: `${lang.labels.clientView} - ${lang.labels.investments} - ${lang.labels.investment} ${investment ? dateFormatAbbreviation(investment.authentic_act_date) : ""}`}, browse: null})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment])

  useEffect( () => {
    setMounted(true)

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { investment
        ?
        <>
          <div className="card card-flush mb-8 border border-gray-300">
            <div className="card-header">
              <div className="card-title">
                <div className="d-flex flex-column py-5">
                { investment.intermediates.map((item, index) => (
                    <label key={index} className="fs-7 text-gray-500">
                      {(item.intermediary.company.name.length > 0) ? item.intermediary.company.name : lang.labels.none} - {item.intermediary.first_name} {item.intermediary.last_name}
                    </label>
                  ))}
                  <h2 className="mt-2">{investment.client.first_name} {investment.client.last_name}</h2>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end">
                <Link to={`/app/investment/update/${investment.client.id}/${investment.id}`} className="btn btn-primary">{lang.labels.editInvestment}</Link>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <h3 className="fw-bold">{lang.labels.information}</h3>
          </div>
          <div className="card card-flush bg-transparent"> 
            <div className="card-body p-0">
              <div className="row d-flex flex-wrap">
                <div className="col mb-5">
                  <div className="d-flex flex-column h-100 p-5 bg-white border rounded-3 border-gray-300">
                    <p className="fw-bolder mb-2 fs-6">{lang.labels.volumeOfGoldPurchased}</p>
                    <label className="fw-bolder fs-2x text-uppercase">{investment.gold_volume_purchased} kg</label>
                  </div>
                </div>
                <div className="col mb-5">
                  <div className="d-flex flex-column h-100 p-5 bg-white border rounded-3 border-gray-300"> 
                    <p className="fw-bolder mb-2 fs-6">{lang.labels.originalValuation}</p>
                    <label className="fw-bolder fs-2x text-uppercase">{moneyFormat(investment.original_valuation_gold_purchased)}</label>
                  </div>
                </div>
                <div className="col mb-5">
                  <div className="d-flex flex-column h-100 p-5 bg-white border rounded-3 border-gray-300">
                    <p className="fw-bolder mb-2 fs-6">{lang.labels.currentValuation}</p>
                    <label className="fw-bolder fs-2x text-uppercase">{currentRatingCalculation(investment.gold_volume_purchased)}</label>
                  </div>
                </div>
                <div className="col mb-5">
                  <div className="d-flex flex-column h-100 p-5 bg-white border rounded-3 border-gray-300">
                    <p className="fw-bolder mb-2 fs-6">{lang.labels.elapsedTime}</p>
                    <label className="fw-bolder fs-2x text-uppercase">{elapsedTimeCalculation(investment.authentic_act_date)} {lang.labels.days}</label>
                  </div>
                </div>
                <div className="col mb-5">
                  <div className="d-flex flex-column h-100 p-5 bg-white border rounded-3 border-gray-300">
                    <p className="fw-bolder mb-2 fs-6">{lang.labels.remainingTime}</p>
                    <label className="fw-bolder fs-2x text-uppercase">{remainingTimeCalculation(investment.retraction_expiration_date)} {lang.labels.days}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5 border border-gray-300">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.goldPriceLockDate}</span>
                  <span className="fs-6">{dateFormat(investment.gold_price_date, lang.codeLanguage)}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.dateOfTheAuthenticAct}</span>
                  <span className="fs-6">{dateFormat(investment.authentic_act_date, lang.codeLanguage)}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.expirationOfTheRightOfRefusal}</span>
                  <span className="fs-6">{dateFormat(investment.retraction_expiration_date, lang.codeLanguage)}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.elapsedTime} ({lang.labels.days})</span>
                  <span className="fs-6">{elapsedTimeCalculation(investment.authentic_act_date)}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.remainingTime} ({lang.labels.days})</span>
                  <span className="fs-6">{remainingTimeCalculation(investment.retraction_expiration_date)}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.volumeOfGoldPurchased}</span>
                  <span className="fs-6">{Math.floor(investment.gold_volume_purchased).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.originalPriceOfGold}</span>
                  <span className="fs-6">{Math.floor(investment.original_price_gold).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.originalValuationOfGoldPurchased}</span>
                  <span className="fs-6">{Math.floor(investment.original_valuation_gold_purchased).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.goldFuturesPurchasePrice}</span>
                  <span className="fs-6">{Math.floor(investment.gold_purchase_price).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.profitMarginForTheCustomer}</span>
                  <span className="fs-6">{Math.floor(investment.gold_purchase_price - investment.original_valuation_gold_purchased).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.futuresSellingPriceOfGold}</span>
                  <span className="fs-6">{Math.floor(investment.gold_sale_price).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.profitMargin}</span>
                  <span className="fs-6">{Math.floor(investment.gold_sale_price-investment.gold_purchase_price).toLocaleString('fr-FR').replace(/\s/g, "'")}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.intermediaryCommission}</span>
                  <span className="fs-6">{intermediaryCommissionCalculation(investment.intermediates, investment.original_valuation_gold_purchased)}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.securityCompany}</span>
                  <span className="fs-6">{investment.safe_box.company_name}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.safeNumber}</span>
                  <span className="fs-6">{investment.safe_box.safe_box_number}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted  fs-6">{lang.labels.notary}</span>
                  <span className="fs-6">{investment.notary.first_name} {investment.notary.last_name}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush bg-transparent"> 
            <div className="card-body p-0">
              <div className="row d-flex flex-wrap">
              <div className="col-12 mb-5">
                <div className="d-flex flex-column h-100 p-5 bg-white border rounded-3 border-gray-300">
                  <p className="fw-bolder mb-2 fs-6">{lang.labels.valueOfAllocations}</p>
                  <span className="fw-bolder fs-2x text-uppercase">{moneyFormat(investment.ingots.reduce((acc, item) => acc + item.original_value, 0))}</span>
                </div>
              </div>
              </div>
            </div>
          </div>
          { investment.ingots.length > 0 &&
            <>
              <div className="mb-3">
                <h3 className="fw-bold">{lang.labels.allocations}</h3>
              </div>
              <div className="card card-flush mb-5">
                <div className="card-body p-0">
                  <div className="dataTables_wrapper">
                    <div className="table-responsive border border-2 rounded">
                      <table className="table align-middle table-row-bordered fs-6 g-5 mb-0">
                        <tbody>
                          { investment.ingots.map((item, index) => (
                            <tr key={index} className="bg-hover-light">
                              <td className="min-w-300px fw-bold">
                                <Link to={"/app/ingot/view/" + item.id} className="d-flex align-items-center">
                                  <div className="d-flex flex-column">
                                    <span className="fs-7 text-gray-800 text-uppercase">{item.serial_number}</span>
                                    <span className="fs-6 text-gray-900">{moneyFormat(item.original_value)}</span>
                                    <span className="fs-7 text-gray-700">{item.refiner.name} {item.weight.value} {weightUnitFormat(item.weight.unity, lang)} {item.purity.value}</span>
                                  </div>
                                </Link>
                              </td>
                              <td className="w-175px text-end">
                                <div className="justify-content-end d-block">
                                  <Link to={"/app/ingot/update/" + item.id} className="btn btn-sm btn-light-primary me-5">
                                    {lang.labels.edit}
                                  </Link>
                                  <img className="w-20px" src={arrowNext} alt='Ir'/>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          { investment.payment_plan.length > 0 &&
            <>
              <div className="mb-3">
                <h3 className="fw-bold">{lang.labels.paymentPlan}</h3>
              </div>
              <div className="card card-flush bg-transparent"> 
                <div className="card-body p-0">
                  <div className="row d-flex flex-wrap">
                  { investment.payment_plan && investment.payment_plan.map((item, index) => (
                    <div key={index} className="col-12 col-md-6 col-xl-4 mb-5">
                      <div className="d-flex flex-row flex-md-column justify-content-between border-bottom border-md-0">
                        <span className="fs-5 fw-bolder text-muted">{lang.labels.dueDate} Nº {index + 1}</span>
                        <span className="fs-6"><b>{lang.labels.date}:</b> {dateFormat(item.date, lang.codeLanguage)}</span>
                        <span className="fs-6"><b>{lang.labels.amount}:</b> {moneyFormat(item.amount)}</span>
                      </div>
                    </div> 
                    ))}
                  </div>
                </div>
              </div>
            </>
          }
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }    
    </div>
  )
};

export default InvestmentViewPage;
