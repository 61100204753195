import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { UserModel } from '../models/user.model';
import { appToken } from '../scripts/app.token.script';
import { authUserLoggedIn } from '../scripts/auth.user.script';
import { extractBase64 } from '../scripts/extract.base.64.script';
import { Message } from '../models/message.model';

export class UserService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getUsers(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        users: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/users.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/users`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getUser(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: UserModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/user.data.json').then(resp => { response = resp.data.user }).catch(err => { alert(err) })

      if (authUserLoggedIn()) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/user/profile`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.user
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postUser(avatar: any, first_name: string, last_name: string, email: string, phone: string, role: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: UserModel[] | null = null

      let response: UserModel | null = {
        id: "newuser",
        avatar: (avatar && typeof avatar === 'string') ? avatar : '',
        first_name: first_name,
        last_name: last_name,
        email: email,
        role: role,
        phone: phone,
        password: ""
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/users.data.json').then(resp => { users = resp.data.users }).catch(err => { alert(err) })

      if (!users!.some(item => item.id === response!.id)) {
        if (avatar) { extractBase64(avatar).then((doc: any) => {response!.avatar = doc.base}) } else { response!.avatar = response.avatar }

        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/users`
      let header: any = { headers: { Authorization: appToken() } }
      let data: FormData = new FormData()

      data.append("avatar", avatar)
      data.append("first_name", first_name)
      data.append("last_name", last_name)
      data.append("phone", phone)
      data.append("email", email)
      data.append("role", role)

      await axios.post(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.user
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    
      return this.apiResponse
    }
  }

  public static async putUser(id_user: string, avatar: any, first_name: string, last_name: string, email: string, phone: string, role: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: UserModel[] | null = null

      let response: UserModel | null = {
        id: id_user,
        avatar: (avatar && typeof avatar === 'string') ? avatar : '',
        first_name: first_name,
        last_name: last_name,
        role: role,
        email: email,
        phone: phone,
        password: ""
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/users.data.json').then(resp => { users = resp.data.users }).catch(err => { alert(err) })

      if (users!.some(item => item.id === response!.id)) {
        if (avatar) { extractBase64(avatar).then((doc: any) => {response!.avatar = doc.base}) } else { response!.avatar = response.avatar }

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/users/${id_user}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: FormData = new FormData()

      data.append("avatar", avatar)
      data.append("first_name", first_name)
      data.append("last_name", last_name)
      data.append("phone", phone)
      data.append("email", email)
      data.append("role", role)

      await axios.put(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.user
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    
      return this.apiResponse
    }
  }

  public static async deleteUser(id_user: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let users: UserModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/users.data.json').then(resp => { users = resp.data.users }).catch(err => { alert(err) })

      if (users!.some(item => item.id === id_user)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/users/${id_user}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
    
      return this.apiResponse
    }
  }
}
