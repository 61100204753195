import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextIngot } from './Ingot';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../../types/pagination.type';
import { IngotModel } from '../../../models/ingot.model';
import { IngotService } from '../../../services/ingot.service';
import { imageIngot } from '../../../scripts/image.object.script';
import { Message } from '../../../models/message.model';
import { weightUnitFormat } from '../../../scripts/weight.units.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';

export interface IngotListPageProps {};

let errorResponse: Error, messageResponse: Message, ingotsResponse: any;

const IngotListPage: React.FunctionComponent<IngotListPageProps> = props => {
  const {setRoute} = UseOutletContextIngot()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [ingots, setIngots] = useState<IngotModel[] | undefined | null>(null)
  const [search, setSearch] = useState({text: {value: '', valid: false}, list: [] as IngotModel[]})

  const loadIngots = async () => {
    let totalPages: number = 1
    let totalByPage: number = 10
    let listPagesOfPagination: number[] = []

    await IngotService.getIngots(false).then( (response) => {
      if (response.status === 200) {
        ingotsResponse = response.data        

        if (ingotsResponse.ingots.length % totalByPage === 0) {
          totalPages = Math.floor(ingotsResponse.ingots.length / totalByPage)
        } else {
          totalPages = Math.floor(ingotsResponse.ingots.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPagination.push(i)
        }

        setIngots(ingotsResponse.ingots)
        setSearch({...search, list: ingotsResponse.ingots})
        setPagination({...pagination, current: 1, total: totalByPage, pages: listPagesOfPagination, limits: {min: 0, max: totalByPage}})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitIngotDelete = async (item: string) => {
    if (ingots) {
      Swal.fire({
        title: lang.labels.areYouSureYouWanToDeleteThisIngot,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
            await IngotService.deleteIngot(item).then( (response) => {
              if (response.status === 200) {
                messageResponse = response.data

                let list = ingots.filter((ingot) => (ingot.id !== item))
                let listSearch = search.list.filter((ingot) => (ingot.id !== item))

                setIngots(list)
                setSearch({...search, list: listSearch})

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.codeLanguage),
                  text: lang.labels.updatingIngotListReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions)
              } else {
                errorResponse = response.data

                Swal.fire({
                  title: getError(errorResponse.code, lang.codeLanguage),
                  text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                  icon: 'error',
                  buttonsStyling: !1,
                  confirmButtonText: lang.labels.OkGotIt,
                  customClass: { confirmButton:'h-100 btn btn-primary' }
                } as SweetAlertOptions)
              }
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          }
      })
    }
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let validity: boolean = false
    let list: IngotModel[] = []
    let totalPages: number = 10
    let listPagesOfPagination: number[] = []

    if (ingots && text.length > 0) {
      for (let communication of ingots) {
        let refiner: string = communication.refiner.name.toLowerCase()
        let serie: string = communication.serial_number.toLocaleLowerCase()
        if (refiner.indexOf(text.toLowerCase()) !== -1 || serie.indexOf(text.toLowerCase()) !== -1) {
          list.push(communication)
        }
      }
      validity = true
    } else if (ingots! && text.length === 0) {
      validity = false
      list = ingots
    }

    if (list.length % pagination.total === 0) {
      totalPages = Math.floor(list.length / pagination.total)
    } else {
      totalPages = Math.floor(list.length / pagination.total) + 1
    }

    for (let i = 1; i <= totalPages; i++) {
      listPagesOfPagination.push(i)
    }

    setSearch({...search, text: { value: text, valid: validity}, list: list})
    setPagination({...pagination, current: 1, total: pagination.total, pages: listPagesOfPagination, limits: {min: 0, max: pagination.total}})
  }

  const executePaginationSelect = (item: number) => {
    let minLimitNew: number = pagination.total * (item - 1)
    let maxLimitNew: number = pagination.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-button-previous')
    } else {
      pageLimitEnabled('pagination-button-previous')
    }

    if (item === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }else {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(item, elements)
    setPagination({...pagination, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationPrevious = () => {
    let currentNew: number = pagination.current - 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-button-previous')
    }

    if (currentNew < pagination.pages.length) {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationNext = () => {
    let currentNew: number = pagination.current + 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-button-previous')
    }

    if (currentNew === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  function rowClass(index: number): string {
    if (pagination.limits.min <= index && index < pagination.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.ingots, branch: lang.labels.listOfIngots}, browse: {target: lang.labels.addIngot, link: "/app/ingot/create"}})
    loadIngots()

    return () => setMounted(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { ingots 
        ?
        <div className="card">
          <div className="card-header border-0">
            <div className="card-toolbar flex-row-fluid justify-content-end">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px w-md-500px ps-14" type="text" placeholder={`${lang.labels.search}...`} maxLength={200} onChange={handleChangeSearch} />
              </div>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed gy-5">
                  <thead>
                    <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase">
                      <th className="min-w-300px sorting">{lang.labels.ingots}</th>
                      <th className="w-275px sorting_disabled text-end">{lang.labels.actions}</th>
                    </tr>
                  </thead>
                  { search.list.length > 0
                    ?
                    <tbody className="text-gray-600 fs-6 fw-bold">
                      { search.list.map (( (item, index) => { return (
                        <tr key={index} className={`row-dashed bg-hover-light ${rowClass(index)}`} onClick={()=>{navigate("/app/ingot/view/" + item.id)}}>
                          <td className="d-flex align-items-center ps-3">
                            <span className="symbol symbol-55px overflow-hidden me-5">
                              <img src={imageIngot(item.photo_ingot)} alt="photography"/>
                            </span>
                            <div className="d-flex flex-column">
                              <span className="fs-7 text-uppercase">{item.serial_number}</span>
                              <span className="fs-6 text-gray-900">{item.refiner.name} {item.weight.value} {weightUnitFormat(item.weight.unity, lang)} {item.purity.value}</span>
                              <span className="fs-7 text-gray-700">{item.available ? lang.labels.available : lang.labels.noAvailable}</span>
                            </div>
                          </td>
                          <td className="text-end pe-1">
                            <Link to={"/app/ingot/update/" + item.id} className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation()}}>{lang.labels.edit}</Link>
                            <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation(); submitIngotDelete(item.id);}}>{lang.labels.delete}</button>
                            <img className="w-20px" src={arrowNext} alt='Go'/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              { search.list.length > 0 && pagination.pages.length > 1 &&
                <div className="row">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers">
                      <ul className="pagination">
                        <li id="pagination-button-previous" className="paginate_button page-item previous disabled">
                          <button className="page-link" type="button" onClick={executePaginationPrevious}>
                            <i className="previous"></i>
                          </button>
                        </li>
                        { pagination.pages.map (( (item, index) => { return (
                          <li key={index} id={"pagination-button-" + item} className={`paginate_button page-item ${item === pagination.current && 'active' }`}>
                            <button className="page-link" type="button" onClick={() => executePaginationSelect(item)}>{item}</button>
                          </li>
                        )}))}
                        <li id="pagination-button-next" className="paginate_button page-item next">
                          <button className="page-link" type="button" onClick={executePaginationNext}>
                            <i className="next"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default IngotListPage;
