import { countries } from '../languages/context/countries';

const codeEnMap: { [key: string]: string } = {}
const codeEsMap: { [key: string]: string } = {}
const codeFrMap: { [key: string]: string } = {}
const nameEnMap: { [key: string]: string } = {}
const nameEsMap: { [key: string]: string } = {}
const nameFrMap: { [key: string]: string } = {}


const mapCodeAndName = (country: any) => {
  codeEnMap[country.code] = country.en
  codeEsMap[country.code] = country.es
  codeFrMap[country.code] = country.fr
  nameEnMap[country.en] = country.code
  nameEsMap[country.es] = country.code
  nameFrMap[country.fr] = country.code
}

countries.forEach(mapCodeAndName)

export function getNames(code_language: string): string[] {
  return countries.map((country: any) => {
    switch (code_language) {
      case 'ES':
        return country.es;
      case 'FR':
        return country.fr;
      default:
        return country.en;
    }
  }).sort((a, b) => {
    return a.localeCompare(b, undefined, { sensitivity: "accent" });
  });
}

export function getCode(name_en: string, code_language: string): string {
  switch (code_language) {
    case 'ES':
      return nameEsMap[name_en];
    case 'FR':
      return nameFrMap[name_en];
    default:
      return nameEnMap[name_en];
  }
}

export function getName(code: string, code_language: string): string {
  switch (code_language) {
    case 'ES':
      return codeEsMap[code];
    case 'FR':
      return codeFrMap[code];
    default:
      return codeEnMap[code];
  }
}