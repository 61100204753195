import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getNames, getCode, getName } from '../../../scripts/countries.script'
import { format } from 'date-fns';

import { UseOutletContextClient } from './Client';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { ClientService } from '../../../services/client.service';
import { Error } from '../../../models/error.model';
import { inputValid, inputInvalid, restartInput } from '../../../types/legend.input.type';
import { civilStatusList, civilStatusValue} from '../../../scripts/civil.status.script';
import { pensionPerYearList, pensionPerYearValue } from '../../../scripts/pension.per.year.script';
import { fortuneList, fortuneValue } from '../../../scripts/fortune.script';
import { Message } from '../../../models/message.model';
import { modalShow } from '../../../types/modal.type';
import { HeritageOriginModel } from '../../../models/heritage.origin.model';
import AppModalSpouseAdd from '../../../components/modal/ModalSpouseAdd';
import { SpouseModel } from '../../../models/spouse.model';
import { expressions } from '../../../scripts/regular.expressions.script';

interface ClientCreateProps {};

let errorResponse: Error, serviceResponse: Message;

const ClientCreatePage: React.FunctionComponent<ClientCreateProps> = () => {
  const { setRoute, heritages } = UseOutletContextClient()
  const { lang } = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: true})
  const [phone, setPhone] = useState({value: '', valid: true})
  const [placeOfBirth, setPlaceOfBirth] = useState({value: '', valid: true})
  const [street, setStreet] = useState({value: '', valid: true})
  const [city, setCity] = useState({value: '', valid: true})
  const [iban, setIban] = useState({value: '', valid: true})
  const [bic, setBic] = useState({value: '', valid: true})
  const [sourceOfFunds, setSourceOfFunds] = useState({value: '', valid: true})
  const [pep, setPep] = useState({value: false, valid: true})
  const [taxpayer, setTaxpayer] = useState({value: '', valid: true})
  const [fieldOfActivity, setFieldOfActivity] = useState({value: '', valid: true})
  const [civilStatus, setCivilStatus] = useState({value: '', valid: true})
  const [pensionPerYear, setPensionPerYear] = useState({value: '', valid: true})
  const [fortune, setFortune] = useState({value: '', valid: true})
  const [nationality, setNationality] = useState({value: '', valid: true})
  const [countryEmployer, setCountryEmployer] = useState({value: '', valid: true})
  const [professionalActivity, setProfessionalActivity] = useState({value: '', valid: true})
  const [previousProfessionalActivity, setPreviousProfessionalActivity] = useState({value: '', valid: true})
  const [previousEmployer, setPreviousEmployer] = useState({value: '', valid: true})
  const [diploma, setDiploma] = useState({value: '', valid: true})
  const [member, setMember] = useState({value: false, valid: true})
  const [fatherName, setFatherName] = useState({value: '', valid: true})
  const [motherName, setMotherName] = useState({value: '', valid: true})
  const [dateOfBirth, setDateOfBirth] = useState({value: '', valid: true})
  const [dateOfBackground, setDateOfBackground] = useState({value: '', valid: true})
  const [country, setCountry] = useState({value: '', valid: true})
  const [currentEmployer, setCurrentEmployer] = useState({value: '', valid: true})
  const [zipCode, setZipCode] = useState({value: '', valid: true})
  const [testClient, setTestClient] = useState({value: false, valid: true})
  const [heritageOrigins, setHeritageOrigins] = useState({value: [] as string[], object: [] as HeritageOriginModel[], valid: [] as boolean[]})
  const [spouse, setSpouse] = useState<SpouseModel | undefined | null>(null)
  const [clientData, setClientData] = useState({email:'', phone: '', placeOfBirth: '', street: '', city: '', iban: '', bic: '', sourceOfFunds: '', pep: false, taxpayer: '', fieldOfActivity: '', pensionPerYear: '', fortune: '', nationality: '', countryEmployer: '', professionalActivity: '', previousProfessionalActivity: '', previousEmployer: '', diploma: '', member: false, fatherName: '', motherName: '', dateOfBirth: '', dateOfBackground: '', country: '', currentEmployer: '', zipCode: '', heritageOrigins: [] as HeritageOriginModel[]})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitClientCreate = async () => {
    setLoadIndicator('on')

    if (lastName.valid && firstName.valid && email.valid && phone.valid && dateOfBirth.valid && placeOfBirth.valid && street.valid && city.valid && country.valid && zipCode.valid && taxpayer.valid && pep.valid && nationality.valid && civilStatus.valid && fatherName.valid && motherName.valid && iban.valid && bic.valid && professionalActivity.valid && dateOfBackground.valid && currentEmployer.valid && countryEmployer.valid && fieldOfActivity.valid && previousProfessionalActivity.valid && previousEmployer.valid && pensionPerYear.valid && fortune.valid && heritageOrigins.valid && sourceOfFunds.valid && diploma.valid && member.valid && testClient.valid) {
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = bic.value.toUpperCase().trim().replaceAll(' ', '')

      await ClientService.postClient(lastName.value, firstName.value, email.value, phone.value, dateOfBirth.value, placeOfBirth.value, street.value, city.value, country.value, zipCode.value, taxpayer.value, pep.value, nationality.value, civilStatus.value, fatherName.value, motherName.value, valueIban, valueBic, professionalActivity.value, dateOfBackground.value, currentEmployer.value, countryEmployer.value, fieldOfActivity.value, previousProfessionalActivity.value, previousEmployer.value, pensionPerYear.value, fortune.value, heritageOrigins.value, sourceOfFunds.value, diploma.value, member.value, testClient.value, spouse).then( (response) => {
        if (response.status === 201) {
          serviceResponse = response.data

          Swal.fire({
            title: getMessage(serviceResponse.message, lang.codeLanguage),
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const onCancel = async () => {
    Swal.fire({
      title: lang.labels.areYouSureYouWantToCancel,
      text: lang.labels.ifYouAcceptYouMayHaveToFillOutTheFormAgain,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: lang.labels.yes,
      cancelButtonText: lang.labels.noCancel,
      customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(-1)
      }
    })
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeFieldOfActivity= (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFieldOfActivity({...fieldOfActivity, value: event.target.value})
  }

  const handleChangeFatherName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFatherName({...fatherName, value: event.target.value})
  }

  const handleChangeMotherName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setMotherName({...motherName, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangePlaceOfBirth = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPlaceOfBirth({...placeOfBirth, value: event.target.value})
  }

  const handleChangeCurrentEmployer = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCurrentEmployer({...currentEmployer, value: event.target.value})
  }

  const handleChangeProfessionalActivity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setProfessionalActivity({...professionalActivity, value: event.target.value})
  }

  const handleChangePreviusProfessionalActivity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPreviousProfessionalActivity({...previousProfessionalActivity, value: event.target.value})
  }

  const handleChangePreviusEmployer = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPreviousEmployer({...previousEmployer, value: event.target.value})
  }

  const handleChangeDiploma = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDiploma({...diploma, value: event.target.value})
  }

  const handleChangeTaxpayer = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTaxpayer({...taxpayer, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setIban({...iban, value: event.target.value})
  }

  const handleChangeBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBic({...bic, value: event.target.value})
  }

  const handleChangeSourceOfFunds = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setSourceOfFunds({...sourceOfFunds, value: event.target.value})
  }

  const handleChangeDateOfBirth = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDateOfBirth({...dateOfBirth, value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleDateOfBackground = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDateOfBackground({...dateOfBackground, value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleChangeMember = (item: boolean) => {
    setMember({...member, value: item, valid: true})
  }

  const handleChangePep = (item: boolean) => {
    setPep({...pep, value: item, valid: true})
  }

  const handleChangeCivilStatus = (item: string) => {
    setCivilStatus({...civilStatus, value: item, valid: true})
  }

  const handleChangePensionPerYear = (item: string) => {
    setPensionPerYear({...pensionPerYear, value: item, valid: true})
  }

  const handleChangeFortune = (item: string) => {
    setFortune({...fortune, value: item, valid: true})
  }

  const handleChangeOriginOfHeritage = (item: HeritageOriginModel) => {
    setHeritageOrigins({value: [...heritageOrigins.value, item.id], object: [...heritageOrigins.object, item], valid: [...heritageOrigins.valid, true]})
  }

  const handleChangeNationality = (item: string) => {
    setNationality({...nationality, value: item, valid: true})
  }

  const handleChangeCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangeCountryEmployer = (item: string) => {
    setCountryEmployer({...countryEmployer, value: item, valid: true})
  }

  const handleChangeTestClient = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTestClient({...testClient, value: event.target.checked})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const handleRemoveHeritage = (index: number) => {
    let tempHeritageOriginsValue = heritageOrigins.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempHeritageOriginsObject = heritageOrigins.object.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempHeritageOriginsValid = heritageOrigins.valid.filter((_temp_item, temp_index) => (temp_index !== index))

    setHeritageOrigins({value: tempHeritageOriginsValue, object: tempHeritageOriginsObject, valid: tempHeritageOriginsValid})
  }

  const validateFirstName = () => {
    if (expressions.text.test(firstName.value)) {
      setFirstName({...firstName, valid: true})
      inputValid('input-first-name')
    } else {
      setFirstName({...firstName, valid: false})
      inputInvalid('input-first-name')
    }
  }

  const validateLastName = () => {
    if (expressions.text.test(lastName.value)) {
      setLastName({...lastName, valid: true})
      inputValid('input-last-name')
    } else {
      setLastName({...lastName, valid: false})
      inputInvalid('input-last-name')
    }
  }

  const validateEmail = () => {
    if (email.value.length > 0) {
      if (expressions.email.test(email.value)) {
        setEmail({...email, valid: true})
        inputValid('input-email')
      } else {
        setEmail({...email, valid: false})
        inputInvalid('input-email')
      }
    } else if (email.value.length === 0) {
      setEmail({...email, valid: true})
      restartInput('input-email')
    }
  }

  const validateZipCode = () => {
    if (zipCode.value.length > 0) {
      if (expressions.zip.test(zipCode.value)) {
        setZipCode({...zipCode, valid: true})
        inputValid('input-zip-code')
      } else {
        setZipCode({...zipCode, valid: false})
        inputInvalid('input-zip-code')
      }
    } else if (zipCode.value.length === 0) {
      setZipCode({...zipCode, valid: true})
      restartInput('input-zip-code')
    }
  }

  const validatePlaceOfBirth = () => {
    if (placeOfBirth.value.length > 0) {
      if (expressions.text.test(placeOfBirth.value)) {
        setPlaceOfBirth({...placeOfBirth, valid: true})
        inputValid('input-place-of-birth')
      } else {
        setPlaceOfBirth({...placeOfBirth, valid: false})
        inputInvalid('input-place-of-birth')
      }
    } else if (placeOfBirth.value.length === 0) {
      setPlaceOfBirth({...placeOfBirth, valid: true})
      restartInput('input-place-of-birth')
    }
  }

  const validateTaxpayer = () => {
    if (taxpayer.value.length > 0) {
      if (expressions.nif.test(taxpayer.value)) {
        setTaxpayer({...taxpayer, valid: true})
        inputValid('input-taxpayer')
      } else {
        setTaxpayer({...taxpayer, valid: false})
        inputInvalid('input-taxpayer')
      }
    } else if (taxpayer.value.length === 0) {
      setTaxpayer({...taxpayer, valid: true})
      restartInput('input-taxpayer')
    }
  }

  const validatePhone = () => {
    if (phone.value.length > 0) {
      if (expressions.phone.test(phone.value)) {
        setPhone({...phone, valid: true})
        inputValid('input-phone')
      } else {
        setPhone({...phone, valid: false})
        inputInvalid('input-phone')
      }
    } else if (phone.value.length === 0) {
      setPhone({...phone, valid: true})
      restartInput('input-phone')
    }
  }

  const validateFatherName = () => {
    if (fatherName.value.length > 0) {
      if (expressions.text.test(fatherName.value)) {
        setFatherName({...fatherName, valid: true})
        inputValid('input-father-name')
      } else {
        setFatherName({...fatherName, valid: false})
        inputInvalid('input-father-name')
      }
    } else if (fatherName.value.length === 0) {
      setFatherName({...fatherName, valid: true})
      restartInput('input-father-name')
    }
  }

  const validateMotherName = () => {
    if (motherName.value.length > 0) {
      if (expressions.text.test(motherName.value)) {
        setMotherName({...motherName, valid: true})
        inputValid('input-mother-name')
      } else {
        setMotherName({...motherName, valid: false})
        inputInvalid('input-mother-name')
      }
    } else if (motherName.value.length === 0) {
      setMotherName({...motherName, valid: true})
      restartInput('input-mother-name')
    }
  }

  const validateCurrentEmployer = () => {
    if (currentEmployer.value.length > 0) {
      if (expressions.text.test(currentEmployer.value)) {
        setCurrentEmployer({...currentEmployer, valid: true})
        inputValid('input-current-employer')
      } else {
        setCurrentEmployer({...currentEmployer, valid: false})
        inputInvalid('input-current-employer')
      }
    } else if (currentEmployer.value.length === 0) {
      setCurrentEmployer({...currentEmployer, valid: true})
      restartInput('input-current-employer')
    }
  }

  const validateFieldOfActivity = () => {
    if (fieldOfActivity.value.length > 0) {
      if (expressions.text.test(fieldOfActivity.value)) {
        setFieldOfActivity({...fieldOfActivity, valid: true})
        inputValid('input-field-of-activity')
      } else {
        setFieldOfActivity({...fieldOfActivity, valid: false})
        inputInvalid('input-field-of-activity')
      }
    } else if (fieldOfActivity.value.length === 0) {
      setFieldOfActivity({...fieldOfActivity, valid: true})
      restartInput('input-field-of-activity')
    }
  }

  const validateStreet = () => {
    if (street.value.length > 0) {
      if (expressions.street.test(street.value)) {
        setStreet({...street, valid: true})
        inputValid('input-street')
      } else {
        setStreet({...street, valid: false})
        inputInvalid('input-street')
      }
    } else if (street.value.length === 0) {
      setStreet({...street, valid: true})
      restartInput('input-street')
    }
  }

  const validateProfessionalActivity = () => {
    if (professionalActivity.value.length > 0) {
      if (expressions.text.test(professionalActivity.value)) {
        setProfessionalActivity({...professionalActivity, valid: true})
        inputValid('input-professional-activity')
      } else {
        setProfessionalActivity({...professionalActivity, valid: false})
        inputInvalid('input-professional-activity')
      }
    } else if (professionalActivity.value.length === 0) {
      setProfessionalActivity({...professionalActivity, valid: true})
      restartInput('input-professional-activity')
    }
  }

  const validatePreviousProfessionalActivity = () => {
    if (previousProfessionalActivity.value.length > 0) {
      if (expressions.text.test(previousProfessionalActivity.value)) {
        setPreviousProfessionalActivity({...previousProfessionalActivity, valid: true})
        inputValid('input-previous-professional-activity')
      } else {
        setPreviousProfessionalActivity({...previousProfessionalActivity, valid: false})
        inputInvalid('input-previous-professional-activity')
      }
    } else if (previousProfessionalActivity.value.length === 0) {
      setPreviousProfessionalActivity({...previousProfessionalActivity, valid: true})
      restartInput('input-previous-professional-activity')
    }
  }

  const validatePreviousEmployer = () => {
    if (previousEmployer.value.length > 0) {
      if (expressions.text.test(previousEmployer.value)) {
        setPreviousEmployer({...previousEmployer, valid: true})
        inputValid('input-previous-employer')
      } else {
        setPreviousEmployer({...previousEmployer, valid: false})
        inputInvalid('input-previous-employer')
      }
    } else if (previousEmployer.value.length === 0) {
      setPreviousEmployer({...previousEmployer, valid: true})
      restartInput('input-previous-employer')
    }
  }

  const validateDiploma = () => {
    if (diploma.value.length > 0) {
      if (expressions.text.test(diploma.value)) {
        setDiploma({...diploma, valid: true})
        inputValid('input-diploma')
      } else {
        setDiploma({...diploma, valid: false})
        inputInvalid('input-diploma')
      }
    } else if (diploma.value.length === 0) {
      setDiploma({...diploma, valid: true})
      restartInput('input-diploma')
    }
  }

  const validateCity = () => {
    if (city.value.length > 0) {
      if (expressions.location.test(city.value)) {
        setCity({...city, valid: true})
        inputValid('input-city')
      } else {
        setCity({...city, valid: false})
        inputInvalid('input-city')
      }
    } else if (city.value.length === 0) {
      setCity({...city, valid: true})
      restartInput('input-city')
    }
  }

  const validateIban = () => {
    if (iban.value.length > 0) {
      if (expressions.iban.test(iban.value)) {
        setIban({...iban, valid: true})
        inputValid('input-iban')
      } else {
        setIban({...iban, valid: false})
        inputInvalid('input-iban')
      }
    } else if (iban.value.length === 0) {
      setIban({...iban, valid: true})
      restartInput('input-iban')
    }
  }

  const validateBic = () => {
    if (bic.value.length > 0) {
      if (expressions.swiftBic.test(bic.value)) {
        setBic({...bic, valid: true})
        inputValid('input-bic')
      } else {
        setBic({...bic, valid: false})
        inputInvalid('input-bic')
      }
    } else if (bic.value.length === 0) {
      setBic({...bic, valid: true})
      restartInput('input-bic')
    }
  }

  const validateSourceOfFunds = () => {
    if (expressions.textsize.test(sourceOfFunds.value)) {
      setSourceOfFunds({...sourceOfFunds, valid: true})
    } else {
      setSourceOfFunds({...sourceOfFunds, valid: false})
    }
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  const executeAddSpouse = async () => {
    setClientData({email: email.value, phone: phone.value, placeOfBirth: placeOfBirth.value, street: street.value, city: city.value, iban: iban.value, bic: bic.value, sourceOfFunds: sourceOfFunds.value, pep: pep.value, taxpayer: taxpayer.value, fieldOfActivity: fieldOfActivity.value, pensionPerYear: pensionPerYear.value, fortune: fortune.value, nationality: nationality.value, countryEmployer: countryEmployer.value, professionalActivity: professionalActivity.value, previousProfessionalActivity: previousProfessionalActivity.value, previousEmployer: previousEmployer.value, diploma: diploma.value, member: member.value, fatherName: fatherName.value, motherName: motherName.value, dateOfBirth: dateOfBirth.value, dateOfBackground: dateOfBackground.value, country: country.value, currentEmployer: currentEmployer.value, zipCode: zipCode.value, heritageOrigins: heritageOrigins.object})
    modalShow('modal-spouse-add')
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.clients, branch: lang.labels.createClient}, browse: null})

    return () => setMounted(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { heritages 
        ?
        <div className="form">
          <div className="card card-flush mb-5">
            <div className="card-header">
              <div className="card-title">
                <h3 className="text-uppercase">{lang.labels.pleaseEnterTheFollowingInformationToCreateNewClient}</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              <p className="fst-italic text-muted mb-5">
                {lang.labels.fieldsMarkedWithAnAsterisk}<span className="text-danger">(*)</span>{lang.labels.areRequired}
              </p>
              <h5 className="mb-5">{lang.labels.clientInformation}</h5>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label required fw-bold fs-6">{lang.labels.lastName}</label>
                  <input id="input-last-name" className="form-control form-control-solid" type="text" name="last-name" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label required fw-bold fs-6">{lang.labels.firstName}</label>
                  <input id="input-first-name" className="form-control form-control-solid" type="text" name="first-name" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.email}</label>
                  <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.phone}</label>
                  <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.maritalStatus}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {civilStatus.valid && civilStatus.value.length > 0 ? civilStatusValue(civilStatus.value, lang) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { civilStatusList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === civilStatus.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === civilStatus.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCivilStatus(item.code)}>{item.desc}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.nationality}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {nationality.valid && nationality.value.length > 0 ? getName(nationality.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === nationality.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === nationality.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeNationality(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.dateOfBirth}</label>
                  <input className="form-control form-control-solid pe-2" type="date" name="dateOfBirth" max={maxDate()} value={dateOfBirth.value} onChange={handleChangeDateOfBirth} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.placeOfBirth}</label>
                  <input id="input-place-of-birth" className="form-control form-control-solid" type="text" name="place-of-birth" value={placeOfBirth.value} onChange={handleChangePlaceOfBirth} onKeyUp={validatePlaceOfBirth} onBlur={validatePlaceOfBirth} />
                </div>
              </div>
              <div className="row mb-10">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.firstNameAndLastNameFather}</label>
                  <input id="input-father-name" className="form-control form-control-solid" type="text" name="father-name" value={fatherName.value} onChange={handleChangeFatherName} onKeyUp={validateFatherName} onBlur={validateFatherName} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.maidenNameMother}</label>
                  <input id="input-mother-name" className="form-control form-control-solid" type="text" name="motherName" value={motherName.value} onChange={handleChangeMotherName} onKeyUp={validateMotherName} onBlur={validateMotherName} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button className="btn btn-secondary" onClick={executeAddSpouse}>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                          <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                          <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                        </svg>
                      </span>
                      {lang.labels.addSpouse}
                    </div>
                  </button>
                </div>
                <div className="col d-flex justify-content-end">
                  <div className="form-check form-switch form-check-custom form-check-solid">
                    <label className="col-form-label fw-bold fs-5 me-5">{lang.labels.testClient}</label>
                    <input className="form-check-input w-55px h-30px" type="checkbox" checked={testClient.value} onChange={handleChangeTestClient}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5">
            <div className="card-body">
              <h5 className="mb-5">{lang.labels.extraInformation}</h5>
              <div className="row fv-row">
                <div className="mb-5 col-12">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.pepClose}</label>
                  <div className="fv-row d-flex overflow-hidden my-3">
                    <div className="d-flex align-items-center cursor-pointer me-5">
                      <label className="fw-bolder fs-6 me-2">{lang.labels.yes}</label>
                      <span className="form-check form-check-custom form-check-solid">
                        <input className="form-check-input" type="radio" name="pep" checked={pep.value} onChange={() => handleChangePep(true)} />
                      </span>
                    </div>
                    <div className="d-flex align-items-center cursor-pointer">
                      <label className="fw-bolder fs-6 me-2">{lang.labels.no}</label>
                      <span className="form-check form-check-custom form-check-solid">
                        <input className="form-check-input" type="radio" name="pep" checked={!pep.value} onChange={() => handleChangePep(false)} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-5 col-12">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.taxpayerNumber}</label>
                  <input id="input-taxpayer" className="form-control form-control-solid" type="text" name="taxpayer" value={taxpayer.value} onChange={handleChangeTaxpayer} onKeyUp={validateTaxpayer} onBlur={validateTaxpayer} />
                </div>
                <div className="col-12">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.diploma}</label>
                  <input id="input-diploma" className="form-control form-control-solid" type="text" name="diploma" value={diploma.value} onChange={handleChangeDiploma} onKeyUp={validateDiploma} onBlur={validateDiploma} />
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5">
            <div className="card-body">
              <h5 className="mb-5">{lang.labels.residentialAddress}</h5>
              <div className="row fv-row">
                <div className="mb-5 col-12">
                  <label className="col-form-label fs-6">{lang.labels.street}</label>
                  <input id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                </div>
                <div className="row mb-5">
                  <div className="mb-5 mb-xl-0 col-xl-9">
                    <label className="col-form-label fs-6">{lang.labels.city}</label>
                    <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                  </div>
                  <div className="col-xl-3">
                    <label className="col-form-label fs-6">{lang.labels.postcode}</label>
                    <input id="input-zip-code" className="form-control form-control-solid" type="text" name="zipCode" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                  </div>
                </div>
                <div className="col-12">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.country}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {country.valid && country.value.length > 0 ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === country.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5">
            <div className="card-body">
              <h5 className="mb-5">{lang.labels.bankInformation}</h5>
              <div className="row fv-row">
                <div className="mb-5 col-12">
                  <label className="col-form-label fs-6">{lang.labels.iban}</label>
                  <input id="input-iban" className="form-control form-control-solid" type="text" name="iban" value={iban.value} onChange={handleChangeIban} onKeyUp={validateIban} onBlur={validateIban} />
                </div>
                <div className="col-12">
                  <label className="col-form-label fs-6">{lang.labels.bicSwift}</label>
                  <input id="input-bic" className="form-control form-control-solid" type="text" name="bic" value={bic.value} onChange={handleChangeBic} onKeyUp={validateBic} onBlur={validateBic} />
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5">
            <div className="card-body">
              <h5 className="mb-5">{lang.labels.professionalActivityInformation}</h5>
              <div className="row mb-5">
                <div className="col-12">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.fieldOfActivity}</label>
                  <input id="input-field-of-activity" className="form-control form-control-solid" type="text" name="FieldOfActivity" value={fieldOfActivity.value} onChange={handleChangeFieldOfActivity} onKeyUp={validateFieldOfActivity} onBlur={validateFieldOfActivity} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.professionalActivity}</label>
                  <input id="input-professional-activity" className="form-control form-control-solid" type="text" name="professional-activity" value={professionalActivity.value} onChange={handleChangeProfessionalActivity} onKeyUp={validateProfessionalActivity} onBlur={validateProfessionalActivity} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.currentEmployer}</label>
                  <input id="input-current-employer" className="form-control form-control-solid" type="text" name="currentEmployer" value={currentEmployer.value} onChange={handleChangeCurrentEmployer} onKeyUp={validateCurrentEmployer} onBlur={validateCurrentEmployer} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.startDateOfBackgroundOn}</label>
                  <input className="form-control form-control-solid pe-2" type="date" name="dateOfBackground" max={maxDate()} value={dateOfBackground.value} onChange={handleDateOfBackground} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.countryOfCurrentEmployer}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {countryEmployer.valid && countryEmployer.value.length > 0 ? getName(countryEmployer.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="client" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === countryEmployer.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === countryEmployer.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountryEmployer(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.previousProfessionalActivity}</label>
                  <input id="input-previous-professional-activity" className="form-control form-control-solid" type="text" name="previousProfessionalActivity" value={previousProfessionalActivity.value} onChange={handleChangePreviusProfessionalActivity} onKeyUp={validatePreviousProfessionalActivity} onBlur={validatePreviousProfessionalActivity} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.previousEmployer}</label>
                  <input id="input-previous-employer" className="form-control form-control-solid" type="text" name="previousEmployer" value={previousEmployer.value} onChange={handleChangePreviusEmployer} onKeyUp={validatePreviousEmployer} onBlur={validatePreviousEmployer} />
                </div>
              </div>
              <div className="row">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.incomeOrPensionReceivedForAnYear}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {pensionPerYear.valid && pensionPerYear.value.length > 0 ? pensionPerYearValue(lang, pensionPerYear.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { pensionPerYearList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === pensionPerYear.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === pensionPerYear.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePensionPerYear(item.code)}>{item.desc}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.memberOfTheBoardOfDirectors}</label>
                  <div className="fv-row d-flex overflow-hidden my-3">
                    <div className="d-flex align-items-center cursor-pointer me-5">
                      <label className="fw-bolder fs-6 me-2">{lang.labels.yes}</label>
                      <span className="form-check form-check-custom form-check-solid">
                        <input className="form-check-input" type="radio" name="member" checked={member.value} onChange={() => handleChangeMember(true)} />
                      </span>
                    </div>
                    <div className="d-flex align-items-center cursor-pointer">
                      <label className="fw-bolder fs-6 me-2">{lang.labels.no}</label>
                      <span className="form-check form-check-custom form-check-solid">
                        <input className="form-check-input" type="radio" name="member" checked={!member.value} onChange={() => handleChangeMember(false)} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5">
            <div className="card-body">
              <h5 className="mb-5">{lang.labels.originAndDeterminationAssetValues}</h5>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.originOfHeritageValues}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { heritages.map((item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${heritageOrigins.value.some(sub_item => sub_item === item.id) && "d-none"}`} role="option" aria-selected={heritageOrigins.value.some(sub_item => sub_item === item.id) ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeOriginOfHeritage(item)}>{item.description}</Dropdown.Item>
                              </li>
                            )})}
                            <li className={`select2-results__option select2-results__option--selectable ${!heritages.every(item => heritageOrigins.value.some(sub_item => sub_item === item.id)) && "d-none"}`} role="option" aria-selected="false">
                              <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.nonEsxistOptions}</Dropdown.Item>
                            </li>
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  { heritageOrigins.value.length > 0 &&
                    <div className="d-print-none card-rounded border border-dashed border-gray-300 bg-gray-100 w-100 mt-3">
                      { heritageOrigins.object.map((item, index) => { return (
                        <label key={index} className="badge badge-secondary d-inline-flex align-items-center m-3">
                          <span className="overflow-hidden d-inline-block me-2">
                            <div className="d-flex align-items-center mw-500px h-20px">
                              <div className="text-truncate">
                                {item.description}
                              </div>
                            </div>
                          </span>
                          <span className="btn btn-icon svg-icon svg-icon-3 h-20px w-20px" onClick={() => handleRemoveHeritage(index)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z" fill="black"/>
                              <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z" fill="black"/>
                            </svg>
                          </span>
                        </label>
                      )})}
                    </div>
                  }
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fw-bold fs-6">{lang.labels.fortuneIncludingRealEstate}</label>
                  <Dropdown>  
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {fortune.valid && fortune.value.length > 0 ? fortuneValue(lang, fortune.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { fortuneList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === fortune.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === fortune.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFortune(item.code)}>{item.desc}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-12">
                  <label className="col-form-label fw-bold fs-6">
                    {lang.labels.sourceOfFunds}
                    <span className="text-muted fst-italic fs-7 ms-1">({lang.labels.maximum1kCharacters})</span>
                  </label>
                  <textarea className="form-control form-control-solid resize-none" rows={8} name="sourceOfFunds" maxLength={1000} autoComplete="off" value={sourceOfFunds.value} onFocus={handleChangeSourceOfFunds} onChange={handleChangeSourceOfFunds} onKeyUp={validateSourceOfFunds} onBlur={validateSourceOfFunds} />
                  <div className={`d-flex justify-content-end ${sourceOfFunds.value.length === 1000 ? "text-danger" : "text-muted"} mt-2 ms-2"`}>{sourceOfFunds.value.length}/1000 {sourceOfFunds.value.length === 1000 && "The character limit has been reached"}</div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush p-3">
            <div className="d-flex justify-content-end">
              <div className="mb-xl-0 col-12 col-md-6  d-flex pe-6 ps-3">
                <button className="btn btn-primary w-100" type="button" data-kt-indicator={loadIndicator} onClick={submitClientCreate}>
                  <span className="indicator-label">{lang.labels.save}</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button className="btn btn-light-primary w-100 ms-3" type="reset" onClick={onCancel}>
                  {lang.labels.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalSpouseAdd clientData={clientData} heritages={heritages} setSpouse={setSpouse}></AppModalSpouseAdd>
    </div>
  )
}

export default ClientCreatePage;
