import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { Dropdown } from 'react-bootstrap';
import { inputFileSizeInvalid, inputFileTypeInvalid, inputFileTypeSizeValid, restartInputFileTypeSize } from '../../types/legend.input.file.type';
import { removeTooltip, uploadTooltip } from '../../types/tooltip.type';
import { extractBase64 } from '../../scripts/extract.base.64.script';
import { FaExclamationCircle } from 'react-icons/fa';
import { cleanInputFile } from '../../types/input.file.type';
import { getCode, getName, getNames } from '../../scripts/countries.script';
import { SecurityService } from '../../services/security.service';
import { CompanySecurityModel } from '../../models/company.security.model';
import { listNameSortedAscending, listNameSortedDescending } from '../../scripts/order.asc.desc.list.script';
import imageDefault from '../../assets/images/default.png';
import imageUpload from '../../assets/images/upload.png';

interface AppModalSecurityCreateProps {
  orderAscDesc: string,
  securityCompanies: CompanySecurityModel[] | undefined | null,
  setSecurityCompanies: Dispatch<SetStateAction<CompanySecurityModel[] | undefined | null>>
};

const AppModalSecurityCreate: React.FunctionComponent<AppModalSecurityCreateProps> = ({orderAscDesc, securityCompanies, setSecurityCompanies}) => {
  const {lang} = StateLanguage()

  const expressions = {
    text: /^[a-zA-ZñÑáéíóúÁÉÍÓÚ]+$/,
    name: /^[a-zA-ZÀ-ÿ\s.'’’-]{1,70}$/,
    phone: /^\+?[0-9]{1,4}[ -]*\(?[0-9]{1,4}\)?[ -]*[0-9\- ]{4,10}$/,
    email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    street: /^[A-Za-zÀ-ÿ0-9'\\.\-\s\\,\\#]{1,200}$/,
    location: /^[a-zA-ZÀ-ÿ]+(?:[\s-][a-zA-ZÀ-ÿ]+)*$/,
    iban: /^([A-Z]{2})\s*(\d{2})(\s*(\d{4})){1,2}\s*(\d{2})?(\s*(\d{4})){1,3}\s*([A-Z0-9]{1,10})$/,
    bic: /^([A-Z]{4}\s?[A-Z]{2}\s?[A-Z0-9]{2}\s?([A-Z0-9]{0,3})?)$/,
    business: /^[A-Za-z0-9]{5,50}$/,
    postcode: /^[A-Za-z0-9]+[-\s]?[A-Za-z0-9]+$|^[A-Za-z]+$|^[0-9]+$/,
    website: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    number: /^\d{1,100}$/,
    companyNumber: /^[A-Za-z0-9\s.,;:()'"?!¿¡\-_/]+$/
  }

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [logo, setLogo] = useState({value: '', file: null as any, valid: true})
  const [name, setName] = useState({value: '', valid: false})
  const [number, setNumber] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [postcode, setPostcode] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: false})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitSecurityCompanyCreate = async () => {
    setLoadIndicator('on')

    if (securityCompanies && name.valid && street.valid && postcode.valid && city.valid && country.valid && phone.valid && email.valid && website.valid && logo.valid) {
      await SecurityService.postSecurityCompany(name.value, number.value, street.value, postcode.value, city.value, country.value, phone.value, email.value, website.value, logo.file, []).then( (response) => {
        if (response.status === 201) {
          let serviceResponse: CompanySecurityModel = response.data
          let list = (orderAscDesc === "asc") ? listNameSortedAscending([...securityCompanies, serviceResponse]) : listNameSortedDescending([...securityCompanies, serviceResponse])

          setSecurityCompanies(list)

          Swal.fire({
            title: lang.labels.successfullyCreatedSecurityCompany,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalSecurityCompanyCreate()
          })
        } else {
          let errorResponse: Error = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576

    if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
      setLogo({...logo, value: '', file: null, valid: true})
      inputFileTypeInvalid('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      cleanInputFile()
    } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
      setLogo({...logo, value: '', file: null, valid: true})
      inputFileSizeInvalid('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      cleanInputFile()
    } else {
      extractBase64(archiveCapture).then((image: any) => {
        setLogo({...logo, value: image.base, file: archiveCapture, valid: true})
        inputFileTypeSizeValid('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      })
    }
  }

  const handleChangeCompanyName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeCompanyBusinessNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNumber({...number, value: event.target.value})
  }

  const handleChangeCompanyAddress = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeCompanyPostcode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPostcode({...postcode, value: event.target.value})
  }

  const handleChangeCompanyCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeCompanyCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangeCompanyPhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeCompanyEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeCompanyWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const handleRemoveLogo = () => {
    setLogo({...logo, value: '', file: null, valid: true})
    restartInputFileTypeSize('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
    removeTooltip()
    cleanInputFile()
  }

  const validateCompanyName = () => {
    if (expressions.name.test(name.value)) {
      setName({...name, valid: true})
      inputValid('modal-user-create-input-company-name')
    } else {
      setName({...name, valid: false})
      inputInvalid('modal-user-create-input-company-name')
    }
  }

  const validateCompanyBusinessNumber = () => {
    if (expressions.companyNumber.test(number.value)) {
      setNumber({...number, valid: true})
      inputValid('modal-user-create-input-company-business-number')
    } else {
      setNumber({...number, valid: false})
      inputInvalid('modal-user-create-input-company-business-number')
    }
  }

  const validateCompanyStreet = () => {
    if (expressions.street.test(street.value)) {
      setStreet({...street, valid: true})
      inputValid('modal-user-create-input-company-street')
    } else {
      setStreet({...street, valid: false})
      inputInvalid('modal-user-create-input-company-street')
    }
  }

  const validateCompanyPostcode = () => {
    if (expressions.postcode.test(postcode.value)) {
      setPostcode({...postcode, valid: true})
      inputValid('modal-user-create-input-company-postcode')
    } else {
      setPostcode({...postcode, valid: false})
      inputInvalid('modal-user-create-input-company-postcode')
    }
  }

  const validateCompanyCity = () => {
    if (expressions.location.test(city.value)) {
      setCity({...city, valid: true})
      inputValid('modal-user-create-input-company-city')
    } else {
      setCity({...city, valid: false})
      inputInvalid('modal-user-create-input-company-city')
      }
  }

  const validateCompanyPhone = () => {
    if (expressions.phone.test(phone.value)) {
      setPhone({...phone, valid: true})
      inputValid('modal-user-create-input-company-phone')
    } else {
      setPhone({...phone, valid: false})
      inputInvalid('modal-user-create-input-company-phone')
    }
  }

  const validateCompanyEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email, valid: true})
      inputValid('modal-user-create-input-company-email')
    } else {
      setEmail({...email, valid: false})
      inputInvalid('modal-user-create-input-company-email')
    }
  }

  const validateCompanyWebsite = () => {
    if (expressions.website.test(website.value)) {
      setWebsite({...website, valid: true})
      inputValid('modal-user-create-input-company-website')
    } else {
      setWebsite({...website, valid: false})
      inputInvalid('modal-user-create-input-company-website')
    }
  }

  const executeHideModalSecurityCompanyCreate = () => {
    modalHide('modal-security-company-create')

    setTimeout( () => {
      restartInputFileTypeSize('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      restartInput('modal-user-create-input-company-name')
      restartInput('modal-user-create-input-company-business-number')
      restartInput('modal-user-create-input-company-street')
      restartInput('modal-user-create-input-company-postcode')
      restartInput('modal-user-create-input-company-city')
      restartInput('modal-user-create-input-company-phone')
      restartInput('modal-user-create-input-company-email')
      restartInput('modal-user-create-input-company-website')

      setLogo({value: "", file: null, valid: true})
      setName({value: "", valid: false})
      setNumber({value: "", valid: false})
      setStreet({value: "", valid: false})
      setPostcode({value: "", valid: true})
      setCity({value: "", valid: false})
      setCountry({value: "", valid: true})
      setPhone({value: "", valid: false})
      setEmail({value: "", valid: false})
      setWebsite({value: "", valid: false})
      cleanInputFile()
    }, 200 )
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="modal-security-company-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.createSecurityCompany}</h2>
            </div>
          </div>
          <div className="modal-body">
            <p className="fst-italic text-muted mb-5">
              {lang.labels.fieldsMarkedWithAnAsterisk}<span className="text-danger">(*)</span>{lang.labels.areRequired}
            </p>
            <div className="mb-5">
              <label className="col-form-label fw-bold fs-6 d-flex align-items-center">
                {lang.labels.logo}
                <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.addImageOrDragAndDrop}><FaExclamationCircle /></i>
              </label>
              <div className="my-5">
                <div className="position-relative image-input image-input-outline" data-kt-image-input="true">
                  <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="file" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                  <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white ${(!logo.valid || logo.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveLogo}>
                    <i className="bi bi-x fs-2"></i>
                  </span>
                  <img src={logo.valid && logo.value.length > 0 ? logo.value : imageDefault} className="image-input-wrapper w-200px h-200px" alt="logo" />
                  <div className="position-absolute top-50 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center" style={{ pointerEvents: 'none' }}>
                    <img src={imageUpload} className="w-35px h-35px opacity-50" alt="Upload" />
                  </div>
                </div>
              </div>
              <div id="modal-user-create-container-validate-logo-type" className="fv-plugins-message-container invalid-feedback d-none">
                <div data-field="avatar" data-validator="type">{lang.labels.invalidFileType}</div>
              </div>
              <div id="modal-user-create-container-validate-logo-size" className="fv-plugins-message-container invalid-feedback d-none">
                <div data-field="avatar" data-validator="size">{lang.labels.fileSizeNotAllowed}</div>
              </div>
            </div>
            <div className="mb-5">
              <label className="col-form-label required fw-bold fs-6">{lang.labels.companyName}</label>
              <input id="modal-user-create-input-company-name" className="form-control form-control-solid" maxLength={70} type="text" name="company-name" value={name.value} onChange={handleChangeCompanyName} onKeyUp={validateCompanyName} onBlur={validateCompanyName} />
            </div>
            <div className="row mb-5">
              <div className="mb-8 mb-xl-0 col-xl-6">
                <label className="col-form-label required fw-bold fs-6">{lang.labels.businessNumber}</label>
                <input id="modal-user-create-input-company-business-number" className="form-control form-control-solid" type="text" name="company-business-number" value={number.value} onChange={handleChangeCompanyBusinessNumber} onKeyUp={validateCompanyBusinessNumber} onBlur={validateCompanyBusinessNumber} />
              </div>
              <div className="col-xl-6">
                <label className="col-form-label required fw-bold fs-6">{lang.labels.website}</label>
                <input id="modal-user-create-input-company-website" className="form-control form-control-solid" type="text" name="company-website" value={website.value} onChange={handleChangeCompanyWebsite} onKeyUp={validateCompanyWebsite} onBlur={validateCompanyWebsite} />
              </div>
            </div>
            <div className="row mb-5">
              <div className="mb-8 mb-xl-0 col-xl-6">
                <label className="col-form-label required fw-bold fs-6">{lang.labels.email}</label>
                <input id="modal-user-create-input-company-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeCompanyEmail} onKeyUp={validateCompanyEmail} onBlur={validateCompanyEmail} />
              </div>
              <div className="col-xl-6">
                <label className="col-form-label required fw-bold fs-6">{lang.labels.phone}</label>
                <input id="modal-user-create-input-company-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangeCompanyPhone} onKeyUp={validateCompanyPhone} onBlur={validateCompanyPhone} />
              </div>
            </div>
            <div className="mb-5">
              <label className="col-form-label required">{lang.labels.address}</label>
              <input id="modal-user-create-input-company-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeCompanyAddress} onKeyUp={validateCompanyStreet} onBlur={validateCompanyStreet} />
            </div>
            <div className="row mb-5">
              <div className="mb-5 mb-xl-0 col-xl-3">
                <label className="col-form-label required">{lang.labels.postcode}</label>
                <input id="modal-user-create-input-company-postcode" className="form-control form-control-solid" type="text" name="postcode" value={postcode.value} onChange={handleChangeCompanyPostcode} onKeyUp={validateCompanyPostcode} onBlur={validateCompanyPostcode} />
              </div>
              <div className="mb-5 mb-xl-0 col-xl-4">
                <label className="col-form-label required">{lang.labels.city}</label>
                <input id="modal-user-create-input-company-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCompanyCity} onKeyUp={validateCompanyCity} onBlur={validateCompanyCity} />
              </div>
              <div className="col-xl-5">
              <label className="col-form-label required">{lang.labels.country}</label>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {country.valid && country.value.length > 0 ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-search select2-search--dropdown">
                        <input className="select2-search__field" type="text" name="country" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                      </span>
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { searchCountry.list.length > 0
                            ?
                            <>
                              { searchCountry.list.map (( (item, index) => { return (
                                <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === country.value ? "true" : "false"}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCompanyCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                </li>
                              )}))}
                            </>
                            :
                            <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                          }
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitSecurityCompanyCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalSecurityCompanyCreate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalSecurityCreate;
