import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../../languages/config/StateLanguage';
import { DocumentModel } from '../../../../models/document.model';
import { dateFormat } from '../../../../scripts/date.format.script';
import { modalShow } from '../../../../types/modal.type';
import { ClientModel } from '../../../../models/client.model';
import { getError, getMessage } from '../../../../languages/context/response';
import { Error } from '../../../../models/error.model';
import { DocumentService } from '../../../../services/document.service';
import { Message } from '../../../../models/message.model';
import { documentTypeValue } from '../../../../scripts/document.type.script';
import PDFThumbnail from '../../../../components/pdf/PdfThumbnail'
import AppModalDocumentUpdate from '../../../../components/modal/ModalDocumentUpdate';

interface DocumentComponentProps {
  type: string,
  searchDocuments: {text: { value: string }, list: DocumentModel[]},
  client: ClientModel | undefined | null,
  documents: DocumentModel[] | undefined | null,
  setDocuments: Dispatch<SetStateAction<DocumentModel[] | undefined | null>>
}

const DocumentListView: React.FC<DocumentComponentProps> = ({type, searchDocuments, client, documents, setDocuments}) => {
  const {lang} = StateLanguage()

  const [document, setDocument] = useState<DocumentModel | undefined | null>(null)

  const submitDocumentCreate = (item: DocumentModel) => {
    setDocument(item)
    modalShow('modal-document-update-' + type)
  }

  const submitDocumentDelete = async (item: string) => {
    if (client && documents) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisDocument,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await DocumentService.deleteDocument(client.id, item).then( (response) => {
            if (response.status === 200) {
              let messageResponse: Message = response.data
              let list = documents.filter((doc) => (doc.id !== item))

              setDocuments(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingDocumentListReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  return (
    <>
      { searchDocuments.list.some(item => item.type === type) &&
        <div className="mb-8 ms-10 d-inline-flex flex-wrap flex-column">
          <h5 className="text-center fw-bold">{documentTypeValue(type, lang)}</h5>
          <div className="d-flex flex-wrap px-0 py-2">
            { searchDocuments.list.filter(item => item.type === type).map (( (item, index) => { return (
              <div key={index} className="card card-flush rounded-5 p-3 m-2 border border-hover-secondary">
                <div className="card-body p-0 h-160px w-150px">
                  <div className="d-flex rounded-2 cursor-pointer mb-2 h-auto" data-bs-toggle="modal" data-bs-target={`#form-pdf-${item.id}`}>
                    <PDFThumbnail url_pdf={item.file}/>
                  </div>
                  <Dropdown className="position-absolute top-0 end-0 m-2 rounded-2">
                    <Dropdown.Toggle variant="btn btn-icon btn-light-primary w-auto h-auto">
                      <span className="svg-icon svg-icon-2 px-1">
                        <svg width="64px" height="64px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 0, 0)rotate(0)">
                          <path d="M7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10C6.10457 10 7 10.8954 7 12Z" fill="#ffffff"></path>
                          <path d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="#ffffff"></path>
                          <path d="M21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10C20.1046 10 21 10.8954 21 12Z" fill="#ffffff"></path>
                        </svg>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-8 py-2" align={"end"}>
                      <Dropdown.Item bsPrefix="menu-item px-2" onClick={() => submitDocumentCreate(item)}>
                        <div className="menu-link fw-bold px-3 py-1">{lang.labels.edit}</div>
                      </Dropdown.Item>
                      <Dropdown.Item bsPrefix="menu-item px-2" onClick={() => submitDocumentDelete(item.id)}>
                        <div className="menu-link fw-bold px-3 py-1">{lang.labels.delete}</div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <span className="w-75 fs-7 fw-bold-900 d-block">{item.name}</span>
                  <p className="text-muted fs-7 mb-0">{dateFormat(item.created_at, lang.codeLanguage)}</p>
                  <div id={`form-pdf-${item.id}`} className="modal fade" tabIndex={-1} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <iframe id="modal-form-view-container" title={item.name} src={item.file} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}))}
          </div>
        </div>
      }
      <AppModalDocumentUpdate title={type} client={client} file={document} documents={documents} setFile={setDocument} setDocuments={setDocuments}></AppModalDocumentUpdate>
    </>
  );
};

export default DocumentListView;
