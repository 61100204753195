import React, { useEffect, useState } from 'react';

import { UseOutletContext } from '../Main';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { imageUser } from '../../../scripts/image.object.script';
import { userRoleValue } from '../../../scripts/user.role.script';

export interface AccountPageProps {};

const AccountPage: React.FunctionComponent<AccountPageProps> = () => {
  const {setRoute, userLoggedIn} = UseOutletContext()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: null, branch: lang.labels.userProfile}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { userLoggedIn
        ?
        <>
          <div className="card mb-5">
            <div className="card-body">
              <div className="d-flex flex-wrap flex-sm-nowrap">
                <div className="me-10">
                  <div className="symbol symbol-80px symbol-lg-100px symbol-fixed position-relative">
                    <img src={imageUser(userLoggedIn.avatar)} alt="avatar" />
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <label className="text-gray-800 fs-2 fw-bolder mb-2">
                    {userLoggedIn.first_name} {userLoggedIn.last_name}
                  </label>
                  <span className="badge badge-light-primary h-35px fs-7 text-uppercase d-flex flex-column justify-content-center">
                    {userRoleValue(userLoggedIn.role, lang)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <h3 className="fw-bolder">{lang.labels.profileDetails}</h3>
              </div>
            </div>
            <div>
            <div className="card-body p-9">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">{lang.labels.firstName}</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-gray-800">
                    {userLoggedIn.first_name}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">{lang.labels.lastName}</label>
                <div className="col-lg-8 fv-row">
                  <span className="fw-bolder fs-6 text-gray-800">
                    {userLoggedIn.last_name}
                  </span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">{lang.labels.email}</label>
                <div className="col-lg-8 d-flex align-items-center">
                <span className="fw-bolder fs-6 text-gray-800">
                    {userLoggedIn.email.length > 0 ? userLoggedIn.email : lang.labels.none}
                  </span>
                </div>
              </div>
              <div className="row">
                <label className="col-lg-4 fw-bold text-muted">{lang.labels.phone}</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-gray-800">
                    {userLoggedIn.phone.length > 0 ? userLoggedIn.phone : lang.labels.none}
                  </span>
                </div>
              </div>
            </div>
            </div>
          </div>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default AccountPage;
