import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';

import { Container } from '../../../styles/container.style';
import { UseOutletContextIngot } from './Ingot';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { extractBase64 } from '../../../scripts/extract.base.64.script';
import { IngotService } from '../../../services/ingot.service';
import { inputValid, inputInvalid } from '../../../types/legend.input.type';
import { inputFileInvalid, restartInputFile } from '../../../types/legend.input.file.type';
import { puritySelected, refinerSelected, weightSelected } from '../../../scripts/selected.object.script';
import { weightUnitValue } from '../../../scripts/weight.units.script';
import { expressions } from '../../../scripts/regular.expressions.script';

interface IngotCreateProps {};

let errorResponse: Error, messageResponse: Message;

const IngotCreatePage: React.FunctionComponent<IngotCreateProps> = () => {
  const {setRoute, refineries, weights, purities} = UseOutletContextIngot()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [loadFile, setLoadFile] = useState({photo: 'off', certificate: 'off'})
  const [serialNumber, setSerialNumber] = useState({value: '', valid: false})
  const [originalValue, setOriginalValue] = useState({value: '', valid: false})
  const [refiner, setRefiner] = useState({value: '', valid: false})
  const [weight, setWeight] = useState({value: '', valid: false})
  const [purity, setPurity] = useState({value: '', valid: false})
  const [photo, setPhoto] = useState({value: '', file: null as any, valid: true})
  const [certificate, setCertificate] = useState({value: '', file: null as any, valid: true})

  const submitIngotCreate = async () => {
    setLoadIndicator('on')

    if (serialNumber.valid && originalValue.valid && refiner.valid && weight.valid && purity.valid && photo.valid && certificate.valid) {
      await IngotService.postIngot(serialNumber.value, originalValue.value, refiner.value, weight.value ,purity.value, photo.file, certificate.file).then( (response) => {
        if (response.status === 201) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.codeLanguage),
            text: lang.labels.updatingTheListOfBarsBackToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  } 

  const handleChangeSerialNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setSerialNumber({...serialNumber, value: event.target.value})
  }

  const handleChangeOriginalValue = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setOriginalValue({...originalValue, value: event.target.value})
  }

  const handleChangeRefiner = (item: string) => {
    setRefiner({...refiner, value: item, valid: true})
  }

  const handleChangeWeight = (item: string) => {
    setWeight({...weight, value: item, valid: true})
  }

  const handleChangePurity = (item: string) => {
    setPurity({...purity, value: item, valid: true})
  }

  const handleChangeIngotPhoto = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLoadFile({...loadFile, photo: 'on'})

    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']

    if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
      setPhoto({...photo, value: '', file: null, valid: false})
      inputFileInvalid('container-validate-ingot-photo-type')
    } else {
      extractBase64(archiveCapture).then( (image: any) => {
        setPhoto({...photo, value: image.base, file: archiveCapture, valid: true})
        restartInputFile('container-validate-ingot-photo-type')
      })
    }

    setLoadFile({...loadFile, photo: 'off'})
  }

  const handleChangeCertificatePhoto = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLoadFile({...loadFile, certificate: 'on'})

    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']

    if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
      setCertificate({...certificate, value: '', file: null, valid: false})
      inputFileInvalid('container-validate-certificate-photo-type')
    } else {
      extractBase64(archiveCapture).then( (image: any) => {
        setCertificate({...certificate, value: image.base, file: archiveCapture, valid: true})
        restartInputFile('container-validate-certificate-photo-type')
      })
    }

    setLoadFile({...loadFile, certificate: 'off'})
  }

  const validateSerialNumber = () => {
    if (expressions.text.test(serialNumber.value)) {
      setSerialNumber({...serialNumber, valid: true})
      inputValid('input-serial-number')
    } else {
      setSerialNumber({...serialNumber, valid: false})
      inputInvalid('input-serial-number')
    }
  }

  const validateOriginalValue = () => {
    if (expressions.amount.test(originalValue.value)) {
      setOriginalValue({...originalValue, valid: true})
      inputValid('input-original-value')
    } else {
      setOriginalValue({...originalValue, valid: false})
      inputInvalid('input-original-value')
    }
  }

  const onCancel = async () => {
    Swal.fire({
    title: lang.labels.areYouSureYouWantToCancel,
    text: lang.labels.ifYouAcceptYouMayHaveToFillOutTheFormAgain,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: lang.labels.yes,
    cancelButtonText: lang.labels.noCancel,
    customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(-1)
      }
    })
  }

  const handleClearRefiner = (e: any) => {
    setRefiner({value: '', valid: false})
    e.stopPropagation();
  }

  const handleClearWeight = (e: any) => {
    setWeight({value: '', valid: false})
    e.stopPropagation();
  }

  const handleClearPurity = (e: any) => {
    setPurity({value: '', valid: false})
    e.stopPropagation();
  }

  const handleRemoveIngotPhoto = () => {
    setPhoto({...photo, value: '', file: null, valid: true})
  }

  const handleRemoveCertificatePhoto = () => {
    setCertificate({...certificate, value: '', file: null, valid: true})
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.ingots, branch: lang.labels.createIngot}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { refineries && weights && purities
        ?
        <div className="form">
          <div className="card card-flush mb-5">
            <div className="card-header">
              <div className="card-title">
                <h3 className="text-uppercase">{lang.labels.addIngot}</h3>
              </div>
            </div>
            <div className='card-body pt-0'>
              <p className="fst-italic text-muted mb-10">
                {lang.labels.fieldsMarkedWithAnAsterisk}<span className="text-danger">(*)</span>{lang.labels.areRequired}
              </p>
              <div className="col">
                <div className="mb-5 col-xl-12">
                  <label className="form-label required">{lang.labels.serialNumber}</label>
                  <input id="input-serial-number" className="form-control form-control-solid" type="text" name="serialNumber" value={serialNumber.value} onChange={handleChangeSerialNumber} onKeyUp={validateSerialNumber} onBlur={validateSerialNumber} />
                </div>
                <div className="mb-5 col-xl-12">
                  <label className="form-label required">{lang.labels.originalValue} (CHF)</label>
                  <input id="input-original-value" className="form-control form-control-solid" type="text" name="originalValue" value={originalValue.value} onChange={handleChangeOriginalValue} onKeyUp={validateOriginalValue} onBlur={validateOriginalValue} />
                </div>
                <div className="mb-5 col-xl-12">
                  <label className="form-label required">{lang.labels.refiner}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {refiner.valid ? refinerSelected(refineries, refiner.value) : lang.labels.selectOption}
                            </span>
                          </span>
                          { refiner.valid &&
                            <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearRefiner}>
                              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </span>
                          }
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { refineries.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === refiner.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === refiner.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeRefiner(item.id)}>{item.name}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mb-5 col-xl-12">
                  <label className="form-label required">{lang.labels.ingotWeight}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {weight.valid ? weightSelected(weights, weight.value, lang) : lang.labels.selectOption}
                            </span>
                          </span>
                          { weight.valid &&
                            <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearWeight}>
                              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </span>
                          }
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { weights.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === weight.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === weight.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeWeight(item.id)}>{item.value} {weightUnitValue(item.unity, lang)}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mb-5 col-xl-12">
                  <label className="form-label required">{lang.labels.purity}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {purity.valid ? puritySelected(purities, purity.value) : lang.labels.selectOption}
                            </span>
                          </span>
                          { purity.valid &&
                            <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearPurity}>
                              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </span>
                          }
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { purities.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === purity.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === purity.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePurity(item.id)}>{item.value}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <label className="form-label">{lang.labels.photoOfTheIngot}</label> 
                    <div className="position-relative btn btn-outline bg-gray-100 d-flex align-items-center" data-kt-indicator={loadFile.photo}>
                      <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="photo" accept=".png, .jpg, .jpeg" onChange={handleChangeIngotPhoto} />
                      { photo.value
                        ?
                        <img src={photo.value} className="rounded w-100 h-auto" alt="photography"/>
                        :
                        <>
                          <span className="svg-icon svg-icon-1 me-3">
                            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" stroke="#000000">
                              <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round"></path>
                              <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </span>
                          <span className="indicator-label text-muted">{lang.labels.addAPhotoOfTheIngot}</span>
                          <span className="indicator-progress text-muted">
                            {lang.labels.pleaseWait}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </>
                      }
                    </div>
                    <Container property={(!photo.file) ? "true" : "false"}>
                      <div className="form-text">{lang.labels.theFileMustBeInImageFormat}</div>
                    </Container>
                    <Container property={(photo.file) ? "true" : "false"}>
                      <div className="d-flex justify-content-end mt-2">
                        <button className="btn btn-sm btn-danger text-uppercase d-flex align-items-center px-3" type="button" data-kt-image-input-action="remove" onClick={handleRemoveIngotPhoto}>
                          <span className="svg-icon svg-icon-3 me-1">
                            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </span>
                          {lang.labels.delete}
                        </button>
                      </div>
                    </Container>
                    <div id="container-validate-ingot-photo-type" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="ingot-photo" data-validator="type">{lang.labels.invalidFileType}</div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <label className="form-label">{lang.labels.photoOfTheCertificateOfAuthenticity}</label> 
                    <div className="position-relative btn btn-outline bg-gray-100 d-flex align-items-center" data-kt-indicator={loadFile.certificate}>
                      <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="photo" accept=".png, .jpg, .jpeg" onChange={handleChangeCertificatePhoto} />
                      { certificate.value
                        ?
                        <img src={certificate.value} className="rounded w-100 h-auto" alt="certificate"/>
                        :
                        <>
                          <span className="svg-icon svg-icon-1 me-3">
                            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" stroke="#000000">
                              <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round"></path>
                              <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </span>
                          <span className="indicator-label text-muted">{lang.labels.addAPhotoOfTheCertificateOfAuthenticity}</span>
                          <span className="indicator-progress text-muted">
                            {lang.labels.pleaseWait}
                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </>
                      }
                    </div>
                    <Container property={(!certificate.file) ? "true" : "false"}>
                      <div className="form-text">{lang.labels.theFileMustBeInImageFormat}</div>
                    </Container>
                    <Container property={(certificate.file) ? "true" : "false"}>
                      <div className="d-flex justify-content-end mt-2">
                        <button className="btn btn-sm btn-danger text-uppercase d-flex align-items-center px-3" type="button" data-kt-image-input-action="remove" onClick={handleRemoveCertificatePhoto}>
                          <span className="svg-icon svg-icon-3 me-1">
                            <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                          </span>
                          {lang.labels.delete}
                        </button>
                      </div>
                    </Container>
                    <div id="container-validate-certificate-photo-type" className="fv-plugins-message-container invalid-feedback d-none">
                      <div data-field="ingot-photo" data-validator="type">{lang.labels.invalidFileType}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <div className="card card-flush p-3">
            <div className="d-flex justify-content-end">
              <div className="mb-xl-0 col-12 col-md-6  d-flex pe-6 ps-3">
                <button className="btn btn-primary w-100" type="button" data-kt-indicator={loadIndicator} onClick={submitIngotCreate}>
                  <span className="indicator-label">{lang.labels.save}</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button className="btn btn-light-primary w-100 ms-3" type="reset" onClick={onCancel}>
                  {lang.labels.cancel}
                </button>
              </div>
            </div>    
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default IngotCreatePage;
