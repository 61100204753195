import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { InvestmentModel } from '../models/investment.model';
import { IngotModel } from '../models/ingot.model';
import { CompanySecurityModel } from '../models/company.security.model';
import { NotaryModel } from '../models/notary.model';
import { RateModel } from '../models/rate.model';
import { IntermediaryModel } from '../models/intermediary.model';
import { appToken } from '../scripts/app.token.script';

export class InvestmentService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getInvestments(id_client: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        client_id: id_client,
        investments: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/investments.data.json').then(resp => { response = resp.data }).catch(error => { alert(error) })

      if (response.client_id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/investments`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async getInvestment(id_client: string, id_investment: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: InvestmentModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/investment.data.json').then(resp => { response = resp.data.investment }).catch(error => { alert(error) })

      if (response!.id === id_investment) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/investments/${id_investment}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.investment
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postInvestment(id_client: string, authentic_act_date: string, gold_price_date: string, retraction_expiration_date: string, gold_volume_purchased: string, original_price_gold: string, gold_purchase_price: string, gold_sale_price: string, ingots: string[], security_company: string, safe_number: string, notary: string, payment_plan: { date: string, amount: string }[], intermediates: { intermediary: string, rate: string }[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let investments: InvestmentModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/investments.data.json').then(resp => { investments = resp.data.investments }).catch(error => { alert(error) })

      if (!investments!.some(item => item.authentic_act_date === authentic_act_date && item.gold_price_date === gold_price_date && item.retraction_expiration_date === retraction_expiration_date)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/investments`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        authentic_act_date: authentic_act_date,
        gold_price_date: gold_price_date,
        retraction_expiration_date: retraction_expiration_date,
        gold_volume_purchased: gold_volume_purchased,
        original_valuation_gold_purchased: original_price_gold,
        gold_purchase_price: gold_purchase_price,
        gold_sale_price: gold_sale_price,
        ingots: ingots,
        safe_box: {
          id_company: security_company,
          safe_box_number: safe_number,
        },
        notary: notary,
        payment_plan: payment_plan,
        intermediaries: intermediates
      }

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putInvestment(id_client: string, id_investment: string, authentic_act_date: string, gold_price_date: string, retraction_expiration_date: string, gold_volume_purchased: string, original_price_gold: string, gold_purchase_price: string, gold_sale_price: string, ingots: string[], security_company: string, safe_number: string, notary: string, payment_plan: { date: string, amount: string }[], intermediates: { intermediary: string, rate: string }[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let investments: InvestmentModel[] | null = null
      let ingotAll: IngotModel[] | null = null
      let securityCompanyAll: CompanySecurityModel[] | null = null
      let notaryAll: NotaryModel[] | null = null
      let intermediaryAll: IntermediaryModel[] | null = null
      let rateAll: RateModel[] | null = null
      let response: InvestmentModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/investments.data.json').then(resp => { investments = resp.data.investments }).catch(error => { alert(error) })
      await axios.get('../../../../../database/investment.data.json').then(resp => { response = resp.data.investment }).catch(error => { alert(error) })
      await axios.get('../../../../../database/ingots.data.json').then(resp => { ingotAll = resp.data.ingots }).catch(err => { alert(err) })
      await axios.get('../../../../../database/security.companies.data.json').then(resp => { securityCompanyAll = resp.data.security_companies }).catch(err => { alert(err) })
      await axios.get('../../../../../database/notaries.data.json').then(resp => { notaryAll = resp.data.notaries }).catch(error => { alert(error) })
      await axios.get('../../../../../database/intermediaries.data.json').then(resp => { intermediaryAll = resp.data.intermediaries }).catch(err => { alert(err) })
      await axios.get('../../../../../database/rates.data.json').then(resp => { rateAll = resp.data.rates }).catch(err => { alert(err) })

      if (investments!.some(item => item.id === id_investment)) {
        let listIngots = ingotAll!.filter((item) => (ingots.includes(item.id)))
        let objectSecurityCompany = securityCompanyAll!.find((item) => (item.id === security_company))
        let objectNotary = notaryAll!.find((item) => (item.id === notary))
        let listPayment = payment_plan.map((item, index) => ({ id: `payment_${index + 1}`, date: item.date, amount: Number(item.amount) }))
        let listIntermediates = intermediates.map((item) => ({ intermediary: intermediaryAll!.find((sub_item) => (sub_item.id === item.intermediary))!, annual_commission: rateAll!.find((sub_item) => (sub_item.id === item.rate))! }))

        response!.authentic_act_date = authentic_act_date
        response!.gold_price_date = gold_price_date
        response!.retraction_expiration_date = retraction_expiration_date
        response!.gold_volume_purchased = Number(gold_volume_purchased)
        response!.original_price_gold = Number(original_price_gold)
        response!.gold_purchase_price = Number(gold_purchase_price)
        response!.gold_sale_price = Number(gold_sale_price)
        response!.ingots = listIngots
        response!.safe_box.id_company = objectSecurityCompany!.id
        response!.safe_box.company_name = objectSecurityCompany!.name
        response!.safe_box.safe_box_number = safe_number
        response!.notary = objectNotary!
        response!.payment_plan = listPayment
        response!.intermediates = listIntermediates

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/investments/${id_investment}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        authentic_act_date: authentic_act_date,
        gold_price_date: gold_price_date,
        retraction_expiration_date: retraction_expiration_date,
        gold_volume_purchased: gold_volume_purchased,
        original_valuation_gold_purchased: original_price_gold,
        gold_purchase_price: gold_purchase_price,
        gold_sale_price: gold_sale_price,
        ingots: ingots,
        safe_box: {
          id_company: security_company,
          safe_box_number: safe_number,
        },
        notary: notary,
        payment_plan: payment_plan,
        intermediaries: intermediates
      }

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async deleteInvestment(id_client: string, id_investment: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let investments: InvestmentModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/investments.data.json').then(resp => { investments = resp.data.investments }).catch(error => { alert(error) })

      if (investments!.some(item => item.id === id_investment)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/investments/${id_investment}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }
}
