import logoDefaultUser from '../assets/images/avatar.png';
import logoDefaultCompany from '../assets/images/company.png';
import logoDefaultIngot from '../assets/images/ingot.png';

export function imageUser(avatar: string): string {
  return (avatar.length === 0) ? logoDefaultUser : avatar
}

export function imageCompany(logo: string): string {
  return (logo.length === 0) ? logoDefaultCompany : logo
}

export function imageIngot(photo: string): string {
  return (photo.length === 0) ? logoDefaultIngot : photo
}
