import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { IntermediaryModel } from '../models/intermediary.model';
import { appToken } from '../scripts/app.token.script';

export class IntermediaryService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getIntermediaries(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        intermediaries: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/intermediaries.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/intermediaries`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getIntermediary(id_intermediary: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: IntermediaryModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/intermediary.data.json').then(resp => { response = resp.data.intermediary }).catch(error => { alert(error) })

      if (response!.id === id_intermediary) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/intermediaries/${id_intermediary}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.intermediary
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postIntermediary(avatar: any, first_name: string, last_name: string, date_of_birth: string, place_of_birth: string, email: string, phone: string, address: string, postcode: string, city: string, country: string, tin: string, nationality: string, civil_status: string, iban: string, swift: string, company_name: string, company_business_number: string, company_address: string, company_postcode: string, company_city: string, company_country: string, company_phone: string, company_email: string, company_website: string, company_iban: string, company_bic: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let intermediaries: IntermediaryModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/intermediaries.data.json').then(resp => { intermediaries = resp.data.intermediaries }).catch(err => { alert(err) })

      if (!intermediaries!.some(item => item.first_name === first_name && item.last_name === last_name)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/intermediaries`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('avatar', avatar)
      data.append('first_name', first_name)
      data.append('last_name', last_name)
      data.append('phone', phone)
      data.append('email', email)
      data.append('birthdate', date_of_birth)
      data.append('birthplace', place_of_birth)
      data.append('nationality', nationality)
      data.append('civil_status', civil_status)
      data.append('swiftBic', swift)
      data.append('iban', iban)
      data.append('tin', tin)

      data.append('address', JSON.stringify({
        street: address,
        city,
        zip_code: postcode,
        country
      }))

      data.append('company', JSON.stringify({
        company_name,
        company_number: company_business_number,
        address: {
          street: company_address,
          city: company_city,
          zip_code: company_postcode,
          country: company_country
        },
        phone: company_phone,
        email: company_email,
        website: company_website,
        iban: company_iban,
        swift_bic: company_bic
      }))

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putIntermediary(id_intermediary: string, avatar: any, first_name: string, last_name: string, date_of_birth: string, place_of_birth: string, email: string, phone: string, address: string, postcode: string, city: string, country: string, tin: string, nationality: string, civil_status: string, iban: string, swift: string, company_name: string, company_business_number: string, company_address: string, company_postcode: string, company_city: string, company_country: string, company_phone: string, company_email: string, company_website: string, company_iban: string, company_bic: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let intermediaries: IntermediaryModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/intermediaries.data.json').then(resp => { intermediaries = resp.data.intermediaries }).catch(err => { alert(err) })

      if (intermediaries!.some(item => item.id === id_intermediary)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/intermediaries/${id_intermediary}`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('avatar', avatar)
      data.append('first_name', first_name)
      data.append('last_name', last_name)
      data.append('phone', phone)
      data.append('email', email)
      data.append('birthdate', date_of_birth)
      data.append('birthplace', place_of_birth)
      data.append('nationality', nationality)
      data.append('civil_status', civil_status)
      data.append('swiftBic', swift)
      data.append('iban', iban)
      data.append('tin', tin)

      data.append('address', JSON.stringify({
        street: address,
        city,
        zip_code: postcode,
        country
      }))

      data.append('company', JSON.stringify({
        company_name,
        company_number: company_business_number,
        address: {
          street: company_address,
          city: company_city,
          zip_code: company_postcode,
          country: company_country
        },
        phone: company_phone,
        email: company_email,
        website: company_website,
        iban: company_iban,
        swift_bic: company_bic
      }))

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteIntermediary(id_intermediary: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let intermediaries: IntermediaryModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/intermediaries.data.json').then(resp => { intermediaries = resp.data.intermediaries }).catch(err => { alert(err) })

      if (intermediaries!.some(item => item.id === id_intermediary)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/intermediaries/${id_intermediary}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}