import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { uploadTooltip } from '../../../types/tooltip.type';
import { getName } from '../../../scripts/countries.script';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { UseOutletContextSecurity } from './Security';
import { SecurityService } from '../../../services/security.service';
import { CompanySecurityModel } from '../../../models/company.security.model';
import { imageCompany } from '../../../scripts/image.object.script';
import { Message } from '../../../models/message.model';
import { listSortedAscending, listSortedDescending} from '../../../scripts/order.asc.desc.list.script';

export interface SecurityViewPageProps {};

let errorResponse: Error, messageResponse: Message, serviceResponse: any;

const SecurityViewPage: React.FunctionComponent<SecurityViewPageProps> = props => {
  const {setRoute} = UseOutletContextSecurity()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [orderAscDesc, setOrderAscDesc] = useState("asc")
  const [securityCompany, setSecuritycompany] = useState<CompanySecurityModel | undefined | null>(null)

  const loadSecurityCompany = async (id_security_company: string) => {
    await SecurityService.getSecurityCompany(id_security_company).then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data
        serviceResponse.number_safes = listSortedAscending(serviceResponse.number_safes)

        setSecuritycompany(serviceResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitSecurityCompanyDelete = async () => {
    if (securityCompany) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisSecurityCompany,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await SecurityService.deleteSecurity(securityCompany.id).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/security/list')
              })
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const handleChangeOrderAscDesc = (item: string) => {
    if (securityCompany && orderAscDesc !== item) {
      let tempSecurityCompany: CompanySecurityModel = securityCompany

      switch (item) {
        case 'asc':
          tempSecurityCompany.number_safes = listSortedAscending(securityCompany.number_safes)
          break;
        case 'desc':
          tempSecurityCompany.number_safes = listSortedDescending(securityCompany.number_safes)
          break;
      }

      setOrderAscDesc(item)
      setSecuritycompany(tempSecurityCompany)
    }
  }

  function colorBullet(item: string): string {
    if (securityCompany) {
      if (securityCompany.number_safes_used.includes(item)) {
        return 'bg-success'
      } else {
        return 'bg-danger'
      }
    } else {
      return 'bg-primary'
    }
  }

  useEffect( () => {
    if(param.id) {
      loadSecurityCompany(param.id)
      uploadTooltip()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setRoute({main: false, path: {root: lang.labels.security, branch: `${securityCompany ? securityCompany.name : ""} - ${lang.labels.safes}`}, browse: null})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityCompany])

  useEffect( () => {
    setMounted(true)

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { securityCompany
        ?
        <>
          <div className="card overflow-hidden mb-5">
            <div className="card-header py-5"> 
              <div className="card-title">
                <div className="d-sm-flex align-items-center">
                  <div className="symbol symbol-100px mb-3 mb-sm-0">
                    <img src={imageCompany(securityCompany.logo)} alt="logo"/>
                  </div>
                  <div className="ms-5 d-flex flex-column justify-content-center">
                    <span className="fw-bolder fs-3 text-gray-800">
                      {securityCompany.name}
                    </span>
                    <span className="fw-bold text-gray-600 fs-6">
                      {securityCompany.address.street}, {securityCompany.address.zip_code}, {securityCompany.address.city}, {getName(securityCompany.address.country, lang.codeLanguage)}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
                <Link to={"/app/security/update/" + securityCompany.id} className="btn btn-primary">{lang.labels.editSecurityCompany}</Link>
                <button className="btn btn-light-primary d-none" type="button" onClick={submitSecurityCompanyDelete}>{lang.labels.deleteSecurityCompany}</button>
              </div>
            </div>
          </div>
          <div className="card card-flush d-none">
            <div className="card-body">
              <h4 className="mb-4">{lang.labels.information}</h4>
              <div className="row mb-5">
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.companyName}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.name}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.businessNumber}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.number}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.email}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.email}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.phone}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.phone}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.website}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.website}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h4 className="mb-4">{lang.labels.address}</h4>
              <div className="row">
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.address}:</td>
                        <td className="text-gray-800 min-w-200px">{securityCompany.address.street}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.city}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.address.city}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.postcode}:</td>
                        <td className="min-w-200px text-gray-800">{securityCompany.address.zip_code}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.country}:</td>
                        <td className="min-w-200px text-gray-800">{getName(securityCompany.address.country, lang.codeLanguage)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush">
            <div className="card-header">
              <div className="card-title">
                <h4>{lang.labels.safes}</h4>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
                <div className="nav-group nav-group-outline p-1">
                  <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-100px px-0 ${orderAscDesc === "asc" && "active"}`} onClick={() => handleChangeOrderAscDesc("asc")}>{lang.labels.asc}</button>
                  <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-100px px-0 ${orderAscDesc === "desc" && "active"}`} onClick={() => handleChangeOrderAscDesc("desc")}>{lang.labels.desc}</button>
                </div>
              </div>
            </div>
            { securityCompany.number_safes.length > 0 &&
              <div className="card-body pt-0">
              <div className="d-flex flex-wrap">
                { securityCompany.number_safes.map (( (item, index) => { return (
                  <div key={index} className="w-200px h-40px d-flex align-items-center fs-5">
                    <span className={`bullet-bs ${colorBullet(item)} me-3`}></span>
                    {item}
                  </div>
                )}))}
              </div>
            </div>
            }
          </div>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default SecurityViewPage;