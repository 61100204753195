import { languageEn } from './language_en';
import { languageFr } from './language_fr';

export const getError = (code: number, lang: string) => {
  switch (code) {
    case 1000:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1000
        default:
          return languageEn.labels.error1000
      }
    case 1001:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1001
        default:
          return languageEn.labels.error1001
      }
    case 1002:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1002
        default:
          return languageEn.labels.error1002
      }
    case 1003:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1003
        default:
          return languageEn.labels.error1003
      }

    case 1005:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1005
        default:
          return languageEn.labels.error1005
      }
    case 1006:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1006
        default:
          return languageEn.labels.error1006
      }
    case 1007:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1007
        default:
          return languageEn.labels.error1007
      }
    case 1008:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1008
        default:
          return languageEn.labels.error1008
      }
    case 1009:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1009
        default:
          return languageEn.labels.error1009
      }
    case 1010:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1010
        default:
          return languageEn.labels.error1010
      }
    case 1011:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1011
        default:
          return languageEn.labels.error1011
      }
    case 1012:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1012
        default:
          return languageEn.labels.error1012
      }
    case 1013:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1013
        default:
          return languageEn.labels.error1013
      }
    case 1014:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1014
        default:
          return languageEn.labels.error1014
      }
    case 1015:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1015
        default:
          return languageEn.labels.error1015
      }
    case 1016:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1016
        default:
          return languageEn.labels.error1016
      }
    case 1017:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1017
        default:
          return languageEn.labels.error1017
      }
    case 1018:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1018
        default:
          return languageEn.labels.error1018
      }
    case 1019:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1019
        default:
          return languageEn.labels.error1019
      }
    case 1020:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1020
        default:
          return languageEn.labels.error1020
      }
    case 1021:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1021
        default:
          return languageEn.labels.error1021
      }
    case 1022:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1022
        default:
          return languageEn.labels.error1022
      }
    case 1023:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1023
        default:
          return languageEn.labels.error1023
      }
    case 1024:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1024
        default:
          return languageEn.labels.error1024
      }
    case 1025:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1025
        default:
          return languageEn.labels.error1025
      }
    case 1026:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1026
        default:
          return languageEn.labels.error1026
      }
    case 1027:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1027
        default:
          return languageEn.labels.error1027
      }
    case 1028:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1028
        default:
          return languageEn.labels.error1028
      }
    case 1029:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1029
        default:
          return languageEn.labels.error1029
      }
    case 1030:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1030
        default:
          return languageEn.labels.error1030
      }
    case 1031:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1031
        default:
          return languageEn.labels.error1031
      }
    case 1032:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1032
        default:
          return languageEn.labels.error1032
      }
    case 1033:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1033
        default:
          return languageEn.labels.error1033
      }
    case 1034:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1034
        default:
          return languageEn.labels.error1034
      }
    case 1035:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1035
        default:
          return languageEn.labels.error1035
      }
    case 1036:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1036
        default:
          return languageEn.labels.error1036
      }
    case 1037:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1037
        default:
          return languageEn.labels.error1037
      }
    case 1038:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1038
        default:
          return languageEn.labels.error1038
      }
    case 1039:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1039
        default:
          return languageEn.labels.error1039
      }
    case 1040:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1040
        default:
          return languageEn.labels.error1040
      }
    case 1041:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1041
        default:
          return languageEn.labels.error1041
      }
    case 1042:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1042
        default:
          return languageEn.labels.error1042
      }
    case 1043:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1043
        default:
          return languageEn.labels.error1043
      }
    case 1044:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1044
        default:
          return languageEn.labels.error1044
      }
    case 1045:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1045
        default:
          return languageEn.labels.error1045
      }
    case 1046:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1046
        default:
          return languageEn.labels.error1046
      }
    case 1047:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1047
        default:
          return languageEn.labels.error1047
      }
    case 1048:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1048
        default:
          return languageEn.labels.error1048
      }
    case 1049:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1049
        default:
          return languageEn.labels.error1049
      }
    case 1050:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1050
        default:
          return languageEn.labels.error1050
      }
    case 1051:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1051
        default:
          return languageEn.labels.error1051
      }
    case 1052:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1052
        default:
          return languageEn.labels.error1052
      }
    case 1053:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1053
        default:
          return languageEn.labels.error1053
      }
    case 1054:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1054
        default:
          return languageEn.labels.error1054
      }
    case 1055:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1055
        default:
          return languageEn.labels.error1055
      }
    case 1056:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1056
        default:
          return languageEn.labels.error1056
      }
    case 1057:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1057
        default:
          return languageEn.labels.error1057
      }
    case 1058:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1058
        default:
          return languageEn.labels.error1058
      }
    case 1059:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1059
        default:
          return languageEn.labels.error1059
      }
    case 1060:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1060
        default:
          return languageEn.labels.error1060
      }
    case 1061:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1061
        default:
          return languageEn.labels.error1061
      }
    case 1062:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1062
        default:
          return languageEn.labels.error1062
      }
    case 1063:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1063
        default:
          return languageEn.labels.error1063
      }
    case 1064:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1064
        default:
          return languageEn.labels.error1064
      }
    case 1065:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1065
        default:
          return languageEn.labels.error1065
      }
    case 1066:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1066
        default:
          return languageEn.labels.error1066
      }
    case 1067:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1067
        default:
          return languageEn.labels.error1067
      }
    case 1068:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1068
        default:
          return languageEn.labels.error1068
      }
    case 1069:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1069
        default:
          return languageEn.labels.error1069
      }
    case 1070:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1070
        default:
          return languageEn.labels.error1070
      }
    case 1071:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1071
        default:
          return languageEn.labels.error1071
      }
    case 1072:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1072
        default:
          return languageEn.labels.error1072
      }
    case 1073:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1073
        default:
          return languageEn.labels.error1073
      }
    case 1074:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1074
        default:
          return languageEn.labels.error1074
      }
    case 1075:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1075
        default:
          return languageEn.labels.error1075
      }
    case 1076:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1076
        default:
          return languageEn.labels.error1076
      }
    case 1077:
      switch (lang) {
        case "FR":
          return languageFr.labels.error1077
        default:
          return languageEn.labels.error1077
      }


    default:
      switch (lang) {
        case "FR":
          return languageFr.labels.errorOccurredInDatabase
        default:
          return languageEn.labels.errorOccurredInDatabase
      }
  }
}

export const getMessage = (message: string, lang: string) => {
  switch (message) {
    case "You have successfully logged in":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyLoggedIn
        default:
          return languageEn.labels.successfullyLoggedIn
      }
    case "Successfully updated password":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedPassword
        default:
          return languageEn.labels.successfullyUpdatedPassword
      }
    case "Successfully updated account":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedAccount
        default:
          return languageEn.labels.successfullyUpdatedAccount
      }
    case "Successfully created client":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedClient
        default:
          return languageEn.labels.successfullyCreatedClient
      }
    case "Successfully updated client":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedClient
        default:
          return languageEn.labels.successfullyUpdatedClient
      }
    case "Successfully deleted client":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedClient
        default:
          return languageEn.labels.successfullyDeletedClient
      }
    case "Successfully created communication":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedCommunication
        default:
          return languageEn.labels.successfullyCreatedCommunication
      }
    case "Successfully deleted communication":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedCommunication
        default:
          return languageEn.labels.successfullyDeletedCommunication
      }
    case "Successfully updated communication":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedCommunication
        default:
          return languageEn.labels.successfullyUpdatedCommunication
      }
    case "Successfully created document":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedDocument
        default:
          return languageEn.labels.successfullyCreatedDocument
      }
    case "Successfully deleted document":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedDocument
        default:
          return languageEn.labels.successfullyDeletedDocument
      }
    case "Successfully updated document":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedDocument
        default:
          return languageEn.labels.successfullyUpdatedDocument
      }
    case "Successfully created heritage origin":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedHeritageOrigin
        default:
          return languageEn.labels.successfullyCreatedHeritageOrigin
      }
    case "Successfully created ingot":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedIngot
        default:
          return languageEn.labels.successfullyCreatedIngot
      }
    case "Successfully deleted ingot":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedIngot
        default:
          return languageEn.labels.successfullyDeletedIngot
      }
    case "Successfully updated ingot":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedIngot
        default:
          return languageEn.labels.successfullyUpdatedIngot
      }
    case "Successfully created intermediary":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedIntermediary
        default:
          return languageEn.labels.successfullyCreatedIntermediary
      }
    case "Successfully created investment":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedInvestment
        default:
          return languageEn.labels.successfullyCreatedInvestment
      }
    case "Successfully deleted investment":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedInvestment
        default:
          return languageEn.labels.successfullyDeletedInvestment
      }
    case "Successfully updated investment":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedInvestment
        default:
          return languageEn.labels.successfullyUpdatedInvestment
      }
    case "Successfully created notary":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedNotary
        default:
          return languageEn.labels.successfullyCreatedNotary
      }
    case "Successfully deleted notary":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedNotary
        default:
          return languageEn.labels.successfullyDeletedNotary
      }
    case "Successfully updated notary":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedNotary
        default:
          return languageEn.labels.successfullyUpdatedNotary
      }
    case "Successfully created security company":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedSecurityCompany
        default:
          return languageEn.labels.successfullyCreatedSecurityCompany
      }
    case "Successfully updated security company":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedSecurityCompany
        default:
          return languageEn.labels.successfullyUpdatedSecurityCompany
      }
    case "Successfully created withdrawal":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyCreatedWithdrawal
        default:
          return languageEn.labels.successfullyCreatedWithdrawal
      }
    case "Successfully deleted withdrawal":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedWithdrawal
        default:
          return languageEn.labels.successfullyDeletedWithdrawal
      }
    case "Successfully updated withdrawal status":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyUpdatedWithdrawalStatus
        default:
          return languageEn.labels.successfullyUpdatedWithdrawalStatus
      }
    case "Successfully deleted financial document":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedFinancialDocument
        default:
          return languageEn.labels.successfullyDeletedFinancialDocument
      }
    case "Successfully deleted general document":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedGeneralDocument
        default:
          return languageEn.labels.successfullyDeletedGeneralDocument
      }
    case "Successfully deleted refiner":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedRefiner
        default:
          return languageEn.labels.successfullyDeletedRefiner
      }
    case "Successfully deleted weight":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedWeight
        default:
          return languageEn.labels.successfullyDeletedWeight
      }
    case "Successfully deleted purity":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedPurity
        default:
          return languageEn.labels.successfullyDeletedPurity
      }
    case "Successfully deleted security company":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedSecurityCompany
        default:
          return languageEn.labels.successfullyDeletedSecurityCompany
      }
    case "Successfully deleted intermediary":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedIntermediary
        default:
          return languageEn.labels.successfullyDeletedIntermediary
      }
    case "Successfully deleted commission":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedCommission
        default:
          return languageEn.labels.successfullyDeletedCommission
      }
    case "Successfully deleted heritage origin":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedHeritageOrigin
        default:
          return languageEn.labels.successfullyDeletedHeritageOrigin
      }
    case "Successfully deleted type ingot":
      switch (lang) {
        case "FR":
          return languageFr.labels.successfullyDeletedTypeIngot
        default:
          return languageEn.labels.successfullyDeletedTypeIngot
      }

    default:
      switch (lang) {
        case "FR":
          return languageFr.labels.successful
        default:
          return languageEn.labels.successful
      }
  }
}

