import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { ClientModel } from '../../../models/client.model';
import { NotaryModel } from '../../../models/notary.model';
import { HeritageOriginModel } from '../../../models/heritage.origin.model';
import { ClientService } from '../../../services/client.service';
import { getError } from '../../../languages/context/response';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { Error } from '../../../models/error.model';
import { HeritageOriginService } from '../../../services/heritage.origin.service';
import { IngotModel } from '../../../models/ingot.model';
import { RateModel } from '../../../models/rate.model';
import { IntermediaryModel } from '../../../models/intermediary.model';
import { CompanySecurityModel } from '../../../models/company.security.model';
import { SecurityService } from '../../../services/security.service';
import { IntermediaryService } from '../../../services/intermediary.service';
import { RateService } from '../../../services/rate.service';
import { IngotService } from '../../../services/ingot.service';
import { NotaryService } from '../../../services/notary.service';

export interface ClientPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string, branch: string} | null, browse: {target: string, link: string} | null} | null>>,
  clients: ClientModel[] | undefined | null,
  notaries: NotaryModel[] | undefined | null,
  ingots: IngotModel[] | undefined | null,
  rates: RateModel[] | undefined | null,
  intermediaries: IntermediaryModel[] | undefined | null,
  securityCompanies: CompanySecurityModel[] | undefined | null,
  heritages: HeritageOriginModel[] | undefined | null,
  notaryList: NotaryModel[] | undefined | null,
  goldPrice: number,
  setClients: Dispatch<SetStateAction<ClientModel[] | undefined | null>>
};

const ClientPage: React.FunctionComponent<ClientPageProps> = () => {
  const {setRoute, goldPrice} = UseOutletContext()
  const {lang} = StateLanguage()

  const [clients, setClients] = useState<ClientModel[] | undefined | null>(null)
  const [notaries, setNotaries] = useState<NotaryModel[] | undefined | null>(null)
  const [ingots, setIngots] = useState<IngotModel[] | undefined | null>(null)
  const [rates, setRates] = useState<RateModel[] | undefined | null>(null)
  const [intermediaries, setIntermediaries] = useState<IntermediaryModel[] | undefined | null>(null)
  const [securityCompanies, setSecurityCompanies] = useState<CompanySecurityModel[] | undefined | null>(null)

  const [heritages, setHeritages] = useState<HeritageOriginModel[] | undefined | null>(null)

  const loadClients = async () => {
    await ClientService.getClients(false, false).then( (response) => {
      if (response.status === 200) {
        let clientsResponse: any = response.data
        setClients(clientsResponse.clients)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadNotaries = async () => {
    await NotaryService.getNotaries().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setNotaries(serviceResponse.notaries)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadIngots = async () => {
    await IngotService.getIngots(true).then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setIngots(serviceResponse.ingots)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadRates = async () => {
    await RateService.getRates().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setRates(serviceResponse.rates)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadIntermediaries = async () => {
    await IntermediaryService.getIntermediaries().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setIntermediaries(serviceResponse.intermediaries)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadSecurityCompanies = async () => {
    await SecurityService.getSecurityCompanies().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setSecurityCompanies(serviceResponse.security_companies)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadHeritages = async () => {
    await HeritageOriginService.getHeritages().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setHeritages(serviceResponse.heritage_origins)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    loadClients()
    loadNotaries()
    loadIngots()
    loadRates()
    loadSecurityCompanies()
    loadIntermediaries()
    loadHeritages()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Outlet context={{setRoute, goldPrice, clients, notaries, ingots, rates, intermediaries, securityCompanies, heritages, setClients}}></Outlet>
  );
}

export function UseOutletContextClient() {
  return useOutletContext<ContexType>()
};

export default ClientPage;
