import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { modalShow } from '../../../types/modal.type';
import { DocumentModel } from '../../../models/document.model';
import { GeneralInformationService } from '../../../services/general.information.service';
import { dateFormat } from '../../../scripts/date.format.script';
import AppModalGeneralInformationUpdate from '../../../components/modal/ModalGeneralInformationUpdate';
import AppModalGeneralInformationCreate from '../../../components/modal/ModalGeneralInformationCreate';
import PDFThumbnail from './../../../components/pdf/PdfThumbnail'
import arrowNext from '../../../assets/images/svg/arrow-next.svg';

export interface GeneralInformationsPageProps {
  generalInformations: DocumentModel[] | undefined | null,
  setGeneralInformations: Dispatch<SetStateAction<DocumentModel[] | undefined | null>>
};

const GeneralInformationsPage: React.FunctionComponent<GeneralInformationsPageProps> = ({generalInformations, setGeneralInformations}) => {
  const {lang} = StateLanguage()

  const [listGeneralInformations, setListGeneralInformations] = useState<DocumentModel[] | undefined | null>(null)
  const [generalInformation, setGeneralInformation] = useState<DocumentModel | undefined | null>(null)

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: DocumentModel[] = []

    if (generalInformations && text.length > 0) {
      for (let generalInformation of generalInformations) {
        let name: string = generalInformation.name.toLocaleLowerCase() 
        if (name.indexOf(text.toLowerCase()) !== -1) {
          list.push(generalInformation)
        }
      }
    } else if (generalInformations! && listGeneralInformations! && text.length === 0) {
      list = generalInformations
    } else {
      list = []
    }

    setListGeneralInformations(list)
  }

  const submitGeneralInformationDelete = async (item: string) => {
    if (generalInformations) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisGeneralInformation,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await GeneralInformationService.deleteGeneralInformation(item).then( (response) => {
            if (response.status === 200) {
              let messageResponse: Message = response.data
              let list = generalInformations.filter((generalInformation) => (generalInformation.id !== item))

              setGeneralInformations(list)
              setListGeneralInformations(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingGeneralInformationReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitGeneralInformationUpdate = async (item: DocumentModel) => {
    setGeneralInformation(item)
    modalShow('modal-general-information-update')
  }

  const submitGeneralInformationCreate = () => {
    modalShow('modal-general-information-create')
  }

  useEffect( () => {
    setListGeneralInformations(generalInformations)
  }, [generalInformations])

  return (
    <>
      <div className="d-flex flex-wrap flex-stack mb-5 px-2">
        <h3 className="fw-bolder my-2">{lang.labels.generalInformations}</h3>
        <div className="d-flex flex-wrap align-items-center my-1">
          <div className="d-flex align-items-center position-relative">
            <span className="svg-icon svg-icon-1 position-absolute ms-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
              </svg>
            </span>
            <input className="form-control form-control-solid  bg-white w-250px w-md-350px ps-14" maxLength={200} type="text" placeholder={lang.labels.search} onChange={handleChangeSearch} />
          </div>
          <button className="btn btn-primary ms-5" onClick={submitGeneralInformationCreate}>
            {lang.labels.addDocument}
          </button>
        </div>
      </div>
      <div className="card">
        <div className="card-body p-0">
          <div className="dataTables_wrapper">
            <div className="table-responsive border border-2 rounded">
              <table className="table align-middle table-row-bordered fs-6 g-5 mb-0">
                { listGeneralInformations && listGeneralInformations.length > 0
                  ?
                  <tbody>
                    { listGeneralInformations.map (( (item, index) => { return (
                      <tr key={index} className="bg-hover-light">
                        <td className="min-w-300px fw-bold">
                          <a href={item.file} className="d-flex align-items-center" target="_blank" rel="noopener noreferrer">
                            <div className="d-flex w-50px h-60px overflow-hidden me-5">
                              <PDFThumbnail url_pdf={item.file}/>
                            </div>
                            <div className="d-flex flex-column">
                              <span className="text-gray-900">{item.name}</span>
                              <span className="text-gray-700 fs-7">{dateFormat(item.date, lang.codeLanguage)}</span>
                            </div>
                          </a>
                        </td>
                        <td className="min-w-250px text-end">
                          <div className="justify-content-end ">
                            <button className="btn btn-sm btn-light-primary me-5" type="button" onClick={(event) => {event.stopPropagation(); submitGeneralInformationUpdate(item);}}>
                              {lang.labels.edit}
                            </button>
                            <button className="btn btn-sm btn-light-primary me-5" type="button" onClick={(event) => {event.stopPropagation(); submitGeneralInformationDelete(item.id);}}>
                              {lang.labels.delete}
                            </button>
                            <img className="w-20px" src={arrowNext} alt='Go'/>
                          </div>
                        </td>
                      </tr>
                    )}))}
                  </tbody>
                  :
                  <tbody className="text-gray-600 fw-bold">
                    <tr className="odd">
                      <td valign="top" colSpan={2} className="text-center">
                        {lang.labels.noMatchingRecordsFound}
                      </td>
                    </tr>
                  </tbody>
                }
              </table>
            </div>
          </div>
        </div>
      </div>
      <AppModalGeneralInformationUpdate generalInformation={generalInformation} generalInformations={generalInformations} listGeneralInformations={listGeneralInformations} setGeneralInformations={setGeneralInformations} setListGeneralInformations={setListGeneralInformations}></AppModalGeneralInformationUpdate>
      <AppModalGeneralInformationCreate generalInformations={generalInformations} listGeneralInformations={listGeneralInformations} setGeneralInformations={setGeneralInformations} setListGeneralInformations={setListGeneralInformations}></AppModalGeneralInformationCreate>
    </>
  )
};

export default GeneralInformationsPage;
