import { createContext, useReducer, ReactNode } from 'react';
import { configLanguage } from './ConfigLanguage';
import { Action, initialState, languageReducer } from './LanguageReducer';
import { Language } from '../context/language';

const init = () => {
  const hasData = localStorage.getItem('language')

  if (hasData === null) {
    return initialState;
  }

  if (hasData === configLanguage.lang.french.code) {
    return {
      codeLanguage: configLanguage.lang.french.code,
      labels: configLanguage.lang.french.labels
    }
  }

  // return {
  //   codeLanguage: configLanguage.lang.english.code,
  //   labels: configLanguage.lang.english.labels
  // }

  return {
    codeLanguage: configLanguage.lang.french.code,
    labels: configLanguage.lang.french.labels
  }
}

type Props = { children: ReactNode };
type ContextDispatch = (action: Action) => void;
type Context = { lang: Language };

export const CreatedContextLanguage = createContext<Context>({
  lang: initialState
})

export const CreatedContextDispatch = createContext<ContextDispatch>(() => { });

export const LanguageContext = ({ children }: Props) => {
  const [lang, dispatch] = useReducer(languageReducer, initialState, init)

  return (
    <CreatedContextDispatch.Provider value={dispatch}>
      <CreatedContextLanguage.Provider value={{lang}}>
        {children}
      </CreatedContextLanguage.Provider>
    </CreatedContextDispatch.Provider>
  )
}
