import { Language } from "../languages/context/language"

export function documentTypeList(lang: Language): { code: string, desc: string, total: number }[] {
  return [
    { code: 'identity_document', desc: lang.labels.identityDocument, total: 4 },
    { code: 'identity_document_spouse', desc: lang.labels.identityDocumentSpouse, total: 4 },
    { code: 'extract_prosecution_office', desc: lang.labels.extractProsecutionOffice, total: 1 },
    { code: 'tax_declaration', desc: lang.labels.lastTaxDeclaration, total: 1 },
    { code: 'purchase_gold', desc: lang.labels.purchaseGold, total: 1 },
    { code: 'executive_title', desc: lang.labels.executiveTitle, total: 1 },
    { code: 'tax_notice', desc: lang.labels.lastTaxNotice, total: 1 },
    { code: 'proof_address', desc: lang.labels.utilityBill, total: 1 },
    { code: 'bank_statement_confirming_existence_funds', desc: lang.labels.bankStatementConfirmingExistenceOfFunds, total: 50 },
    { code: 'form_a', desc: lang.labels.formA, total: 1 },
    { code: 'tax_compliance_certificate', desc: lang.labels.taxComplianceCertificate, total: 1 },
    { code: 'kyc', desc: lang.labels.kyc, total: 1 },
  ]
}

export function documentTypeValue(code: string, lang: Language): string {
  let element = documentTypeList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}

export function documentTypeTotal(code: string, lang: Language): number {
  let element = documentTypeList(lang).find((item) => item.code === code)

  return element ? element.total : 0
}