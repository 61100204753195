import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { WithdrawalRequestModel } from '../models/withdrawal.request.model';
import { appToken } from '../scripts/app.token.script';

export class WithdrawalRequestService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getWithdrawalRequests(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        withdrawal_requests: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/withdrawal.requests.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/withdrawals`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getClientWithdrawalRequests(id_client: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        client_id: id_client,
        withdrawal_requests: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/withdrawal.requests.client.data.json').then(resp => { response = resp.data }).catch(error => { alert(error) })

      if (response.client_id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/withdrawals`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getWithdrawalRequest(id_client: string, id_withdrawal: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: WithdrawalRequestModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/withdrawal.request.data.json').then(resp => { response = resp.data.withdrawal_request }).catch(error => { alert(error) })

      if (response!.id === id_withdrawal && response!.client.id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/withdrawals/${id_withdrawal}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.withdrawal
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putWithdrawalRequestStatus(id_client: string, id_withdrawal: string, status: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let WithdrawalRequest: WithdrawalRequestModel | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/withdrawal.request.data.json').then(resp => { WithdrawalRequest = resp.data.withdrawal_request }).catch(error => { alert(error) })

      if (WithdrawalRequest!.id === id_withdrawal && WithdrawalRequest!.client.id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/withdrawals/${id_withdrawal}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {status}

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.withdrawal
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteWithdrawalRequest(id_client: string, id_withdrawal: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let withdrawalRequests: WithdrawalRequestModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/withdrawal.requests.data.json').then(resp => { withdrawalRequests = resp.data.withdrawal_requests }).catch(err => { alert(err) })

      if (withdrawalRequests!.some(item => item.id === id_withdrawal)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/withdrawals/${id_withdrawal}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}
