import React, { useEffect, useState } from 'react';

import { StateLanguage } from '../../languages/config/StateLanguage';

import logotipo from '../../assets/images/svg/sgi-emblem.svg';
import background from '../../assets/images/themes/image-new.png';

export interface ComingSoonPageProps {};

const ComingSoonPage: React.FunctionComponent<ComingSoonPageProps> = props => {
  const {lang} = StateLanguage()

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const targetDate = new Date('2024-07-31T23:59:59Z')

    const interval = setInterval(() => {
      let currentDate = new Date()
      let difference = targetDate.getTime() - currentDate.getTime()
      let remainingDays = Math.floor(difference / (1000 * 60 * 60 * 24))
      let remainingHours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      let remainingMinutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      let remainingSeconds = Math.floor((difference % (1000 * 60)) / 1000)

      setDays(remainingDays)
      setHours(remainingHours)
      setMinutes(remainingMinutes)
      setSeconds(remainingSeconds)

      if (difference <= 0) {
        clearInterval(interval)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="d-flex flex-column flex-root h-100">
			<div className="d-flex flex-column flex-column-fluid">
        <div className="d-flex flex-column flex-center p-10">
          <div className="cursor-pointer">
            <img src={logotipo} className="h-55px h-lg-85px mb-5" alt="logotipo" />
					</div>
          <div className="pt-lg-10 mb-15 text-center">
            <h1 className="fw-bolder fs-4x text-gray-900 mb-5">{lang.labels.comingSoon}</h1>
            <div className="fw-bold fs-3 text-gray-400">
              {lang.labels.thePageYouLookedNotFound}
              <br />
              {lang.labels.pageInProduction}
            </div>
          </div>
					<div className="d-flex text-center mb-10 mb-lg-15">
						<div className="w-65px rounded-3 bg-body shadow p-5 mx-3">
							<div className="fs-2 fw-bolder text-gray-800">{days}</div>
							<div className="fs-7 fw-bold text-muted">days</div>
						</div>
						<div className="w-65px rounded-3 bg-body shadow p-5 mx-3">
							<div className="fs-2 fw-bolder text-gray-800">{hours}</div>
							<div className="fs-7 text-muted">hrs</div>
						</div>
						<div className="w-65px rounded-3 bg-body shadow p-5 mx-3">
							<div className="fs-2 fw-bolder text-gray-800">{minutes}</div>
							<div className="fs-7 text-muted">min</div>
						</div>
						<div className="w-65px rounded-3 bg-body shadow p-5 mx-3">
							<div className="fs-2 fw-bolder text-gray-800">{seconds}</div>
							<div className="fs-7 text-muted">sec</div>
						</div>
					</div>
          <img src={background} className="h-300px h-md-200px" alt="background" />
        </div>
			</div>
		</div>
  )
};

export default ComingSoonPage;
