import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';
import { RefinerModel } from '../../../models/refiner.model';
import { WeightModel } from '../../../models/weight.model';
import { PurityModel } from '../../../models/purity.model';
import { RefinerService } from '../../../services/refiner.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { getError } from '../../../languages/context/response';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { Error } from '../../../models/error.model';
import { WeightService } from '../../../services/weight.service';
import { PurityService } from '../../../services/purity.service';

export interface IngotPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string, branch: string} | null, browse: {target: string, link: string} | null} | null>>,
  refineries: RefinerModel[] | undefined | null,
  weights: WeightModel[] | undefined | null,
  purities: PurityModel[] | undefined | null,
};

const IngotPage: React.FunctionComponent<IngotPageProps> = () => {
  const {setRoute} = UseOutletContext()
  const {lang} = StateLanguage()

  const [refineries, setRefineries] = useState<RefinerModel[] | undefined | null>(null)
  const [weights, setWeights] = useState<WeightModel[] | undefined | null>(null)
  const [purities, setPurities] = useState<PurityModel[] | undefined | null>(null)

  const loadRefineries = async () => {
    await RefinerService.getRefineries().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data

        setRefineries(serviceResponse.refineries)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadWeights = async () => {
    await WeightService.getWeights().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data

        setWeights(serviceResponse.weights)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadPurities = async () => {
    await PurityService.getPurities().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data

        setPurities(serviceResponse.purities)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    loadRefineries()
    loadWeights()
    loadPurities()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Outlet context={{setRoute, refineries, weights, purities}}></Outlet>
  );
}

export function UseOutletContextIngot() {
  return useOutletContext<ContexType>()
};

export default IngotPage;
