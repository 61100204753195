/* eslint-disable no-useless-escape */

export const expressions = {
  textsize: /^[\s\S]{1,1000}$/,
  number: /^\d{1,100}$/,
  text: /^[A-Za-zÀ-ÿ0-9\s\-_!@#$%^&*(){}[\]<>?¿¡+=.,;:'"|\\\\/´¨`´áéíóúüñÁÉÍÓÚÜÑÄäËëÏïÖöÜüÿ]{1,500}$/,
  money: /^\d+(?:\.\d{1,2})?$/,
  volume: /^([1-4]?\d{1,2}|500)$/,
  phone: /^\+?[0-9]{1,4}[ -]*\(?[0-9]{1,4}\)?[ -]*[0-9\- ]{4,10}$/,
  street: /^[A-Za-zÀ-ÿ0-9'\\.\-\s\\,]{1,200}$/,
  email: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  website: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\\+\\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\\+\\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\\+~%\\/.\w-_]*)?\??(?:[-\\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
  zip: /^\d{3,5}([-]|\s*)?(\d{3,5})?$/,
  purity: /^\d{0,3}(\.\d{1,2})?$/,
  password: /^.{8,}$/,
  location: /^[a-zA-ZÀ-ÿ]+(?:[\s-][a-zA-ZÀ-ÿ]+)*$/,
  nif: /^([A-Z]{0,3}-?\d+(\.\d+)*(\s[A-Z]{0,4})?)$/,
  iban: /^([A-Z]{2})\s*(\d{2})(\s*(\d{4})){1,2}\s*(\d{2})?(\s*(\d{4})){1,3}\s*([A-Z0-9]{1,10})$/,
  swiftBic: /^([A-Z]{4}\s?[A-Z]{2}\s?[A-Z0-9]{2}\s?([A-Z0-9]{0,3})?)$/,
  amount: /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/,
  business: /^[A-Za-z0-9\s\-./()[\]+#*]{3,40}$/,
  postcode: /^[A-Za-z0-9]+[-\s]?[A-Za-z0-9]+$|^[A-Za-z]+$|^[0-9]+$/,
  companyNumber: /^[A-Za-z0-9\s.,;:()'"?!¿¡\-_/]+$/,

}