import React, { useEffect } from 'react';

import { UseOutletContext } from '../main/Main';
import { StateLanguage } from '../../languages/config/StateLanguage';

import background from '../../assets/images/themes/image-error.png';

export interface NotFoundMainPageProps {};

const NotFoundMainPage: React.FunctionComponent<NotFoundMainPageProps> = props => {
  const {setRoute} = UseOutletContext()
  const {lang} = StateLanguage()

  useEffect( () => {
    setRoute(null)
  })

  return (
    <div className="d-flex flex-column flex-column-fluid text-center p-10 w-100 h-100">
      <div className="pt-lg-10 mb-20">
        <h1 className="fw-bolder fs-4x text-gray-900 mb-10">{lang.labels.pageNotFound}</h1>
        <div className="fw-bold fs-3 text-gray-400">
          {lang.labels.thePageYouLookedNotFound}
          <br />
          {lang.labels.pleaseCorrectThePathOfThePageInOrderToAccess}
        </div>
      </div>
      <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-125px min-h-lg-200px" style={{backgroundImage: `url(${background})`}}></div>
    </div>
  )
};

export default NotFoundMainPage;
