import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { format } from 'date-fns';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput } from '../../types/legend.input.type';
import { NotaryModel } from '../../models/notary.model';
import { IngotModel } from '../../models/ingot.model';
import { IntermediaryModel } from '../../models/intermediary.model';
import { RateModel } from '../../models/rate.model';
import { CompanySecurityModel } from '../../models/company.security.model';
import { modalHide } from '../../types/modal.type';
import { InvestmentService } from '../../services/investment.service';
import { InvestmentModel } from '../../models/investment.model';
import { dateString, datetimeString } from '../../scripts/date.format.script';
import { weightUnitFormat } from '../../scripts/weight.units.script';
import { rateSelected } from '../../scripts/selected.object.script';
import { listIngotSortedAscending } from '../../scripts/order.asc.desc.list.script';
import { moneyFormat } from '../../scripts/money.format.script';
import { expressions } from '../../scripts/regular.expressions.script';

interface AppModalInvestmentUpdateProps {
  investment: InvestmentModel | undefined | null,
  notary: NotaryModel | undefined | null,
  investments: InvestmentModel[] | undefined | null,
  ingots: IngotModel[] | undefined | null,
  rates: RateModel[] | undefined | null,
  intermediaries: IntermediaryModel[] | undefined | null,
  securityCompanies: CompanySecurityModel[] | undefined | null,
  notaries: NotaryModel[] | undefined | null,
  setNotary: Dispatch<SetStateAction<NotaryModel | undefined | null>> | null,
  setInvestments: Dispatch<SetStateAction<InvestmentModel[] | undefined | null>> | null
};

let errorResponse: Error, serviceResponse: any;

const AppModalInvestmentUpdate: React.FunctionComponent<AppModalInvestmentUpdateProps> = ({investment, notary, investments, ingots, rates, intermediaries, securityCompanies, notaries, setNotary, setInvestments}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [authenticActDate, setAuthenticActDate] = useState({value: '', valid: false})
  const [goldPriceLockDate, setGoldPriceLockDate] = useState({value: '', valid: false})
  const [validityRightRefusalDate, setValidityRightRefusalDate] = useState({value: '', valid: false})
  const [volumeGoldPurchased, setVolumeGoldPurchased] = useState({value: '', valid: false})
  const [originalValorizationGold, setOriginalValorizationGold] = useState({value: '', valid: false})
  const [futuresSellingPriceGold, setfuturesSellingPriceGold] = useState({value: '', valid: false})
  const [goldFuturesPurchasePrice, setGoldFuturesPurchasePrice] = useState({value: '', valid: false})
  const [securityCompany, setSecurityCompany] = useState({value: '', object: null as any, valid: false}) 
  const [safeNumber, setSafeNumber] = useState({value: '', valid: false})
  const [investmentNotary, setInvestmentNotary] = useState({value: '', object: null as any, valid: false}) 
  const [investmentIngots, setInvestmentIngots] = useState({value: [] as string[], object: [] as any[], valid: [] as boolean[]})
  const [investmentPaymentPlan, setInvestmentPaymentPlan] = useState({value: [] as {date: string, amount: string}[], valid: [] as {date: boolean, amount: boolean}[]})
  const [investmentIntermediates, setInvestmentIntermediates] = useState({value: [] as {intermediary: string, rate: string}[], valid: [] as {intermediary: boolean, rate: boolean}[]})

  const submitInvestmentUpdate = async () => {
    setLoadIndicator('on')

    let validInvestmentIngots = investmentIngots.valid.length > 0 && investmentIngots.valid.every(item => item)
    let validInvestmentPaymentPlan = investmentPaymentPlan.valid.length > 0 && investmentPaymentPlan.valid.every(item => item.date && item.amount)
    let validInvestmentIntermediate = investmentIntermediates.valid.length > 0 &&  investmentIntermediates.valid.every(item => item.intermediary && item.rate)

    if (investment && authenticActDate.valid && goldPriceLockDate.valid && validityRightRefusalDate.valid && volumeGoldPurchased.valid && originalValorizationGold.valid && goldFuturesPurchasePrice.valid && futuresSellingPriceGold.valid && securityCompany.valid && safeNumber.valid && investmentNotary.valid && validInvestmentIngots && validInvestmentPaymentPlan && validInvestmentIntermediate) {
      let dateLocaleAuthenticActDate: string = format((new Date(datetimeString(authenticActDate.value))), 'yyyy-MM-dd HH:mm:ss zzzz')
      let dateLocaleGoldPriceLockDate: string = format((new Date(datetimeString(goldPriceLockDate.value))), 'yyyy-MM-dd HH:mm:ss zzzz')
      let dateLocaleValidityRightRefusalDate: string = format((new Date(datetimeString(validityRightRefusalDate.value))), 'yyyy-MM-dd HH:mm:ss zzzz')

      let valueInvestmentPaymentPlan = investmentPaymentPlan.value.map(item => {
        return {date: format((new Date(`${item.date}T23:59:59`)), 'yyyy-MM-dd HH:mm:ss zzzz'), amount: item.amount}
      })

      await InvestmentService.putInvestment(investment.client.id, investment.id, dateLocaleAuthenticActDate, dateLocaleGoldPriceLockDate, dateLocaleValidityRightRefusalDate, volumeGoldPurchased.value, originalValorizationGold.value, goldFuturesPurchasePrice.value, futuresSellingPriceGold.value, investmentIngots.value, securityCompany.value, safeNumber.value, investmentNotary.value, valueInvestmentPaymentPlan, investmentIntermediates.value).then( (response) => {
        if (response.status === 200) {
          serviceResponse = response.data

          if (notary && !notaries && setNotary) {
            let list = notary.investments.map((item) => {
              if (item.id === serviceResponse.id) {
                return serviceResponse
              }
              return item
            })

            setNotary(prevState => {
              if (prevState) {
                return { ...prevState, investments: list }
              }
              return null
            })
          }

          if (investments && setInvestments) {
            let list = investments.map((item) => {
              if (item.id === serviceResponse.id) {
                return serviceResponse
              }
              return item
            })

            setInvestments(list)
          }

          Swal.fire({
            title: lang.labels.successfullyUpdatedInvestment,
            text: lang.labels.updatedInvestmentListReturToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalInvestmentUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeAuthenticAct = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setAuthenticActDate({value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleChangeGoldPriceLockDate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setGoldPriceLockDate({value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleChangeValidityRightRefusal = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setValidityRightRefusalDate({...validityRightRefusalDate, value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleChangeVolumeGoldPurchased = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setVolumeGoldPurchased({...volumeGoldPurchased, value: event.target.value})
  }

  const handleChangeGoldFuturesPurchasePrice = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setGoldFuturesPurchasePrice({...goldFuturesPurchasePrice, value: event.target.value})
  }

  const handleChangeFuturesSellingPriceGold = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setfuturesSellingPriceGold({...futuresSellingPriceGold, value: event.target.value})
  }

  const handleChangeOriginalValorizationGold = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setOriginalValorizationGold({...originalValorizationGold, value: event.target.value})
  }

  const handleChangeIngot = (item: IngotModel, index: number) => {
    if (!investmentIngots.value.some(temp_item => temp_item === item.id)) {
      let tempIngotValue = investmentIngots.value.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return item.id
        } else {
          return temp_item
        }
      })
      let tempIngotObject = investmentIngots.object.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return item
        } else {
          return temp_item
        }
      })
      let tempIngotValid = investmentIngots.valid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return true
        } else {
          return temp_item
        }
      })
      setInvestmentIngots({value: tempIngotValue, object: tempIngotObject, valid: tempIngotValid})
    }
  }

  const handleChangeSecurityComany = (item: CompanySecurityModel) => {
    setSecurityCompany({value: item.id, object: item, valid: true})
    setSafeNumber({value: '', valid: false})
  }

  const handleChangeSafeNumber = (item: number) => {
    setSafeNumber({value: item.toString(), valid: true})
  }

  const handleChangeNotary = (item: NotaryModel) => {
    setInvestmentNotary({value: item.id, object: item, valid: true})
  }

  const handleChangePaymentPlanDate = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempPaymentPlanValue = investmentPaymentPlan.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {date: event.target.value, amount: temp_item.amount}
      } else {
        return temp_item
      }
    })

    let tempPaymentPlanValid = investmentPaymentPlan.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {date: event.target.value.length > 0, amount: temp_item.amount}
      } else {
        return temp_item
      }
    })

    setInvestmentPaymentPlan({value: tempPaymentPlanValue, valid: tempPaymentPlanValid})
  }

  const handleChangePaymentPlanAmount = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempPaymentPlanValue = investmentPaymentPlan.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {date: temp_item.date, amount: event.target.value}
      } else {
        return temp_item
      }
    })

    setInvestmentPaymentPlan({...investmentPaymentPlan, value: tempPaymentPlanValue})
  }

  const handleChangeIntermediary = (index: number, item: string) => {
    let tempIntermediariesValue = investmentIntermediates.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {intermediary: item, rate: temp_item.rate}
      } else {
        return temp_item
      }
    })

    let tempIntermediariesValid = investmentIntermediates.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {intermediary: true, rate: temp_item.rate}
      } else {
        return temp_item
      }
    })

    setInvestmentIntermediates({...investmentIntermediates, value: tempIntermediariesValue, valid: tempIntermediariesValid})
  }

  const handleChangeRate = (index: number, item: string) => {
    let tempRatesValue = investmentIntermediates.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {intermediary: temp_item.intermediary, rate: item}
      } else {
        return temp_item
      }
    })

    let tempRatesValid = investmentIntermediates.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return {intermediary: temp_item.intermediary, rate: true}
      } else {
        return temp_item
      }
    })

    setInvestmentIntermediates({...investmentIntermediates, value: tempRatesValue, valid: tempRatesValid})
  }

  const handleClearSecurityCompany = (event: any) => {
    setSecurityCompany({...securityCompany, value: '', object: null, valid: false})
    setSafeNumber({value: '', valid: false})

    event.stopPropagation();
  }

  const handleClearSafeNumber = (e: any) => {
    setSafeNumber({...safeNumber, value: '', valid: false})
    e.stopPropagation()
  }

  const handleClearNotary = (e: any) => {
    setInvestmentNotary({...investmentNotary, value: '', object: null, valid: false})
    e.stopPropagation()
  }

  const handleClearIngot = (event: any, index: number) => {
    let tempIngotValue = investmentIngots.value.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return ''
      } else {
        return temp_item
      }
    })
    let tempIngotObject = investmentIngots.object.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return null
      } else {
        return temp_item
      }
    })
    let tempIngotValid = investmentIngots.valid.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return false
      } else {
        return temp_item
      }
    })

    setInvestmentIngots({value: tempIngotValue, object: tempIngotObject, valid: tempIngotValid})
    event.stopPropagation()
  }

  const validateVolumeGoldPurchased = () => {
    if (expressions.volume.test(volumeGoldPurchased.value)) {
      let investmentIngotsValue: string[] = []
      let investmentIngotsObject: any[] = []
      let investmentIngotsValid: boolean[] = []

      for (let i = 0; i < parseInt(volumeGoldPurchased.value, 10); i++) {
        investmentIngotsValue.push('')
        investmentIngotsObject.push(null)
        investmentIngotsValid.push(false)
      }

      setVolumeGoldPurchased({...volumeGoldPurchased, valid: true})
      setInvestmentIngots({value: investmentIngotsValue, object: investmentIngotsObject, valid: investmentIngotsValid})
      inputValid('modal-investment-update-input-volume-gold-purchased')
    } else {
      setInvestmentIngots({value: [], object: [], valid: []})
      setVolumeGoldPurchased({...volumeGoldPurchased, valid: false})
      inputInvalid('modal-investment-update-input-volume-gold-purchased')
    }
  }

  const validateGoldFuturesPurchasePrice = () => {
    if (expressions.money.test( goldFuturesPurchasePrice.value)) {
      setGoldFuturesPurchasePrice({...goldFuturesPurchasePrice, valid: true})
      inputValid('modal-investment-update-input-gold-futures-purchase-price')
    } else {
      setGoldFuturesPurchasePrice({...goldFuturesPurchasePrice, valid: false})
      inputInvalid('modal-investment-update-input-gold-futures-purchase-price')
    }
  }

  const validateFuturesSellingPriceGold = () => {
    if (expressions.money.test( futuresSellingPriceGold.value)) {
      setfuturesSellingPriceGold({...futuresSellingPriceGold, valid: true})
      inputValid('modal-investment-update-input-futures-selling-price-gold')
    } else {
      setfuturesSellingPriceGold({...futuresSellingPriceGold, valid: false})
      inputInvalid('modal-investment-update-input-futures-selling-price-gold')
    }
  }

  const validateOriginalValorizationGold = () => {
    if (expressions.money.test( originalValorizationGold.value)) {
      setOriginalValorizationGold({...originalValorizationGold, valid: true})
      inputValid('modal-investment-update-input-original-valorization-gold')
    } else {
      setOriginalValorizationGold({...originalValorizationGold, valid: false})
      inputInvalid('modal-investment-update-input-original-valorization-gold')
    }
  }

  const validatePaymentPlanAmount = (index: number) => {
    if (expressions.money.test(investmentPaymentPlan.value[index].amount)) {
      let tempPaymentPlanValid = investmentPaymentPlan.valid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return {date: temp_item.date, amount: true}
        } else {
          return temp_item
        }
      })
      setInvestmentPaymentPlan({...investmentPaymentPlan, valid: tempPaymentPlanValid})
      inputValid('modal-investment-update-input-payment-amount-' + index)
    } else {
      let tempPaymentPlanValid = investmentPaymentPlan.valid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return {date: temp_item.date, amount: false}
        } else {
          return temp_item
        }
      })
      setInvestmentPaymentPlan({...investmentPaymentPlan, valid: tempPaymentPlanValid})
      inputInvalid('modal-investment-update-input-payment-amount-' + index)
    }
  }

  const executeAddPaymentPlan = () => {
    if (!investmentPaymentPlan.valid.some(item => !item.date || !item.amount)) {
      setInvestmentPaymentPlan(prevState => ({
        value: [...prevState.value, {date: '', amount: ''}],
        valid: [...prevState.valid, {date: false, amount: false}],
      }))
    }
  }

  const executeAddIntermediates = () => {
    if(investmentIntermediates.value.length < 3 && !investmentIntermediates.valid.some(item => !item.intermediary || !item.rate)) {
      setInvestmentIntermediates(prevState => ({
        value: [...prevState.value, {intermediary: '', rate: ''}],
        valid: [...prevState.valid, {intermediary: false, rate: false}],
      }))
    }
  }

  const executeRemovePaymentPlan = (index: number) => {
    if (index >= 2) {
      setInvestmentPaymentPlan(prevState => ({
        value: prevState.value.filter((_, sub_index) => sub_index !== index),
        valid: prevState.valid.filter((_, sub_index) => sub_index !== index),
      }))
    }
  }

  const executeRemoveIntermediates = (index: number) => {
    if (index >= 1) {
      setInvestmentIntermediates(prevState => ({
        value: prevState.value.filter((_, sub_index) => sub_index !== index),
        valid: prevState.valid.filter((_, sub_index) => sub_index !== index),
      }))
    }
  }

  function orderIngots(list: IngotModel[]): IngotModel[] {
    return listIngotSortedAscending(list, lang)
  }

  const executeHideModalInvestmentUpdate = () => {
    modalHide('modal-investment-update')

    setTimeout( () => {
      restartInput('modal-investment-update-input-volume-gold-purchased')
      restartInput('modal-investment-update-input-gold-futures-purchase-price')
      restartInput('modal-investment-update-input-futures-selling-price-gold')
      restartInput('modal-investment-update-input-original-valorization-gold')

      for (let index in investmentPaymentPlan.value) {
        restartInput('modal-investment-update-input-payment-amount-' + index)
      }
    }, 200 )
  }

  function selectedIngots(list: IngotModel[]): IngotModel[] {
    return list.filter(item => item !== null)
  }

  function amountPayment(): string {
    let total: number = 0
    investmentPaymentPlan.value.forEach(item => total += Number(item.amount))
    return total.toFixed(2)
  } 

  useEffect( () => {
    if(investment && securityCompanies) {
      let investmentIngotsValue: string[] = []
      let investmentIngotsValid: boolean[] = []
      let investmentPaymentPlanValue: {date: string, amount: string}[] = []
      let investmentPaymentPlanValid: {date: boolean, amount: boolean}[] = []
      let investmentIntermediateValue: {intermediary: string, rate: string}[] = []
      let investmentIntermediateValid: {intermediary: boolean, rate: boolean}[] = []

      investment.ingots.forEach(item => {
        investmentIngotsValue.push(item.id);
        investmentIngotsValid.push(true);
      })

      investment.payment_plan.forEach(item => {
        investmentPaymentPlanValue.push({date: format((new Date(dateString(item.date))), 'yyyy-MM-dd'), amount: item.amount.toString()});
        investmentPaymentPlanValid.push({date: true, amount: true});
      })

      investment.intermediates.forEach(item => {
        investmentIntermediateValue.push({intermediary: item.intermediary.id, rate: item.annual_commission.id});
        investmentIntermediateValid.push({intermediary: true, rate: true});
      })

      setAuthenticActDate({value: format((new Date(dateString(investment.authentic_act_date))), 'yyyy-MM-dd'), valid: true})
      setGoldPriceLockDate({value: format((new Date(dateString(investment.gold_price_date))), 'yyyy-MM-dd'), valid: true})
      setValidityRightRefusalDate({value: format((new Date(dateString(investment.retraction_expiration_date))), 'yyyy-MM-dd'), valid: true})
      setVolumeGoldPurchased({value: investment.gold_volume_purchased.toString(), valid: true})
      setOriginalValorizationGold({value: investment.original_valuation_gold_purchased.toString(), valid: true})
      setGoldFuturesPurchasePrice({value: investment.gold_purchase_price.toString(), valid: true})
      setfuturesSellingPriceGold({value: investment.gold_sale_price.toString(), valid: true})
      setSecurityCompany({value: investment.safe_box.id_company, object: securityCompanies.find(item => item.id === investment.safe_box.id_company), valid: true})
      setSafeNumber({value: investment.safe_box.safe_box_number, valid: true})
      setInvestmentNotary({value: investment.notary.id, object: investment.notary, valid: true})
      setInvestmentIngots({value: investmentIngotsValue, object: investment.ingots, valid: investmentIngotsValid})
      setInvestmentPaymentPlan({value: investmentPaymentPlanValue, valid: investmentPaymentPlanValid})
      setInvestmentIntermediates({value: investmentIntermediateValue, valid: investmentIntermediateValid})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investment])

  return (
    <div id="modal-investment-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.updatedInvestment}</h2>
            </div>
          </div>
          <div className="modal-body">
            { investment && ingots && rates && securityCompanies && intermediaries
              ?
              <div className="form">
                <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.dates}</p>
                <div className="card card-flush mb-5">
                  <div className="card-body p-0 ">
                    <div className="row">
                      <div className="mb-2 col-12 col-sm-4">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.authenticAct}</label>
                        <input className="form-control form-control-solid" type="date" name="authenticActDate" value={authenticActDate.value} onChange={handleChangeAuthenticAct} />
                      </div>
                      <div className="mb-2 col-12 col-sm-4">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.goldPriceLockDate}</label>
                        <input className="form-control form-control-solid" type="date" name="goldPriceLockDate" value={goldPriceLockDate.value} onChange={handleChangeGoldPriceLockDate} />
                      </div>
                      <div className="mb-2 col-12 col-sm-4">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.validityOfTheRightOfRefusal}</label>
                        <input className="form-control form-control-solid" type="date" name="validityOfTheRightOfRefusal" value={validityRightRefusalDate.value} onChange={handleChangeValidityRightRefusal} />
                      </div>
                    </div> 
                  </div>
                </div>
                <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.investment}</p>
                <div className="card card-flush mb-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="mb-2 col-12 col-sm-6">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.volumeOfGoldPurchased}</label>
                        <input id="modal-investment-update-input-volume-gold-purchased" className="form-control form-control-solid" type="text" name="volumeGoldPurchased" value={volumeGoldPurchased.value} onChange={handleChangeVolumeGoldPurchased} onKeyUp={validateVolumeGoldPurchased} onBlur={validateVolumeGoldPurchased}/>
                      </div>
                      <div className="mb-2 col-12 col-sm-6">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.originalValuationOfGoldPurchased}</label>
                        <input id="modal-investment-update-input-original-valorization-gold" className="form-control form-control-solid" type="text" name="originalValorizationGold" value={originalValorizationGold.value} onChange={handleChangeOriginalValorizationGold} onKeyUp={validateOriginalValorizationGold} onBlur={validateOriginalValorizationGold} />
                      </div>
                    </div> 
                    <div className="row">
                      <div className="mb-2 col-12 col-sm-6">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.goldFuturesPurchasePrice}</label>
                        <input id="modal-investment-update-input-gold-futures-purchase-price" className="form-control form-control-solid" type="text" name="goldFuturesPurchasePrice" value={goldFuturesPurchasePrice.value} onChange={handleChangeGoldFuturesPurchasePrice} onKeyUp={validateGoldFuturesPurchasePrice} onBlur={validateGoldFuturesPurchasePrice} />
                      </div>
                      <div className="mb-2 col-12 col-sm-6">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.futuresSellingPriceOfGold}</label>
                        <input id="modal-investment-update-input-futures-selling-price-gold" className="form-control form-control-solid" type="text" name="futuresSellingPriceGold" value={futuresSellingPriceGold.value} onChange={handleChangeFuturesSellingPriceGold} onKeyUp={validateFuturesSellingPriceGold} onBlur={validateFuturesSellingPriceGold} />
                      </div>
                    </div>
                  </div>
                </div>
                <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.ingots}</p>
                { investmentIngots.value.length > 0
                  ?
                  <div className="card card-flush mb-5">
                    <div className="card-body p-0">
                    { investmentIngots.value.map(((_, index) => { return (
                      <div key={index} className="d-flex align-items-center my-2">
                        <label className="w-100px fs-7 fw-bold required">{lang.labels.ingot} {index + 1}:</label>
                        <div className="w-100">
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between">
                                  <span className="select2-selection__rendered" role="textbox">
                                    <span className="select2-selection__placeholder">
                                      {investmentIngots.valid[index] ? `${investmentIngots.object[index].refiner.name} ${investmentIngots.object[index].weight.value} ${weightUnitFormat(investmentIngots.object[index].weight.unity, lang)} ${investmentIngots.object[index].purity.value}, ${investmentIngots.object[index].serial_number}` : lang.labels.selectOption}
                                    </span>
                                  </span>
                                  { investmentIngots.valid[index] &&
                                    <span className="svg-icon svg-icon-primary svg-icon-2" onClick={(e) => handleClearIngot(e, index)}>
                                      <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                      </svg>
                                    </span>
                                  }
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    { orderIngots(ingots).map((sub_item, sub_index) => (
                                        <li key={sub_index} className={`select2-results__option select2-results__option--selectable ${sub_item.id === investmentIngots.value[index] ? "select2-results__option--selected" : investmentIngots.value.some(temp_item => temp_item === sub_item.id) && "d-none"}`} role="option" aria-selected={sub_item.id === investmentIngots.value[index] ? "true" : "false"}>
                                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeIngot(sub_item, index)}>
                                            {sub_item.refiner.name} {sub_item.weight.value} {weightUnitFormat(sub_item.weight.unity, lang)} {sub_item.purity.value}, {sub_item.serial_number}
                                          </Dropdown.Item>
                                        </li>
                                      ))}
                                    <li className={`select2-results__option select2-results__option--selectable ${!ingots.every(sub_item => investmentIngots.value.some(temp_item => temp_item === sub_item.id)) && "d-none"}`} role="option" aria-selected="false">
                                      <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.noneIngots}</Dropdown.Item>
                                    </li>
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}))}
                    { selectedIngots(investmentIngots.object).length > 0 &&
                      <div className="mt-5">
                        <p className="fs-6 fw-bold text-gray-600 mb-2">{lang.labels.detailOfTheIngots}</p>
                        <div className="table-responsive">
                          <table className="table align-middle table-row-dashed mb-2 gy-1">
                            <thead>
                              <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase">
                                <th className="w-25px">{lang.labels.ingot}</th>
                                <th className="w-200px">{lang.labels.serialNumber}</th>
                                <th className="w-200px">{lang.labels.refiner}</th>
                                <th className="w-200px">{lang.labels.ingotWeight}</th>
                                <th className="w-200px">{lang.labels.purity}</th>
                                <th className="w-200px">{lang.labels.originalValue}</th>
                              </tr>
                            </thead>
                            <tbody className="text-gray-600 fw-bold fs-6">
                              { selectedIngots(investmentIngots.object).map(((item, index) => { return (
                                <tr key={index} className="row-dashed">
                                  <td>{index + 1}</td>
                                  <td>{item.serial_number}</td>
                                  <td>{item.refiner.name}</td>
                                  <td>{item.weight.value} {item.weight.unity.toUpperCase()}</td>
                                  <td>{item.purity.value}</td>
                                  <td>{moneyFormat(item.original_value)}</td>
                                </tr>
                              )}))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }
                    </div>
                  </div>
                  :
                  <div className="card card-flush mb-5">
                    <span className="fs-6 fw-bold my-3">{lang.labels.noneIngots}</span>
                  </div>
                }
                <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.personalSafe}</p>
                <div className="card card-flush mb-5">
                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-12 col-sm-6">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.securityCompany}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    { securityCompany.valid ? securityCompany.object.name : lang.labels.selectOption }
                                  </span>
                                </span>
                                { securityCompany.valid &&
                                  <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearSecurityCompany}>
                                    <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                  </span>
                                }
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { securityCompanies.map((item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === securityCompany.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.id === securityCompany.value ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeSecurityComany(item)}>{item.name}</Dropdown.Item>
                                    </li>
                                  )})}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-12 col-sm-6">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.safeNumber}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    { safeNumber.valid ? safeNumber.value : lang.labels.selectOption }
                                  </span>
                                </span>
                                { safeNumber.valid &&
                                  <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearSafeNumber}>
                                    <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                    </svg>
                                  </span>
                                }
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { securityCompany.valid
                                    ?
                                    <>
                                      { securityCompany.object.number_safes.map((item: number, index: number) => (
                                        <li key={index} className={`select2-results__option select2-results__option--selectable ${item.toString() === safeNumber.value && "select2-results__option--selected"}`} role="option" aria-selected={item.toString() === safeNumber.value ? "true" : "false"}>
                                          <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeSafeNumber(item)}>{item}</Dropdown.Item>
                                        </li>
                                      ))}
                                    </>
                                    :
                                    <li className="select2-results__option select2-results__option--selectable select2-results__option--selected" role="option" aria-selected="false">
                                      <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.nonEsxistOptions}</Dropdown.Item>
                                    </li>
                                  }
                                  
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                { notaries &&
                  <>
                    <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.notaries}</p>
                    <div className="card card-flush mb-5">
                      <div className="card-body p-0">
                        <label className="col-form-label fw-bold fs-6 required">{lang.labels.notary}</label>
                          <Dropdown>
                            <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between">
                                  <span className="select2-selection__rendered" role="textbox">
                                    <span className="select2-selection__placeholder">
                                      { investmentNotary.valid ? `${investmentNotary.object.first_name} ${investmentNotary.object.last_name}` : lang.labels.selectOption }
                                    </span>
                                  </span>
                                  { investmentNotary.valid &&
                                    <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearNotary}>
                                      <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                      </svg>
                                    </span>
                                  }
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    { notaries.map((item, index) => { return (
                                      <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === investmentNotary.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.id === investmentNotary.value ? "true" : "false"}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeNotary(item)}>{item.first_name} {item.last_name}</Dropdown.Item>
                                      </li>
                                    )})}
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                      </div>
                    </div>
                  </>
                }
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.paymentPlan}</p>
                  <button className="btn btn-sm btn-primary" onClick={executeAddPaymentPlan}>{lang.labels.addPaymentPlan}</button>
                </div>
                <div className="card card-flush mb-5">
                  <div className="card-body p-0">
                    { investmentPaymentPlan.value.map((item, index) => (
                      <div key={index} className="row">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="col-form-label fw-bold fs-6">{lang.labels.payment} {index + 1}:</label>
                          { index >= 2 &&
                            <div className="d-flex align-items-center svg-icon svg-icon-3 cursor-pointer" onClick={() => executeRemovePaymentPlan(index)}>
                              <span className="fs-7 pe-1 text-primary">
                                {lang.labels.delete}
                              </span>
                              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#7239ea" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </div>
                          }
                        </div>
                        <div className="col-12 col-sm-6 mb-5 mb-sm-2">
                          <span className="required">{lang.labels.date}</span>
                          <input className="form-control form-control-solid" type="date" name="paymentPlanDate" value={item.date} onChange={(event) => handleChangePaymentPlanDate(index, event)} />
                        </div>
                        <div className="col-12 col-sm-6 mb-5 mb-sm-2">
                          <span className="required">{lang.labels.amount}</span>
                          <input id={"modal-investment-update-input-payment-amount-" + index} className="form-control form-control-solid" type="text" placeholder={amountPayment()} value={item.amount} onChange={(event) => handleChangePaymentPlanAmount(index, event)}  onKeyUp={() => validatePaymentPlanAmount(index)} onBlur={() => validatePaymentPlanAmount(index)} />
                        </div>
                      </div> 
                    ))}
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <p className="fs-5 fw-bold text-gray-600 mb-0">{lang.labels.intermediaries}</p>
                  <button className={`btn btn-sm btn-primary ${investmentIntermediates.value.length >= 3 ? "disabled" : "enabled"}`} onClick={executeAddIntermediates}>{lang.labels.addIntermediaries}</button>
                </div>
                <div className="card card-flush">
                  <div className="card-body p-0">
                    { investmentIntermediates.value.map((item, index) => (
                      <div key={index} className="row">
                        <div className="d-flex justify-content-between align-items-center">
                          <label className="col-form-label fw-bold fs-6">{lang.labels.intermediary} {index + 1}:</label>
                          { index >= 1 && item &&
                            <div className="d-flex align-items-center svg-icon svg-icon-3 cursor-pointer" onClick={() => executeRemoveIntermediates(index)}>
                              <span className="fs-7 pe-1 text-primary">
                                {lang.labels.delete}
                              </span>
                              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#7239ea" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </div>
                          }
                        </div>
                        <div className="col-12 col-sm-6 mb-5 mb-sm-2">
                          <span className="required">{lang.labels.intermediary}</span>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0 mt-2">
                              <span className="selection">
                                <span className="select2-selection select2-selection--single form-select form-select-solid">
                                  <span className="select2-selection__rendered" role="textbox">
                                    <span className="select2-selection__placeholder">
                                      { investmentIntermediates.valid[index].intermediary ? `${intermediaries.find(sub_item => sub_item.id === investmentIntermediates.value[index].intermediary)!.first_name} ${intermediaries.find(sub_item => sub_item.id === investmentIntermediates.value[index].intermediary)!.last_name}` : lang.labels.selectOption }
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                              <span className="select2-dropdown select2-dropdown--below">
                                <span className="select2-results">
                                  <ul className="select2-results__options" role="listbox">
                                    { intermediaries.map((sub_item, sub_index) => ( 
                                      <li key={sub_index} className={`select2-results__option select2-results__option--selectable ${sub_item.id === investmentIntermediates.value[index].intermediary && "select2-results__option--selected"}`} role="option" aria-selected={sub_item.id === investmentIntermediates.value[index].intermediary ? "true" : "false"}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeIntermediary(index, sub_item.id)}>{sub_item.first_name} {sub_item.last_name}</Dropdown.Item>
                                      </li>
                                    ))}
                                  </ul>
                                </span>
                              </span>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="col-12 col-sm-6 mb-5 mb-sm-2">
                          <span className="required">{lang.labels.commision}</span>
                          <Dropdown>
                      <Dropdown.Toggle
                        variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0 mt-2">
                        <span className="selection">
                          <span className="select2-selection select2-selection--single form-select form-select-solid">
                            <span className="select2-selection__rendered" role="textbox">
                              <span className="select2-selection__placeholder">
                                { investmentIntermediates.valid[index].rate ? rateSelected(rates, investmentIntermediates.value[index].rate, lang) : lang.labels.selectOption }
                              </span>
                            </span>
                          </span>
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                        <span className="select2-dropdown select2-dropdown--below">
                          <span className="select2-results">
                            <ul className="select2-results__options" role="listbox">
                              { rates.map((sub_item, sub_index) => ( 
                                <li key={sub_index} className={`select2-results__option select2-results__option--selectable ${ sub_item.id === investmentIntermediates.value[index].rate && "select2-results__option--selected"}`} role="option" aria-selected={sub_item.id === investmentIntermediates.value[index].rate ? "true" : "false"}>
                                  <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeRate(index, sub_item.id)}>
                                    {sub_item.value.toFixed(2)} % {sub_item.type === "unique" ? lang.labels.ofSingleCommission : `${lang.labels.ofAnnualRecurringCommission} ${sub_item.years} ${lang.labels.years}`}
                                  </Dropdown.Item>
                                </li>
                              ))}
                            </ul>
                          </span>
                        </span>
                      </Dropdown.Menu>
                    </Dropdown>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitInvestmentUpdate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary  ms-3" type="reset" onClick={executeHideModalInvestmentUpdate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalInvestmentUpdate;
