import React from 'react';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

import background from '../../assets/images/themes/image-error.png';

export interface NotFoundAuthPageProps {};

const NotFoundAuthPage: React.FunctionComponent<NotFoundAuthPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate('/auth/login', {replace: true})
  }

  return (
    <div className="w-lg-700px bg-body rounded shadow-sm p-10 mx-auto">
      <div className="d-flex flex-column flex-column-fluid text-center">
        <div className="mb-20">
          <h1 className="fw-bolder fs-3x text-gray-900 mb-10">{lang.labels.pageNotFound}</h1>
          <div className="fw-bold fs-3 text-gray-400 mb-10">
            {lang.labels.thePageYouLookedNotFound}
            <br />
            {lang.labels.pleaseCorrectThePathOfThePageInOrderToAccess}
          </div>
          <div className="text-center">
            <button className="btn btn-lg btn-primary fw-bolder" onClick={handleOnClick}>{lang.labels.goToLoginPage}</button>
          </div>
        </div>
        <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-150px" style={{backgroundImage: `url(${background})`}}></div>
      </div>
    </div>
  )
};

export default NotFoundAuthPage;
