import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { format } from 'date-fns';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { inputValid, inputInvalid, restartInput } from '../../../types/legend.input.type';
import { Message } from '../../../models/message.model';
import { UseOutletContextIntermediary } from './Intermediary';
import { IntermediaryService } from '../../../services/intermediary.service';
import { IntermediaryModel } from '../../../models/intermediary.model';
import { civilStatusList, civilStatusValue } from '../../../scripts/civil.status.script';
import { getNames, getCode, getName } from '../../../scripts/countries.script'
import { inputFileSizeInvalid, inputFileTypeInvalid, inputFileTypeSizeValid, restartInputFileTypeSize } from '../../../types/legend.input.file.type';
import { removeTooltip, uploadTooltip } from '../../../types/tooltip.type';
import { cleanInputFile } from '../../../types/input.file.type';
import { extractBase64 } from '../../../scripts/extract.base.64.script';
import { FaExclamationCircle } from 'react-icons/fa';
import imageDefault from '../../../assets/images/default.png';
import imageUpload from '../../../assets/images/upload.png';
import { expressions } from '../../../scripts/regular.expressions.script';

interface IntermediaryUpdateProps {};

let errorResponse: Error, messageResponse: Message, serviceResponse: any;

const IntermediaryUpdatePage: React.FunctionComponent<IntermediaryUpdateProps> = () => {
  const { setRoute } = UseOutletContextIntermediary()
  const { lang } = StateLanguage()
  const navigate = useNavigate()
  const param = useParams()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [avatar, setAvatar] = useState({value: '', file: null as any, valid: true})
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [dateOfBirth, setDateOfBirth] = useState({value: '', valid: false})
  const [placeOfBirth, setPlaceOfBirth] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [postcode, setPostcode] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [tin, setTin] = useState({value: '', valid: false})
  const [nationality, setNationality] = useState({value: '', valid: false})
  const [civilStatus, setCivilStatus] = useState({value: '', valid: false})
  const [iban, setIban] = useState({value: '', valid: false})
  const [swiftBic, setSwiftBic] = useState({value: '', valid: false})
  const [companyName, setCompanyName] = useState({value: '', valid: false})
  const [companyBusinessNumber, setCompanyBusinessNumber] = useState({value: '', valid: false})
  const [companyAddress, setCompanyAddress] = useState({value: '', valid: false})
  const [companyPostcode, setCompanyPostcode] = useState({value: '', valid: false})
  const [companyCity, setCompanyCity] = useState({value: '', valid: false})
  const [companyCountry, setCompanyCountry] = useState({value: '', valid: false})
  const [companyPhone, setCompanyPhone] = useState({value: '', valid: false})
  const [companyEmail, setCompanyEmail] = useState({value: '', valid: false})
  const [companyWebsite, setCompanyWebsite] = useState({value: '', valid: false})
  const [companyIban, setCompanyIban] = useState({value: '', valid: false})
  const [companySwiftBic, setCompanySwiftBic] = useState({value: '', valid: false})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})
  const [intermediary, setIntermediary] = useState<IntermediaryModel | undefined | null>(null)

  const loadIntermediary = async (id_intermediary: string) => {
    await IntermediaryService.getIntermediary(id_intermediary).then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data

        setIntermediary(serviceResponse)
        setAvatar({value: serviceResponse.avatar, file: serviceResponse.avatar, valid: true})
        setFirstName({value: serviceResponse.first_name, valid: true})
        setLastName({value: serviceResponse.last_name, valid: true})
        setDateOfBirth({value: serviceResponse.date_of_birth, valid: true})
        setPlaceOfBirth({value: serviceResponse.place_of_birth, valid: true})
        setEmail({value: serviceResponse.email, valid: true})
        setPhone({value: serviceResponse.phone, valid: true})
        setStreet({value: serviceResponse.address.street, valid: true})
        setPostcode({value: serviceResponse.address.zip_code, valid: true})
        setCity({value: serviceResponse.address.city, valid: true})
        setCountry({value: serviceResponse.address.country, valid: true})
        setTin({value: serviceResponse.tin, valid: true})
        setNationality({value: serviceResponse.nationality, valid: true})
        setCivilStatus({value: serviceResponse.civil_status, valid: true})
        setIban({value: serviceResponse.iban, valid: true})
        setSwiftBic({value: serviceResponse.swift_bic, valid: true})
        setCompanyName({value: serviceResponse.company.name, valid: true})
        setCompanyBusinessNumber({value: serviceResponse.company.number, valid: true})
        setCompanyAddress({value: serviceResponse.company.address.street, valid: true})
        setCompanyPostcode({value: serviceResponse.company.address.zip_code, valid: true})
        setCompanyCity({value: serviceResponse.company.address.city, valid: true})
        setCompanyCountry({value: serviceResponse.company.address.country, valid: true})
        setCompanyPhone({value: serviceResponse.company.phone, valid: true})
        setCompanyEmail({value: serviceResponse.company.email, valid: true})
        setCompanyWebsite({value: serviceResponse.company.website, valid: true})
        setCompanyIban({value: serviceResponse.company.iban, valid: true})
        setCompanySwiftBic({value: serviceResponse.company.swift_bic, valid: true})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitIntermediaryUpdate = async () => {
    setLoadIndicator('on')

    if (intermediary && avatar.valid && firstName.valid && lastName.valid && dateOfBirth.valid && placeOfBirth.valid && email.valid && phone.valid && street.valid && postcode.valid && city.valid && country.valid && tin.valid && nationality.valid && civilStatus.valid && iban.valid && swiftBic.valid && companyName.valid && companyBusinessNumber.valid && companyAddress.valid && companyPostcode.valid && companyCity.valid && companyCountry.valid && companyPhone.valid && companyEmail.valid && companyWebsite.valid && companyIban.valid && companySwiftBic.valid) {
      let valueIban: string = iban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueBic: string = swiftBic.value.toUpperCase().trim().replaceAll(' ', '')
      let valueCompanyIban: string = companyIban.value.toUpperCase().trim().replaceAll(' ', '')
      let valueCompanyBic: string = companySwiftBic.value.toUpperCase().trim().replaceAll(' ', '')

      await IntermediaryService.putIntermediary(intermediary.id, avatar.file, firstName.value, lastName.value, dateOfBirth.value, placeOfBirth.value, email.value, phone.value, street.value, postcode.value, city.value, country.value, tin.value, nationality.value, civilStatus.value, valueIban, valueBic, companyName.value, companyBusinessNumber.value, companyAddress.value, companyPostcode.value, companyCity.value, companyCountry.value, companyPhone.value, companyEmail.value, companyWebsite.value, valueCompanyIban, valueCompanyBic).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.codeLanguage),
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }  

  const onCancel = async () => {
    if (intermediary) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToCancel,
        text: lang.labels.ifYouAcceptYouMayHaveToFillOutTheFormAgain,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yes,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(-1)
        }
      })
    }
  }

  const handleChangeAvatar = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576

    if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
      setAvatar({...avatar, value: '', file: null, valid: true})
      inputFileTypeInvalid('container-validate-avatar-type', 'container-validate-avatar-size')
      cleanInputFile()
    } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
      setAvatar({...avatar, value: '', file: null, valid: true})
      inputFileSizeInvalid('container-validate-avatar-type', 'container-validate-avatar-size')
      cleanInputFile()
    } else {
      extractBase64(archiveCapture).then((image: any) => {
        setAvatar({...avatar, value: image.base, file: archiveCapture, valid: true})
        inputFileTypeSizeValid('container-validate-avatar-type', 'container-validate-avatar-size')
      })
    }
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeDateOfBirth = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDateOfBirth({...dateOfBirth, value: event.target.value, valid: event.target.value.length > 0})
  }

    const handleChangePlaceOfBirth = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPlaceOfBirth({...placeOfBirth, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeAddress = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangePostcode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPostcode({...postcode, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangeTin = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTin({...tin, value: event.target.value})
  }

  const handleChangeNationality = (item: string) => {
    setNationality({...nationality, value: item, valid: true})
  }

  const handleChangeCivilStatus = (item: string) => {
    setCivilStatus({...civilStatus, value: item, valid: true})
  }

  const handleChangeIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setIban({...iban, value: event.target.value})
  }

  const handleChangeBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setSwiftBic({...swiftBic, value: event.target.value})
  }

  const handleChangeCompanyName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyName({...companyName, value: event.target.value})
  }

  const handleChangeCompanyBusinessNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyBusinessNumber({...companyBusinessNumber, value: event.target.value})
  }

  const handleChangeCompanyAddress = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyAddress({...companyAddress, value: event.target.value})
  }

  const handleChangeCompanyPostcode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyPostcode({...companyPostcode, value: event.target.value})
  }

  const handleChangeCompanyCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyCity({...companyCity, value: event.target.value})
  }

  const handleChangeCompanyCountry  = (item: string) => {
    setCompanyCountry({...companyCountry, value: item, valid: true})
  }

  const handleChangeCompanyPhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyPhone({...companyPhone, value: event.target.value})
  }

  const handleChangeCompanyEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyEmail({...companyEmail, value: event.target.value})
  }

  const handleChangeCompanyWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyWebsite({...companyWebsite, value: event.target.value})
  }

  const handleChangeCompanyIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanyIban({...companyIban, value: event.target.value})
  }

  const handleChangeCompanyBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCompanySwiftBic({...companySwiftBic, value: event.target.value})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const handleRemoveAvatar = () => {
    setAvatar({...avatar, value: '', file: null, valid: true})
    restartInputFileTypeSize('container-validate-avatar-type', 'container-validate-avatar-size')
    removeTooltip()
    cleanInputFile()
  }

  const validateFirstName = () => {
    if (expressions.text.test(firstName.value)) {
      setFirstName({...firstName, valid: true})
      inputValid('input-first-name')
    } else {
      setFirstName({...firstName, valid: false})
      inputInvalid('input-first-name')
    }
  }

  const validateLastName = () => {
    if (expressions.text.test(lastName.value)) {
      setLastName({...lastName, valid: true})
      inputValid('input-last-name')
    } else {
      setLastName({...lastName, valid: false})
      inputInvalid('input-last-name')
    }
  }

  const validatePlaceOfBirth = () => {
    if (expressions.text.test(placeOfBirth.value)) {
      setPlaceOfBirth({...placeOfBirth, valid: true})
      inputValid('input-place-of-birth')
    } else {
      setPlaceOfBirth({...placeOfBirth, valid: false})
      inputInvalid('input-place-of-birth')
    }
  }

  const validateEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email, valid: true})
      inputValid('input-email')
    } else {
      setEmail({...email, valid: false})
      inputInvalid('input-email')
    }
  }

  const validatePhone = () => {
    if (expressions.phone.test(phone.value)) {
      setPhone({...phone, valid: true})
      inputValid('input-phone')
    } else {
      setPhone({...phone, valid: false})
      inputInvalid('input-phone')
    }
  }

  const validateStreet = () => {
    if (expressions.street.test(street.value)) {
      setStreet({...street, valid: true})
      inputValid('input-street')
    } else {
      setStreet({...street, valid: false})
      inputInvalid('input-street')
    }
  }

  const validatePostcode = () => {
    if (expressions.postcode.test(postcode.value)) {
      setPostcode({...postcode, valid: true})
      inputValid('input-postcode')
    } else {
      setPostcode({...postcode, valid: false})
      inputInvalid('input-postcode')
    }
  }

  const validateCity = () => {
    if (expressions.location.test(city.value)) {
      setCity({...city, valid: true})
      inputValid('input-city')
    } else {
      setCity({...city, valid: false})
      inputInvalid('input-city')
    }
  }

  const validateTin = () => {
    if (tin.value.length > 0) {
      if (expressions.nif.test(tin.value)) {
        setTin({...tin, valid: true})
        inputValid('input-tin')
      } else {
        setTin({...tin, valid: false})
        inputInvalid('input-tin')
      }
    } else if (iban.value.length === 0) {
      setTin({...tin, valid: true})
      restartInput('input-tin')
    }
  }

  const validateIban = () => {
    if (iban.value.length > 0) {
      if (expressions.iban.test(iban.value)) {
        setIban({...iban, valid: true})
        inputValid('input-iban')
      } else {
        setIban({...iban, valid: false})
        inputInvalid('input-iban')
      }
    } else if (iban.value.length === 0) {
      setIban({...iban, valid: true})
      restartInput('input-iban')
    }
  }

  const validateSwiftBic = () => {
    if (swiftBic.value.length > 0) {
      if (expressions.swiftBic.test(swiftBic.value)) {
        setSwiftBic({...swiftBic, valid: true})
        inputValid('input-swift-bic')
      } else {
        setSwiftBic({...swiftBic, valid: false})
        inputInvalid('input-swift-bic')
      }
    } else if (swiftBic.value.length === 0) {
      setSwiftBic({...swiftBic, valid: true})
      restartInput('input-swift-bic')
    }
  }

  const validateCompanyName = () => {
    if (companyName.value.length > 0) {
      if (expressions.text.test(companyName.value)) {
        setCompanyName({...companyName, valid: true})
        inputValid('input-company-name')
      } else {
        setCompanyName({...companyName, valid: false})
        inputInvalid('input-company-name')
      }
    } else if (companyName.value.length === 0) {
      setCompanyName({...companyName, valid: true})
      restartInput('input-company-name')
    }
  }

  const validateCompanyBusinessNumber = () => {
    if (companyBusinessNumber.value.length > 0) {
      if (expressions.business.test(companyBusinessNumber.value)) {
        setCompanyBusinessNumber({...companyBusinessNumber, valid: true})
        inputValid('input-company-business-number')
      } else {
        setCompanyBusinessNumber({...companyBusinessNumber, valid: false})
        inputInvalid('input-company-business-number')
      }
    } else if (companyBusinessNumber.value.length === 0) {
      setCompanyBusinessNumber({...companyBusinessNumber, valid: true})
      restartInput('input-company-business-number')
    }
  }

  const validateCompanyStreet = () => {
    if (companyAddress.value.length > 0) {
      if (expressions.street.test(companyAddress.value)) {
        setCompanyAddress({...companyAddress, valid: true})
        inputValid('input-company-street')
      } else {
        setCompanyAddress({...companyAddress, valid: false})
        inputInvalid('input-company-street')
      }
    } else if (companyAddress.value.length === 0) {
      setCompanyAddress({...companyAddress, valid: true})
      restartInput('input-company-street')
    }
  }

  const validateCompanyPostcode = () => {
    if (companyPostcode.value.length > 0) {
      if (expressions.postcode.test(companyPostcode.value)) {
        setCompanyPostcode({...companyPostcode, valid: true})
        inputValid('input-company-postcode')
      } else {
        setCompanyPostcode({...companyPostcode, valid: false})
        inputInvalid('input-company-postcode')
      }
    } else if (companyPostcode.value.length === 0) {
      setCompanyPostcode({...companyPostcode, valid: true})
      restartInput('input-company-postcode')
    }
  }

  const validateCompanyCity = () => {
    if (companyCity.value.length > 0) {
      if (expressions.location.test(companyCity.value)) {
        setCompanyCity({...companyCity, valid: true})
        inputValid('input-company-city')
      } else {
        setCompanyCity({...companyCity, valid: false})
        inputInvalid('input-company-city')
      }
    } else if (companyCity.value.length === 0) {
      setCompanyCity({...companyCity, valid: true})
      restartInput('input-company-city')
    }
  }

  const validateCompanyPhone = () => {
    if (companyPhone.value.length > 0) {
      if (expressions.phone.test(companyPhone.value)) {
        setCompanyPhone({...companyPhone, valid: true})
        inputValid('input-company-phone')
      } else {
        setCompanyPhone({...companyPhone, valid: false})
        inputInvalid('input-company-phone')
      }
    } else if (companyPhone.value.length === 0) {
      setCompanyPhone({...companyPhone, valid: true})
      restartInput('input-company-phone')
    }
  }

  const validateCompanyEmail = () => {
    if (companyEmail.value.length > 0) {
      if (expressions.email.test(companyEmail.value)) {
        setCompanyEmail({...companyEmail, valid: true})
        inputValid('input-company-email')
      } else {
        setCompanyEmail({...companyEmail, valid: false})
        inputInvalid('input-company-email')
      }
    } else if (companyEmail.value.length === 0) {
      setCompanyEmail({...companyEmail, valid: true})
      restartInput('input-company-email')
    }
  }

  const validateCompanyWebsite = () => {
    if (companyWebsite.value.length > 0) {
      if (expressions.website.test(companyWebsite.value)) {
        setCompanyWebsite({...companyWebsite, valid: true})
        inputValid('container-validate-company-website')
      } else {
        setCompanyWebsite({...companyWebsite, valid: false})
        inputInvalid('container-validate-company-website')
      }
    } else if (companyWebsite.value.length === 0) {
      setCompanyWebsite({...companyWebsite, valid: true})
      restartInput('container-validate-company-website')
    }
  }

  const validateCompanyIban = () => {
    if (companyIban.value.length > 0) {
      if (expressions.iban.test(companyIban.value)) {
        setCompanyIban({...companyIban, valid: true})
        inputValid('input-company-iban')
      } else {
        setCompanyIban({...companyIban, valid: false})
        inputInvalid('input-company-iban')
      }
    } else if (companyIban.value.length === 0) {
      setCompanyIban({...companyIban, valid: true})
      restartInput('input-company-iban')
    }
  }

  const validateCompanySwiftBic = () => {
    if (companySwiftBic.value.length > 0) {
      if (expressions.swiftBic.test(companySwiftBic.value)) {
        setCompanySwiftBic({...companySwiftBic, valid: true})
        inputValid('input-company-swift-bic')
      } else {
        setCompanySwiftBic({...companySwiftBic, valid: false})
        inputInvalid('input-company-swift-bic')
      }
    } else if (companySwiftBic.value.length === 0) {
      setCompanySwiftBic({...companySwiftBic, valid: true})
      restartInput('input-company-swift-bic')
    }
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  useEffect( () => {
    if(param.id) {
      loadIntermediary(param.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.intermediaries, branch: lang.labels.updateIntermediary}, browse: null})
    uploadTooltip()

    return () => setMounted(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { intermediary
        ?
        <div className="form">
          <div className="card card-flush mb-5">
            <div className="card-header">
              <div className="card-title">
                <h3 className="text-uppercase">{lang.labels.pleaseEnterTheFollowingInformationToUpdateAnIntermedary}</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              <p className="fst-italic text-muted mb-10">
                {lang.labels.fieldsMarkedWithAnAsterisk}<span className="text-danger">(*)</span>{lang.labels.areRequired}
              </p>
              <h5 className="mb-5">{lang.labels.information}</h5>
              <div className="row mb-5">
                <label className="col-form-label fs-6 d-flex align-items-center">
                  {lang.labels.avatar}
                  <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.addImageOrDragAndDrop}><FaExclamationCircle /></i>
                </label>
                <div className="my-5">
                  <div className="position-relative image-input image-input-outline" data-kt-image-input="true">
                    <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="file" accept=".png, .jpg, .jpeg" onChange={handleChangeAvatar} />
                    <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white ${(!avatar.valid || avatar.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveAvatar}>
                      <i className="bi bi-x fs-2"></i>
                    </span>
                    <img src={avatar.valid && avatar.value.length > 0 ? avatar.value : imageDefault} className="image-input-wrapper w-200px h-200px" alt="avatar" />
                    <div className="position-absolute top-50 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center" style={{ pointerEvents: 'none' }}>
                      <img src={imageUpload} className="w-35px h-35px opacity-50" alt="Upload" />
                    </div>
                  </div>
                </div>
                <div id="container-validate-avatar-type" className="fv-plugins-message-container invalid-feedback d-none">
                  <div data-field="avatar" data-validator="type">{lang.labels.invalidFileType}</div>
                </div>
                <div id="container-validate-avatar-size" className="fv-plugins-message-container invalid-feedback d-none">
                  <div data-field="avatar" data-validator="size">{lang.labels.fileSizeNotAllowed}</div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label required fs-6">{lang.labels.firstName}</label>
                  <input id="input-first-name" className="form-control form-control-solid" type="text" name="first-name" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label required fs-6">{lang.labels.lastName}</label>
                  <input id="input-last-name" className="form-control form-control-solid" type="text" name="last-name" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label required fs-6">{lang.labels.email}</label>
                  <input id="input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label required fs-6">{lang.labels.phone}</label>
                  <input id="input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                </div>
              </div>
              <div className="row mb-10">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label required fs-6">{lang.labels.dateOfBirth}</label>
                  <input className="form-control form-control-solid pe-2" type="date" name="dateOfBirth" max={maxDate()} value={dateOfBirth.value} onChange={handleChangeDateOfBirth} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label required fs-6">{lang.labels.placeOfBirth}</label>
                  <input id="input-place-of-birth" className="form-control form-control-solid" type="text" name="place-of-birth" value={placeOfBirth.value} onChange={handleChangePlaceOfBirth} onKeyUp={validatePlaceOfBirth} onBlur={validatePlaceOfBirth} />
                </div>
              </div>
              <h5 className="mb-5">{lang.labels.additionalInformation}</h5>
              <div className="mb-5">
                <label className="col-form-label required fs-6">{lang.labels.street}</label>
                <input id="input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeAddress} onKeyUp={validateStreet} onBlur={validateStreet} />
              </div>
              <div className="row mb-10">
                <div className="mb-5 mb-xl-0 col-xl-3">
                  <label className="col-form-label required fs-6">{lang.labels.postcode}</label>
                  <input id="input-postcode" className="form-control form-control-solid" type="text" name="postcode" value={postcode.value} onChange={handleChangePostcode} onKeyUp={validatePostcode} onBlur={validatePostcode} />
                </div>
                <div className="mb-5 mb-xl-0 col-xl-4">
                  <label className="col-form-label required fs-6">{lang.labels.city}</label>
                  <input id="input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                </div>
                <div className="col-xl-5">
                  <label className="col-form-label required fs-6">{lang.labels.country}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {country.valid ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.length > 0
                              ?
                              <>
                                { searchCountry.list.map (( (item, index) => { return (
                                  <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === nationality.value ? "true" : "false"}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                  </li>
                                )}))}
                              </>
                              :
                              <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                            }
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <h5 className="mb-5">{lang.labels.extraInformation}</h5>
              <div className="row mb-10">
                <div className="mb-5 mb-xl-0 col-xl-4">
                  <label className="col-form-label fs-6">{lang.labels.tin}</label>
                  <input id="input-tin" className="form-control form-control-solid" type="text" name="tin" value={tin.value} onChange={handleChangeTin} onKeyUp={validateTin} onBlur={validateTin} />
                </div>
                <div className="mb-5 mb-xl-0 col-xl-4">
                  <label className="col-form-label required fs-6">{lang.labels.nationality}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {nationality.valid ? getName(nationality.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.length > 0
                              ?
                              <>
                                { searchCountry.list.map (( (item, index) => { return (
                                  <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === nationality.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === nationality.value ? "true" : "false"}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeNationality(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                  </li>
                                )}))}
                              </>
                              :
                              <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                            }
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-xl-4">
                  <label className="col-form-label required fs-6">{lang.labels.maritalStatus}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {civilStatus.valid && civilStatus.value.length > 0 ? civilStatusValue(civilStatus.value, lang) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { civilStatusList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === civilStatus.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === civilStatus.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCivilStatus(item.code)}>{item.desc}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <h5 className="mb-5">{lang.labels.bankInformation}</h5>
              <div className="row">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.iban}</label>
                  <input id="input-iban" className="form-control form-control-solid" type="text" name="iban" value={iban.value} onChange={handleChangeIban} onKeyUp={validateIban} onBlur={validateIban} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.bicSwift}</label>
                  <input id="input-swift-bic" className="form-control form-control-solid" type="text" name="swift-bic" value={swiftBic.value} onChange={handleChangeBic} onKeyUp={validateSwiftBic} onBlur={validateSwiftBic} />
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush mb-5">
            <div className="card-body">
              <h4 className="mb-5">{lang.labels.company}</h4>
              <div className="mb-5">
                <label className="col-form-label fs-6">{lang.labels.companyName}</label>
                <input id="input-company-name" className="form-control form-control-solid" type="text" name="company-name" value={companyName.value} onChange={handleChangeCompanyName} onKeyUp={validateCompanyName} onBlur={validateCompanyName} />
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.businessNumber}</label>
                  <input id="input-company-business-number" className="form-control form-control-solid" type="text" name="company-business-number" value={companyBusinessNumber.value} onChange={handleChangeCompanyBusinessNumber} onKeyUp={validateCompanyBusinessNumber} onBlur={validateCompanyBusinessNumber} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.website}</label>
                  <input id="input-company-website" className="form-control form-control-solid" type="text" name="company-website" value={companyWebsite.value} onChange={handleChangeCompanyWebsite} onKeyUp={validateCompanyWebsite} onBlur={validateCompanyWebsite} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.email}</label>
                  <input id="input-company-email" className="form-control form-control-solid" type="text" name="email" value={companyEmail.value} onChange={handleChangeCompanyEmail} onKeyUp={validateCompanyEmail} onBlur={validateCompanyEmail} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.phone}</label>
                  <input id="input-company-phone" className="form-control form-control-solid" type="text" name="phone" value={companyPhone.value} onChange={handleChangeCompanyPhone} onKeyUp={validateCompanyPhone} onBlur={validateCompanyPhone} />
                </div>
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.iban}</label>
                  <input id="input-company-iban" className="form-control form-control-solid" type="text" name="iban" value={companyIban.value} onChange={handleChangeCompanyIban} onKeyUp={validateCompanyIban} onBlur={validateCompanyIban} />
                </div>
                <div className="col-xl-6">
                  <label className="col-form-label fs-6">{lang.labels.bicSwift}</label>
                  <input id="input-company-swift-bic" className="form-control form-control-solid" type="text" name="swift-bic" value={companySwiftBic.value} onChange={handleChangeCompanyBic} onKeyUp={validateCompanySwiftBic} onBlur={validateCompanySwiftBic} />
                </div>
              </div>
              <div className="mb-5">
                <label className="col-form-label fs-6">{lang.labels.street}</label>
                <input id="input-company-street" className="form-control form-control-solid" type="text" name="street" value={companyAddress.value} onChange={handleChangeCompanyAddress} onKeyUp={validateCompanyStreet} onBlur={validateCompanyStreet} />
              </div>
              <div className="row mb-5">
                <div className="mb-5 mb-xl-0 col-xl-3">
                  <label className="col-form-label fs-6">{lang.labels.postcode}</label>
                  <input id="input-company-postcode" className="form-control form-control-solid" type="text" name="postcode" value={companyPostcode.value} onChange={handleChangeCompanyPostcode} onKeyUp={validateCompanyPostcode} onBlur={validateCompanyPostcode} />
                </div>
                <div className="mb-5 mb-xl-0 col-xl-4">
                  <label className="col-form-label fs-6">{lang.labels.city}</label>
                  <input id="input-company-city" className="form-control form-control-solid" type="text" name="city" value={companyCity.value} onChange={handleChangeCompanyCity} onKeyUp={validateCompanyCity} onBlur={validateCompanyCity} />
                </div>
                <div className="col-xl-5">
                <label className="col-form-label fs-6">{lang.labels.country}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {companyCountry.valid && companyCountry.value.length > 0 ? getName(companyCountry.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="country" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.length > 0
                              ?
                              <>
                                { searchCountry.list.map (( (item, index) => { return (
                                  <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === companyCountry.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === companyCountry.value ? "true" : "false"}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCompanyCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                  </li>
                                )}))}
                              </>
                              :
                              <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                            }
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flush p-3">
            <div className="d-flex justify-content-end">
              <div className="mb-xl-0 col-12 col-md-6  d-flex pe-6 ps-3">
                <button className="btn btn-primary w-100" type="button" data-kt-indicator={loadIndicator} onClick={submitIntermediaryUpdate}>
                  <span className="indicator-label">{lang.labels.save}</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button className="btn btn-light-primary w-100 ms-3" type="reset" onClick={onCancel}>
                  {lang.labels.cancel}
                </button>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default IntermediaryUpdatePage;
