import { Language } from "../languages/context/language"

export function fortuneList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: '0m-2m', desc: `0 ${lang.labels.to} 2 ${lang.labels.millions} CHF` },
    { code: '2m-5m', desc: `2 ${lang.labels.to} 5 ${lang.labels.millions} CHF` },
    { code: '>5m', desc: `> 5 ${lang.labels.millions} CHF` },
  ]
}

export function fortuneValue(lang: Language, code: string): string {
  let element = fortuneList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}