import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { RefinerModel } from '../../models/refiner.model';
import { RefinerService } from '../../services/refiner.service';
import { listNameSortedAscending, listNameSortedDescending } from '../../scripts/order.asc.desc.list.script';

interface AppModalRefinerUpdateProps {
  orderAscDesc: string,
  refiner: RefinerModel | undefined | null,
  refineries: RefinerModel[] | undefined | null,
  setRefiner: Dispatch<SetStateAction<RefinerModel | undefined | null>>,
  setRefineries: Dispatch<SetStateAction<RefinerModel[] | undefined | null>>
};

let errorResponse: Error, serviceResponse: any;

const AppModalRefinerUpdate: React.FunctionComponent<AppModalRefinerUpdateProps> = ({orderAscDesc, refiner, refineries, setRefiner, setRefineries}) => {
  const {lang} = StateLanguage()

  const expressions = {
    text: /^[A-Za-zÀ-ÿ0-9\s()\-_'"/*]{1,500}$/,
  }

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [valueRefiner, setValueRefiner] = useState({value: '', valid: false})

  const submitRefinerUpdate = async () => {
    setLoadIndicator('on')

    if (refineries && refiner && valueRefiner.valid) {
      await RefinerService.putRefiner(refiner.id, valueRefiner.value).then( (response) => {
        if (response.status === 200) {
          serviceResponse = response.data

          let list = refineries.map((item) => {
            if (item.id === serviceResponse.id) {
              return serviceResponse;
            }
            return item
          })

          setRefineries((orderAscDesc === "asc") ? listNameSortedAscending(list) : listNameSortedDescending(list))

          Swal.fire({
            title: lang.labels.successfullyUpdatedRefiner,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalRefinerUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          }) 
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeValueRefiner = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setValueRefiner({...valueRefiner, value: event.target.value})
  }

  const validateValueRefiner = () => {
    if (expressions.text.test(valueRefiner.value)) {
      setValueRefiner({...valueRefiner, valid: true})
      inputValid('modal-refiner-update-input-value-refiner')
    } else {
      setValueRefiner({...valueRefiner, valid: false})
      inputInvalid('modal-refiner-update-input-value-refiner')
      }
  }

  const executeHideModalRefinerUpdate = () => {
    modalHide('modal-refiner-update')

    setTimeout( () => {
      restartInput('modal-refiner-update-input-value-refiner')
    }, 200 )
  }

  useEffect(() => {
    if (refiner) {
      setValueRefiner({value: refiner.name.toString(), valid: true })
    }
  }, [refiner]);

  return (
    <div id="modal-refiner-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-550px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.updateRefiner}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className='row fv-row'>
              <div className="d-flex justify-content-between">
                <label className="form-label">{lang.labels.name}</label>
                <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
              </div>
              <input id="modal-refiner-update-input-value-refiner" className="form-control form-control-solid" type="text" name="value-refiner" value={valueRefiner.value} onChange={handleChangeValueRefiner} onKeyUp={validateValueRefiner} onBlur={validateValueRefiner} />
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitRefinerUpdate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalRefinerUpdate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalRefinerUpdate;
