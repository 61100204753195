import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { IngotTypeModel } from '../models/ingot.type.model';
import { RefinerModel } from '../models/refiner.model';
import { WeightModel } from '../models/weight.model';
import { PurityModel } from '../models/purity.model';
import { appToken } from '../scripts/app.token.script';

export class IngotTypeService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getIngotTypes(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        ingot_types: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingot.types.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingot/types`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postIngotType(refiner: string, weight: string, purity: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ingotTypes: IngotTypeModel[] | null = null
      let refineries: RefinerModel[] | null = null
      let weights: WeightModel[] | null = null
      let purities: PurityModel[] | null = null

      let response: IngotTypeModel | null = {
        id: "newingottype",
        refiner: {
          id: refiner,
          name: ''
        },
        weight: {
          id: weight,
          value: 0,
          unity: ''
        },
        purity: {
          id: purity,
          value: 0
        }
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingot.types.data.json').then(response => { ingotTypes = response.data.ingot_types }).catch(error => { alert(error) })
      await axios.get('../../../../../database/refineries.data.json').then(resp => { refineries = resp.data.refineries }).catch(err => { alert(err) })
      await axios.get('../../../../../database/weights.data.json').then(resp => { weights = resp.data.weights }).catch(err => { alert(err) })
      await axios.get('../../../../../database/purities.data.json').then(resp => { purities = resp.data.purities }).catch(err => { alert(err) })

      if (!ingotTypes!.some(item => item.refiner.id === refiner && item.weight.id === weight && item.purity.id === purity)) {
        response!.refiner = refineries!.find(item => item.id === refiner)!
        response!.weight = weights!.find(item => item.id === weight)!
        response!.purity = purities!.find(item => item.id === purity)!

        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingot/types`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        refiner: refiner,
        weight: weight,
        purity: purity
      }

      await axios.post(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.ingot_type
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putIngotType(id_ingot_type: string, refiner: string, weight: string, purity: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ingotTypes: IngotTypeModel[] | null = null
      let refineries: RefinerModel[] | null = null
      let weights: WeightModel[] | null = null
      let purities: PurityModel[] | null = null

      let response: IngotTypeModel | null = {
        id: id_ingot_type,
        refiner: {
          id: refiner,
          name: ''
        },
        weight: {
          id: weight,
          value: 0,
          unity: ''
        },
        purity: {
          id: purity,
          value: 0
        }
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingot.types.data.json').then(response => { ingotTypes = response.data.ingot_types }).catch(error => { alert(error) })
      await axios.get('../../../../../database/refineries.data.json').then(resp => { refineries = resp.data.refineries }).catch(err => { alert(err) })
      await axios.get('../../../../../database/weights.data.json').then(resp => { weights = resp.data.weights }).catch(err => { alert(err) })
      await axios.get('../../../../../database/purities.data.json').then(resp => { purities = resp.data.purities }).catch(err => { alert(err) })

      if (ingotTypes!.some(item => item.id === id_ingot_type)) {
        response!.refiner = refineries!.find(item => item.id === refiner)!
        response!.weight = weights!.find(item => item.id === weight)!
        response!.purity = purities!.find(item => item.id === purity)!

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingot/types/${id_ingot_type}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        refiner: refiner,
        weight: weight,
        purity: purity
      }

      await axios.put(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteIngotType(id_ingot_type: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ingotTypes: IngotTypeModel[] | null = null

      let response: Message | null = {
        message: 'Successfully response'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingot.types.data.json').then(response => { ingotTypes = response.data.ingot_types }).catch(error => { alert(error) })

      if (ingotTypes!.some(item => item.id === id_ingot_type)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingot/types/${id_ingot_type}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}