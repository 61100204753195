import React, { Dispatch, SetStateAction } from 'react';
import Cookies from 'universal-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { UserModel } from '../../models/user.model';
import { dropdownHideActive } from '../../types/dropdown.type';
import { imageUser } from '../../scripts/image.object.script';
import { navbarAsideHide } from '../../types/navbar.type';

interface AppDropdownUserProfileProps {
  activeDrawer: {value: string, aside: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string}>>,
  userLoggedIn: UserModel | undefined | null
};

const AppDropdownUserProfile: React.FunctionComponent<AppDropdownUserProfileProps> = ({activeDrawer, setActiveDrawer, userLoggedIn}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const submitSignOut = () => {
    Swal.fire({
      title: lang.labels.youWantLogOut,
      text: lang.labels.willNotBeAbleToReturnUntilYouLogin,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: lang.labels.yesLogOut,
      cancelButtonText: lang.labels.noCancel,
      customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
    }).then( (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: lang.labels.youHaveSuccessfullySignedOut,
          text: lang.labels.redirectingToLogin,
          icon: 'success',
          showConfirmButton: false,
          timer: 1800
        } as SweetAlertOptions).then( () => {
          cookies.remove('token', {path: '/', sameSite: 'lax'})
          cookies.remove('expires_at', {path: '/', sameSite: 'lax'})
          navigate('/auth/login')
        })
      }
    })
  }

  const executeDropdownHide = () => {
    dropdownHideActive('button-user-profile', 'dropdown-user-profile', 'overlay-menu')

    if (window.innerWidth < 992) {
      setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
      navbarAsideHide('navbar-aside', 'overlay-drawer')
    }
  }

  return (
    <div id="dropdown-user-profile" className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
      <div className="menu-item px-3" >
        { userLoggedIn &&
          <div className="menu-content d-flex align-items-center px-2">
            <div className="symbol symbol-50px me-3">
              <img src={imageUser(userLoggedIn.avatar)} alt="avatar" />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bolder align-items-center d-inline-block overflow-hidden text-truncate mw-175px fs-5">
                {userLoggedIn.first_name} {userLoggedIn.last_name}
              </div>
              <span className="fw-bold text-muted fs-8">{userLoggedIn.email}</span>
            </div>
          </div>
        }
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <Link to="/app/account" className="menu-link px-5" onClick={executeDropdownHide}>
          {lang.labels.myProfile}
        </Link>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <div className="menu-content menu-link px-5" onClick={submitSignOut}>
          <label className="form-check form-check-custom pulse pulse-success">
            <FaSignOutAlt className="text-black fs-3"></FaSignOutAlt>
            <span className="pulse-ring ms-n1"></span>
            <span className="form-check-label text-gray-900 fs-7">{lang.labels.signOut}</span>
          </label>
        </div>
      </div>
    </div>
  )
}

export default AppDropdownUserProfile;
