import { Language } from "../languages/context/language"

export function pensionPerYearList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: '<300k', desc: `< 300,000 CHF` },
    { code: '300k-1m', desc: `300,000 CHF ${lang.labels.to} 1 ${lang.labels.million} CHF` },
    { code: '>1m', desc: `> 1 million CHF` },
  ]
}

export function pensionPerYearValue(lang: Language, code: string): string {
  let element = pensionPerYearList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}