import Cookies from 'universal-cookie';

const cookies = new Cookies()

export function authUserLoggedIn(): boolean {
  if (cookies.get('token') && cookies.get('expires_at')) {
    let dateString: string = `${cookies.get('expires_at').split('GMT')[0].replace(' ', 'T').replace(' ', '')}${cookies.get('expires_at').split('GMT')[1]}`
    let dateExpiration: Date = new Date(dateString)
    let timeExpiration: number = dateExpiration.getTime()
    let timestamp: number = Date.now()

    return (timeExpiration > timestamp) ? true : false
  } else {
    return false
  }
}
