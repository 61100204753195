import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { WeightModel } from '../models/weight.model';
import { appToken } from '../scripts/app.token.script';

export class WeightService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getWeights(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        weights: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/weights.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/weights`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postWeight(value: string, unity: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let weights: WeightModel[] | null = null

      let response: WeightModel | null = {
        id: "newweight",
        value: Number(value),
        unity: unity,
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/weights.data.json').then(resp => { weights = resp.data.weights }).catch(err => { alert(err) })

      if (!weights!.some(item => item.value === Number(value) && item.unity === unity)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/weights`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        value: value,
        unit: unity
      }

      await axios.post(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.weight
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putWeight(id_weight: string, value: string, unity: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let weights: WeightModel[] | null = null

      let response: WeightModel | null = {
        id: id_weight,
        value: Number(value),
        unity: unity,
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/weights.data.json').then(resp => { weights = resp.data.weights }).catch(err => { alert(err) })

      if (weights!.some(item => item.id === id_weight)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/weights/${id_weight}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        value: value,
        unit: unity
      }

      await axios.put(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.weight
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteWeight(id_weight: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let weights: WeightModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/weights.data.json').then(resp => { weights = resp.data.weights }).catch(err => { alert(err) })

      if (weights!.some(item => item.id === id_weight)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/weights/${id_weight}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}