import axios from 'axios';
import format from 'date-fns/format';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { DocumentModel } from '../models/document.model';
import { extractBase64 } from '../scripts/extract.base.64.script';
import { Message } from '../models/message.model';
import { appToken } from '../scripts/app.token.script';

export class DocumentService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getDocuments(id_client: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        client_id: id_client,
        documents: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/documents.data.json').then(resp => { response = resp.data }).catch(error => { alert(error) })

      if (response.client_id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/documents`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async postDocument(id_client: string, name: string, type: string, file: any, client_portal: boolean): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let documents: DocumentModel[] | null = null

      let response: DocumentModel | null = {
        id: "newdocument",
        name: name,
        type: type,
        date: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
        created_at: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
        file: (file && typeof file === 'string') ? file : '',
        client_portal: true
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/documents.data.json').then(resp => { documents = resp.data.documents }).catch(error => { alert(error) })

      if (!documents!.some(item => item.name === name && item.type === type)) {
        if (file) { extractBase64(file).then((doc: any) => { response!.file = doc.base }) } else { response!.file = response.file }

        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/documents`
      let header: any = { headers: { Authorization: appToken() } }
      let data: FormData = new FormData()

      data.append("name", name)
      data.append("type", type)
      data.append("document", file)
      data.append("client_portal", JSON.stringify(client_portal))

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.document
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async putDocument(id_client: string, id_document: string, name: string, type: string, file: any, client_portal: boolean): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let documents: DocumentModel[] | null = null

      let response: DocumentModel | null = {
        id: id_document,
        name: name,
        type: type,
        date: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
        created_at: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
        file: (file && typeof file === 'string') ? file : '',
        client_portal: true
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/documents.data.json').then(resp => { documents = resp.data.documents }).catch(error => { alert(error) })

      if (documents!.some(item => item.id === id_document)) {
        if (file) { extractBase64(file).then((doc: any) => { response!.file = doc.base }) } else { response!.file = response.file }

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/documents/${id_document}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: FormData = new FormData()

      data.append("name", name)
      data.append("type", type)
      data.append("document", file)
      data.append("client_portal", JSON.stringify(client_portal))

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.document
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async deleteDocument(id_client: string, id_document: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let documents: DocumentModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/documents.data.json').then(resp => { documents = resp.data.documents }).catch(error => { alert(error) })

      if (documents!.some(item => item.id === id_document)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}/documents/${id_document}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}