import { Language } from "../languages/context/language"

export function weightUnitList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'g', desc: lang.labels.gram },
    { code: 'kg', desc: lang.labels.kilogram },
  ]
}

export function weightUnitValue(code: string, lang: Language): string {
  let element = weightUnitList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}

export function weightUnitFormat(code: string, lang: Language): string {
  switch (code) {
    case 'g':
      return 'GRAM GOLD'
    case 'kg':
      return 'KILO GOLD'
    default:
      return weightUnitValue(code, lang)
  }
}