import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContexWithdrawalRequest } from './WithdrawalRequest';
import { Error } from '../../../models/error.model';
import { WithdrawalRequestService } from '../../../services/withdrawal.request.service';
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../../types/pagination.type';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { WithdrawalRequestModel } from '../../../models/withdrawal.request.model';
import { moneyFormat } from '../../../scripts/money.format.script';
import { dateFormat } from '../../../scripts/date.format.script';

export interface WithdrawalRequestListPageProps {};

let errorResponse: Error, withdrawalRequestsResponse: any;

const WithdrawalRequestListPage: React.FunctionComponent<WithdrawalRequestListPageProps> = props => {
  const { setRoute } = UseOutletContexWithdrawalRequest()
  const { lang } = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [paginationOpen, setPaginationOpen] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [paginationAccepted, setPaginationAccepted] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [paginationDenied, setPaginationDenied] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [withdrawalOpen, setWithdrawalOpen] = useState<WithdrawalRequestModel[] | undefined | null>(null)
  const [withdrawalAccepted, setWithdrawalAccepted] = useState<WithdrawalRequestModel[] | undefined | null>(null)
  const [withdrawalDenied, setWithdrawalDenied] = useState<WithdrawalRequestModel[] | undefined | null>(null)

  const loadWithdrawalRequests = async () => {
    let totalPages: number = 1
    let totalByPage: number = 10
    let listPagesOfPaginationOpen: number[] = []
    let listPagesOfPaginationAccepted: number[] = []
    let listPagesOfPaginationDenied: number[] = []

    await WithdrawalRequestService.getWithdrawalRequests().then( (response) => {
      if (response.status === 200) {
        withdrawalRequestsResponse = response.data

        const openWithdrawals = withdrawalRequestsResponse.withdrawal_requests.filter((withdrawal: WithdrawalRequestModel) => withdrawal.status === "open" )
        const acceptedWithdrawals = withdrawalRequestsResponse.withdrawal_requests.filter((withdrawal: WithdrawalRequestModel) => withdrawal.status === "accepted" )
        const deniedWithdrawals = withdrawalRequestsResponse.withdrawal_requests.filter((withdrawal: WithdrawalRequestModel) => withdrawal.status === "denied" )

        if (openWithdrawals.length % totalByPage === 0) {
          totalPages = Math.floor(openWithdrawals.length / totalByPage)
        } else {
          totalPages = Math.floor(openWithdrawals.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPaginationOpen.push(i)
        }

        if (acceptedWithdrawals.length % totalByPage === 0) {
          totalPages = Math.floor(acceptedWithdrawals.length / totalByPage)
        } else {
          totalPages = Math.floor(acceptedWithdrawals.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPaginationAccepted.push(i)
        }

        if (deniedWithdrawals.length % totalByPage === 0) {
          totalPages = Math.floor(deniedWithdrawals.length / totalByPage)
        } else {
          totalPages = Math.floor(deniedWithdrawals.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPaginationDenied.push(i)
        }

        setWithdrawalOpen(openWithdrawals)
        setWithdrawalAccepted(acceptedWithdrawals)
        setWithdrawalDenied(deniedWithdrawals)

        setPaginationOpen({...paginationOpen, current: 1, total: totalByPage, pages: listPagesOfPaginationOpen, limits: {min: 0, max: totalByPage}})
        setPaginationAccepted({...paginationAccepted, current: 1, total: totalByPage, pages: listPagesOfPaginationAccepted, limits: {min: 0, max: totalByPage}})
        setPaginationDenied({...paginationDenied, current: 1, total: totalByPage, pages: listPagesOfPaginationDenied, limits: {min: 0, max: totalByPage}})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const executePaginationOpenSelect = (item: number) => {
    let minLimitNew: number = paginationOpen.total * (item - 1)
    let maxLimitNew: number = paginationOpen.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-open-button-previous')
    } else {
      pageLimitEnabled('pagination-open-button-previous')
    }

    if (item === paginationOpen.pages.length) {
      pageLimitDisabled('pagination-open-button-next')
    }else {
      pageLimitEnabled('pagination-open-button-next')
    }

    for (let item of paginationOpen.pages) {
      elements.push('pagination-open-button-' + item)
    }

    pageCurrent(item, elements)
    setPaginationOpen({...paginationOpen, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationOpenPrevious = () => {
    let currentNew: number = paginationOpen.current - 1
    let minLimitNew: number = paginationOpen.total * (currentNew - 1)
    let maxLimitNew: number = paginationOpen.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-open-button-previous')
    }

    if (currentNew < paginationOpen.pages.length) {
      pageLimitEnabled('pagination-open-button-next')
    }

    for (let item of paginationOpen.pages) {
      elements.push('pagination-open-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPaginationOpen({...paginationOpen, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationOpenNext = () => {
    let currentNew: number = paginationOpen.current + 1
    let minLimitNew: number = paginationOpen.total * (currentNew - 1)
    let maxLimitNew: number = paginationOpen.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-open-button-previous')
    }

    if (currentNew === paginationOpen.pages.length) {
      pageLimitDisabled('pagination-open-button-next')
    }

    for (let item of paginationOpen.pages) {
      elements.push('pagination-open-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPaginationOpen({...paginationOpen, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }


  const executePaginationAcceptedSelect = (item: number) => {
    let minLimitNew: number = paginationAccepted.total * (item - 1)
    let maxLimitNew: number = paginationAccepted.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-accepted-button-previous')
    } else {
      pageLimitEnabled('pagination-accepted-button-previous')
    }

    if (item === paginationAccepted.pages.length) {
      pageLimitDisabled('pagination-accepted-button-next')
    }else {
      pageLimitEnabled('pagination-accepted-button-next')
    }

    for (let item of paginationAccepted.pages) {
      elements.push('pagination-accepted-button-' + item)
    }

    pageCurrent(item, elements)
    setPaginationAccepted({...paginationAccepted, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationAcceptedPrevious = () => {
    let currentNew: number = paginationAccepted.current - 1
    let minLimitNew: number = paginationAccepted.total * (currentNew - 1)
    let maxLimitNew: number = paginationAccepted.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-accepted-button-previous')
    }

    if (currentNew < paginationAccepted.pages.length) {
      pageLimitEnabled('pagination-accepted-button-next')
    }

    for (let item of paginationAccepted.pages) {
      elements.push('pagination-accepted-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPaginationAccepted({...paginationAccepted, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationAcceptedNext = () => {
    let currentNew: number = paginationAccepted.current + 1
    let minLimitNew: number = paginationAccepted.total * (currentNew - 1)
    let maxLimitNew: number = paginationAccepted.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-accepted-button-previous')
    }

    if (currentNew === paginationAccepted.pages.length) {
      pageLimitDisabled('pagination-accepted-button-next')
    }

    for (let item of paginationAccepted.pages) {
      elements.push('pagination-accepted-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPaginationAccepted({...paginationAccepted, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationDeniedSelect = (item: number) => {
    let minLimitNew: number = paginationDenied.total * (item - 1)
    let maxLimitNew: number = paginationDenied.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-denied-button-previous')
    } else {
      pageLimitEnabled('pagination-denied-button-previous')
    }

    if (item === paginationDenied.pages.length) {
      pageLimitDisabled('pagination-denied-button-next')
    }else {
      pageLimitEnabled('pagination-denied-button-next')
    }

    for (let item of paginationDenied.pages) {
      elements.push('pagination-denied-button-' + item)
    }

    pageCurrent(item, elements)
    setPaginationDenied({...paginationDenied, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationDeniedPrevious = () => {
    let currentNew: number = paginationDenied.current - 1
    let minLimitNew: number = paginationDenied.total * (currentNew - 1)
    let maxLimitNew: number = paginationDenied.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-denied-button-previous')
    }

    if (currentNew < paginationDenied.pages.length) {
      pageLimitEnabled('pagination-denied-button-next')
    }

    for (let item of paginationDenied.pages) {
      elements.push('pagination-denied-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPaginationDenied({...paginationDenied, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationDeniedNext = () => {
    let currentNew: number = paginationDenied.current + 1
    let minLimitNew: number = paginationDenied.total * (currentNew - 1)
    let maxLimitNew: number = paginationDenied.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-denied-button-previous')
    }

    if (currentNew === paginationDenied.pages.length) {
      pageLimitDisabled('pagination-denied-button-next')
    }

    for (let item of paginationDenied.pages) {
      elements.push('pagination-denied-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPaginationDenied({...paginationDenied, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  function rowClassOpen(index: number): string {
    if (paginationOpen.limits.min <= index && index < paginationOpen.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  function rowClassAccepted(index: number): string {
    if (paginationAccepted.limits.min <= index && index < paginationAccepted.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  function rowClassDenied(index: number): string {
    if (paginationDenied.limits.min <= index && index < paginationDenied.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.withdrawalRequests, branch: lang.labels.withdrawalRequestList}, browse: null})
    loadWithdrawalRequests()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { withdrawalOpen && withdrawalAccepted && withdrawalDenied
        ?
        <>
          <div className="d-flex flex-wrap flex-stack mb-5">
            <h1 className="fw-bold">
              {lang.labels.withdrawalRequests}
              <span className="fs-6 text-gray-500 fw-semibold ms-3">
                ({withdrawalOpen.length + withdrawalAccepted.length + withdrawalDenied.length})
              </span>
            </h1>
          </div>
          <div className="bg-transparent m-3">
            <h3 className="fw-bold">{lang.labels.open}</h3>
          </div>
          <div className="card border border-gray-300 mb-10">
            <div className="card-body py-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-5">
                    <thead>
                      <tr className="border-bottom text-start text-muted text-uppercase fw-bolder fs-7">
                        <th className="sorting">{lang.labels.dateOfDemand}</th>
                        <th className="sorting">{lang.labels.client}</th>
                        <th className="sorting text-end">{lang.labels.withdrawalAmount}</th>
                      </tr>
                    </thead>
                    { withdrawalOpen.length > 0
                      ?              
                      <tbody className="fs-6 fw-bold">
                        { withdrawalOpen.map(( (item, index) => { return (
                          <tr key={item.id} className={`row-dashed bg-hover-light ${rowClassOpen(index)}`} onClick={()=>{navigate("/app/withdrawal/view/" + item.client.id + "/" + item.id)}}>
                            <td className="text-gray-600 ps-2">{dateFormat(item.created_at, lang.codeLanguage)}</td>
                            <td className="text-gray-700">{item.client.first_name} {item.client.last_name}</td>
                            <td className="text-gray-800 text-end pe-2">{moneyFormat(item.amount)}</td>
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={3} className="text-center">
                            {lang.labels.noMatchingRecordsFound}
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
                { withdrawalOpen.length > 0 && paginationOpen.pages.length > 1 &&
                  <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                      <div className="dataTables_paginate paging_simple_numbers">
                        <ul className="pagination">
                          <li id="pagination-open-button-previous" className="paginate_button page-item previous disabled">
                            <button className="page-link" type="button" onClick={executePaginationOpenPrevious}>
                              <i className="previous"></i>
                            </button>
                          </li>
                          { paginationOpen.pages.map (( (item, index) => { return (
                            <li key={index} id={"pagination-open-button-" + item} className={`paginate_button page-item ${item === paginationOpen.current && 'active'}`}>
                              <button className="page-link" type="button" onClick={() => executePaginationOpenSelect(item)}>{item}</button>
                            </li>
                          )}))}
                          <li id="pagination-open-button-next" className="paginate_button page-item next">
                            <button className="page-link" type="button" onClick={executePaginationOpenNext}>
                              <i className="next"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="bg-transparent m-3">
            <h3 className="fw-bold">{lang.labels.accepted}</h3>
          </div>
          <div className="card border border-gray-300 mb-10">
            <div className="card-body py-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-5">
                    <thead>
                      <tr className="border-bottom text-start text-muted text-uppercase fw-bolder fs-7">
                        <th className="sorting">{lang.labels.dateOfDemand}</th>
                        <th className="sorting">{lang.labels.client}</th>
                        <th className="sorting text-end">{lang.labels.withdrawalAmount}</th>
                      </tr>
                    </thead>
                    { withdrawalAccepted.length > 0
                      ?              
                      <tbody className="fs-6 fw-bold">
                        { withdrawalAccepted.map(( (item, index) => { return (
                          <tr key={item.id} className={`row-dashed bg-hover-light ${rowClassAccepted(index)}`} onClick={()=>{navigate("/app/withdrawal/view/" + item.client.id + "/" + item.id)}}>
                            <td className="text-gray-600 ps-2">{dateFormat(item.created_at, lang.codeLanguage)}</td>
                            <td className="text-gray-700">{item.client.first_name} {item.client.last_name}</td>
                            <td className="text-gray-800 text-end pe-2">{moneyFormat(item.amount)}</td>
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={3} className="text-center">
                            {lang.labels.noMatchingRecordsFound}
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
                { withdrawalAccepted.length > 0 && paginationAccepted.pages.length > 1 &&
                  <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                      <div className="dataTables_paginate paging_simple_numbers">
                        <ul className="pagination">
                          <li id="pagination-accepted-button-previous" className="paginate_button page-item previous disabled">
                            <button className="page-link" type="button" onClick={executePaginationAcceptedPrevious}>
                              <i className="previous"></i>
                            </button>
                          </li>
                          { paginationAccepted.pages.map (( (item, index) => { return (
                            <li key={index} id={"pagination-accepted-button-" + item} className={`paginate_button page-item ${item === paginationAccepted.current && 'active'}`}>
                              <button className="page-link" type="button" onClick={() => executePaginationAcceptedSelect(item)}>{item}</button>
                            </li>
                          )}))}
                          <li id="pagination-accepted-button-next" className="paginate_button page-item next">
                            <button className="page-link" type="button" onClick={executePaginationAcceptedNext}>
                              <i className="next"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="bg-transparent m-3">
            <h3 className="fw-bold">{lang.labels.denied}</h3>
          </div>
          <div className="card border border-gray-300 mb-10">
            <div className="card-body py-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-5">
                    <thead>
                      <tr className="border-bottom text-start text-muted text-uppercase fw-bolder fs-7">
                        <th className="sorting">{lang.labels.dateOfDemand}</th>
                        <th className="sorting">{lang.labels.client}</th>
                        <th className="sorting text-end">{lang.labels.withdrawalAmount}</th>
                      </tr>
                    </thead>
                    { withdrawalDenied.length > 0
                      ?              
                      <tbody className="fs-6 fw-bold">
                        { withdrawalDenied.map(( (item, index) => { return (
                          <tr key={item.id} className={`row-dashed bg-hover-light ${rowClassDenied(index)}`} onClick={()=>{navigate("/app/withdrawal/view/" + item.client.id + "/" + item.id)}}>
                            <td className="text-gray-600 ps-2">{dateFormat(item.created_at, lang.codeLanguage)}</td>
                            <td className="text-gray-700">{item.client.first_name} {item.client.last_name}</td>
                            <td className="text-gray-800 text-end pe-2">{moneyFormat(item.amount)}</td>
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={3} className="text-center">
                            {lang.labels.noMatchingRecordsFound}
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
                { withdrawalDenied.length > 0 && paginationDenied.pages.length > 1 &&
                  <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                      <div className="dataTables_paginate paging_simple_numbers">
                        <ul className="pagination">
                          <li id="pagination-denied-button-previous" className="paginate_button page-item previous disabled">
                            <button className="page-link" type="button" onClick={executePaginationDeniedPrevious}>
                              <i className="previous"></i>
                            </button>
                          </li>
                          { paginationDenied.pages.map (( (item, index) => { return (
                            <li key={index} id={"pagination-denied-button-" + item} className={`paginate_button page-item ${item === paginationDenied.current && 'active'}`}>
                              <button className="page-link" type="button" onClick={() => executePaginationDeniedSelect(item)}>{item}</button>
                            </li>
                          )}))}
                          <li id="pagination-denied-button-next" className="paginate_button page-item next">
                            <button className="page-link" type="button" onClick={executePaginationDeniedNext}>
                              <i className="next"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default WithdrawalRequestListPage;
