import { Language } from "../languages/context/language"

export function userRoleList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'admin', desc: lang.labels.admin },
    { code: 'user', desc: lang.labels.user },
  ]
}

export function userRoleValue(code: string, lang: Language): string {
  let element = userRoleList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}