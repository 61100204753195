export function appLanguageValidate(param_language: any): boolean {
  if (param_language) {
    if (param_language === 'en' || param_language === 'fr' ) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export function appLanguageUpload(dispatchLanguage: any): string {
  let idiom: any = 'french'

  if (localStorage.getItem('language')) {
    idiom = localStorage.getItem('language')
  } else {
    localStorage.setItem('language', idiom)
  }

  dispatchLanguage({type: idiom})

  return idiom
}

export function appLanguageSave(param_language: any) {
  if (param_language) {
    switch (param_language) {
      case 'en':
        localStorage.setItem('language', 'english')
        break;
      case 'fr':
        localStorage.setItem('language', 'french')
        break;
      default:
        if (!localStorage.getItem('language')) {
          localStorage.setItem('language', '')
        }
        break;
    }
  } else if (!localStorage.getItem('language')) {
    localStorage.setItem('language', '')
  }
}
