function navbarShow(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('desactive')
  element?.classList.add('active')
}

function navbarHide(identifier: string) {
  let element = document.getElementById(identifier)

  element?.classList.remove('active')
  element?.classList.add('desactive')
}

function navbarAsideShow(identifier: string, overlay: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(overlay)

  element1?.classList.add('drawer')
  element1?.classList.add('drawer-start')
  element1?.classList.add('drawer-on')
  element2?.classList.add('drawer-overlay')
}

function navbarAsideHide(identifier: string, overlay: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(overlay)

  element1?.classList.remove('drawer')
  element1?.classList.remove('drawer-start')
  element1?.classList.remove('drawer-on')
  element2?.classList.remove('drawer-overlay')
}

export { navbarShow, navbarHide, navbarAsideShow, navbarAsideHide }
