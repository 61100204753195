import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { IngotModel } from '../models/ingot.model';
import { appToken } from '../scripts/app.token.script';

export class IngotService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getIngots(available: boolean): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        filter: {
          available: available,
        },
        ingots: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingots.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        let list: IngotModel[] = response.ingots

        if (available) {
          list = response.ingots.filter((item: IngotModel) => (item.available === true))
        }

        response.ingots = list

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots?available=${available}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async getIngot(id_ingot: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: IngotModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingot.data.json').then(resp => { response = resp.data.ingot }).catch(error => { alert(error) })

      if (response!.id === id_ingot) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/${id_ingot}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.ingot
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postIngot(serial_number: string, original_value: string, refiner: string, weight: string, purity: string, photo: any, cetificate: any): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ingots: IngotModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingots.data.json').then(resp => { ingots = resp.data.ingots }).catch(err => { alert(err) })

      if (!ingots!.some(item => item.serial_number === serial_number)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('original_value', original_value)
      data.append('refiner', refiner)
      data.append('weight', weight)
      data.append('purity', purity)
      data.append('serial_number', serial_number)
      data.append('photo', photo)
      data.append('certificate', cetificate)

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putIngot(id_ingot: string, serial_number: string, original_value: string, refiner: string, weight: string, purity: string, photo: any, cetificate: any): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ingots: IngotModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingots.data.json').then(resp => { ingots = resp.data.ingots }).catch(err => { alert(err) })

      if (ingots!.some(item => item.id === id_ingot)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/${id_ingot}`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('original_value', original_value)
      data.append('refiner', refiner)
      data.append('weight', weight)
      data.append('purity', purity)
      data.append('serial_number', serial_number)
      data.append('photo', photo)
      data.append('certificate', cetificate)

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteIngot(id_ingot: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let ingots: IngotModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/ingots.data.json').then(resp => { ingots = resp.data.ingots }).catch(err => { alert(err) })

      if (ingots!.some(item => item.id === id_ingot)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/ingots/${id_ingot}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }
}