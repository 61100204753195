import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { NotaryService } from '../../services/notary.service';
import { NotaryModel } from '../../models/notary.model';
import { getCode, getName, getNames } from '../../scripts/countries.script';
import { expressions } from '../../scripts/regular.expressions.script';

interface AppModalNotaryUpdateProps {
  notary: NotaryModel | undefined | null,
  notaries: NotaryModel[] | undefined | null,
  setNotary: Dispatch<SetStateAction<NotaryModel | undefined | null>>,
  setNotaries: Dispatch<SetStateAction<NotaryModel[] | undefined | null>> | null
};

let errorResponse: Error, serviceResponse: NotaryModel;

const AppModalNotaryUpdate: React.FunctionComponent<AppModalNotaryUpdateProps> = ({notary, notaries, setNotary, setNotaries}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [zipCode, setZipCode] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: false})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const submitNotaryUpdate = async () => {
    setLoadIndicator('on')

    if (notary && firstName.valid && lastName.valid && street.valid && city.valid && zipCode.valid && city.valid && country.valid && email.valid && phone.valid && website.valid) {
      await NotaryService.putNotary(notary.id, firstName.value, lastName.value, street.value, zipCode.value, city.value, country.value, phone.value, email.value, website.value).then( (response) => {
        if (response.status === 200) {
          serviceResponse = response.data

          if (notaries && setNotaries) {
            let list = notaries.map((item) => {
              if (item.id === serviceResponse.id) {
                return serviceResponse;
              }
              return item
            })

            setNotaries(list)
          } else {
            setNotary(serviceResponse)
          }

          Swal.fire({
            title: lang.labels.successfullyUpdatedNotary,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalNotaryUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeCompanyCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const validateFirstName = () => {
    if (expressions.text.test(firstName.value)) {
      setFirstName({...firstName, valid: true})
      inputValid('modal-notary-update-input-first-name')
    } else {
      setFirstName({...firstName, valid: false})
      inputInvalid('modal-notary-update-input-first-name')
    }
  }

  const validateLastName = () => {
    if (expressions.text.test(lastName.value)) {
      setLastName({...lastName, valid: true})
      inputValid('modal-notary-update-input-last-name')
    } else {
      setLastName({...lastName, valid: false})
      inputInvalid('modal-notary-update-input-last-name')
    }
  }

  const validateStreet = () => {
    if (expressions.street.test(street.value)) {
      setStreet({...street, valid: true})
      inputValid('modal-notary-update-input-street')
    } else {
      setStreet({...street, valid: false})
      inputInvalid('modal-notary-update-input-street')
    }
  }

  const validateZipCode = () => {
    if (expressions.zip.test(zipCode.value)) {
      setZipCode({...zipCode, valid: true})
      inputValid('modal-notary-update-input-zip-code')
    } else {
      setZipCode({...zipCode, valid: false})
      inputInvalid('modal-notary-update-input-zip-code')
    }
  }

  const validateCity = () => {
    if (expressions.text.test(city.value)) {
      setCity({...city, valid: true})
      inputValid('modal-notary-update-input-city')
    } else {
      setCity({...city, valid: false})
      inputInvalid('modal-notary-update-input-city')
    }
  }

  const validatePhone = () => {
    if (expressions.phone.test(phone.value)) {
      setPhone({...phone, valid: true})
      inputValid('modal-notary-update-input-phone')
    } else {
      setPhone({...phone, valid: false})
      inputInvalid('modal-notary-update-input-phone')
    }
  }

  const validateEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email, valid: true})
      inputValid('modal-notary-update-input-email')
    } else {
      setEmail({...email, valid: false})
      inputInvalid('modal-notary-update-input-email')
    }
  }

  const validateWebsite = () => {
    if (website.value.length > 0) {
      if (expressions.website.test(website.value)) {
        setWebsite({...website, valid: true})
        inputValid('modal-notary-update-input-website')
      } else {
        setWebsite({...website, valid: false})
        inputInvalid('modal-notary-update-input-website')
      }
    } else if (website.value.length === 0) {
      setWebsite({...website, valid: true})
      restartInput('modal-notary-update-input-website')
    }
  }

  const executeHideModalNotaryUpdate = () => {
    modalHide('modal-notary-update')

    setTimeout( () => {
      restartInput('modal-notary-update-input-first-name')
      restartInput('modal-notary-update-input-last-name')
      restartInput('modal-notary-update-input-street')
      restartInput('modal-notary-update-input-zip-code-ville')
      restartInput('modal-notary-update-input-city')
      restartInput('modal-notary-update-input-phone')
      restartInput('modal-notary-update-input-email')
      restartInput('modal-notary-update-input-website')
    }, 200 )
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  useEffect(() => {
    if(notary){
      setFirstName({value: notary.first_name, valid: true})
      setLastName({value: notary.last_name, valid: true})
      setStreet({value: notary.address.street, valid: true})
      setZipCode({value: notary.address.zip_code, valid: true})
      setCity({value: notary.address.city, valid: true})
      setCountry({value: notary.address.country, valid: true})
      setPhone({value: notary.phone, valid: true})
      setEmail({value: notary.email, valid: true})
      setWebsite({value: notary.website, valid: true})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [notary]);

  return (
    <div id="modal-notary-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-600px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex justify-content-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.editNotary}</h2>
            </div>
          </div>
          <div className="modal-body">
            { notary
              ?
              <div className="form">
                <div className="mb-5">
                  <label className="form-label required">{lang.labels.firstName}</label>
                  <input id="modal-notary-update-input-first-name" className="form-control form-control-solid" type="text" name="firstName" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                </div>
                <div className="mb-5">
                  <label className="form-label required">{lang.labels.lastName}</label>
                  <input id="modal-notary-update-input-last-name" className="form-control form-control-solid" type="text" name="lastName" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                </div>
                <div className="mb-5">
                  <label className="form-label required">{lang.labels.street}</label>
                  <input id="modal-notary-update-input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                </div>
                <div className="row mb-0 mb-xl-5">
                  <div className="mb-5 mb-xl-0 col-xl-4">
                  <label className="form-label required">{lang.labels.postcode}</label>
                      <input id="modal-notary-update-input-zip-code" className="form-control form-control-solid" type="text" name="zipCode" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                  </div>
                  <div className="mb-5 mb-xl-0 col-xl-8">
                    <label className="form-label required">{lang.labels.city}</label>
                    <input id="modal-notary-update-input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                  </div>
                </div>
                <div className="mb-5">
                  <label className="form-label required">{lang.labels.country}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {country.valid && country.value.length > 0 ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="country" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { searchCountry.list.length > 0
                              ?
                              <>
                                { searchCountry.list.map (( (item, index) => { return (
                                  <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === country.value ? "true" : "false"}>
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCompanyCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                  </li>
                                )}))}
                              </>
                              :
                              <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                            }
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mb-5">
                  <label className="form-label required">{lang.labels.phone}</label>
                  <input id="modal-notary-update-input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                </div>
                <div className="mb-5">
                  <label className="form-label required">{lang.labels.email}</label>
                  <input id="modal-notary-update-input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                </div>
                <div className="mb-0">
                  <label className="form-label">{lang.labels.website}</label>
                  <input id="modal-notary-update-input-website" className="form-control form-control-solid" type="text" name="website" value={website.value} onChange={handleChangeWebsite} onKeyUp={validateWebsite} onBlur={validateWebsite} />
                </div>
              </div> 
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitNotaryUpdate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light ms-3" type="reset" onClick={executeHideModalNotaryUpdate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalNotaryUpdate;
