import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FaExclamationCircle } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { modalHide } from '../../types/modal.type';
import { documentTypeList, documentTypeValue } from '../../scripts/document.type.script';
import { Error } from '../../models/error.model';
import { inputInvalid, inputValid, restartInput } from '../../types/legend.input.type';
import { DocumentService } from '../../services/document.service';
import { getError } from '../../languages/context/response';
import { ClientModel } from '../../models/client.model';
import { DocumentModel } from '../../models/document.model';
import { cleanInputFile } from '../../types/input.file.type';
import { uploadTooltip } from '../../types/tooltip.type';
import { expressions } from '../../scripts/regular.expressions.script';

interface AppModalDocumentUpdateProps {
  title: string,
  client: ClientModel | undefined | null,
  file: DocumentModel | undefined | null,
  documents: DocumentModel[] | undefined | null,
  setFile: Dispatch<SetStateAction<DocumentModel | undefined | null>>
  setDocuments: Dispatch<SetStateAction<DocumentModel[] | undefined | null>>
};

let errorResponse: Error, documentResponse: any;

const AppModalDocumentUpdate: React.FunctionComponent<AppModalDocumentUpdateProps> = ({title, client, file, documents, setFile, setDocuments}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [loadFile, setLoadFile] = useState('off')
  const [type, setType] = useState({value: '', valid: false})
  const [name, setName] = useState({value: '', valid: false})
  const [archive, setArchive] = useState({value: {name: '', size: 0, type: '', file: null as any}, valid: {file: false, message: ''}})
  const [clientPortal, setClientPortal] = useState(false)

  const submitDocumentUpdate = async () => {
    setLoadIndicator('on')

    if (documents && client && file && type.valid && name.valid && archive.valid.file) {
      await DocumentService.putDocument(client.id, file.id, name.value, type.value, archive.value.file, clientPortal).then( (response) => {
        if (response.status === 200) {
          documentResponse = response.data

          let list = documents.map((item) => {
            if (item.id === documentResponse.id) {
              return documentResponse;
            }
            return item
          })

          setDocuments(list)

          Swal.fire({
            title: lang.labels.documentAddedCuccessfully,
            text: lang.labels.updatingDocumentListReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModaDocumentUpload()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeType = (item: string) => {
    setType({value: item, valid: true})
  }

  const handleChangeName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeFile = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLoadFile('on')

    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['application/pdf']
    let archiveSize: number = 1099511627776

    let archiveValue: {name: string, size: number, type: string, file: any} = {
      name: archiveCapture.name,
      size: Number((archiveCapture.size / 1048576).toFixed(2)),
      type: archiveCapture.type,
      file: archiveCapture,
    }

    let archiveValid: {file: boolean, message: string} = 
    (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type))))
      ? {file: false, message: lang.labels.invalidFileType}
      : (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) 
        ? {file: false, message: lang.labels.fileSizeNotAllowed}
        : {file: true, message: lang.labels.valid}

    setArchive({value: archiveValue, valid: archiveValid})
    setLoadFile('off')
  }

  const handleChangeClientPortal = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setClientPortal(event.target.checked)
  }

  const validateName = () => {
    if (expressions.text.test(name.value)) {
      setName({...name, valid: true})
      inputValid('modal-document-update-' + title + '-input-name')
    } else {
      setName({...name, valid: false})
      inputInvalid('modal-document-update-' + title + '-input-name')
    }
  }

  const executeRemoveFile = () => {
    setArchive({value: {name: '', size: 0, type: '', file: null as any}, valid: {file: false, message: ''}})
    setName({value: '', valid: false})

    restartInput('modal-document-create-input-name')
    cleanInputFile()
  }

  const executeHideModaDocumentUpload = () => {
    modalHide('modal-document-update-' + title)

    setTimeout( () => {
      restartInput('modal-document-update-' + title + '-input-name')
      cleanInputFile()
    }, 200 )
  }

  useEffect(() => {
    if (file) {
      setName({value: file.name, valid: true})
      setType({value: file.type, valid: true})
      setArchive({value: {name: file.name, size: 1, type: 'application/pdf', file: file.file}, valid: {file: true, message: lang.labels.valid}})
      setClientPortal(file.client_portal ? true : false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id={"modal-document-update-" + title} className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-700px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.updateDocument}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="col-xl-12 mb-5">
              <label className="form-label required">{lang.labels.selectDocumentType}</label>
              <Dropdown>
                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                  <span className="selection">
                    <span className="select2-selection select2-selection--single form-select form-select-solid ps-4">
                      <span className="select2-selection__rendered" role="textbox">
                        <span className="select2-selection__placeholder">
                          { type.valid ? documentTypeValue(type.value, lang) : lang.labels.selectOption }
                        </span>
                      </span>
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                  <span className="select2-dropdown select2-dropdown--below">
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox">
                        { documentTypeList(lang).map (( (item, index) => { return (
                          <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === type.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === type.value ? "true" : "false"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeType(item.code)}>{item.desc}</Dropdown.Item>
                          </li>
                        )}))}
                      </ul>
                    </span>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-xl-12 mb-5">
              <label className="form-label required">{lang.labels.name}</label>
              <input id="modal-document-create-input-name" className="form-control form-control-solid" type="text" name="name" value={name.value} onChange={handleChangeName} onKeyUp={validateName} onBlur={validateName} />
            </div>
            <div className="col-xl-12 mb-5">
              <label className="form-label d-flex align-items-center">
                <span className="required">{lang.labels.file}</span>
                <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.maximumFileFormat10MBPerFile}><FaExclamationCircle /></i>
              </label>
              <div className="dropzone dropzone-queue cursor-pointer-none">
                <div className={`dropzone-panel ${archive.value.file && "d-none"}`}>
                  <div className="position-relative btn btn-outline bg-gray-100 h-55px d-flex align-items-center" data-kt-indicator={loadFile}>
                    <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="file" accept=".pdf" onChange={handleChangeFile} />
                    <span className="svg-icon svg-icon-1 me-3">
                      <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" stroke="#000000">
                        <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      </svg>
                    </span>
                    <span className="indicator-label text-muted">{lang.labels.selectOrDragADocument}</span>
                    <span className="indicator-progress text-muted">
                      {lang.labels.pleaseWait}
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </div>
                </div>
                <div className={`dropzone-items ${!archive.value.file && "d-none"}`}>
                  <div className="dropzone-item dz-image-preview h-55px mt-0">
                    <div className="dropzone-file">
                      <div className="dropzone-filename d-flex align-items-center text-dark">
                        <span className="svg-icon svg-icon-2 me-3">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black"/>
                            <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                          </svg>
                        </span>
                        <span>{archive.value.name}</span>
                        <span className='mx-3'>-</span>
                        <span>{archive.value.size} MB</span>
                      </div>
                      { !archive.valid.file &&
                        <div className="dropzone-error mt-0">{archive.valid.message}</div>
                      }
                    </div>
                    <div className="dropzone-toolbar">
                      <span className="dropzone-delete" onClick={executeRemoveFile}>
                        <i className="bi bi-x fs-1"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="form-check form-switch form-check-custom form-check-solid d-flex align-items-center justify-content-between">
                <div className="d-flex flex-column">
                  <label className="form-check-label fw-bold fs-6 ms-0">{lang.labels.clientPortal}</label>
                  <span className="text-muted mb-0">{lang.labels.makeTheDocumentAvailableOnTheClientPortal}</span>
                </div>
                <input className="form-check-input w-50px h-25px" type="checkbox" checked={clientPortal} onChange={handleChangeClientPortal} />
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitDocumentUpdate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModaDocumentUpload}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppModalDocumentUpdate;
