import React, { useEffect } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_en_US from "@amcharts/amcharts5/locales/en_US";
import am5locales_fr_FR from "@amcharts/amcharts5/locales/fr_FR";
import { StateLanguage } from '../../languages/config/StateLanguage';

export interface AppChartLineGoldPricesProps {
  id: string;
  data: { date: string; gold_price: number; }[];
}

const GoldPriceChart: React.FunctionComponent<AppChartLineGoldPricesProps> = ({ id, data }) => {
  const {lang} = StateLanguage()
  const language = lang.codeLanguage === "FR" ? "fr_FR": "en_EN"

  useEffect(() => {
    let root = am5.Root.new(id);

    root.dateFormatter.setAll({
      dateFields: ["valueX"]
    });

    root.locale = (language === 'fr_FR') ? am5locales_fr_FR : am5locales_en_US;
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none"
    }));

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX"
    }));

    cursor.lineY.set("visible", false);

    let dataList = data.map((item) => {
      return {
        "date": new Date(item.date).getTime(),
        "gold_price": item.gold_price,
        "date_long": item.date
      };
    });

    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: { timeUnit: "day", count: 1 },
      renderer: am5xy.AxisRendererX.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {})
    }));

    let series = chart.series.push(am5xy.LineSeries.new(root, {
      name: "Gold prices",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "gold_price",
      valueXField: "date",
      tooltip: am5.Tooltip.new(root, {
        labelText: `${lang.labels.date}: {date_long}\n${lang.labels.goldPrices}: {gold_price} CHF`
      })
    }));

    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal",
      marginBottom: 30,
      marginTop: 0
    }));

    series.strokes.template.setAll({
      strokeWidth: 3,
    });

    series.data.setAll(dataList);
    series.appear(1000);
    chart.appear(1000, 100);

    setTimeout(() => {
      xAxis.zoomToDates((new Date(new Date().getFullYear(), 0, 1)), (new Date()));
    }, 500);

    return () => {
      root.dispose();
    };
  }, [id, data, language, lang.labels.date, lang.labels.goldPrices]);

  return (
    <div id={id} style={{ width: "100%", height: "500px" }} />
  );
};

export default GoldPriceChart;
