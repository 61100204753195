import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { authUserLoggedIn } from '../../scripts/auth.user.script';

import background from '../../assets/videos/screen.gif';

export interface HomePageProps {};

const HomePage: React.FunctionComponent<HomePageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  useEffect( () => {
    setTimeout( () => {
      if (authUserLoggedIn()) {
        navigate('/app/dashboard', {replace: true})
      } else {
        navigate('/auth/login', {replace: true})
      }
    }, 5000 )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid" style={{backgroundColor: "#FBFBFB"}}>
        <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-10">
          <div className="h-100 w-100 cursor-pointer">
            <img src={background} className="h-100" alt="logotipo" />
          </div>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.about}</a>
            <a href="mailto:contact@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.purchase}</a>
            <a href="mailto:support@digitaladmin.ch" className="text-muted text-hover-primary px-2">{lang.labels.support}</a>
          </div>
        </div>
      </div>
    </div>
  )
};

export default HomePage;
