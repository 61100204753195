import React, { Dispatch, SetStateAction} from 'react';

import { overlayOffcanvas, overlayMenu } from '../types/overlay.type';
import { navbarAsideHide } from '../types/navbar.type';
import { dropdownHideActive } from '../types/dropdown.type';

interface AppOverlayProps {
  activeDrawer: {value: string, aside: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string}>>
};

const AppOverlay: React.FunctionComponent<AppOverlayProps> = ({activeDrawer, setActiveDrawer}) => {
  const executeOverlayDrawer = () => {
    switch ('on') {
      case activeDrawer.aside:
        setActiveDrawer({...activeDrawer, value: 'off'})
        navbarAsideHide('navbar-aside', 'overlay-drawer')
        break;
      default:
        setActiveDrawer({...activeDrawer, aside: 'off', value: 'off'})
        overlayOffcanvas('overlay-drawer', 'navbar-aside')
        break;
    }
  }

  const executeOverlayMenu = () => {
    switch (true) {
      case document.getElementById('button-user-profile')?.classList.contains('active'):
        dropdownHideActive('button-user-profile', 'dropdown-user-profile', 'overlay-menu')
        break;
      default:
        overlayMenu('overlay-menu', ['button-user-profile'], ['dropdown-user-profile'])
        break;
    }
  }

  return (
    <>
      <div id="overlay-drawer" className="" onClick={executeOverlayDrawer}></div>
      <div id="overlay-menu" className="" onClick={executeOverlayMenu}></div>
    </>
  )
}

export default AppOverlay;
