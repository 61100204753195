import { languageFr } from '../context/language_fr';
import { languageEn } from '../context/language_en';

export const configLanguage = {
	lang:{
		french: languageFr,
		english: languageEn
	},

	languageDefault: languageFr
}
