import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { UserModel } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { Dropdown } from 'react-bootstrap';
import { inputFileSizeInvalid, inputFileTypeInvalid, inputFileTypeSizeValid, restartInputFileTypeSize } from '../../types/legend.input.file.type';
import { extractBase64 } from '../../scripts/extract.base.64.script';
import { removeTooltip, uploadTooltip } from '../../types/tooltip.type';
import { userRoleList, userRoleValue } from '../../scripts/user.role.script';
import { FaExclamationCircle } from 'react-icons/fa';
import { cleanInputFile } from '../../types/input.file.type';
import { listLastNameFirstNameSortedAscending, listLastNameFirstNameSortedDescending } from '../../scripts/order.asc.desc.list.script';
import imageAvatar from '../../assets/images/avatar.png';
import imageUpload from '../../assets/images/upload.png';

interface AppModalUserCreateProps {
  orderAscDesc: string,
  users: UserModel[] | undefined | null,
  listUsers: UserModel[] | undefined | null,
  setUsers: Dispatch<SetStateAction<UserModel[] | undefined | null>>,
  setListUsers: Dispatch<SetStateAction<UserModel[] | undefined | null>>
};

let errorResponse: Error, serviceResponse: any;

const AppModalUserCreate: React.FunctionComponent<AppModalUserCreateProps> = ({orderAscDesc, users, listUsers, setUsers, setListUsers}) => {
  const {lang} = StateLanguage()

  const expressions = {
    text: /^[A-Za-zÀ-ÿ0-9\s]{1,500}$/,
    email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
    phone: /^\+?[0-9]{1,4}[ -]*\(?[0-9]{1,4}\)?[ -]*[0-9\- ]{4,10}$/,
  }

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [avatar, setAvatar] = useState({value: '', file: null as any, valid: true})
  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: true})
  const [role, setRole] = useState({value: '', valid: false})

  const submitUserCreate = async () => {
    setLoadIndicator('on')

    if (users && listUsers && avatar.valid && firstName.valid && lastName.valid && email.valid && phone.valid && role.valid) {
      await UserService.postUser(avatar.file, firstName.value, lastName.value, email.value, phone.value, role.value).then( (response) => {
        if (response.status === 201) {
          serviceResponse = response.data

          let listAll = (orderAscDesc === "asc") ? listLastNameFirstNameSortedAscending([...users, serviceResponse]) : listLastNameFirstNameSortedDescending([...users, serviceResponse])
          let listView = (orderAscDesc === "asc") ? listLastNameFirstNameSortedAscending([...listUsers, serviceResponse]) : listLastNameFirstNameSortedDescending([...listUsers, serviceResponse])

          setUsers(listAll)
          setListUsers(listView)

          Swal.fire({
            title: lang.labels.successfullyCreatedUser,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalUserCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          }) 
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeAvatar = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576

    if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
      setAvatar({...avatar, value: '', file: null, valid: true})
      inputFileTypeInvalid('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      cleanInputFile()
    } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
      setAvatar({...avatar, value: '', file: null, valid: true})
      inputFileSizeInvalid('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      cleanInputFile()
    } else {
      extractBase64(archiveCapture).then((image: any) => {
        setAvatar({...avatar, value: image.base, file: archiveCapture, valid: true})
        inputFileTypeSizeValid('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      })
    }
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeRole = (item: string) => {
    setRole({ ...role, value: item, valid: true })
  }

  const handleRemoveAvatar = () => {
    setAvatar({...avatar, value: '', file: null, valid: true})
    restartInputFileTypeSize('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
    removeTooltip()
    cleanInputFile()
  }

  const validateFirstName = () => {
    if (expressions.text.test(firstName.value)) {
      setFirstName({...firstName, valid: true})
      inputValid('modal-user-create-input-first-name')
    } else {
      setFirstName({...firstName, valid: false})
      inputInvalid('modal-user-create-input-first-name')
    }
  }

  const validateLastName = () => {
    if (expressions.text.test(lastName.value)) {
      setLastName({...lastName, valid: true})
      inputValid('modal-user-create-input-last-name')
    } else {
      setLastName({...lastName, valid: false})
      inputInvalid('modal-user-create-input-last-name')
    }
  }

  const validateEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email, valid: true})
      inputValid('modal-user-create-input-email')
    } else {
      setEmail({...email, valid: false})
      inputInvalid('modal-user-create-input-email')
    }
  }

  const validatePhone = () => {
    if (phone.value.length > 0) {
      if (expressions.phone.test(phone.value)) {
        setPhone({...phone, valid: true})
        inputValid('modal-user-create-input-phone')
      } else {
        setPhone({...phone, valid: false})
        inputInvalid('modal-user-create-input-phone')
      }
    } else if (phone.value.length === 0) {
      setPhone({...phone, valid: true})
      restartInput('modal-user-create-input-phone')
    }
  }

  const executeHideModalUserCreate = () => {
    modalHide('modal-user-create')

    setTimeout( () => {
      restartInputFileTypeSize('modal-user-create-container-validate-logo-type', 'modal-user-create-container-validate-logo-size')
      restartInput('modal-user-create-input-first-name')
      restartInput('modal-user-create-input-last-name')
      restartInput('modal-user-create-input-email')
      restartInput('modal-user-create-input-phone')

      setAvatar({value: "", file: null, valid: true});
      setFirstName({value: "", valid: false});
      setLastName({value: "", valid: false});
      setEmail({value: "", valid: false});
      setPhone({value: "", valid: true});
      setRole({value: "", valid: false});

      cleanInputFile()
    }, 200 )
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="modal-user-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.createUser}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-4 mb-8 mb-lg-0">
                <div className="fv-row mb-10">
                  <label className="form-label d-flex align-items-center">
                    {lang.labels.avatar}
                    <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.addImageOrDragAndDrop}><FaExclamationCircle /></i>
                  </label>
                  <div className="my-5">
                    <div className="position-relative image-input image-input-outline" data-kt-image-input="true">
                      <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="file" accept=".png, .jpg, .jpeg" onChange={handleChangeAvatar} />
                      <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white ${(!avatar.valid || avatar.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveAvatar}>
                        <i className="bi bi-x fs-2"></i>
                      </span>
                      <img src={avatar.valid && avatar.value.length > 0 ? avatar.value : imageAvatar} className="image-input-wrapper w-150px h-150px" alt="avatar" />
                      <div className="position-absolute top-50 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center" style={{ pointerEvents: 'none' }}>
                        <img src={imageUpload} className="w-35px h-35px opacity-50" alt="Upload" />
                      </div>
                    </div>
                  </div>
                  <div id="modal-user-create-container-validate-logo-type" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="avatar" data-validator="type">{lang.labels.invalidFileType}</div>
                  </div>
                  <div id="modal-user-create-container-validate-logo-size" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="avatar" data-validator="size">{lang.labels.fileSizeNotAllowed}</div>
                  </div>
                </div>
                <div className="fv-row">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.role}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {role.valid ? userRoleValue(role.value, lang) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { userRoleList(lang).map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === role.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === role.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeRole(item.code)}>{item.desc}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="fv-row mb-5">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.firstName}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <input id="modal-user-create-input-first-name" className="form-control form-control-solid" type="text" name="first-name" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                </div>  
                <div className="fv-row mb-5">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.lastName}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <input id="modal-user-create-input-last-name" className="form-control form-control-solid" type="text" name="last-name" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                </div>  
                <div className="fv-row mb-5">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.email}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <input id="modal-user-create-input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                </div>
                <div className="fv-row">
                  <label className="form-label">{lang.labels.phone}</label>
                  <input id="modal-user-create-input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitUserCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light ms-3" type="reset" onClick={executeHideModalUserCreate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalUserCreate;
