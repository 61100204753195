import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { IngotModel } from '../../../models/ingot.model';
import { IngotService } from '../../../services/ingot.service';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { SecurityService } from '../../../services/security.service';
import { CompanySecurityModel } from '../../../models/company.security.model';
import { RateModel } from '../../../models/rate.model';
import { RateService } from '../../../services/rate.service';
import { IntermediaryService } from '../../../services/intermediary.service';
import { IntermediaryModel } from '../../../models/intermediary.model';

export interface NotaryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string, branch: string} | null, browse: {target: string, link: string} | null} | null>>,
  ingots: IngotModel[] | undefined | null,
  rates: RateModel[] | undefined | null,
  intermediaries: IntermediaryModel[] | undefined | null,
  securityCompanies: CompanySecurityModel[] | undefined | null
};

const NotaryPage: React.FunctionComponent<NotaryPageProps> = () => {
  const {setRoute} = UseOutletContext()
  const {lang} = StateLanguage()

  const [ingots, setIngots] = useState<IngotModel[] | undefined | null>(null)
  const [rates, setRates] = useState<RateModel[] | undefined | null>(null)
  const [intermediaries, setIntermediaries] = useState<IntermediaryModel[] | undefined | null>(null)
  const [securityCompanies, setSecurityCompanies] = useState<CompanySecurityModel[] | undefined | null>(null)

  const loadIngots = async () => {
    await IngotService.getIngots(true).then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setIngots(serviceResponse.ingots)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadRates = async () => {
    await RateService.getRates().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setRates(serviceResponse.rates)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadIntermediaries = async () => {
    await IntermediaryService.getIntermediaries().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setIntermediaries(serviceResponse.intermediaries)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadSecurityCompanies = async () => {
    await SecurityService.getSecurityCompanies().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setSecurityCompanies(serviceResponse.security_companies)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    loadIngots()
    loadRates()
    loadSecurityCompanies()
    loadIntermediaries()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Outlet context={{setRoute, ingots, rates, intermediaries, securityCompanies}}></Outlet>
  );
}

export function UseOutletContextNotary() {
  return useOutletContext<ContexType>()
};

export default NotaryPage;
