import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { modalShow } from '../../../types/modal.type';
import { UseOutletContextParameter } from './Parameter';
import { PurityService } from '../../../services/purity.service';
import { PurityModel } from '../../../models/purity.model';
import { listValueSortedAscending, listValueSortedDescending } from '../../../scripts/order.asc.desc.list.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';
import AppModalPurityUpdate from '../../../components/modal/ModalPurityUpdate';
import AppModalPurityCreate from '../../../components/modal/ModalPurityCreate';

export interface PuritiesPageProps {};

const PuritiesPage: React.FunctionComponent<PuritiesPageProps> = props => {
  const {setRoute, purities, setPurities} = UseOutletContextParameter()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [orderAscDesc, setOrderAscDesc] = useState("asc")
  const [purity, setPurity] = useState<PurityModel | undefined | null>(null)

  const submitPurityDelete = async (item: string) => {
    if (purities) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisPurity,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await PurityService.deletePurity(item).then( (response) => {
            if (response.status === 200) {
              let messageResponse: Message = response.data
              let list = purities.filter((purity) => (purity.id !== item))

              setPurities(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitPurityUpdate = async (item: PurityModel) => {
    setPurity(item)
    modalShow('modal-purity-update')
  }

  const submitPurityCreate = () => {
    modalShow('modal-purity-create')
  }

  const handleChangeOrderAscDesc = (item: string) => {
    if (purities && orderAscDesc !== item) {
      setOrderAscDesc(item)

      switch (item) {
        case 'asc':
          setPurities(listValueSortedAscending(purities))
          break;
        case 'desc':
          setPurities(listValueSortedDescending(purities))
          break;
      }
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.database, branch: lang.labels.purities}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <>
      { purities 
        ?
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h2>{lang.labels.purityList}</h2>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
              <div className="nav-group nav-group-outline p-1">
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "asc" && "active"}`} onClick={() => handleChangeOrderAscDesc("asc")}>{lang.labels.asc}</button>
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "desc" && "active"}`} onClick={() => handleChangeOrderAscDesc("desc")}>{lang.labels.desc}</button>
              </div>
              <button className="btn btn-primary" onClick={submitPurityCreate}>
                {lang.labels.addPurity}
              </button>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-3">
                  { purities.length > 0
                    ?
                    <tbody className="text-gray-900">
                      { purities.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed bg-hover-light" onClick={(event) => {event.stopPropagation(); submitPurityUpdate(item);}}>
                          <td className="min-w-300px fw-bold ps-3">
                            <div className="d-flex flex-row align-items-center">
                              {item.value.toFixed(1)}
                            </div>
                          </td>
                          <td className="w-150px text-end pe-1">
                            <button className="btn btn-sm btn-light-primary me-5" type="button" onClick={(event) => {event.stopPropagation(); submitPurityDelete(item.id);}}>
                              {lang.labels.delete}
                            </button>
                            <img className="w-20px" src={arrowNext} alt='Go'/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalPurityUpdate orderAscDesc={orderAscDesc} purity={purity} purities={purities} setPurity={setPurity} setPurities={setPurities}></AppModalPurityUpdate>
      <AppModalPurityCreate orderAscDesc={orderAscDesc} purities={purities} setPurities={setPurities}></AppModalPurityCreate>
    </>
  )
};

export default PuritiesPage;
