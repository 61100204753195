import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { CompanySecurityModel } from '../models/company.security.model';
import { appToken } from '../scripts/app.token.script';
import { extractBase64 } from '../scripts/extract.base.64.script';

export class SecurityService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getSecurityCompanies(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        security_companies: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/security.companies.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/security_companies`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getSecurityCompany(id_company_security: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: CompanySecurityModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/security.company.data.json').then(resp => { response = resp.data.security_company }).catch(error => { alert(error) })

      if (response!.id === id_company_security) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/security_companies/${id_company_security}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.security_company
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async getSecurityCompanySafes(id_company_security: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        id_company: id_company_security,
        number_safes: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/security.company.safes.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response!.id_company === id_company_security) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/security_companies/${id_company_security}/safes`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postSecurityCompany(name: string, number: string, street: string, postcode: string, city: string, country: string, phone: string, email: string, website: string, logo: any, safes: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let securityCompanies: CompanySecurityModel[] | null = null

      let response: CompanySecurityModel | null = {
        id: "newsecuritycompany",
        name: name,
        number: number,
        phone: phone,
        email: email,
        website: website,
        logo: (logo && typeof logo === 'string') ? logo : '',
        address: {
          street: street,
          zip_code: postcode,
          city: city,
          country: country
        },
        number_safes: [],
        number_safes_used: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/security.companies.data.json').then(resp => { securityCompanies = resp.data.security_companies }).catch(err => { alert(err) })

      if (!securityCompanies!.some(item => item.id === response!.id)) {
        if (logo) { extractBase64(logo).then((doc: any) => {response!.logo = doc.base}) } else { response!.logo = response.logo }

        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/security_companies`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('name', name)
      data.append('company_number', number)
      data.append('street', street)
      data.append('zip_code', postcode)
      data.append('city', city)
      data.append('country', country)
      data.append('phone', phone)
      data.append('email', email)
      data.append('website', website)
      data.append('logo', logo)

      safes.forEach(safe => {
        data.append('safes', safe)
      })

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.security_company
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putSecurityCompany(id_company_security: string, name: string, number: string, street: string, postcode: string, city: string, country: string, phone: string, email: string, website: string, logo: any, safes: string[]): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let securityCompanies: CompanySecurityModel[] | null = null

      let response: CompanySecurityModel | null = {
        id: id_company_security,
        name: name,
        number: number,
        phone: phone,
        email: email,
        website: website,
        logo: (logo && typeof logo === 'string') ? logo : '',
        address: {
          street: street,
          zip_code: postcode,
          city: city,
          country: country
        },
        number_safes: [],
        number_safes_used: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/security.companies.data.json').then(resp => { securityCompanies = resp.data.security_companies }).catch(err => { alert(err) })

      if (securityCompanies!.some(item => item.id === id_company_security)) {
        if (logo) { extractBase64(logo).then((doc: any) => {response!.logo = doc.base}) } else { response!.logo = response.logo }

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/security_companies/${id_company_security}`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('name', name)
      data.append('company_number', number)
      data.append('street', street)
      data.append('zip_code', postcode)
      data.append('city', city)
      data.append('country', country)
      data.append('phone', phone)
      data.append('email', email)
      data.append('website', website)
      data.append('logo', logo)

      safes.forEach(safe => {
        data.append('safes', JSON.stringify(safe))
      })

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.security_company
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteSecurity(id_company_security: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let securityCompanies: CompanySecurityModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/security.companies.data.json').then(resp => { securityCompanies = resp.data.security_companies }).catch(err => { alert(err) })

      if (securityCompanies!.some(item => item.id === id_company_security)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/security_companies/${id_company_security}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}