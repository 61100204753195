import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../../types/pagination.type';
import { CompanySecurityModel } from '../../../models/company.security.model';
import { UseOutletContextSecurity } from './Security';
import { SecurityService } from '../../../services/security.service';
import { getName } from '../../../scripts/countries.script';
import { imageCompany } from '../../../scripts/image.object.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';

export interface SecurityListPageProps {};

let errorResponse: Error, serviceResponse: any;

const SecurityListPage: React.FunctionComponent<SecurityListPageProps> = props => {
  const {setRoute} = UseOutletContextSecurity()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [securityCompanies, setSecurityCompanies] = useState<CompanySecurityModel[] | undefined | null>(null)

  const loadSecurityCompanies = async () => {
    let totalPages: number = 1
    let totalByPage: number = 10
    let listPagesOfPagination: number[] = []

    await SecurityService.getSecurityCompanies().then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data        

        if (serviceResponse.security_companies.length % totalByPage === 0) {
          totalPages = Math.floor(serviceResponse.security_companies.length / totalByPage)
        } else {
          totalPages = Math.floor(serviceResponse.security_companies.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPagination.push(i)
        }

        setSecurityCompanies(serviceResponse.security_companies)
        setPagination({...pagination, current: 1, total: totalByPage, pages: listPagesOfPagination, limits: {min: 0, max: totalByPage}})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const executePaginationSelect = (item: number) => {
    let minLimitNew: number = pagination.total * (item - 1)
    let maxLimitNew: number = pagination.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-button-previous')
    } else {
      pageLimitEnabled('pagination-button-previous')
    }

    if (item === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }else {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(item, elements)
    setPagination({...pagination, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationPrevious = () => {
    let currentNew: number = pagination.current - 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-button-previous')
    }

    if (currentNew < pagination.pages.length) {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationNext = () => {
    let currentNew: number = pagination.current + 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-button-previous')
    }

    if (currentNew === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  function rowClass(index: number): string {
    if (pagination.limits.min <= index && index < pagination.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.security, branch: lang.labels.securityCompanyList}, browse: null})
    setSecurityCompanies(null)

    loadSecurityCompanies()

    return () => setMounted(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { securityCompanies 
        ?
        <div className="card">
          <div className="card-body">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed gy-5">
                  <thead>
                    <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase">
                      <th colSpan={2} className="min-w-300px sorting">{lang.labels.securityCompanies}</th>
                    </tr>
                  </thead>
                  { securityCompanies.length > 0
                    ?
                    <tbody className="text-gray-600 fs-6 fw-bold">
                      { securityCompanies.map (( (item, index) => { return (
                        <tr key={index} className={`row-dashed bg-hover-light ${rowClass(index)}`} onClick={()=>{navigate("/app/security/view/" + item.id)}}>
                          <td className="d-flex align-items-center ps-3">
                            <span className="symbol symbol-50px overflow-hidden me-5">
                              <img src={imageCompany(item.logo)} alt='logo'/>
                            </span>
                            <div className="d-flex flex-column">
                              <span className="text-gray-900">{item.name}</span>
                              <span className="fs-7 text-gray-700">{item.address.street}, {item.address.zip_code}, {item.address.city}, {getName(item.address.country, lang.codeLanguage)}</span>
                            </div>
                          </td>
                          <td className="text-end pe-1">
                            <img className= "w-20px" src={arrowNext} alt="Go"/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
              { securityCompanies.length > 0 && pagination.pages.length > 1 &&
                <div className="row">
                  <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                  <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div className="dataTables_paginate paging_simple_numbers">
                      <ul className="pagination">
                        <li id="pagination-button-previous" className="paginate_button page-item previous disabled">
                          <button className="page-link" type="button" onClick={executePaginationPrevious}>
                            <i className="previous"></i>
                          </button>
                        </li>
                        { pagination.pages.map (( (item, index) => { return (
                          <li key={index} id={"pagination-button-" + item} className={`paginate_button page-item ${item === pagination.current && 'active' }`}>
                            <button className="page-link" type="button" onClick={() => executePaginationSelect(item)}>{item}</button>
                          </li>
                        )}))}
                        <li id="pagination-button-next" className="paginate_button page-item next">
                          <button className="page-link" type="button" onClick={executePaginationNext}>
                            <i className="next"></i>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default SecurityListPage;
