function inputFileValid(content: string) {
  let element = document.getElementById(content)

  element?.classList.remove('d-block')
  element?.classList.add('d-none')
}

function inputFileInvalid(content: string) {
  let element = document.getElementById(content)

  element?.classList.remove('d-none')
  element?.classList.add('d-block')
}

function restartInputFile(content: string) {
  let element = document.getElementById(content)

  element?.classList.remove('d-block')
  element?.classList.add('d-none')
}

function inputFileTypeSizeValid(content_type: string, content_size: string) {
  let element1 = document.getElementById(content_type)
  let element2 = document.getElementById(content_size)

  element1?.classList.remove('d-block')
  element2?.classList.remove('d-block')

  element1?.classList.add('d-none')
  element2?.classList.add('d-none')
}

function inputFileTypeInvalid(content_type: string, content_size: string) {
  let element1 = document.getElementById(content_type)
  let element2 = document.getElementById(content_size)

  element1?.classList.remove('d-none')
  element2?.classList.remove('d-block')

  element1?.classList.add('d-block')
  element2?.classList.add('d-none')
}

function inputFileSizeInvalid(content_type: string, content_size: string) {
  let element1 = document.getElementById(content_type)
  let element2 = document.getElementById(content_size)

  element1?.classList.remove('d-block')
  element2?.classList.remove('d-none')

  element1?.classList.add('d-none')
  element2?.classList.add('d-block')
}

function restartInputFileTypeSize(content_type: string, content_size: string) {
  let element1 = document.getElementById(content_type)
  let element2 = document.getElementById(content_size)

  element1?.classList.remove('d-block')
  element2?.classList.remove('d-block')

  element1?.classList.add('d-none')
  element2?.classList.add('d-none')
}

export { inputFileValid, inputFileInvalid, restartInputFile, inputFileTypeSizeValid, inputFileTypeInvalid, inputFileSizeInvalid, restartInputFileTypeSize }
