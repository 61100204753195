import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { ClientModel } from '../models/client.model';
import { appToken } from '../scripts/app.token.script';

export class ClientService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getClients(test_client: boolean, is_deleted: boolean): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        filter: {
          test_client: test_client,
          is_deleted: is_deleted
        },
        clients: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/clients.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        let newList: ClientModel[] = []

        if (test_client === false && is_deleted === false) {
          newList = response.clients.filter((item: ClientModel) => (item.test_client === false && item.is_deleted === false))
        }

        if (test_client === true) {
          newList = response.clients.filter((item: ClientModel) => (item.test_client === true))
        }

        if (is_deleted === true) {
          newList = response.clients.filter((item: ClientModel) => (item.is_deleted === true))
        }

        response.filter.is_deleted = is_deleted
        response.filter.test_client = test_client
        response.clients = newList

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients?test_client=${test_client}&eliminated=${is_deleted}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async getClient(id_client: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: ClientModel | null = null

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/client.data.json').then(resp => { response = resp.data.client }).catch(error => { alert(error) })

      if (response!.id === id_client) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.client
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })
      return this.apiResponse
    }
  }

  public static async postClient(last_name: string, first_name: string, email: string, phone: string, birthdate: string, birthplace: string, street: string, city: string, country: string, zip_code: string, taxpayer_number: string, pep_family: boolean, nationality: string, civil_status: string, father: string, mother: string, iban: string, swiftBic: string, professional_activity: string, function_start_date: string, current_employer: string, country_current_employer: string, activity_area: string, previus_professional_activity: string, previus_employer: string, annual_income_or_pension: string, fortune: string, origin_of_patrimonial_assets: string[], source_of_funds: string, diploma: string, board_member: boolean, test_client: boolean, spouse: any): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let clients: ClientModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/clients.data.json').then(resp => { clients = resp.data.clients }).catch(err => { alert(err) })

      if (!clients!.some(item => item.first_name === first_name && item.last_name === last_name && item.email === email)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        last_name: last_name,
        first_name: first_name,
        email: email,
        phone: phone,
        birthdate: birthdate,
        birthplace: birthplace,
        street: street,
        city: city,
        country: country,
        zip_code: zip_code,
        taxpayer_number: taxpayer_number,
        pep_family: pep_family,
        nationality: nationality,
        civil_status: civil_status,
        father: father,
        mother: mother,
        iban: iban,
        swiftBic: swiftBic,
        professional_activity: professional_activity,
        function_start_date: function_start_date,
        current_employer: current_employer,
        country_current_employer: country_current_employer,
        activity_area: activity_area,
        previus_professional_activity: previus_professional_activity,
        previus_employer: previus_employer,
        annual_income_or_pension: annual_income_or_pension,
        fortune: fortune,
        origin_of_patrimonial_assets: origin_of_patrimonial_assets,
        source_of_funds: source_of_funds,
        diploma: diploma,
        board_member: board_member,
        test_client: test_client,
        spouse: spouse
      }

      await axios.post(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putClient(id_client: string, last_name: string, first_name: string, email: string, phone: string, birthdate: string, birthplace: string, street: string, city: string, country: string, zip_code: string, taxpayer_number: string, pep_family: boolean, nationality: string, civil_status: string, father: string, mother: string, iban: string, swiftBic: string, professional_activity: string, function_start_date: string, current_employer: string, country_current_employer: string, activity_area: string, previus_professional_activity: string, previus_employer: string, annual_income_or_pension: string, fortune: string, origin_of_patrimonial_assets: string[], source_of_funds: string, diploma: string, board_member: boolean, test_client: boolean, spouse: any): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let clients: ClientModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/clients.data.json').then(resp => { clients = resp.data.clients }).catch(err => { alert(err) })

      if (clients!.some(item => item.id === id_client)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        last_name: last_name,
        first_name: first_name,
        email: email,
        phone: phone,
        birthdate: birthdate,
        birthplace: birthplace,
        street: street,
        city: city,
        country: country,
        zip_code: zip_code,
        taxpayer_number: taxpayer_number,
        pep_family: pep_family,
        nationality: nationality,
        civil_status: civil_status,
        father: father,
        mother: mother,
        iban: iban,
        swiftBic: swiftBic,
        professional_activity: professional_activity,
        function_start_date: function_start_date,
        current_employer: current_employer,
        country_current_employer: country_current_employer,
        activity_area: activity_area,
        previus_professional_activity: previus_professional_activity,
        previus_employer: previus_employer,
        annual_income_or_pension: annual_income_or_pension,
        fortune: fortune,
        origin_of_patrimonial_assets: origin_of_patrimonial_assets,
        source_of_funds: source_of_funds,
        diploma: diploma,
        board_member: board_member,
        test_client: test_client,
        spouse: spouse
      }

      await axios.put(url, data, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteClient(id_client: string, remove: boolean): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let clients: ClientModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/clients.data.json').then(resp => { clients = resp.data.clients }).catch(err => { alert(err) })

      if (clients!.some(item => item.id === id_client)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/clients/${id_client}${remove ? '/remove' : ''}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then(response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch(error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}
