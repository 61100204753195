import React, { Dispatch, SetStateAction, useEffect} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { StateLanguage } from '../languages/config/StateLanguage';
import { UserModel } from '../models/user.model';
import { headerShow, headerHide } from '../types/header.visibility.type';
import { navbarAsideHide } from '../types/navbar.type';
import { dropdownShowActive } from '../types/dropdown.type';
import { navTabReload } from '../types/nav.tab.type';
import { removeTooltip, uploadTooltip } from '../types/tooltip.type';
import { imageUser } from '../scripts/image.object.script';

import logotipo from '../assets/images/svg/sgi-emblem.svg';

interface AppNavbarProps {
  scrollVisibility: {header: string, top: string},
  activeDrawer: {value: string, aside: string},
  setScrollVisibility: Dispatch<SetStateAction<{header: string, top: string}>>,
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string}>>,
  userLoggedIn: UserModel | undefined | null,
};

const AppNavbar: React.FunctionComponent<AppNavbarProps> = ({scrollVisibility, activeDrawer, setScrollVisibility, setActiveDrawer, userLoggedIn}) => {
  const {lang} = StateLanguage()
  const location = useLocation()
  const navigate = useNavigate()

  const executeClickOnTab = (item: string) => {
    if (window.innerWidth < 992) {
      setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
      navbarAsideHide('navbar-aside', 'overlay-drawer')
    }

    switch (item) {
      case 'option-dashboard':
        navigate('/app/dashboard')
        break;
      case 'option-client':
        navigate('/app/client/list')
        break;
      case 'option-ingot':
        navigate('/app/ingot/list')
        break;
      case 'option-notary':
        navigate('/app/notary/list')
        break;
      case 'option-security':
        navigate('/app/security/list')
        break;
      case 'option-intermediary':
        navigate('/app/intermediary/list')
        break;
      case 'option-communication':
        navigate('/app/communication/list')
        break;
      case 'option-withdrawal':
        navigate('/app/withdrawal/list')
        break;
      case 'option-parameter':
        navigate('/app/parameter/users')
        break;
      case 'option-company':
        navigate('/app/company')
        break;
    }
  }

  const executeUserProfile = () => {
    if (userLoggedIn) {
      dropdownShowActive('button-user-profile', 'dropdown-user-profile', 'overlay-menu')
    }
  }

  function toggleVisible() {
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 200) {
      setScrollVisibility({...scrollVisibility, header: 'on', top: 'on'})
      headerShow('wrapper-header')
    }  else if (scrolled <= 200) {
      setScrollVisibility({...scrollVisibility, header: 'off', top: 'off'})
      headerHide('wrapper-header')
    }
  }

  function handleResize() {
    const width = window.innerWidth

    if (width > 992) {
      setActiveDrawer({...activeDrawer, value: 'off', aside: 'off'})
      navbarAsideHide('navbar-aside', 'overlay-drawer')
    }
  }

  useEffect( () => {
    let list_tab: string[] = Array.from(document.querySelectorAll('button[data-bs-toggle="tab"]')).map((item) => item.id);

    switch (`/app/${location.pathname.split('/')[2]}`) {
      case '/app/dashboard':
        navTabReload(list_tab, 'option-dashboard')
        break;
      case '/app/client':
        navTabReload(list_tab, 'option-client')
        break;
      case '/app/ingot':
        navTabReload(list_tab, 'option-ingot')
        break;
      case '/app/notary':
        navTabReload(list_tab, 'option-notary')
        break;
      case '/app/security':
        navTabReload(list_tab, 'option-security')
        break;
      case '/app/intermediary':
        navTabReload(list_tab, 'option-intermediary')
        break;
      case '/app/communication':
        navTabReload(list_tab, 'option-communication')
        break;
      case '/app/withdrawal':
        navTabReload(list_tab, 'option-withdrawal')
        break;
      case '/app/parameter':
        navTabReload(list_tab, 'option-parameter')
        break;
      case '/app/company':
        navTabReload(list_tab, 'option-company')
        break;
      default:
        navTabReload(list_tab, 'none')
        break;
    }
  }, [location])

  useEffect( () => {
    window.addEventListener('scroll', toggleVisible)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('scroll', toggleVisible)
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div id="navbar-aside" className="aside bg-white" data-kt-drawer="true">
      <div className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
        <div className="aside-logo d-none d-lg-flex p-5">
          <img src={logotipo} className="h-75px cursor-pointer" alt="logotipo" />
        </div>
        <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0">
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <button id="option-dashboard" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="2" y="2" width="9" height="9" rx="2" fill="black" />
                    <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="black" />
                    <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="black" />
                    <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="black" />
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.dashboard}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-client" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black"/>
                    <path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black"/>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.clients}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-ingot" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg fill="#a2a7b4" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 39 39">
                    <path d="M28.253,21.525c-0.33,0.619-0.938,1-1.6,1h-14.31c-0.659,0-1.269-0.381-1.599-1c-0.33-0.617-0.33-1.381,0-2l3.578-6.712 c0.329-0.619,0.938-1,1.599-1h7.153c0.659,0,1.269,0.381,1.599,1l3.578,6.712C28.583,20.146,28.583,20.908,28.253,21.525z M17.753,31.775l-3.577-6.711c-0.33-0.619-0.939-1-1.599-1H5.423c-0.659,0-1.269,0.381-1.598,1l-3.578,6.711 c-0.33,0.619-0.33,1.383,0,2c0.33,0.619,0.938,1,1.599,1h14.308c0.659,0,1.27-0.381,1.599-1 C18.083,33.158,18.083,32.396,17.753,31.775z M38.752,31.775l-3.578-6.711c-0.33-0.619-0.938-1-1.598-1h-7.154 c-0.659,0-1.27,0.381-1.599,1l-3.577,6.711c-0.33,0.619-0.33,1.383,0,2c0.329,0.619,0.938,1,1.598,1h14.309 c0.66,0,1.271-0.381,1.6-1C39.083,33.158,39.083,32.396,38.752,31.775z M32.117,12.468l-0.639,2.994l-2.996,0.638l2.996,0.637 l0.639,2.994l0.638-2.994l2.996-0.637l-2.996-0.638L32.117,12.468z M8.888,8.975L8.25,11.968l-2.996,0.638l2.996,0.637l0.638,2.994 l0.638-2.994l2.996-0.637l-2.996-0.638L8.888,8.975z M24.139,4.225L23.5,7.218l-2.996,0.638L23.5,8.494l0.639,2.994l0.638-2.994 l2.996-0.637l-2.996-0.638L24.139,4.225z"></path>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.ingots}</span>
              </button>
            </li>
            <li className="nav-item mb-2 d-none">
              <button id="option-notary" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black"/>
                    <path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black"/>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.notaries}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-security" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 20C6 20.6 5.6 21 5 21C4.4 21 4 20.6 4 20H6ZM18 20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20H18Z" fill="black"/>
                    <path opacity="0.3" d="M21 20H3C2.4 20 2 19.6 2 19V3C2 2.4 2.4 2 3 2H21C21.6 2 22 2.4 22 3V19C22 19.6 21.6 20 21 20ZM12 10H10.7C10.5 9.7 10.3 9.50005 10 9.30005V8C10 7.4 9.6 7 9 7C8.4 7 8 7.4 8 8V9.30005C7.7 9.50005 7.5 9.7 7.3 10H6C5.4 10 5 10.4 5 11C5 11.6 5.4 12 6 12H7.3C7.5 12.3 7.7 12.5 8 12.7V14C8 14.6 8.4 15 9 15C9.6 15 10 14.6 10 14V12.7C10.3 12.5 10.5 12.3 10.7 12H12C12.6 12 13 11.6 13 11C13 10.4 12.6 10 12 10Z" fill="black"/>
                    <path d="M18.5 11C18.5 10.2 17.8 9.5 17 9.5C16.2 9.5 15.5 10.2 15.5 11C15.5 11.4 15.7 11.8 16 12.1V13C16 13.6 16.4 14 17 14C17.6 14 18 13.6 18 13V12.1C18.3 11.8 18.5 11.4 18.5 11Z" fill="black"/>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.safes}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-intermediary" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black"/>
                    <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black"/>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.intermediaries}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-communication" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3 ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg fill="#424b62" width="64px" height="64px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M22,5V9L12,13,2,9V5A1,1,0,0,1,3,4H21A1,1,0,0,1,22,5ZM2,11.154V19a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V11.154l-10,4Z"></path>
                    </g>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.communications}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-withdrawal" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg fill="#a2a7b4" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 954 954"  transform="rotate(180)">
                    <path d="M909,82.15H45c-24.9,0-45,20.1-45,45v226.3c0,24.9,20.1,45,45,45h135.7v-55v-55H135c-13.8,0-25-11.2-25-25v-46.3 c0-13.8,11.2-25,25-25h77.2v37.3v59v55v55v80.5V821.85c0,27.602,22.4,50,50,50h429.6c27.601,0,50-22.398,50-50V479.051v-80.5v-55 v-55v-59v-37.3H819c13.8,0,25,11.2,25,25v46.3c0,13.8-11.2,25-25,25h-38.1v55v55H909c24.9,0,45-20.1,45-45v-226.4 C954,102.351,933.9,82.15,909,82.15z M650.5,444.05h-33.7v52.901c0,13.799-11.2,25-25,25s-25-11.201-25-25V444.05h-65.7v55.6 c0,13.801-11.199,25-25,25H360.5c-13.8,0-25-11.199-25-25v-55.6h-33.7c-13.8,0-25-11.2-25-25c0-8.5,4.2-16,10.7-20.5 c4-2.8,9-4.5,14.3-4.5h33.7v-44.5c0-2.1,0.3-4.1,0.7-6.1c2.7-10.9,12.5-18.9,24.3-18.9s21.5,8.1,24.3,18.9c0.5,1.9,0.7,4,0.7,6.1 v44.5h65.7v-50.5v-2.6c0-13.8,11.2-25,25-25h115.7c13.8,0,25,11.2,25,25v2.6v50.5H650.6c5.301,0,10.2,1.7,14.301,4.5 c6.5,4.5,10.699,12,10.699,20.5C675.5,432.85,664.3,444.05,650.5,444.05z"></path>
                    <rect x="385.5" y="444.05" width="65.7" height="30.601"></rect>
                    <rect x="501.1" y="365.951" width="65.7" height="28.1"></rect>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.withdrawalRequests}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-parameter" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="black"/>
                    <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="black"/>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">{lang.labels.database}</span>
              </button>
            </li>
            <li className="nav-item mb-2">
              <button id="option-company" className="nav-link btn btn-custom btn-icon btn-icon-menu d-flex flex-row justify-content-start ps-3" data-bs-toggle="tab" onClick={(e) => executeClickOnTab(e.currentTarget.id)}>
                <span className="svg-icon svg-icon-sx">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.3" d="M18 21.6C16.3 21.6 15 20.3 15 18.6V2.50001C15 2.20001 14.6 1.99996 14.3 2.19996L13 3.59999L11.7 2.3C11.3 1.9 10.7 1.9 10.3 2.3L9 3.59999L7.70001 2.3C7.30001 1.9 6.69999 1.9 6.29999 2.3L5 3.59999L3.70001 2.3C3.50001 2.1 3 2.20001 3 3.50001V18.6C3 20.3 4.3 21.6 6 21.6H18Z" fill="black"/>
                    <path d="M12 12.6H11C10.4 12.6 10 12.2 10 11.6C10 11 10.4 10.6 11 10.6H12C12.6 10.6 13 11 13 11.6C13 12.2 12.6 12.6 12 12.6ZM9 11.6C9 11 8.6 10.6 8 10.6H6C5.4 10.6 5 11 5 11.6C5 12.2 5.4 12.6 6 12.6H8C8.6 12.6 9 12.2 9 11.6ZM9 7.59998C9 6.99998 8.6 6.59998 8 6.59998H6C5.4 6.59998 5 6.99998 5 7.59998C5 8.19998 5.4 8.59998 6 8.59998H8C8.6 8.59998 9 8.19998 9 7.59998ZM13 7.59998C13 6.99998 12.6 6.59998 12 6.59998H11C10.4 6.59998 10 6.99998 10 7.59998C10 8.19998 10.4 8.59998 11 8.59998H12C12.6 8.59998 13 8.19998 13 7.59998ZM13 15.6C13 15 12.6 14.6 12 14.6H10C9.4 14.6 9 15 9 15.6C9 16.2 9.4 16.6 10 16.6H12C12.6 16.6 13 16.2 13 15.6Z" fill="black"/>
                    <path d="M15 18.6C15 20.3 16.3 21.6 18 21.6C19.7 21.6 21 20.3 21 18.6V12.5C21 12.2 20.6 12 20.3 12.2L19 13.6L17.7 12.3C17.3 11.9 16.7 11.9 16.3 12.3L15 13.6V18.6Z" fill="black"/>
                  </svg>
                </span>
                <span className="text-muted text-uppercase ms-2">SGI SA</span>
              </button>
            </li>
          </ul>
        </div>
        { userLoggedIn &&
          <div className="aside-footer d-flex flex-column align-items-center flex-column-auto" onLoad={uploadTooltip}>
            <div className="d-flex align-items-center mb-5" data-bs-toggle="tooltip" data-bs-placement="right" title={lang.labels.userProfile} onClick={removeTooltip}>
              <div id="button-user-profile" className="cursor-pointer symbol symbol-50px px-2 d-flex flex-column align-items-center" onClick={executeUserProfile}>
                <img src={imageUser(userLoggedIn.avatar)} alt="avatar" />
                <span className="text-muted text-capitalize fw-bolder d-inline-block overflow-hidden text-truncate mw-150px px-2 pt-3">
                  {userLoggedIn.first_name} {userLoggedIn.last_name}
                </span>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default AppNavbar;
