import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { RateModel } from '../../models/rate.model';
import { RateService } from '../../services/rate.service';
import { rateTypeList, rateTypeValue, rateTypeYears } from '../../scripts/rate.type.script';
import { listValueSortedAscending, listValueSortedDescending } from '../../scripts/order.asc.desc.list.script';

interface AppModalRateCreateProps {
  orderAscDesc: string,
  rates: RateModel[] | undefined | null,
  setRates: Dispatch<SetStateAction<RateModel[] | undefined | null>>
};

const AppModalRateCreate: React.FunctionComponent<AppModalRateCreateProps> = ({orderAscDesc, rates, setRates}) => {
  const {lang} = StateLanguage()

  const expressions = {
    number: /^\d{0,10}(\.\d{1,2})?$/,
  }

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [valueRate, setValueRate] = useState({value: '', valid: false})
  const [typeRate, setTypeRate] = useState({value: '', valid: false})
  const [yearsRate, setYearsRate] = useState({value: '', valid: false})

  const submitRateCreate = async () => {
    setLoadIndicator('on')

    if (rates && valueRate.valid && typeRate.valid && yearsRate.valid) {
      await RateService.postRate(valueRate.value, typeRate.value, yearsRate.value).then( (response) => {
        if (response.status === 201) {
          let serviceResponse: RateModel = response.data
          let list = (orderAscDesc === "asc") ? listValueSortedAscending([...rates, serviceResponse]) : listValueSortedDescending([...rates, serviceResponse])

          setRates(list)

          Swal.fire({
            title: lang.labels.successfullyCreatedRate,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalRateCreate()
          })
        } else {
          let errorResponse: Error = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          }) 
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeValueRate = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setValueRate({...valueRate, value: event.target.value})
  }

  const handleChangeTypeRate = (item: string) => {
    setTypeRate({...typeRate, value: item, valid: true})

    if (item === 'annual') {
      setYearsRate({value: '', valid: false})
    } else {
      setYearsRate({value: '', valid: true})
    }
  }

  const handleChangeYearsRate = (item: string) => {
    if (document.querySelector('input[name="rate-type-years"]:checked')) {
      setYearsRate({...yearsRate, value: item, valid: true})
    }
  }

  const validateValueRate = () => {
    if (expressions.number.test(valueRate.value)) {
      setValueRate({...valueRate, valid: true})
      inputValid('modal-rate-create-input-value-rate')
    } else {
      setValueRate({...valueRate, valid: false})
      inputInvalid('modal-rate-create-input-value-rate')
    }
  }

  const executeHideModalRateCreate = () => {
    modalHide('modal-rate-create')

    setTimeout( () => {
      restartInput('modal-rate-create-input-value-rate')
      setValueRate({value: '', valid: false})
      setTypeRate({value: '', valid: false})
      setYearsRate({value: '', valid: false})
    }, 200 )
  }

  return (
    <div id="modal-rate-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-550px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.createRate}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className="row fv-row mb-5">
              <div className="d-flex justify-content-between">
                <label className="form-label">{lang.labels.value}</label>
                <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
              </div>
              <input id="modal-rate-update-input-value-rate" className="form-control form-control-solid" type="text" name="value-rate" value={valueRate.value} onChange={handleChangeValueRate} onKeyUp={validateValueRate} onBlur={validateValueRate} />
            </div>
            <div className="roe fv-row mb-5">
              <div className="d-flex justify-content-between">
                <label className="form-label">{lang.labels.type}</label>
                <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                  <span className="selection">
                    <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                      <span className="select2-selection__rendered" role="textbox">
                        <span className="select2-selection__placeholder">
                          {typeRate.valid ? rateTypeValue(typeRate.value, lang) : lang.labels.selectOption}
                        </span>
                      </span>
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                  <span className="select2-dropdown select2-dropdown--below">
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox">
                        { rateTypeList(lang).map (( (item, index) => { return (
                          <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === typeRate.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === typeRate.value ? "true" : "false"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeTypeRate(item.code)}>{item.desc}</Dropdown.Item>
                          </li>
                        )}))}
                      </ul>
                    </span>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            { typeRate.value === 'annual' &&
              <div className="row fv-row">
                <div className="d-flex justify-content-between">
                  <label className="form-label">{lang.labels.years}</label>
                  <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                </div>
                <div className="row" data-kt-buttons="true">
                  { rateTypeYears().map (( (item, index) => { return (
                    <div key={index} className="col">
                      <label className={`btn btn-outline btn-outline-dashed btn-outline-default w-100 p-2 ${item === yearsRate.value && "active"}`}>
                        <input className="btn-check" type="radio" name="rate-type-years" value={item} onClick={() => handleChangeYearsRate(item)} />
                        <span className="fw-bolder fs-3">{item}</span>
                      </label>
                    </div>
                  )}))}
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitRateCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalRateCreate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalRateCreate;
