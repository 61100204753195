import { Language } from "../languages/context/language"

export function documentFinancialStatementTypeList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'balance_and_results', desc: lang.labels.balanceSheetAndIncomeStatement },
    { code: 'auditor_report', desc: lang.labels.auditorReport },
  ]
}

export function documentFinancialStatementTypeValue(code: string, lang: Language): string {
  let element = documentFinancialStatementTypeList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}