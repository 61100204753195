import axios from 'axios';
import { format } from 'date-fns';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { Message } from '../models/message.model';
import { DocumentModel } from '../models/document.model';
import { extractBase64 } from '../scripts/extract.base.64.script';
import { appToken } from '../scripts/app.token.script';

export class GeneralInformationService {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getGeneralInformations(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        general_informations: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/general.informations.data.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/sgi/general/documents`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postGeneralInformation(name: string, date: string, type: string, file: any): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let generalInformations: DocumentModel[] | null = null

      let response: DocumentModel | null = {
        id: "newgeneralInformation",
        name: name,
        date: date,
        type: type,
        created_at: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
        file: (file && typeof file === 'string') ? file : '',
      }
  
      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }
  
      await axios.get('../../../../../database/general.informations.data.json').then(resp => { generalInformations = resp.data.general_informations }).catch(err => { alert(err) })
  
      if (!generalInformations!.some(item => item.name === name)) {
        if (file) { extractBase64(file).then((doc: any) => {response!.file = doc.base}) } else { response!.file = response.file }
  
        this.dataResponse = response
  
        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error
  
        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }
  
      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/sgi/general/documents`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData()

      data.append('name', name)
      data.append('file_date', date)
      data.append('type', type)
      data.append('file', file)

      await axios.post(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.document
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async putGeneralInformation(id_general_informations: string, name: string, date: string, type: string, file: any): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let generalInformations: DocumentModel[] | null = null

      let response: DocumentModel | null = {
        id: id_general_informations,
        name: name,
        date: date,
        type: type,
        created_at: format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz'),
        file: (file && typeof file === 'string') ? file : '',
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/general.informations.data.json').then(resp => { generalInformations = resp.data.general_informations }).catch(err => { alert(err) })

      if (generalInformations!.some(item => item.id === id_general_informations)) {
        if (file) { extractBase64(file).then((doc: any) => {response!.file = doc.base}) } else { response!.file = response.file }

        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/sgi/general/documents/${id_general_informations}`
      let header: any = { headers: { Authorization: appToken() } }
      let data = new FormData();

      data.append('name', name)
      data.append('file_date', date)
      data.append('type', type)
      data.append('file', file)

      await axios.put(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data.document
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async deleteGeneralInformation(id_general_informations: string): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let generalInformations: DocumentModel[] | null = null

      let response: Message | null = {
        message: 'Successful request'
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/general.informations.data.json').then(resp => { generalInformations = resp.data.general_informations }).catch(err => { alert(err) })

      if (generalInformations!.some(item => item.id === id_general_informations)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/sgi/general/documents/${id_general_informations}`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.delete(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}