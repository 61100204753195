function inputValid(identifier: string) {
  let element1 = document.getElementById(identifier)

  element1?.classList.remove('is-invalid')
  element1?.classList.add('is-valid')
}

function inputInvalid(identifier: string) {
  let element1 = document.getElementById(identifier)

  element1?.classList.remove('is-valid')
  element1?.classList.add('is-invalid')
}

function restartInput(identifier: string) {
  let element1 = document.getElementById(identifier)

  element1?.classList.remove('is-valid')
  element1?.classList.remove('is-invalid')
}

function inputValidRequiredIcon(identifier: string, content_1: string, content_2: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(content_1)
  let element3 = document.getElementById(content_2)

  if(element1?.classList.contains('is-invalid')) {
    element1?.classList.remove('is-invalid')
    element2?.classList.remove('d-block')
    element3?.classList.remove('d-block')
  }

  element1?.classList.add('is-valid')
  element2?.classList.add('d-none')
  element3?.classList.add('d-none')
}

function inputInvalidRequiredIcon(value: string, identifier: string, content_1: string, content_2: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(content_1)
  let element3 = document.getElementById(content_2)

  if (element1?.classList.contains('is-valid')) {
    element1?.classList.remove('is-valid')
    element1?.classList.add('is-invalid')
  } else {
    element1?.classList.add('is-invalid')
  }

  if (value.length > 0) {
    element2?.classList.remove('d-none')
    element3?.classList.remove('d-block')

    element2?.classList.add('d-block')
    element3?.classList.add('d-none')
  } else {
    element2?.classList.remove('d-block')
    element3?.classList.remove('d-none')

    element2?.classList.add('d-none')
    element3?.classList.add('d-block')
  }
}

function restartInputRequiredIcon(identifier: string, content_1: string, content_2: string) {
  let element1 = document.getElementById(identifier)
  let element2 = document.getElementById(content_1)
  let element3 = document.getElementById(content_2)

  element1?.classList.remove('is-valid')
  element1?.classList.remove('is-invalid')
  element2?.classList.remove('d-block')
  element2?.classList.add('d-none')
  element3?.classList.remove('d-block')
  element3?.classList.add('d-none')
}

export { inputValid, inputInvalid, restartInput, inputValidRequiredIcon, inputInvalidRequiredIcon, restartInputRequiredIcon }
