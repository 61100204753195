import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { uploadTooltip } from '../../../types/tooltip.type';
import { getName } from '../../../scripts/countries.script';
import { UseOutletContextIntermediary } from './Intermediary';
import { IntermediaryModel } from '../../../models/intermediary.model';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IntermediaryService } from '../../../services/intermediary.service';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { ibanFormat } from '../../../scripts/iban.format.script';
import { bicFormat } from '../../../scripts/bic.format.script';
import { Message } from '../../../models/message.model';
import { dateFormat } from '../../../scripts/date.format.script';
import { civilStatusValue } from '../../../scripts/civil.status.script';
import { imageUser } from '../../../scripts/image.object.script';

export interface IntermediaryViewPageProps {};

let errorResponse: Error, messageResponse: Message, serviceResponse: any;

const IntermediaryViewPage: React.FunctionComponent<IntermediaryViewPageProps> = props => {
  const {setRoute} = UseOutletContextIntermediary()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [intermediary, setIntermediary] = useState<IntermediaryModel | undefined | null>(null)

  const loadIntermediary = async (id_intermediary: string) => {
    await IntermediaryService.getIntermediary(id_intermediary).then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data
        setIntermediary(serviceResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitIntermediaryDelete = async () => {
    if (intermediary) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisIntermediary,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await IntermediaryService.deleteIntermediary(intermediary.id).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingIntermediaryListReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions).then( () => {
                navigate('/app/intermediary/list')
              })
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  useEffect( () => {
    if(param.id) {
      loadIntermediary(param.id)
      uploadTooltip()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.intermediaries, branch: lang.labels.viewIntermediary}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { intermediary
        ?
        <>
          <div className="card overflow-hidden mb-5">
            <div className="card-header py-5"> 
              <div className="card-title">
                <div className="d-sm-flex align-items-center">
                  <div className="symbol symbol-100px mb-3 mb-sm-0">
                    <img src={imageUser(intermediary.avatar)} alt="avatar"/>
                  </div>
                  <div className="ms-5 d-flex flex-column justify-content-center">
                    <span className="fw-bolder fs-3 text-gray-800">
                      {intermediary.last_name} {intermediary.first_name}
                    </span>
                    <span className="fw-bold text-gray-600 fs-6">
                      {dateFormat(intermediary.date_of_birth, lang.codeLanguage)}
                    </span>
                    <span className="fs-7 text-gray-600 fw-bold">
                      {intermediary.place_of_birth}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
                <Link to={"/app/intermediary/update/" + intermediary.id} className="btn btn-primary">{lang.labels.editIntermediary}</Link>
                <button className="btn btn-light-primary" type="button" onClick={submitIntermediaryDelete}>{lang.labels.deleteIntermediary}</button>
              </div>
            </div>
          </div>
          <div className="card card-flush">
            <div className="card-body">
              <h4 className="mb-4">{lang.labels.identity}</h4>
              <div className="row mb-10">
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.firstName}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.first_name}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.lastName}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.last_name}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.email}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.email}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.phone}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.phone}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.dateOfBirth}:</td>
                        <td className="min-w-200px text-gray-800">{dateFormat(intermediary.date_of_birth, lang.codeLanguage)}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.placeOfBirth}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.place_of_birth}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h3 className="mb-4">{lang.labels.address}:</h3>
              <div className="row mb-10">
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.address}:</td>
                        <td className="text-gray-800 min-w-200px">{intermediary.address.street}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.city}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.address.city}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.postcode}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.address.zip_code}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.country}:</td>
                        <td className="min-w-200px text-gray-800">{getName(intermediary.address.country, lang.codeLanguage)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h3 className="mb-4">{lang.labels.additionalInformation}:</h3>
              <div className="row mb-10">
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.tin}:</td>
                        <td className="text-gray-800 min-w-200px">{intermediary.tin}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.nationality}:</td>
                        <td className="min-w-200px text-gray-800">{getName(intermediary.nationality, lang.codeLanguage)}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.maritalStatus}:</td>
                        <td className="min-w-200px text-gray-800">{civilStatusValue(intermediary.civil_status, lang)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.iban}:</td>
                        <td className="min-w-200px text-gray-800">{ibanFormat(intermediary.iban)}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.bicSwift}:</td>
                        <td className="min-w-200px text-gray-800">{bicFormat(intermediary.swift_bic)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h3 className="mb-4">{lang.labels.companyInformation}:</h3>
              <div className="row">
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.companyName}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.name.length > 0 ? intermediary.company.name : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.businessNumber}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.number.length > 0 ? intermediary.company.number : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.email}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.email.length > 0 ? intermediary.company.email : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.phone}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.phone.length > 0 ? intermediary.company.phone : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.website}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.website.length > 0 ? intermediary.company.website : lang.labels.none}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-xl-6">
                  <table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">
                    <tbody>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.iban}:</td>
                        <td className="text-gray-800 min-w-200px">{intermediary.company.iban.length > 0 ? ibanFormat(intermediary.company.iban) : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.bicSwift}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.swift_bic.length > 0 ? bicFormat(intermediary.company.swift_bic) : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.address}:</td>
                        <td className="text-gray-800 min-w-200px">{intermediary.company.address.street.length > 0 ? intermediary.company.address.street : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.postcode}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.address.zip_code.length > 0 ? intermediary.company.address.zip_code : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.city}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.address.city.length > 0 ? intermediary.company.address.city : lang.labels.none}</td>
                      </tr>
                      <tr>
                        <td className="w-175px text-gray-400">{lang.labels.country}:</td>
                        <td className="min-w-200px text-gray-800">{intermediary.company.address.country.length > 0 ? getName(intermediary.company.address.country, lang.codeLanguage) : lang.labels.none}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default IntermediaryViewPage;