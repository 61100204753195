import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextNotary } from './Notary';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { NotaryService } from '../../../services/notary.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { NotaryModel } from '../../../models/notary.model';
import { modalShow } from '../../../types/modal.type';
import { dateFormat } from '../../../scripts/date.format.script';
import { InvestmentService } from '../../../services/investment.service';
import { InvestmentModel } from '../../../models/investment.model';
import { moneyFormat } from '../../../scripts/money.format.script';
import AppModalNotaryUpdate from '../../../components/modal/ModalNotaryUpdate';
import AppModalInvestmentUpdate from '../../../components/modal/ModalInvestmentUpdate';

export interface NotaryViewPageProps {};

let errorResponse: Error, messageResponse: Message, notaryResponse: any, investmentResponse: any;

const NotaryViewPage: React.FunctionComponent<NotaryViewPageProps> = props => {
  const {setRoute, ingots, rates, intermediaries, securityCompanies} = UseOutletContextNotary()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [notary, setNotary] = useState<NotaryModel | undefined | null>(null)
  const [investment, setInvestment] = useState<InvestmentModel | undefined | null>(null)

  const loadNotary = async (id_client: string) => {
    await NotaryService.getNotary(id_client).then( (response) => {
      if (response.status === 200) {
        notaryResponse = response.data
        setNotary(notaryResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitInvestmentUpdate = async (id_client: string, id_investment: string) => {
    await InvestmentService.getInvestment(id_client, id_investment).then( (response) => {
      if (response.status === 200) {
        investmentResponse = response.data
        setInvestment(investmentResponse)
        modalShow('modal-investment-update')
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitInvestmentDelete = async (id_client: string, id_investment: string) => {
    if (notary) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisInvestment,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await InvestmentService.deleteInvestment(id_client, id_investment).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              let list = notary.investments.filter((item) => (item.id !== id_investment))

              setNotary(prevState => {
                if (prevState) {
                  return { ...prevState, investments: list }
                }
                return null
              })

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingInvestmentList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const executeShowModalContactUpdate = () => {
    modalShow('modal-notary-update')
  }

  const executeMouseEnter = () => {
    setIsHovered(true)
  }

  const executeMouseLeave = () => {
    setIsHovered(false)
  }

  useEffect( () => {
    if(param.id) {
      loadNotary(param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setRoute({main: false, path: {root: lang.labels.notaries, branch: `${lang.labels.notaryView} ${notary ? notary.last_name : ""}`}, browse: null})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notary])

  useEffect( () => {
    setMounted(true)

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { notary
        ?
        <>
          <div className="card card-flush border border-gray-300 mb-5">
            <div className="card-header py-5">
              <div className="card-title">
                <div className="d-flex flex-column">
                  <span className="fs-7 fw-bold text-uppercase">{lang.labels.notary}</span>
                  <span className="fs-2 fw-bolder">{`${notary.first_name} ${notary.last_name}`}</span>
                  <span className="fs-6 text-gray-700">{notary.address.zip_code} {notary.address.city}</span>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end">
                <button className="btn btn-primary" onClick={executeShowModalContactUpdate}>{lang.labels.edit}</button>
              </div>
            </div>
          </div>
          <div className="card card-flush border border-gray-300 mb-10">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.fullName}</span>
                  <span className="fs-6">{notary.first_name} {notary.last_name}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.address}</span>
                  <span className="fs-6">{notary.address.street}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.postcode} {lang.labels.city}</span>
                  <span className="fs-6">{notary.address.zip_code} {notary.address.city}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.phone}</span>
                  <span className="fs-6">{notary.phone}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.email}</span>
                  <span className="fs-6">{notary.email}</span>
                </div>
                <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                  <span className="text-muted">{lang.labels.website}</span>
                  <span className="fs-6">{notary.website.length > 0 ? notary.website : lang.labels.none}</span>
                </div>
              </div>
            </div>
          </div>
          { notary.investments &&
            <>
              <div className="bg-transparent mb-5">
                <h2 className="fw-bold">{lang.labels.authenticDeedsSignedInFrontOf} {notary.first_name} {notary.last_name}</h2>
              </div>
              <div className="card card-flush border border-gray-300">
                <div className="card-body py-5">
                  <div className="dataTables_wrapper">
                    <div className="table-responsive">
                      <table className="table align-middle table-row-dashed gy-4">
                        <thead>
                          <tr className="text-muted fw-bolder fs-7 text-uppercase">
                            <th className="min-w-100px text-start">{lang.labels.date}</th>
                            <th className="min-w-100px text-start">{lang.labels.client}</th>
                            <th className="min-w-100px text-end">{lang.labels.numberOfIngots}</th>
                            <th className="min-w-100px text-end">{lang.labels.salePriceInSgi}</th>
                            <th className="w-225px text-end"></th>
                          </tr>
                        </thead>
                        { notary.investments.length > 0
                          ?
                          <tbody className="text-gray-600 fs-6 fw-bold">
                            { notary.investments.map (( (item, index) => { return (
                              <tr key={index} className="row-dashed bg-hover-light" onClick={() => {navigate(`/app/investment/view/${item.client.id}/${item.id}`)}} onMouseEnter={executeMouseEnter} onMouseLeave={executeMouseLeave}>
                                <td className="text-start">
                                  <div className="d-flex align-items-center">
                                    <span className="svg-icon svg-icon-1">
                                      <svg fill="#000000" width="64px" height="64px" viewBox="-1.7 0 20.4 20.4" xmlns="http://www.w3.org/2000/svg" className="cf-icon-svg">
                                        <g id="SVGRepo_bgCarrier"></g>
                                        <g id="SVGRepo_tracerCarrier"></g>
                                        <g id="SVGRepo_iconCarrier">
                                          <path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zm-4.844 1.754a2.249 2.249 0 0 0-.556-1.477l-.001-.002a3.02 3.02 0 0 0-.835-.665l-.003-.002a3.498 3.498 0 0 0-.866-.313H9.31a3.78 3.78 0 0 0-.795-.083 2.849 2.849 0 0 1-.475-.037 1.8 1.8 0 0 1-.494-.158l-.002-.001a1.17 1.17 0 0 1-.371-.298L7.172 9a.733.733 0 0 1-.175-.44.749.749 0 0 1 .421-.63 2.157 2.157 0 0 1 1.11-.297 2.283 2.283 0 0 1 .391.066l.049.01a2.479 2.479 0 0 1 .473.166 1.33 1.33 0 0 1 .381.261.792.792 0 1 0 1.118-1.12 2.902 2.902 0 0 0-.85-.585 3.996 3.996 0 0 0-.785-.268h-.001l-.008-.002v-.786a.792.792 0 1 0-1.583 0v.763a3.557 3.557 0 0 0-1.14.454 2.328 2.328 0 0 0-1.159 1.967 2.296 2.296 0 0 0 .529 1.44 2.724 2.724 0 0 0 .894.717 3.342 3.342 0 0 0 .942.305 4.398 4.398 0 0 0 .736.059 2.202 2.202 0 0 1 .46.046 1.927 1.927 0 0 1 .467.168 1.431 1.431 0 0 1 .382.308.674.674 0 0 1 .165.436c0 .097 0 .324-.385.573a2.182 2.182 0 0 1-1.132.314 3.515 3.515 0 0 1-.494-.06 2.381 2.381 0 0 1-.459-.148h-.001a.953.953 0 0 1-.356-.274.792.792 0 1 0-1.197 1.037 2.516 2.516 0 0 0 .967.708 3.799 3.799 0 0 0 .774.237h.007v.783a.792.792 0 1 0 1.583 0v-.79a3.581 3.581 0 0 0 1.17-.479 2.215 2.215 0 0 0 1.107-1.9z"></path>
                                        </g>
                                      </svg>
                                    </span>
                                    <span className="text-gray-900 ps-1">
                                      {dateFormat(item.authentic_act_date, lang.codeLanguage)}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-start">{item.client.first_name} {item.client.last_name}</td>
                                <td className="text-end">{item.number_ingots}</td>
                                <td className="text-end">{moneyFormat(item.gold_sale_price)}</td>
                                <td className="text-end py-0">
                                  { isHovered &&
                                    <>
                                      <button className="btn btn-sm btn-light-primary" onClick={(e) => {e.stopPropagation(); submitInvestmentUpdate(item.client.id, item.id);}}>{lang.labels.modify}</button>
                                      <button className="btn btn-sm btn-light-primary ms-2" onClick={(e) => {e.stopPropagation(); submitInvestmentDelete(item.client.id, item.id);}}>{lang.labels.delete}</button>
                                    </>
                                  }
                                </td>
                              </tr>
                            )}))}
                          </tbody>
                          :
                          <tbody className="text-gray-600 fw-bold">
                            <tr className="odd">
                              <td valign="top" colSpan={5} className="text-center">
                                {lang.labels.noMatchingRecordsFound}
                              </td>
                            </tr>
                          </tbody>
                        }
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalNotaryUpdate notary={notary} notaries={null} setNotary={setNotary} setNotaries={null}></AppModalNotaryUpdate>
      <AppModalInvestmentUpdate investment={investment} notary={notary} investments={null} ingots={ingots} rates={rates} intermediaries={intermediaries} securityCompanies={securityCompanies} notaries={null} setNotary={setNotary} setInvestments={null}></AppModalInvestmentUpdate>
    </div>
  )
};

export default NotaryViewPage;
