import { Tooltip } from 'bootstrap'

function uploadTooltip() {
  setTimeout( () => {
    Array.from(document.querySelectorAll('li[data-bs-toggle="tooltip"]')).forEach(node => new Tooltip(node))
    Array.from(document.querySelectorAll('div[data-bs-toggle="tooltip"]')).forEach(node => new Tooltip(node))
    Array.from(document.querySelectorAll('i[data-bs-toggle="tooltip"]')).forEach(node => new Tooltip(node))
    Array.from(document.querySelectorAll('label[data-bs-toggle="tooltip"]')).forEach(node => new Tooltip(node))
    Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(node => new Tooltip(node))
    Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(node => new Tooltip(node))
  }, 100 * 6 )
}

function removeTooltip() {
  Array.from(document.querySelectorAll('div[role="tooltip"]')).forEach(node => node.remove())
}

export { uploadTooltip, removeTooltip }
