import { Language } from "../languages/context/language";

export function withdrawalRequestStatusList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'open', desc: lang.labels.open },
    { code: 'accepted', desc: lang.labels.accepted },
    { code: 'denied', desc: lang.labels.denied },
  ]
}

export function withdrawalRequestStatusValue(code: string, lang: Language): string {
  let element = withdrawalRequestStatusList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}