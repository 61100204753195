import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { DocumentModel } from '../../../models/document.model';
import { FinancialStatementService } from '../../../services/financial.statement.service';
import { GeneralInformationService } from '../../../services/general.information.service';
import { Error } from '../../../models/error.model';
import { getError } from '../../../languages/context/response';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import FinancialStatementsPage from './FinancialStatements';
import GeneralInformationsPage from './GeneralInformations';

export interface CompanyPageProps {};

const CompanyPage: React.FunctionComponent<CompanyPageProps> = props => {
  const {setRoute} = UseOutletContext()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [financialStatements, setFinancialStatements] = useState<DocumentModel[] | undefined | null>(null)
  const [generalInformations, setGeneralInformations] = useState<DocumentModel[] | undefined | null>(null)

  const loadFinancialStatementies = async () => {
    await FinancialStatementService.getFinancialStatements().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setFinancialStatements(serviceResponse.financial_statements)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadGeneralInformations = async () => {
    await GeneralInformationService.getGeneralInformations().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setGeneralInformations(serviceResponse.general_informations)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.company, branch: lang.labels.swissGlobalInvestment}, browse: null})
    loadFinancialStatementies()
    loadGeneralInformations()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { financialStatements && generalInformations 
        ?
        <>
          <FinancialStatementsPage financialStatements={financialStatements} setFinancialStatements={setFinancialStatements}></FinancialStatementsPage>
          <div className="h-50px"></div>
          <GeneralInformationsPage generalInformations={generalInformations} setGeneralInformations={setGeneralInformations}></GeneralInformationsPage>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  );
}

export default CompanyPage;
