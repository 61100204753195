import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { modalShow } from '../../../types/modal.type';
import { UseOutletContextParameter } from './Parameter';
import { UserModel } from '../../../models/user.model';
import { userRoleValue } from '../../../scripts/user.role.script';
import { UserService } from '../../../services/user.service';
import { Message } from '../../../models/message.model';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { listLastNameFirstNameSortedAscending, listLastNameFirstNameSortedDescending } from '../../../scripts/order.asc.desc.list.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';
import AppModalUserCreate from '../../../components/modal/ModalUserCreate';
import AppModalUserUpdate from '../../../components/modal/ModalUserUpdate';

export interface UsersPageProps {};

const UsersPage: React.FunctionComponent<UsersPageProps> = props => {
  const {setRoute, users, setUsers} = UseOutletContextParameter()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [orderAscDesc, setOrderAscDesc] = useState("asc")
  const [listUsers, setListUsers] = useState<UserModel[] | undefined | null>(null)
  const [user, setUser] = useState<UserModel | undefined | null>(null)

  const submitUserDelete = async (item: string) => {
    if (users) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisNotary,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await UserService.deleteUser(item).then( (response) => {
            if (response.status === 200) {
              let messageResponse: Message = response.data
              let list = users.filter((user) => (user.id !== item))
              setUsers(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingUserListReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
              
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitUserUpdate = async (item: UserModel) => {
    setUser(item)
    modalShow('modal-user-update')
  }

  const submitUserCreate = () => {
    modalShow('modal-user-create')
  }

  const handleChangeOrderAscDesc = (item: string) => {
    if (listUsers && orderAscDesc !== item) {
      setOrderAscDesc(item)

      switch (item) {
        case 'asc':
          setListUsers(listLastNameFirstNameSortedAscending(listUsers))
          break;
        case 'desc':
          setListUsers(listLastNameFirstNameSortedDescending(listUsers))
          break;
      }
    }
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: UserModel[] = []

    if (users && text.length > 0) {
      for (let user of users) {
        let name: string = `${user.last_name} ${user.first_name}`.toLocaleLowerCase()
        let email: string = user.email.toLocaleLowerCase() 
        if (name.indexOf(text.toLowerCase()) !== -1 || email.indexOf(text.toLowerCase()) !== -1) {
          list.push(user)
        }
      }
    } else if (users && listUsers! && text.length === 0) {
      list = users
    }

    setListUsers(list)
  }

  useEffect( () => {
    if (users) {
      setListUsers(users)
    }
  }, [users])

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.database, branch: lang.labels.users}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <>
      { listUsers 
        ?
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h2>{lang.labels.userList}</h2>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
              <div className="nav-group nav-group-outline p-1">
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "asc" && "active"}`} onClick={() => handleChangeOrderAscDesc("asc")}>{lang.labels.asc}</button>
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "desc" && "active"}`} onClick={() => handleChangeOrderAscDesc("desc")}>{lang.labels.desc}</button>
              </div>
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <input id="input-communication-search" className="form-control form-control-solid w-200px w-md-300px ps-14" type="text" placeholder={lang.labels.search} onChange={handleChangeSearch} />
              </div>
              <button className="btn btn-primary" onClick={submitUserCreate}>
                {lang.labels.addUser}
              </button>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-3">
                  { listUsers.length > 0
                    ?
                    <tbody>
                      { listUsers.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed bg-hover-light" onClick={(event) => {event.stopPropagation(); submitUserUpdate(item);}}>
                          <td className="w-250px fw-bold ps-3">
                            <div className="d-flex flex-column align-items-start">
                              <span className="text-gray-900">{item.first_name} {item.last_name}</span>
                              <span className="fs-7 text-gray-700">{item.email}</span>
                              <span className="fs-7 text-gray-700">{item.phone}</span>
                            </div>
                          </td>
                          <td className="w-auto">
                            <span className="badge py-3 px-5 fs-7 badge-light-info">{userRoleValue(item.role, lang)}</span>
                          </td>
                          <td className="w-150px text-end pe-1">
                            <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation(); submitUserDelete(item.id);}}>
                              {lang.labels.delete}
                            </button>
                            <img className="w-20px" src={arrowNext} alt='Go'/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={3} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalUserUpdate orderAscDesc={orderAscDesc} user={user} users={users} listUsers={listUsers} setUser={setUser} setUsers={setUsers} setListUsers={setListUsers}></AppModalUserUpdate>
      <AppModalUserCreate orderAscDesc={orderAscDesc} users={users} listUsers={listUsers} setUsers={setUsers} setListUsers={setListUsers}></AppModalUserCreate>
    </>
  )
};

export default UsersPage;
