import React from 'react';
import { Outlet } from 'react-router-dom';

import { StateLanguage } from '../../languages/config/StateLanguage';

import emblem from '../../assets/images/svg/sgi-emblem.svg';
import name from '../../assets/images/svg/sgi-name.svg';

export interface AuthPageProps {};

const AuthPage: React.FunctionComponent<AuthPageProps> = props => {
  const {lang} = StateLanguage()

  return (
    <div className="d-flex flex-column flex-root h-100">
      <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
        <div className="d-flex flex-center flex-column flex-column-fluid p-10 overflow-hidden">
          <div className="d-flex flex-column mb-10 user-select-none">
            <img src={emblem} className="h-75px h-sm-85px h-md-100px h-lg-125px mb-0" alt="logotipo" />
            <img src={name} className="h-10px h-sm-15px h-md-18px h-lg-20px d-none" alt="name" />
          </div>
          <Outlet></Outlet>
        </div>
        <div className="d-flex flex-center flex-column-auto p-10">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://digitaladmin.ch" className="text-muted text-hover-primary px-3">{lang.labels.about}</a>
            <a href="mailto:contact@digitaladmin.ch" className="text-muted text-hover-primary px-3">{lang.labels.contact}</a>
            <a href="mailto:support@digitaladmin.ch" className="text-muted text-hover-primary px-3">{lang.labels.support}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
