import { Language } from "../languages/context/language"

export function rateTypeList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'annual', desc: lang.labels.annualRecurringCommission },
    { code: 'unique', desc: lang.labels.singleCommission }
  ]
}

export function rateTypeValue(code: string, lang: Language): string {
  let element = rateTypeList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}

export function rateTypeYears(): string[] {
  return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
}