import React, { useState } from 'react';
import { StateLanguage } from '../../languages/config/StateLanguage';
import { SpouseModel } from '../../models/spouse.model';
import { dateFormat } from '../../scripts/date.format.script';
import { ibanFormat } from '../../scripts/iban.format.script';
import { bicFormat } from '../../scripts/bic.format.script';
import { getName } from '../../scripts/countries.script';
import { pensionPerYearValue } from '../../scripts/pension.per.year.script';
import { fortuneValue } from '../../scripts/fortune.script';
import { HeritageOriginModel } from '../../models/heritage.origin.model';
import { modalHide } from '../../types/modal.type';

interface AppModalSpouseViewProps {
  spouse: SpouseModel | undefined | null
};

const AppModalSpouseView: React.FunctionComponent<AppModalSpouseViewProps> = ({spouse}) => {
  const { lang } = StateLanguage()

  const [expanded, setExpanded] = useState({heritages: false, funds: false})

  const executeToggleExpandedHeritages = () => {
    setExpanded({...expanded, heritages: !expanded.heritages})
  }

  function stringHeritageOrigin(listHeritageOrigin: HeritageOriginModel[]): string {
    return listHeritageOrigin.map(item => item.description).join(' | ')
  }

  const executeHideModalSpouseView = () => {
    modalHide('modal-spouse-view')
  }

  const executeToggleExpandedFunds = () => {
    setExpanded({...expanded, funds: !expanded.funds})
  }

  return (
    <div id="modal-spouse-view" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={executeHideModalSpouseView}>
              <span className="svg-icon svg-icon-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black" />
                  <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black" />
                </svg>
              </span>
            </div>
          </div>
          <div className="modal-body pt-0">
            <h2 className="fw-bolder text-center text-uppercase">{lang.labels.spouseInformation}</h2>
            <div className="separator my-5"></div>
            { spouse 
              ?
              <>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.lastName}</span>
                    <span className="fs-6">{spouse.last_name}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.firstName}</span>
                    <span className="fs-6">{spouse.first_name}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.dateOfBirth}</span>
                    <span className="fs-6">{spouse.birthdate.length > 0 ? dateFormat(spouse.birthdate, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.phone}</span>
                    <span className="fs-6">{spouse.phone.length > 0 ? spouse.phone : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.email}</span>
                    <span className="fs-6">{spouse.email.length > 0 ? spouse.email : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.address}</span>
                    <span className="fs-6">{spouse.address.street.length > 0 ? spouse.address.street : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.city}</span>
                    <span className="fs-6">{spouse.address.city.length > 0 ? spouse.address.city : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.iban}</span>
                    <span className="fs-6">{spouse.iban.length > 0 ? ibanFormat(spouse.iban) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.bicSwift}</span>
                    <span className="fs-6">{spouse.swift_bic.length > 0 ? bicFormat(spouse.swift_bic) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.country}</span>
                    <span className="fs-6">{spouse.address.country.length > 0 ? getName(spouse.address.country, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.taxpayerNumber}</span>
                    <span className="fs-6">{spouse.taxpayer_number.length > 0 ? spouse.taxpayer_number : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.pepClose}</span>
                    <span className="fs-6 text-uppercase">{spouse.pep_family ? lang.labels.no : lang.labels.no}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.nationality}</span>
                    <span className="fs-6">{spouse.nationality.length > 0 ? getName(spouse.nationality, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.professionalActivity}</span>
                    <span className="fs-6">{spouse.professional_activity.length > 0 ? spouse.professional_activity : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.placeOfBirth}</span>
                    <span className="fs-6">{spouse.birthplace ? spouse.birthplace : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.firstNameAndLastNameFather}</span>
                    <span className="fs-6">{spouse.father.length > 0 ? spouse.father : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.maidenNameMother}</span>
                    <span className="fs-6">{spouse.mother.length > 0 ? spouse.mother : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.startDateOfBackgroundOn}</span>
                    <span className="fs-6">{spouse.function_start_date.length > 0 ? dateFormat(spouse.function_start_date, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.currentEmployer}</span>
                    <span className="fs-6">{spouse.current_employer.length > 0 ? spouse.current_employer : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.countryOfCurrentEmployer}</span>
                    <span className="fs-6">{spouse.country_current_employer.length > 0 ? getName(spouse.country_current_employer, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.fieldOfActivity}</span>
                    <span className="fs-6">{spouse.activity_area.length > 0 ? spouse.activity_area : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.previousProfessionalActivity}</span>
                    <span className="fs-6">{spouse.previus_profesional_activity.length > 0 ? spouse.previus_profesional_activity : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.previousEmployer}</span>
                    <span className="fs-6">{spouse.previus_employer.length > 0 ? spouse.previus_employer : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.diploma}</span>
                    <span className="fs-6">{spouse.diploma.length > 0 ? spouse.diploma : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.memberOfTheBoardOfDirectors}</span>
                    <span className="fs-6 text-uppercase">{spouse.board_member ? lang.labels.no : lang.labels.yes}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.incomeOrPensionReceivedForAnYear}</span>
                    <span className="fs-6">{spouse.annual_income_or_pension.length > 0 ? pensionPerYearValue(lang, spouse.annual_income_or_pension) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.fortuneIncludingRealEstate}</span>
                    <span className="fs-6">{spouse.fortune.length > 0 ? fortuneValue(lang, spouse.fortune) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.originOfHeritageValues}</span>
                    <span className="fs-6">
                      { spouse.origin_of_patrimonial_assets.length > 0
                        ?
                        <>
                          { stringHeritageOrigin(spouse.origin_of_patrimonial_assets).length > 120
                            ?
                            <>
                              { expanded.heritages
                                ?
                                <>
                                  {stringHeritageOrigin(spouse.origin_of_patrimonial_assets)}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedHeritages}>{lang.labels.less}</label>
                                </>
                                :
                                <>
                                  {stringHeritageOrigin(spouse.origin_of_patrimonial_assets).slice(0, 120)}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedHeritages}>{lang.labels.seeMore}...</label>
                                </>
                                }
                            </>
                            :
                            stringHeritageOrigin(spouse.origin_of_patrimonial_assets)
                          }
                        </>
                        :
                        lang.labels.none
                      }
                    </span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.sourceOfFunds}</span>
                    <span className="fs-6">
                      { spouse.source_of_funds.length > 0
                        ?
                        <>
                          { spouse.source_of_funds.length > 120
                            ?
                            <>
                              { expanded.funds
                                ?
                                <>
                                  {spouse.source_of_funds}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedFunds}>{lang.labels.less}</label>
                                </>
                                :
                                <>
                                  {spouse.source_of_funds.slice(0, 120)}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedFunds}>{lang.labels.seeMore}...</label>
                                </>
                                }
                            </>
                            :
                            spouse.source_of_funds
                          }
                        </>
                        :
                        lang.labels.none
                      }
                    </span>
                  </div>
                </div>
              </>
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    
  )
};

export default AppModalSpouseView;
