import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { modalShow } from '../../../types/modal.type';
import { CommunicationService } from '../../../services/communication.service';
import { CommunicationModel } from '../../../models/communication.model';
import { dateFormat } from '../../../scripts/date.format.script';
import { UseOutletContextCommunication } from './Communication';
import PDFThumbnail from './../../../components/pdf/PdfThumbnail'
import AppModalCommunicationUpdate from '../../../components/modal/ModalCommunicationUpdate';

export interface CommunicationViewPageProps {};

let errorResponse: Error, messageResponse: Message, communicationResponse: any;

const CommunicationViewPage: React.FunctionComponent<CommunicationViewPageProps> = props => {
  const {setRoute, clients, setClients} = UseOutletContextCommunication()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [communication, setCommunication] = useState< CommunicationModel | undefined | null>(null)

  const loadCommunication = async (id_client: string, id_communication: string) => {
    await CommunicationService.getCommunication(id_client, id_communication).then( (response) => {
      if (response.status === 200) {
        communicationResponse = response.data
        setCommunication(communicationResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitContactDelete = async () => {
    if (clients && communication) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisCommunication,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
            await CommunicationService.deleteCommunication(communication.client.id ,communication.id).then( (response) => {
              if (response.status === 200) {
                messageResponse = response.data

                const list = clients.map(item => {
                  if (item.id === communication.client.id) {
                    return { ...item, count: item.count - 1 }
                  }
                  return item
                })

                setClients(list)

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.codeLanguage),
                  text: lang.labels.updatingCommunicationList,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions).then( () => {
                  navigate(-1)
                })
              } else {
                errorResponse = response.data

                Swal.fire({
                  title: getError(errorResponse.code, lang.codeLanguage),
                  text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                  icon: 'error',
                  buttonsStyling: !1,
                  confirmButtonText: lang.labels.OkGotIt,
                  customClass: { confirmButton:'h-100 btn btn-primary' }
                } as SweetAlertOptions)
              }
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          }
      })
    }
  }

  const executeShowModalCommunicationUpdate = () => {
    modalShow('modal-communication-update')
  }

  useEffect( () => {
    if(param.id_client && param.id_communication) {
      loadCommunication(param.id_client, param.id_communication)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.communication, branch: lang.labels.communicationView}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { communication
        ?
        <>
          <div className="card mb-5">
            <div className="card-header py-5"> 
              <div className="card-title">
                <div className="d-flex flex-column">
                  <span className="text-uppercase fs-6 text-gray-500">{dateFormat(communication.created_at, lang.codeLanguage)}</span>
                  <span className="fs-2">{communication.client.first_name} {communication.client.last_name}</span>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
                <button className="btn btn-primary" onClick={executeShowModalCommunicationUpdate}>{lang.labels.edit}</button>
                <button className="btn btn-light-primary" type="button" onClick={submitContactDelete}>{lang.labels.deleteCommunication}</button>
              </div>
            </div>
          </div>
          <div className="card mb-10">
            <div className="card-header">
              <div className="card-title">
                {communication.title}
              </div>
            </div>
            <div className="card-body">
              <div className="text-justify fs-5">
                {communication.message}
              </div>
            </div>
          </div>
          { communication.files.length > 0 &&
            <>
              <div className="bg-transparent m-3">
                <h3 className="fw-bolder">{lang.labels.files}</h3>
              </div>
              <div className="d-flex flex-wrap"> 
                { communication.files.map(( (item, index) => { return (
                  <div key={index} className="card card-flush border rounded-5 border-1 p-3 m-2 border-hover-secondary">
                    <div className="card-body p-0">
                      <div className="row justify-content-center align-content-center cursor-pointer" data-bs-toggle="modal" data-bs-target={`#form-pdf-${index}`}>
                        <div className="d-flex w-150px h-175px mb-1">
                          <PDFThumbnail url_pdf={item}/>
                        </div>
                      </div>
                      <div id={`form-pdf-${index}`} className="modal fade" tabIndex={-1} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <iframe id="modal-form-view-container" title={item} src={item} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}))}
              </div>
            </>
          }
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalCommunicationUpdate communication={communication} setCommunication={setCommunication}></AppModalCommunicationUpdate >
    </div>
  )
};

export default CommunicationViewPage;
