import { Language } from '../languages/context/language';
import { ClientModel } from '../models/client.model';
import { PurityModel } from '../models/purity.model';
import { RateModel } from '../models/rate.model';
import { RefinerModel } from '../models/refiner.model';
import { WeightModel } from '../models/weight.model';
import { weightUnitValue } from './weight.units.script';

export function rateSelected(list: RateModel[], id: string, lang: Language): string {
  if (list.length > 0 && list.some(item => item.id === id)) {
    let rate: RateModel = list.find(item => item.id === id) as RateModel
      return `${rate.value.toFixed(2)} % ${rate.type === "unique" ? lang.labels.ofSingleCommission : `${lang.labels.ofAnnualRecurringCommission} ${rate.years} ${lang.labels.years}`}`
  } else {
    return id
  }
}

export function refinerSelected(list: RefinerModel[], id: string): string {
  if (list.length > 0 && list.some(item => item.id === id)) {
    let refiner: RefinerModel = list.find(item => item.id === id) as RefinerModel
      return `${refiner.name}`
  } else {
    return id
  }
}

export function weightSelected(list: WeightModel[], id: string, lang: Language): string {
  if (list.length > 0 && list.some(item => item.id === id)) {
    let weight: WeightModel = list.find(item => item.id === id) as WeightModel
      return `${weight.value} ${weightUnitValue(weight.unity, lang)}`
  } else {
    return id
  }
}

export function puritySelected(list: PurityModel[], id: string): string {
  if (list.length > 0 && list.some(item => item.id === id)) {
    let purity: PurityModel = list.find(item => item.id === id) as PurityModel
      return `${purity.value}`
  } else {
    return id
  }
}

export function clientSelected(list: ClientModel[], id: string): string {
  if (list.length > 0 && list.some(item => item.id === id)) {
    let client: ClientModel = list.find((item: ClientModel) => (item.id === id)) as ClientModel
      return `${client.last_name} ${client.first_name}`  
  } else {
    return id
  }
}

export function clientSelectedDropDown(list: ClientModel[], id: string): string {
  if (list.length > 0 && list.some(item => item.id === id)) {
    let client: ClientModel = list.find((item: ClientModel) => (item.id === id)) as ClientModel
      return `${client.last_name} ${client.first_name} (${client.count})`  
  } else {
    return id
  }
}
