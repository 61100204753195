import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from './Main';
import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { DashboardService } from '../../services/dashboard.service';
import { Error } from '../../models/error.model';
import { DashboardInvestmentsInPhysicalGoldModel } from '../../models/dashboard.investments.physical.gold.model';
import { DashboardValuationOfGoldPurchasedModel } from '../../models/dashboard.valuation.gold.purchased.model';
import { DashboardFinancialCommitmentsModel} from '../../models/dashboard.financial.commitments.model';
import { DashboardVolumeOfPhysicalGoldModel } from '../../models/dashboard.volume.physical.gold.model';
import { moneyFormat } from '../../scripts/money.format.script';

import AppChartBarInvestment from '../../components/chart/ChartBarInvestments';
import AppChartLineGoldPrices from '../../components/chart/ChartLineGoldPrice';
import AppChartBarCommitments from '../../components/chart/ChartBarCommitments';
import AppChartBarValuations from '../../components/chart/ChartBarValuations';
import AppChartGoldVolume from '../../components/chart/ChartBarGoldVolume';
//import ChartLineGoldPrices from '../../components/chart/apexChartLineGoldPrices';

export interface DashboardPageProps {};

let errorResponse: Error, dashboardInvestmentsInPhysicalGoldResponse: any, dashboardValuationOfGoldPurchasedResponse: any, dashboardFinancialCommitmentsResponse: any, dashboardVolumeOfPhysicalGoldResponse: any

const DashboardPage: React.FunctionComponent<DashboardPageProps> = props => {
  const {setRoute, goldPrice, goldPriceHistory} = UseOutletContext()
  const {lang} = StateLanguage()

 //const urlFrame = process.env.REACT_APP_URL_FRAME

  const [mounted, setMounted] = useState(false)
  const [investmentsInPhysicalGold, setInvestmentsInPhysicalGold] = useState<DashboardInvestmentsInPhysicalGoldModel | null>(null)
  const [valuationOfGoldPurchased, setValuationOfGoldPurchased] = useState<DashboardValuationOfGoldPurchasedModel | null>(null)
  const [financialCommitments, setFinancialCommitments] = useState<DashboardFinancialCommitmentsModel | null>(null)
  const [volumeOfPhysicalGold, setVolumeOfPhysicalGold] = useState<DashboardVolumeOfPhysicalGoldModel | null>(null)

  const loadDashboardInvestmentsInPhysicalGoldResponse = async () => {
    await DashboardService.getInvestmentsInPhysicalGold().then( (response) => {
      if (response.status === 200) {
        dashboardInvestmentsInPhysicalGoldResponse = response.data
        setInvestmentsInPhysicalGold(dashboardInvestmentsInPhysicalGoldResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadDashboardValuationOfGoldPurchased = async () => {
    await DashboardService.getValuationOfGoldPurchased().then( (response) => {
      if (response.status === 200) {
        dashboardValuationOfGoldPurchasedResponse = response.data
        setValuationOfGoldPurchased(dashboardValuationOfGoldPurchasedResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadDashboardFinancialCommitments = async () => {
    await DashboardService.getFinancialCommitments().then( (response) => {
      if (response.status === 200) {
        dashboardFinancialCommitmentsResponse = response.data
        setFinancialCommitments(dashboardFinancialCommitmentsResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadDashboardVolumeOfPhysicalGold = async () => {
    await DashboardService.getVolumeOfPhysicalGold().then( (response) => {
      if (response.status === 200) {
        dashboardVolumeOfPhysicalGoldResponse = response.data
        setVolumeOfPhysicalGold(dashboardVolumeOfPhysicalGoldResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: 'CRM', branch: lang.labels.dashboard}, browse: null})

    loadDashboardInvestmentsInPhysicalGoldResponse()
    loadDashboardValuationOfGoldPurchased()
    loadDashboardFinancialCommitments()
    loadDashboardVolumeOfPhysicalGold()

    return () => setMounted(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { goldPrice && goldPriceHistory && investmentsInPhysicalGold && valuationOfGoldPurchased && financialCommitments && volumeOfPhysicalGold
        ?
        <>
          <div className="card col-12 col-sm-9 border border-gray-300 p-5 mb-5">
            <h3 className="fw-bold">{lang.labels.goldPrices}</h3>
            <label className="fs-2x fw-bolder">{moneyFormat(goldPrice)}</label>
          </div>
          <div className="card card-flush mb-8 border border-gray-300">
            <div className="card-header">
              <div className="card-title">
                <h2 className="fw-bold">{lang.labels.sgiGoldPriceHistory}</h2>
              </div>
            </div>
            <div className="card-body p-0">
              <AppChartLineGoldPrices id='chart-line-gold-prices' data={goldPriceHistory.gold_prices}></AppChartLineGoldPrices>
              {/* <ChartLineGoldPrices data={goldPriceHistory.gold_prices}></ChartLineGoldPrices> */}
            </div>
          </div>
          <div className="card col-12 col-sm-9 border border-gray-300 p-5 mb-5">
            <h3 className="fw-bold">{lang.labels.totalAmountInvestmentClients}</h3>
            <label className="fs-2x fw-bolder">{moneyFormat(investmentsInPhysicalGold.gold_price.reduce((acc, client) => acc + client.amount_total, 0))}</label>
          </div>
          <div className="card card-flush mb-8 border border-gray-300">
            <div className="card-body p-0">
              <AppChartBarInvestment id={"chart-bar-investments"} info={investmentsInPhysicalGold.gold_price}></AppChartBarInvestment>
            </div>
          </div>
          <div className="card col-12 col-sm-9 border border-gray-300 p-5 mb-5">
            <h3 className="fw-bold">{lang.labels.updatedValuationOfGoldPurchased}</h3>
            <label className="fs-2x fw-bolder">{moneyFormat(valuationOfGoldPurchased.valuations.reduce((acc, valuation) => acc + valuation.ingots_number, 0) * goldPrice)}</label>
          </div>
          <div className="card card-flush mb-8 border border-gray-300">
            <div className="card-body p-0">
              <AppChartBarValuations id={"chart-bar-valuations"} goldPrice={goldPrice} data={valuationOfGoldPurchased.valuations}></AppChartBarValuations>
            </div>
          </div>
          <div className="card col-12 col-sm-9 border border-gray-300 p-5 mb-5">
            <h3 className="fw-bold">{lang.labels.totalAmountUpdatedFinancialCommitments}</h3>
            <label className="fs-2x fw-bolder">{moneyFormat(financialCommitments.commitments.reduce((acc, client) => acc + client.amount_total, 0))}</label>
          </div>
          <div className="card card-flush mb-8 border border-gray-300">
            <div className="card-body p-0">
              <AppChartBarCommitments id={"chart-bar-commitments"} info={financialCommitments.commitments}></AppChartBarCommitments>
            </div>
          </div>
          <div className="card col-12 col-sm-9 border border-gray-300 p-5 mb-5">
            <h3 className="fw-bold">{lang.labels.volumeOfPhysicalGold}</h3>
            <label className="fs-2x fw-bolder">{volumeOfPhysicalGold.gold_volume.reduce((acc, gold) => acc + gold.volume, 0) + " KG"}</label>
          </div>
          <div className="card card-flush mb-8 border border-gray-300">
            <div className="card-body p-0">
              <AppChartGoldVolume id={"chart-bar-gold-volume"} info={volumeOfPhysicalGold.gold_volume}></AppChartGoldVolume>
            </div>
          </div>
          {/* <div className="separator-2 my-10"></div>
          <div className="card card-flush">
            <iframe src={urlFrame} title="Swissquote Website" width="100%" height="500"></iframe>
          </div> */}
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default DashboardPage;
