import React, { useLayoutEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ToastContainer } from 'react-toastify';
import Cookies from 'universal-cookie';

import { DispatchLanguage } from '../languages/config/DispatchLanguage';
import { UserModel} from '../models/user.model';
import { appLanguageUpload } from '../scripts/app.language.script';
import { authUserLoggedIn } from '../scripts/auth.user.script';

import { PublicRoute, PrivateRoute, ProtectedRoute, LanguageRoute } from './Route';

import HomePage from './home/Home';
import AuthPage from './authentication/Auth';
import LoginPage from './authentication/Login';
import RecoverPage from './authentication/Recover';
import MainPage from './main/Main';
import DashboardPage from './main/Dashboard';
import AccountPage from './main/account/Account';
import ClientPage from './main/client/Client';
import ClientListPage from './main/client/ClientList';
import ClientViewPage from './main/client/ClientView';
import ClientCreatePage from './main/client/ClientCreate';
import ClientUpdatePage from './main/client/ClientUpdate';
import InvestmentCreatePage from './main/investment/InvestmentCreate';
import InvestmentUpdatePage from './main/investment/InvestmentUpdate';
import InvestmentViewPage from './main/investment/InvestmentView';
import NotaryPage from './main/notary/Notary';
import NotaireListPage from './main/notary/NotaryList';
import NotaireViewPage from './main/notary/NotaryView';
import NotaireCreatePage from './main/notary/NotaireCreate';
import CommunicationsPage from './main/communication/Communication';
import CommunicationListPage from './main/communication/CommunicationList';
import CommunicationCreatePage from './main/communication/CommunicationCreate';
import ComingSoonPage from './page/ComingSoon';
import SystemErrorPage from './page/SystemError';
import NotFoundAuthPage from './page/NotFoundAuth';
import NotFoundMainPage from './page/NotFoundMain';
import NotFoundPage from './page/NotFound';
import CommunicationViewPage from './main/communication/CommunicationView';
import LingotPage from './main/ingot/Ingot';
import LingotListPage from './main/ingot/IngotList';
import LingotViewPage from './main/ingot/IngotView';
import LingotCreatePage from './main/ingot/IngotCreate';
import ParameterPage from './main/parameter/Parameter';
import IngotTypesPage from './main/parameter/IngotTypes';
import PuritiesPage from './main/parameter/Purities';
import RefineriesPage from './main/parameter/Refineries';
import WeightsPage from './main/parameter/Weights';
import UsersPage from './main/parameter/Users';
import LingotUpdatePage from './main/ingot/IngotUpdate';
import HeritagesPage from './main/parameter/Heritages';
import IntermediaryPage from './main/intermediary/Intermediary';
import SecurityPage from './main/security/Security';
import IntermediaryListPage from './main/intermediary/IntermediaryList';
import SecurityListPage from './main/security/SecurityList';
import RatesPage from './main/parameter/Rates';
import IntermediaryCreatePage from './main/intermediary/IntermediaryCreate';
import SecurityCreatePage from './main/security/SecurityCreate';
import IntermediaryViewPage from './main/intermediary/IntermediaryView';
import SecurityViewPage from './main/security/SecurityView';
import IntermediaryUpdatePage from './main/intermediary/IntermediaryUpdate';
import SecurityUpdatePage from './main/security/SecurityUpdate';
import SafesPage from './main/parameter/Safes';
import CompanyPage from './main/company/Company';
import WithdrawalRequestPage from './main/withdrawal/WithdrawalRequest';
import WithdrawalRequestListPage from './main/withdrawal/WithdrawalRequestList';
import WithdrawalRequestViewPage from './main/withdrawal/WithdrawalRequestView';
import InvestmentPage from './main/investment/Investment';
import SecurityCompaniesPage from './main/parameter/SecurityCompanies';

import './App.scss';
import NotariesPage from './main/parameter/Notaries';

export interface AppPageProps {};

const App: React.FunctionComponent<AppPageProps> = (props) => {
  const cookies = new Cookies()
  const dispatchLanguage = DispatchLanguage()

  const [user, setUser] = useState<UserModel | undefined | null>(null)

  useLayoutEffect( () => {
    appLanguageUpload(dispatchLanguage)

    if (!authUserLoggedIn()) {
      if (cookies.get('token') && cookies.get('expires_at') ) {
        cookies.remove('token', {path: '/', sameSite: 'lax'})
        cookies.remove('expires_at', {path: '/', sameSite: 'lax'})
      }
      if (cookies.get('email') === undefined) {
        cookies.remove('email', {path: '/', sameSite: 'lax'})
      }
    }
  })

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LanguageRoute/>}>
          <Route index element={<HomePage/>}/>
          <Route element={<PublicRoute redirectTo={'/app/main'}/>}>
            <Route path='/auth/*' element={<AuthPage/>}>
              <Route path='login' element={<LoginPage/>}/>
              <Route path='recover' element={<RecoverPage/>}/>
              <Route path='*' element={<NotFoundAuthPage/>}/>
            </Route>
          </Route>
          <Route element={<PrivateRoute redirectTo={'/auth/login'}/>}>
            <Route path='/app/*' element={<MainPage userLoggedIn={user} setUserLoggedIn={setUser}/>}>
              { user &&
                <>
                  <Route path='main' element={<ProtectedRoute user={user} redirectTo={'/app/dashboard'} element={<DashboardPage/>}/>}/>
                  <Route path='dashboard' element={<ProtectedRoute user={user} redirectTo={'/app/dashboard'} element={<DashboardPage/>}/>}/>
                  <Route path='account' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<AccountPage/>}/>}/>
                  <Route path='client/*' element={<ClientPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ClientListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ClientViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ClientCreatePage/>}/>}/>
                    <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<ClientUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='investment/*' element={<InvestmentPage/>}>
                    <Route path='view/:id_client/:id_investment' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvestmentViewPage/>}/>}/>
                    <Route path='create/:id_client' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvestmentCreatePage/>}/>}/>
                    <Route path='update/:id_client/:id_investment' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<InvestmentUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='notary/*' element={<NotaryPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<NotaireListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<NotaireViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<NotaireCreatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='communication/*' element={<CommunicationsPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CommunicationListPage/>}/>}/>
                    <Route path='view/:id_client/:id_communication' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CommunicationViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<CommunicationCreatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='ingot/*' element={<LingotPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<LingotListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<LingotViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<LingotCreatePage/>}/>}/>
                    <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<LingotUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='withdrawal/*' element={<WithdrawalRequestPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<WithdrawalRequestListPage/>}/>}/>
                    <Route path='view/:id_client/:id_withdrawal' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<WithdrawalRequestViewPage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='intermediary/*' element={<IntermediaryPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<IntermediaryListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<IntermediaryViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<IntermediaryCreatePage/>}/>}/>
                    <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<IntermediaryUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='security/*' element={<SecurityPage/>}>
                    <Route path='list' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<SecurityListPage/>}/>}/>
                    <Route path='view/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<SecurityViewPage/>}/>}/>
                    <Route path='create' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<SecurityCreatePage/>}/>}/>
                    <Route path='update/:id' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<SecurityUpdatePage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='parameter/*' element={<ParameterPage/>}>
                    <Route path='users' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<UsersPage/>}/>}/>
                    <Route path='rates' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<RatesPage/>}/>}/>
                    <Route path='heritages' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<HeritagesPage/>}/>}/>
                    <Route path='ingot/types' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<IngotTypesPage/>}/>}/>
                    <Route path='refineries' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<RefineriesPage/>}/>}/>
                    <Route path='purities' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<PuritiesPage/>}/>}/>
                    <Route path='weights' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<WeightsPage/>}/>}/>
                    <Route path='safes' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<SafesPage/>}/>}/>
                    <Route path='security/companies' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<SecurityCompaniesPage/>}/>}/>
                    <Route path='notaries' element={<ProtectedRoute user={user} redirectTo={'/app/main'} element={<NotariesPage/>}/>}/>
                    <Route path='*' element={<NotFoundMainPage/>}/>
                  </Route>
                  <Route path='company' element={<CompanyPage/>}></Route>
                  <Route path='*' element={<NotFoundMainPage/>}/>
                </>
              }
            </Route>
          </Route>
          <Route path='/coming/soon' element={<ComingSoonPage/>}/>
          <Route path='/error' element={<SystemErrorPage/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
        </Route>
      </Routes>
      <Toaster position="bottom-right" reverseOrder={true} toastOptions={{style: {borderRadius: '10px'}}}/>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover={false} theme="dark"/>
    </BrowserRouter>
  );
}

export default App;
