import React, { Dispatch, SetStateAction} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { StateLanguage } from '../languages/config/StateLanguage';
import { navbarAsideShow } from '../types/navbar.type';

import logotipo from '../assets/images/svg/sgi-emblem.svg';

interface AppHeaderProps {
  activeDrawer: {value: string, aside: string},
  setActiveDrawer: Dispatch<SetStateAction<{value: string, aside: string}>>,
  route: {main: boolean, path: {root: string | null, branch: string | null} | null, browse: {target: string, link: string} | null} | null
};

const AppHeader: React.FunctionComponent<AppHeaderProps> = ({activeDrawer, setActiveDrawer, route}) => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const executeNavbarAside = () => {
    setActiveDrawer({...activeDrawer, value: 'on', aside: 'on'})
    navbarAsideShow('navbar-aside', 'overlay-drawer')
  }

  const executeReturn = () => {
    navigate(-1)
  }

  return (
    <div id="wrapper-header" className={route ? "header" : "d-lg-none"}>
      <div className="container-xxl d-flex align-items-center justify-content-between">
        <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-5 pb-lg-0">
          { route && route.path &&
            <>
              <h1 className="text-dark fw-bold my-0 fs-2">{lang.labels.sgi}</h1>
              <ul className="breadcrumb breadcrumb-line text-muted fw-bold fs-base my-1">
                <li className="text-muted me-3">{lang.labels.treeStructure}:</li>
                { route.path.root && 
                  <li className="breadcrumb-item text-dark">{route.path.root}</li>
                }
                { route.path.branch && 
                  <li className="breadcrumb-item text-dark">{route.path.branch}</li>
                }
              </ul>
            </>
          }
        </div>
        <div className="d-flex d-lg-none align-items-center ms-n2 me-2">
          <div className="btn btn-icon btn-active-icon-primary" onClick={executeNavbarAside}>
            <span className="svg-icon svg-icon-2x">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
              </svg>
            </span>
          </div>
          <div className="d-flex align-items-center ms-5 cursor-pointer">
            <img src={logotipo} className="w-35px" alt="logotipo" />
          </div>
        </div>
        { route &&
          <>
            { route.main && route.browse &&
              <div className="d-flex flex-shrink-0">
                <Link to={route.browse.link} className="btn btn-primary d-sm-flex d-none">
                  {route.browse.target}
                </Link>
                <Link to={route.browse.link} className="btn btn-sm btn-primary d-flex d-sm-none">
                  {route.browse.target}
                </Link>
              </div>
            }
            { !route.main && !route.browse && 
              <div className="d-flex flex-shrink-0">
                <button className="btn btn-primary d-lg-flex d-none" onClick={executeReturn}>
                  {lang.labels.previousPage}
                </button>
                <button className="btn btn-sm btn-primary d-flex d-lg-none" onClick={executeReturn}>
                  {lang.labels.previousPage}
                </button>
              </div>
            }
          </>
        }
      </div>  
    </div>
  )
}

export default AppHeader;
