import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { modalHide } from '../../types/modal.type';
import { IngotTypeModel } from '../../models/ingot.type.model';
import { IngotTypeService } from '../../services/ingot.type.service';
import { RefinerModel } from '../../models/refiner.model';
import { PurityModel } from '../../models/purity.model';
import { WeightModel } from '../../models/weight.model';
import { Dropdown } from 'react-bootstrap';
import { puritySelected, refinerSelected, weightSelected } from '../../scripts/selected.object.script';
import { listIngotTypeSortedAscending, listIngotTypeSortedDescending } from '../../scripts/order.asc.desc.list.script';

interface AppModalIngotTypeUpdateProps {
  orderAscDesc: string,
  ingotType: IngotTypeModel | undefined | null,
  ingotTypes: IngotTypeModel[] | undefined | null,
  refineries: RefinerModel[] | undefined | null,
  weights: WeightModel[] | undefined | null,
  purities: PurityModel[] | undefined | null,
  setIngotTypes: Dispatch<SetStateAction<IngotTypeModel[] | undefined | null>>,
};

const AppModalIngotTypeUpdate: React.FunctionComponent<AppModalIngotTypeUpdateProps> = ({orderAscDesc, ingotType, ingotTypes, refineries, weights, purities, setIngotTypes}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [refiner, setRefiner] = useState({value: '', valid: false})
  const [weight, setWeight] = useState({value: '', valid: false})
  const [purity, setPurity] = useState({value: '', valid: false})

  const submitIngotTypeUpdate = async () => {
    setLoadIndicator('on')

    if (ingotTypes && ingotType && refiner.valid && weight.valid && purity.valid) {
      await IngotTypeService.putIngotType(ingotType?.id, refiner.value, weight.value, purity.value).then( (response) => {
        if (response.status === 200) {
          let serviceResponse: IngotTypeModel = response.data

          let list = ingotTypes.map((item) => {
            if (item.id === serviceResponse.id) {
              return serviceResponse;
            }
            return item
          })

          setIngotTypes((orderAscDesc === "asc") ? listIngotTypeSortedAscending(list, lang) : listIngotTypeSortedDescending(list, lang))

          Swal.fire({
            title: lang.labels.successfullyUpdatedTypeIngot,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalIngotTypeUpdate()
          })
        } else {
          let errorResponse: Error = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          }) 
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeRefiner = (item: string) => {
    setRefiner({...refiner, value: item, valid: true})
  }

  const handleChangeWeight = (item: string) => {
    setWeight({...weight, value: item, valid: true})
  }

  const handleChangePurity = (item: string) => {
    setPurity({...purity, value: item, valid: true})
  }

  const executeHideModalIngotTypeUpdate = () => {
    modalHide('modal-ingot-type-update')
  }

  useEffect(() => {
    if (ingotType) {
      setRefiner({value: ingotType.refiner.id, valid: true });
      setWeight({value: ingotType.weight.id, valid: true });
      setPurity({value: ingotType.purity.id, valid: true });
    }
  }, [ingotType]);

  return (
    <div id="modal-ingot-type-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-600px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.updateTypeIngot}</h2>
            </div>
          </div>
          <div className="modal-body">
            { ingotType && refineries && weights && purities
              ?
              <div className='row'>
                <div className="mb-5">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.refiner}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {refiner.valid ? refinerSelected(refineries, refiner.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { refineries.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === refiner.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === refiner.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeRefiner(item.id)}>{item.name}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mb-5">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.weight}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {weight.valid ? weightSelected(weights, weight.value, lang) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { weights.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === weight.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === weight.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeWeight(item.id)}>{item.value} {item.unity}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="mb-0">
                  <div className="d-flex justify-content-between">
                    <label className="form-label">{lang.labels.purity}</label>
                    <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                      <span className="selection">
                        <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            <span className="select2-selection__placeholder">
                              {purity.valid ? puritySelected(purities, purity.value) : lang.labels.selectOption}
                            </span>
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { purities.map (( (item, index) => { return (
                              <li key={index} className={`select2-results__option select2-results__option--selectable ${item.id === purity.value && "select2-results__option--selected"}`} role="option" aria-selected={item.id === purity.value ? "true" : "false"}>
                                <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePurity(item.id)}>{item.value}</Dropdown.Item>
                              </li>
                            )}))}
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitIngotTypeUpdate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalIngotTypeUpdate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalIngotTypeUpdate;
