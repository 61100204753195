import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { WithdrawalRequestService } from '../../../services/withdrawal.request.service';
import { WithdrawalRequestModel } from '../../../models/withdrawal.request.model';
import { UseOutletContexWithdrawalRequest } from './WithdrawalRequest';
import { dateFormat } from '../../../scripts/date.format.script';
import { moneyFormat } from '../../../scripts/money.format.script';
import { withdrawalRequestStatusList, withdrawalRequestStatusValue } from '../../../scripts/withdrawal.request.status.script';

export interface WithdrawalRequestViewPageProps {};

let errorResponse: Error, messageResponse: Message, withdrawalResponse: WithdrawalRequestModel;

const WithdrawalRequestViewPage: React.FunctionComponent<WithdrawalRequestViewPageProps> = props => {
  const {setRoute} = UseOutletContexWithdrawalRequest()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [withdrawalRequest, setWithdrawalRequest] = useState<WithdrawalRequestModel | undefined | null>(null)

  const loadWithdrawalRequest = async (id_client: string, id_withdrawal: string) => {
    await WithdrawalRequestService.getWithdrawalRequest(id_client, id_withdrawal).then( (response) => {
      if (response.status === 200) {
        withdrawalResponse = response.data
        setWithdrawalRequest(withdrawalResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitWithdrawalRequestUpdate = async (id_client: string, id_withdrawal: string, status: string) => {
    if (withdrawalRequest) {
      await WithdrawalRequestService.putWithdrawalRequestStatus(id_client, id_withdrawal, status).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          setWithdrawalRequest(prevState => {
            if (prevState) {
              return { ...prevState, status: status }
            }
            return null
          })

          Swal.fire({
            title: getMessage(messageResponse.message, lang.codeLanguage),
            text: lang.labels.returningToTheWithdrawalRequestPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions)
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    }
  }

  const submitWithdrawalRequestDelete = async (id_client: string, id_withdrawal: string) => {
    if (withdrawalRequest) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisWithdrawalRequest,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await WithdrawalRequestService.deleteWithdrawalRequest(id_client, id_withdrawal).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingWithdrawalRequestList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
              navigate("/app/withdrawal/list")
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const handleChangeStatus = (item: string) => {
    if (withdrawalRequest) {
      submitWithdrawalRequestUpdate(withdrawalRequest.client.id, withdrawalRequest.id, item)
    }
  }

  useEffect(() => {
    if (param.id_client && param.id_withdrawal) {
      loadWithdrawalRequest(param.id_client, param.id_withdrawal);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.withdrawalRequests, branch: lang.labels.withdrawalDemandView}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { withdrawalRequest
        ?
        <>
          <div className="card card-flush border border-gray-300 mb-5">
            <div className="card-header py-5">
              <div className="card-title">
                <div className="d-flex flex-column">
                  <span className="fs-7 fw-bold text-uppercase">{dateFormat(withdrawalRequest.created_at, lang.codeLanguage)}</span>
                  <span className="fs-1 fw-bolder">{lang.labels.withdrawalRequest}</span>
                  <span className="fs-5 fw-bolder text-gray-700">{moneyFormat(withdrawalRequest.amount)}</span>
                </div>
              </div>
              <div className="card-toolbar d-flex flex-column align-items-start">
                <strong className="mb-2">{lang.labels.withdrawalStatus}</strong>
                <Dropdown>
                  <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-250px p-0">
                    <span className="selection">
                      <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between">
                        <span className="select2-selection__rendered" role="textbox">
                          <span className="select2-selection__placeholder">
                            {withdrawalRequestStatusValue(withdrawalRequest.status, lang)}
                          </span>
                        </span>
                      </span>
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                    <span className="select2-dropdown select2-dropdown--below">
                      <span className="select2-results">
                        <ul className="select2-results__options" role="listbox">
                          { withdrawalRequestStatusList(lang).map (( (item, index) => { return (
                            <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === withdrawalRequest.status &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === withdrawalRequest.status ? "true" : "false"}>
                              <Dropdown.Item bsPrefix="select2-results__option__text"  onClick={() => handleChangeStatus(item.code)}>{item.desc}</Dropdown.Item>
                            </li>
                          )}))}
                        </ul>
                      </span>
                    </span>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="bg-transparent m-3">
            <h3 className="fw-bold">{lang.labels.information}</h3>
          </div>
          <div className="card card-flush border border-gray-300 mb-10">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 d-flex justify-content-between justify-content-md-start flex-row flex-md-column border-bottom border-md-0 mb-5">
                  <span className="text-muted">{lang.labels.client}</span>
                  <span className="fs-6">{withdrawalRequest.client.first_name} {withdrawalRequest.client.last_name}</span>
                </div>
                <div className="col-md-4 d-flex justify-content-between justify-content-md-start flex-row flex-md-column border-bottom border-md-0 mb-5">
                  <span className="text-muted">{lang.labels.withdrawalStatus}</span>
                  <span className="fs-6 text-capitalize">{withdrawalRequestStatusValue(withdrawalRequest.status, lang)}</span>
                </div>
                <div className="col-md-4 d-flex justify-content-between justify-content-md-start flex-row flex-md-column border-bottom border-md-0 mb-5">
                  <span className="text-muted">{lang.labels.amount}</span>
                  <span className="fs-6">{moneyFormat(withdrawalRequest.amount)}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 d-flex justify-content-between justify-content-md-start flex-row flex-md-column border-bottom border-md-0 mb-5 mb-md-0">
                  <span className="text-muted">{lang.labels.execution}</span>
                  <span className="fs-6">{dateFormat(withdrawalRequest.execution_date, lang.codeLanguage)}</span>
                </div>
                <div className="col-md-4 d-flex justify-content-between justify-content-md-start flex-row flex-md-column border-bottom border-md-0 mb-0">
                  <span className="text-muted">{lang.labels.requestDate}</span>
                  <span className="fs-6">{dateFormat(withdrawalRequest.created_at, lang.codeLanguage)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="separator-2 my-10"></div>
          <button className="btn btn-primary" type="button" onClick={() => {submitWithdrawalRequestDelete(withdrawalRequest.client.id, withdrawalRequest.id)}}>
            {lang.labels.deleteTheRequest}
          </button>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default WithdrawalRequestViewPage;
