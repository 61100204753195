import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'

import { UserModel } from '../models/user.model';
import { authUserLoggedIn } from '../scripts/auth.user.script';
import { appLanguageSave, appLanguageValidate } from '../scripts/app.language.script';

export type RouteProps = {
  redirectTo: string
};

export type ConditionalProps = {
  state: boolean,
  redirectTo: string,
  element: JSX.Element,
};

export type ProtectedProps = {
  user: UserModel,
  redirectTo: string,
  element: JSX.Element
};

export const LanguageRoute = () => {
  const location = useLocation()
  const param = useParams()

  if (param.language) {
    appLanguageSave(param.language)
  }

  return appLanguageValidate(param.language) ? <Outlet/> : <Navigate to={`/${location.pathname.split('/').slice(2).join('/')}`}/>
};

export const PublicRoute = ({redirectTo}: RouteProps) => {
  return authUserLoggedIn() ? <Navigate to={redirectTo}/> : <Outlet/>
};

export const PrivateRoute = ({redirectTo}: RouteProps) => {
  return authUserLoggedIn() ? <Outlet/> : <Navigate to={redirectTo}/>
};

export const ConditionalRoute = ({state, redirectTo, element}: ConditionalProps) => {
  if (!state) {
    return <Navigate replace to={redirectTo}/>
  }

  return authUserLoggedIn() ? element : <Navigate to={'/auth/login'}/>
};

export const ProtectedRoute = ({user, redirectTo, element}: ProtectedProps) => {
  if (!user) {
    return <Navigate to={redirectTo}/>
  }

  return authUserLoggedIn() ? element : <Navigate to={'/auth/login'}/>
};
