import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { modalShow } from '../../../types/modal.type';
import { UseOutletContextParameter } from './Parameter';
import { IngotTypeModel } from '../../../models/ingot.type.model';
import { IngotTypeService } from '../../../services/ingot.type.service';
import { listIngotTypeSortedAscending, listIngotTypeSortedDescending } from '../../../scripts/order.asc.desc.list.script';
import { weightUnitFormat } from '../../../scripts/weight.units.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';
import AppModalIngotTypeUpdate from '../../../components/modal/ModalIngotTypeUpdate';
import AppModalIngotTypeCreate from '../../../components/modal/ModalIngotTypeCreate';

export interface IngotTypesPageProps {};

const IngotTypesPage: React.FunctionComponent<IngotTypesPageProps> = props => {
  const {setRoute, ingotTypes, refineries, weights, purities, setIngotTypes} = UseOutletContextParameter()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [orderAscDesc, setOrderAscDesc] = useState("asc")
  const [ingotType, setIngotType] = useState<IngotTypeModel | undefined | null>(null)

  const submitIngotTypetDelete = async (item: string) => {
    if (ingotTypes) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisTypeIngot,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await IngotTypeService.deleteIngotType(item).then( (response) => {
            if (response.status === 200) {
              let messageResponse: Message = response.data
              let list = ingotTypes.filter((ingotType) => (ingotType.id !== item))

              setIngotTypes(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingTypesIngotListReturningToPage,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitIngotTypeUpdate = async (item: IngotTypeModel) => {
    setIngotType(item)
    modalShow('modal-ingot-type-update')
  }

  const submitIngotTypeCreate = () => {
    modalShow('modal-ingot-type-create')
  }

  const handleChangeOrderAscDesc = (item: string) => {
    if (ingotTypes && orderAscDesc !== item) {
      setOrderAscDesc(item)

      switch (item) {
        case 'asc':
          setIngotTypes(listIngotTypeSortedAscending(ingotTypes, lang))
          break;
        case 'desc':
          setIngotTypes(listIngotTypeSortedDescending(ingotTypes, lang))
          break;
      }
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.database, branch: lang.labels.ingotTypes}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <>
      { ingotTypes 
        ?
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h2>{lang.labels.ingotTypeList}</h2>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
              <div className="nav-group nav-group-outline p-1">
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "asc" && "active"}`} onClick={() => handleChangeOrderAscDesc("asc")}>{lang.labels.asc}</button>
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "desc" && "active"}`} onClick={() => handleChangeOrderAscDesc("desc")}>{lang.labels.desc}</button>
              </div>
              <button className="btn btn-primary" onClick={submitIngotTypeCreate}>
                {lang.labels.addIngotType}
              </button>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-3">
                  { ingotTypes.length > 0
                    ?
                    <tbody className="text-gray-900">
                      { ingotTypes.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed bg-hover-light" onClick={(event) => {event.stopPropagation(); submitIngotTypeUpdate(item);}}>
                          <td className="min-w-300px fw-bold ps-3">
                            <div className="d-flex flex-row align-items-center">
                              <span>{item.refiner.name}</span>
                              <span className="mx-1">{item.weight.value} {weightUnitFormat(item.weight.unity, lang)}</span>
                              <span>{item.purity.value}</span>
                            </div>
                          </td>
                          <td className="w-150px text-end pe-1">
                            <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation(); submitIngotTypetDelete(item.id);}}>
                              {lang.labels.delete}
                            </button>
                            <img className="w-20px" src={arrowNext} alt='Go'/>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalIngotTypeUpdate orderAscDesc={orderAscDesc} ingotType={ingotType} ingotTypes={ingotTypes} refineries={refineries} weights={weights} purities={purities} setIngotTypes={setIngotTypes}></AppModalIngotTypeUpdate>
      <AppModalIngotTypeCreate orderAscDesc={orderAscDesc} ingotTypes={ingotTypes} refineries={refineries} weights={weights} purities={purities} setIngotTypes={setIngotTypes}></AppModalIngotTypeCreate>
    </>
  )
};

export default IngotTypesPage;
