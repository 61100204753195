import React, { useEffect } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy'
import { StateLanguage } from '../../languages/config/StateLanguage';

export interface AppChartBarValuationsProps {
  id: string,
  goldPrice: number,
  data: {client: string, ingots_number: number, total_original_valuation_gold: number}[]
};

const AppChartBarValuations: React.FunctionComponent<AppChartBarValuationsProps> = ({id, goldPrice, data}) => {
  const {lang} = StateLanguage()

  useEffect( () => {
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));

    chart.set("scrollbarY", am5.Scrollbar.new(root, {
      orientation: "vertical",
      marginLeft: 50,
      marginRight: 0
    }));

    let dataList = data.map((item) => { 
      const percentDifference = (((item.ingots_number * goldPrice) - item.total_original_valuation_gold) / item.total_original_valuation_gold) * 100;
      const percentValue = `${percentDifference.toFixed(2)}%`;
      const difference = (item.ingots_number * goldPrice) - item.total_original_valuation_gold;
      const differenceColor = difference < 0 ? "red" : "green";

      return {
      "client": item.client,
      "present_value": (item.ingots_number * goldPrice),
      "total_original_valuation_gold": item.total_original_valuation_gold,
      percent_difference: percentDifference,
      percent_value: percentValue,
      difference : difference,
      differenceColor: differenceColor
    }})

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 0
    });

    yRenderer.grid.template.set("visible", false);
    yRenderer.grid.template.set("location", 1);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "client",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    yAxis.data.setAll(dataList);

    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("strokeOpacity", 0.1);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: xRenderer
      })
    );

    let series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: lang.labels.presentValue,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "present_value",
      categoryYField: "client",
      clustered: false,
      tooltip: am5.Tooltip.new(root, {
        labelHTML: `<div style="display: flex; flex-direction: column"><span style="font-weight: bold">{name}</span><span>{categoryY}: {valueX} CHF</span><span style="color: {differenceColor}">${lang.labels.difference}: {difference} ({percent_value})</span><span>({difference}/{total_original_valuation_gold})</span></div>`,
        pointerOrientation: "horizontal"
      })
    }));

    series1.columns.template.setAll({
      height: am5.percent(80),
      tooltipX: 0,
      strokeOpacity: 0
    });

    let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: lang.labels.valueAtTimeOfSale,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "total_original_valuation_gold",
      categoryYField: "client",
      clustered: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `[bold]{name}[/]\n{categoryY}: {valueX} CHF`
      })
    }));

    series2.columns.template.setAll({
      height: am5.percent(70),
      tooltipX: 0,
      strokeOpacity: 0
    });

    let legend = chart.children.push(am5.Legend.new(root, {
      marginTop: 10,
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(chart.series.values);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomY"
    }));

    cursor.lineX.set("visible", false);

    series1.data.setAll(dataList);
    series2.data.setAll(dataList);

    series1.appear();
    series2.appear();

    chart.appear(100, 10);

    return () => { root.dispose() }
  }, [id, goldPrice, data, lang])

  return (
    <div id={id} className="w-100 h-700px"></div> 
  )
};

export default AppChartBarValuations;
