function overlayOffcanvas (identifier: string, navbar: string) {
  let elemen1 = document.getElementById(identifier)
  let elemen2 = document.getElementById(navbar)

  elemen1?.classList.remove('drawer-overlay')
  elemen2?.classList.remove('drawer')
  elemen2?.classList.remove('drawer-start')
  elemen2?.classList.remove('drawer-on')
}

function overlayMenu (identifier: string, list_button: string[], list_container: string[]) {
  let element = document.getElementById(identifier)
  element?.classList.remove('menu-overlay')

  for (let button of list_button) {
    let element = document.getElementById(button)
    element?.classList.remove('active')
  }

  for (let container of list_container) {
    let element = document.getElementById(container)
    element?.classList.remove('show')
  }
}

export { overlayOffcanvas, overlayMenu }
