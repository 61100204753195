import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import Cookies from 'universal-cookie';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { AuthenticationService } from '../../services/authentication.service';
import { Error } from '../../models/error.model';
import { Token } from '../../models/token.model';
import { inputValidRequiredIcon, inputInvalidRequiredIcon } from '../../types/legend.input.type';
import { authUserLoggedIn } from '../../scripts/auth.user.script';
import { expressions } from '../../scripts/regular.expressions.script';

export interface LoginPageProps {};

let errorResponse: Error, tokenResponse: Token;

const LoginPage: React.FunctionComponent<LoginPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()
  const cookies = new Cookies()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [email, setEmail] = useState({value: '', valid: false})
  const [password, setPassword] = useState({value: '', valid: false})

  const submitLogin = async (event: React.ChangeEvent <HTMLFormElement>) => {
    event.preventDefault()
    setLoadIndicator('on')

    if (email.valid && password.valid) {
      await AuthenticationService.login(email.value, password.value).then( (response) => {
        if (response.status === 200) {
          tokenResponse = response.data

          cookies.set('token', tokenResponse.authorization, {path: '/', sameSite: 'lax'})
          cookies.set('expires_at', tokenResponse.expires_at, {path: '/', sameSite: 'lax'})
          cookies.set('email', email.value, {path: '/', sameSite: 'lax'})

          Swal.fire({
            title: lang.labels.successfullyLoggedIn,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/app/main')
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        inputInvalidRequiredIcon(email.value, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
        inputInvalidRequiredIcon(password.value, 'input-password', 'container-validate-password-valid', 'container-validate-password-required')
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangePassword = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPassword({...password , value: event.target.value})    
  }

  const validateEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email , valid: true})
      inputValidRequiredIcon('input-email', 'container-validate-email-valid', 'container-validate-email-required')
    } else {
      setEmail({...email , valid: false})
      inputInvalidRequiredIcon(email.value, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
    }
  }

  const validatePassword = () => {
    if (expressions.password.test(password.value)) {
      setPassword({...password , valid: true})
      inputValidRequiredIcon('input-password', 'container-validate-password-valid', 'container-validate-password-required')
    } else {
      setPassword({...password , valid: false})
      inputInvalidRequiredIcon(password.value, 'input-password', 'container-validate-password-valid', 'container-validate-password-required')
    }
  }

  useEffect( () => {
    setMounted(true)

    if (cookies.get('email')) {
      setEmail({...email, value: cookies.get('email'), valid: true})
    }

    if (!authUserLoggedIn()) {
      switch (true) {
        case (!!cookies.get('token') && !!cookies.get('expires_at') && !!cookies.get('email')):
          toast.warn(lang.labels.yourSessionHasExpired)
          break;
        case (!!cookies.get('email')):
          toast.error(lang.labels.yourSessionHasBeenClosed)
          break;
      }
    } 

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
      <form className="form w-100" noValidate onSubmit={submitLogin}>
        <div className="text-center mb-8">
          <h1 className="text-dark mb-3">{lang.labels.signIn}</h1>
        </div>
        <div className="fv-row mb-7">
          <label className="form-label fs-6 fw-bolder text-dark">{lang.labels.email}</label>
          <input id="input-email" className="form-control form-control-lg form-control-solid" type="email" name="email" autoComplete="off" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
          <div id="container-validate-email-valid" className="fv-plugins-message-container invalid-feedback d-none">
            <div data-field="email" data-validator="emailAddress">{lang.labels.theValueIsNotAValidEmailAddress}</div>
          </div>
          <div id="container-validate-email-required" className="fv-plugins-message-container invalid-feedback d-none" >
            <div data-field="email" data-validator="notEmpty">{lang.labels.emailAddressIsRequired}</div>
          </div>
        </div>
        <div className="fv-row mb-10">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">{lang.labels.password}</label>
            <Link to="/auth/recover" className="link-primary fs-6 fw-bolder">{lang.labels.forgotPassword}</Link>
          </div>
          <input id="input-password" className="form-control form-control-lg form-control-solid" type="password" name="password" autoComplete="off" value={password.value} onChange={handleChangePassword} onKeyUp={validatePassword} onBlur={validatePassword} />
          <div id="container-validate-password-valid" className="fv-plugins-message-container invalid-feedback d-none" >
            <div data-field="password" data-validator="password">{lang.labels.thePasswordIsAtLeast8Digits}</div>
          </div>
          <div id="container-validate-password-required" className="fv-plugins-message-container invalid-feedback d-none">
            <div data-field="password" data-validator="notEmpty">{lang.labels.thePasswordIsRequired}</div>
          </div>
        </div>
        <div className="text-center">
          <button className="btn btn-lg btn-primary w-100 mb-5" type="submit" data-kt-indicator={loadIndicator}>
            <span className="indicator-label">{lang.labels.continue}</span>
            <span className="indicator-progress">
              {lang.labels.pleaseWait}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </form>
    </div>
  )
};

export default LoginPage;
