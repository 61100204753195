import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FaExclamationCircle } from 'react-icons/fa';

import { UseOutletContextCommunication } from './Communication';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { CommunicationService } from '../../../services/communication.service';
import { clientSelected } from '../../../scripts/selected.object.script';
import { ClientModel } from '../../../models/client.model';
import { inputValid, inputInvalid } from '../../../types/legend.input.type';
import { CommunicationModel } from '../../../models/communication.model';
import { cleanInputFile } from '../../../types/input.file.type';
import { expressions } from '../../../scripts/regular.expressions.script';

interface CommunicationCreateProps {};

let errorResponse: Error, serviceResponse: any;

const CommunicationCreatePage: React.FunctionComponent<CommunicationCreateProps> = () => {
  const {setRoute, clients} = UseOutletContextCommunication()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [loadFiles, setLoadFiles] = useState('off')
  const [idClients, setIdClients] = useState({value: [] as string[], valid: false})
  const [title, setTitle] = useState({value: '', valid: false})
  const [message, setMessage] = useState({value: '', valid: false})
  const [archivesValue, setArchivesValue] = useState<{name: string, size: number, type: string, file: any}[]>([])
  const [archivesValid, setArchivesValid] = useState<{file: boolean, message: string}[]>([])
  const [search, setsearch] = useState({text: '', list: [] as ClientModel[]})

  const submitCommunicationCreate = async () => {
    setLoadIndicator('on')

    let validArchives = archivesValid.length === 0 || archivesValid.every(item => item.file)

    if (clients && idClients.valid && title.valid && message.valid && validArchives) {
      let fileList = archivesValue.map(archive => archive.file)

      await CommunicationService.postCommunication(idClients.value, title.value, message.value, fileList).then( (response) => {
        if (response.status === 201) {
          serviceResponse = response.data

          serviceResponse.forEach((item: CommunicationModel)  => {
            const client = clients.find((sub_item) => sub_item.id === item.client.id)
            if (client) {
              client.count += 1
            }
          })

          Swal.fire({
            title: lang.labels.successfullyCreatedCommunication,
            text: lang.labels.updatingCommunicationListReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  } 

  const onCancel = async () => {
    Swal.fire({
      title: lang.labels.areYouSureYouWantToCancel,
      text: lang.labels.ifYouAcceptYouMayHaveToFillOutTheFormAgain,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: lang.labels.yes,
      cancelButtonText: lang.labels.noCancel,
      customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(-1)
      }
    })
  }

  const handleChangeClient = (item: string) => {
    let selectedClients: string[] = (idClients.value.includes(item)) ? idClients.value : [...idClients.value, item]
    let validClients: boolean = (selectedClients.length > 0) ? true : false

    setIdClients({value: selectedClients, valid: validClients})
  }

  const handleChangeTitle = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTitle({...title, value: event.target.value})
  }

  const handleChangeMessage = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setMessage({...message, value: event.target.value})
  }

  const handleChangeFiles = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLoadFiles('on')

    let archiveValueList: {name: string, size: number, type: string, file: any}[] = []
    let archiveValidList: {file: boolean, message: string}[] = []
    let archivesCapture: any = event.target.files
    let archiveType: string[] = ['application/pdf']
    let archiveSize: number = 10485760

    for (let archiveCapture of archivesCapture) {

      if (!archivesValue.some((existingArchive) => existingArchive.name === archiveCapture.name)) {
        let archiveValue: {name: string, size: number, type: string, file: any} = {
          name: archiveCapture.name,
          size: Number((archiveCapture.size / 1048576).toFixed(2)),
          type: archiveCapture.type,
          file: archiveCapture,
        }

        let archiveValid: {file: boolean, message: string} = 
        (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type))))
          ? {file: false, message: lang.labels.invalidFileType}
          : (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) 
            ? {file: false, message: lang.labels.fileSizeNotAllowed}
            : {file: true, message: lang.labels.valid}

        archiveValueList.push(archiveValue)
        archiveValidList.push(archiveValid)
      }
    }

    setArchivesValue([...archivesValue, ...archiveValueList]);
    setArchivesValid([...archivesValid, ...archiveValidList]);
    setLoadFiles('off')
  }

  const handleChangesearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: ClientModel[] = []

    if (clients) {
      if (text.length > 0) {
        for (let client of clients) {
          let name: string = `${client.last_name} ${client.first_name}`.toLowerCase()
          if (name.indexOf(text.toLowerCase()) !== -1) {
            list.push(client)
          }
        }
      } else {
        list = clients
      }
    }

    setsearch({...search, text: text, list: list})
  }

  const handleRemoveClient = (item: string) => {
    let selectedClients: string[] = idClients.value.filter((temp_item) => temp_item !== item)
    let validClients: boolean = (selectedClients.length > 0) ? true : false

    setIdClients({value: selectedClients, valid: validClients})
  }

  const handleRemoveFile = (index: number) => {
    let tempUploadIdentificationsValue = archivesValue.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempUploadIdentificationsValid = archivesValid.filter((_temp_item, temp_index) => (temp_index !== index))

    setArchivesValue(tempUploadIdentificationsValue)
    setArchivesValid(tempUploadIdentificationsValid)
    cleanInputFile()
  }

  const validateTitle = () => {
    if (expressions.text.test(title.value)) {
      setTitle({...title, valid: true})
      inputValid('input-title')
    } else {
      setTitle({...title, valid: false})
      inputInvalid('input-title')
    }
  }

  const validateMessage = () => {
    if (expressions.textsize.test(message.value)) {
      setMessage({...message, valid: true})
    } else {
      setMessage({...message, valid: false})
    }
  }

  function uploadDropdown() {
    if (clients) {
      setsearch({text: '', list: clients})
    }
  }

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.communication, branch: lang.labels.createCommunication}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { clients ?
        <div className="form">
          <div className="card mb-5">
            <div className="card-header">
              <div className="card-title">
                <h1 className="text-uppercase">{lang.labels.addCommunication}</h1>
              </div>
            </div>
            <div className="card-body">
              <div className="col">
                <div className="col-xl-12 mb-5">
                  <label className="form-label required">{lang.labels.clients}</label>
                  <Dropdown>
                    <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                      <span className="selection">
                        <span className="select2-selection select2-selection--multiple form-select form-select-solid d-flex justify-content-start py-3" aria-disabled="false">
                          <span className="select2-selection__rendered" role="textbox">
                            {lang.labels.selectOption}
                          </span>
                        </span>
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100 my-1" onLoad={uploadDropdown}>
                      <span className="select2-dropdown select2-dropdown--below">
                        <span className="select2-search select2-search--dropdown">
                          <input className="select2-search__field" type="text" name="client" value={search.text} onChange={handleChangesearch} />
                        </span>
                        <span className="select2-results">
                          <ul className="select2-results__options" role="listbox">
                            { search.list.length > 0
                              ?
                              <>
                                { search.list.map((item, index) => (
                                  <li key={index} className="select2-results__option select2-results__option--selectable" role="option" aria-selected="false">
                                    <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeClient(item.id)}>{item.last_name} {item.first_name}</Dropdown.Item>
                                  </li>
                                ))}
                              </>
                              :
                              <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">
                                {lang.labels.noResultsFound}
                              </li>
                            }
                          </ul>
                        </span>
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>
                  { idClients.valid &&
                    <ul className="p-0 mt-3 mb-5">
                      { idClients.value.map((item, index) => (
                        <li key={index} className="d-flex justify-content-between align-items-center rounded-2 bg-gray-100 w-100 px-5 py-3 mb-2">
                          <span className="text-black-50">{clientSelected(clients, item)}</span>
                          <button className="btn m-0 p-0 d-flex align-items-center" onClick={() => handleRemoveClient(item)}>
                            <span className="text-danger fs-7 pe-1">
                              {lang.labels.delete}
                            </span>
                            <span className="svg-icon svg-icon-2">
                              <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 6L17.1991 18.0129C17.129 19.065 17.0939 19.5911 16.8667 19.99C16.6666 20.3412 16.3648 20.6235 16.0011 20.7998C15.588 21 15.0607 21 14.0062 21H9.99377C8.93927 21 8.41202 21 7.99889 20.7998C7.63517 20.6235 7.33339 20.3412 7.13332 19.99C6.90607 19.5911 6.871 19.065 6.80086 18.0129L6 6M4 6H20M16 6L15.7294 5.18807C15.4671 4.40125 15.3359 4.00784 15.0927 3.71698C14.8779 3.46013 14.6021 3.26132 14.2905 3.13878C13.9376 3 13.523 3 12.6936 3H11.3064C10.477 3 10.0624 3 9.70951 3.13878C9.39792 3.26132 9.12208 3.46013 8.90729 3.71698C8.66405 4.00784 8.53292 4.40125 8.27064 5.18807L8 6M14 10V17M10 10V17" stroke="#7239ea" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                              </svg>
                            </span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  }
                </div>
                <div className="col-xl-12 mb-5">
                  <label className="form-label required">{lang.labels.communicationTitle}</label>
                  <input id="input-title" className="form-control form-control-solid" type="text" name="title" value={title.value} onChange={handleChangeTitle} onKeyUp={validateTitle} onBlur={validateTitle} />
                </div>
                <div className="col-xl-12 mb-5">
                  <label className="form-label required">{lang.labels.message}</label>
                  <textarea className="form-control form-control-solid" rows={5} name="message" autoComplete="off" value={message.value} onChange={handleChangeMessage} onKeyUp={validateMessage} onBlur={validateMessage} />
                </div>
                <div className="col-xl-12">
                  <label className="form-label d-flex align-items-center">
                    {lang.labels.files}
                    <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.maximumFileFormat10MBPerFile}><FaExclamationCircle /></i>
                  </label>
                  <div className="dropzone dropzone-queue cursor-pointer-none">
                    <div className="dropzone-panel">
                      <div className="position-relative btn btn-outline bg-gray-100 h-55px d-flex align-items-center" data-kt-indicator={loadFiles}>
                        <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" multiple type="file" name="file" accept=".pdf" onChange={handleChangeFiles} />
                        <span className="svg-icon svg-icon-1 me-3">
                          <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" stroke="#000000">
                            <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round"></path>
                            <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg>
                        </span>
                        <span className="indicator-label text-muted">{lang.labels.selectOrDragDocuments}</span>
                        <span className="indicator-progress text-muted">
                          {lang.labels.pleaseWait}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                    <div className="dropzone-items">
                      { archivesValue.map (( (item, index) => { return (
                        <div key={index} className="dropzone-item dz-image-preview">
                          <div className="dropzone-file">
                            <div className="dropzone-filename d-flex align-items-center text-dark">
                              <span className="svg-icon svg-icon-2 me-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black"/>
                                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                                </svg>
                              </span>
                              <span>{item.name}</span>
                              <span className="mx-3">-</span>
                              <span>{item.size} MB</span>
                            </div>
                            { !archivesValid[index].file &&
                              <div className="dropzone-error">{archivesValid[index].message}</div>
                            }
                          </div>
                          <div className="dropzone-toolbar">
                            <span className="dropzone-delete" onClick={() => handleRemoveFile(index)}>
                              <i className="bi bi-x fs-1"></i>
                            </span>
                          </div>
                        </div>
                      )}))}
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
          <div className="card card-flush p-3">
            <div className="d-flex justify-content-end">
              <div className="mb-xl-0 col-12 col-md-6  d-flex pe-6 ps-3">
                <button className="btn btn-primary w-100" type="button" data-kt-indicator={loadIndicator} onClick={submitCommunicationCreate}>
                  <span className="indicator-label">{lang.labels.save}</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button className="btn btn-light-primary w-100 ms-3" type="reset" onClick={onCancel}>
                  {lang.labels.cancel}
                </button>
              </div>
            </div>    
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default CommunicationCreatePage;
