import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContextClient } from './Client';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { ClientService } from '../../../services/client.service';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { ClientModel } from '../../../models/client.model';
import { pageCurrent, pageLimitDisabled, pageLimitEnabled } from '../../../types/pagination.type';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';

export interface ClientListPageProps {};

let errorResponse: Error, messageResponse: Message, clientsResponse: any;

const ClientListPage: React.FunctionComponent<ClientListPageProps> = props => {
  const {setRoute, clients, setClients} = UseOutletContextClient()
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [filter, setFilter] = useState({test_client: false, is_deleted: false})
  const [pagination, setPagination] = useState({current: 0, total: 0, pages: [] as number[], limits: {min: 0, max: 0}})
  const [search, setSearch] = useState({text: {value: '', valid: false}, list: [] as ClientModel[]})
  const [clientList, setClientList] = useState<ClientModel[] | undefined | null>(null)

  const loadClientList = async () => {
    let totalPages: number = 1
    let totalByPage: number = 10
    let listPagesOfPagination: number[] = []

    await ClientService.getClients(filter.test_client, filter.is_deleted).then( (response) => {
      if (response.status === 200) {
        clientsResponse = response.data

        if (clientsResponse.clients.length % totalByPage === 0) {
          totalPages = Math.floor(clientsResponse.clients.length / totalByPage)
        } else {
          totalPages = Math.floor(clientsResponse.clients.length / totalByPage) + 1
        }

        for (let i = 1; i <= totalPages; i++) {
          listPagesOfPagination.push(i)
        }

        setClientList(clientsResponse.clients)
        setSearch({...search, list: clientsResponse.clients})
        setPagination({...pagination, current: 1, total: totalByPage, pages: listPagesOfPagination, limits: {min: 0, max: totalByPage}})
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitClientDelete = async (item: string, remove: boolean) => {
    if (clients && clientList) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisClient,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await ClientService.deleteClient(item, remove).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              let list = clients.filter((client) => (client.id !== item))
              let listAll = clientList.filter((client) => (client.id !== item))
              let listSearch = search.list.filter((client) => (client.id !== item))

              setClients(list)
              setClientList(listAll)
              setSearch({...search, list: listSearch})

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
              
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const handleChangeSearch = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let validity: boolean = false
    let list: ClientModel[] = []
    let totalPages: number = 1
    let listPagesOfPagination: number[] = []

    if (clientList && text.length > 0) {
      for (let item of clientList) {
        let client: string = `${item.last_name} ${item.first_name}`.toLocaleLowerCase()
        if (client.indexOf(text.toLowerCase()) !== -1) {
          list.push(item)
        }
      }

      validity = true
    } else if (clientList! && text.length === 0) {
      validity = false
      list = clientList
    }

    if (list.length % pagination.total === 0) {
      totalPages = Math.floor(list.length / pagination.total)
    } else {
      totalPages = Math.floor(list.length / pagination.total) + 1
    }

    for (let i = 1; i <= totalPages; i++) {
      listPagesOfPagination.push(i)
    }

    setSearch({...search, text: {valid: validity, value: text}, list: list})
    setPagination({...pagination, current: 1, total: pagination.total, pages: listPagesOfPagination, limits: {min: 0, max: pagination.total}})
  }

  const handleChangeTestClients = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFilter({...filter, test_client: event.target.checked, is_deleted: false})
    setSearch({...search, text: {valid: false, value: ''}, list: []})
  }

  const handleChangeDeletedClients = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFilter({...filter, test_client: false, is_deleted: event.target.checked})
    setSearch({...search, text: {valid: false, value: ''}, list: []})
  }

  const executePaginationSelect = (item: number) => {
    let minLimitNew: number = pagination.total * (item - 1)
    let maxLimitNew: number = pagination.total * item
    let elements: string[] = []

    if (item === 1) {
      pageLimitDisabled('pagination-button-previous')
    } else {
      pageLimitEnabled('pagination-button-previous')
    }

    if (item === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }else {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(item, elements)
    setPagination({...pagination, current: item, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationPrevious = () => {
    let currentNew: number = pagination.current - 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew === 1) {
      pageLimitDisabled('pagination-button-previous')
    }

    if (currentNew < pagination.pages.length) {
      pageLimitEnabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  const executePaginationNext = () => {
    let currentNew: number = pagination.current + 1
    let minLimitNew: number = pagination.total * (currentNew - 1)
    let maxLimitNew: number = pagination.total * currentNew
    let elements: string[] = []

    if (currentNew > 1) {
      pageLimitEnabled('pagination-button-previous')
    }

    if (currentNew === pagination.pages.length) {
      pageLimitDisabled('pagination-button-next')
    }

    for (let item of pagination.pages) {
      elements.push('pagination-button-' + item)
    }

    pageCurrent(currentNew, elements)
    setPagination({...pagination, current: currentNew, limits: {min: minLimitNew, max: maxLimitNew}})
  }

  function titleColor(): string {
    switch (true) {
      case !filter.test_client && !filter.is_deleted:
        return 'text-success'
      case filter.test_client && !filter.is_deleted:
        return 'text-warning'
      case !filter.test_client && filter.is_deleted:
        return 'text-danger'
      default:
        return 'text-dark'
    }
  }

  function rowClass(index: number): string {
    if (pagination.limits.min <= index && index < pagination.limits.max) {
      if ((index + 1) % 2 !== 0) {
        return 'odd'
      } else {
        return 'even'
      }
    } else {
      return 'd-none'
    }
  }

  useEffect( () => {
    setClientList(null)

    pagination.current = 0
    pagination.total = 0
    pagination.pages = []
    pagination.limits.min = 0
    pagination.limits.max = 0

    loadClientList()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.test_client, filter.is_deleted])

  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.clients, branch: lang.labels.clientList}, browse: {target: lang.labels.createNewClient, link: "/app/client/create"}})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { clients
        ?
        <div className="card">
          <div className="card-header border-0">
            <div className="card-title">
              <div className="d-flex align-items-center position-relative">
                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                  </svg>
                </span>
                <input className="form-control form-control-solid w-350px ps-14" type="text" placeholder={`${lang.labels.search}...`} maxLength={200} onChange={handleChangeSearch} />
              </div>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end gap-5">
              <div className="form-check form-switch form-check-custom form-check-solid">
                <label className="form-check-label fw-bold fs-6 me-2">{lang.labels.virtualClients}</label>
                <input className="form-check-input w-40px h-20px" type="checkbox" checked={filter.test_client} onChange={handleChangeTestClients}/>
              </div>
              <div className="form-check form-switch form-check-custom form-check-solid">
                <label className="form-check-label fw-bold fs-6 me-2">{lang.labels.deletedClients}</label>
                <input className="form-check-input w-40px h-20px" type="checkbox" checked={filter.is_deleted} onChange={handleChangeDeletedClients}/>
              </div>
            </div>
          </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-5">
                    <thead>
                      <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase">
                        <th className={`min-w-300px sorting ${titleColor()}`}>
                          {!filter.test_client && !filter.is_deleted && lang.labels.clients}
                          {filter.test_client && !filter.is_deleted && lang.labels.virtualClients}
                          {!filter.test_client && filter.is_deleted && lang.labels.deletedClients}
                        </th>
                        {!filter.is_deleted && 
                          <th className="w-275px sorting_disabled text-end">
                            {lang.labels.actions}
                          </th>
                        }
                      </tr>
                    </thead>
                    { clientList
                      ?
                      <>
                        { search.list.length > 0
                          ?
                          <tbody className="text-gray-600 fs-6 fw-bold">
                            { search.list.map (( (item, index) => { return (
                              <tr key={index} className={`row-dashed bg-hover-light ${rowClass(index)}`} onClick={()=>{if (!filter.is_deleted) {navigate("/app/client/view/" + item.id)}}}>
                                <td className="d-flex align-items-center ps-3">
                                  <div className="symbol symbol-circle symbol-45px overflow-hidden me-5">
                                    <div className="symbol-label fs-3 bg-light-primary text-primary">
                                      {item.last_name.charAt(0)}{item.first_name.charAt(0)}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column">
                                    <span className="text-gray-900">{item.last_name} {item.first_name}</span>
                                  </div>
                                </td>
                                  <td className="text-end pe-1">
                                    { !filter.is_deleted &&
                                      <Link to={"/app/client/update/" + item.id} className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation()}}>{lang.labels.edit}</Link>
                                    }
                                    <button className="btn btn-sm btn-light-primary me-2" type="button" onClick={(event) => {event.stopPropagation(); submitClientDelete(item.id, item.is_deleted)}}>
                                      { (!filter.is_deleted) ? lang.labels.delete : lang.labels.deletePermanently }
                                    </button>
                                    { !filter.is_deleted &&
                                      <img className= "w-20px" src={arrowNext} alt="Go"/>
                                    }
                                  </td>
                              </tr>
                            )}))}
                          </tbody>
                          :
                          <tbody className="text-gray-600 fw-bold">
                            <tr className="odd">
                              <td valign="top" colSpan={2} className="text-center">
                                {lang.labels.noMatchingRecordsFound}
                              </td>
                            </tr>
                          </tbody>
                        }
                      </>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={2}>
                            <div className="d-flex justify-content-center align-items-center w-100 h-100 my-10">
                              <div className="spinner-border" role="status">
                                <span className="visually-hidden">{lang.labels.loading}</span>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
                { clientList && search.list.length > 0 && pagination.pages.length > 1 &&
                  <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                      <div className="dataTables_paginate paging_simple_numbers">
                        <ul className="pagination">
                          <li id="pagination-button-previous" className="paginate_button page-item previous disabled">
                            <button className="page-link" type="button" onClick={executePaginationPrevious}>
                              <i className="previous"></i>
                            </button>
                          </li>
                          { pagination.pages.map (( (item, index) => { return (
                            <li key={index} id={"pagination-button-" + item} className={`paginate_button page-item ${item === pagination.current && 'active'}`}>
                              <button className="page-link" type="button" onClick={() => executePaginationSelect(item)}>{item}</button>
                            </li>
                          )}))}
                          <li id="pagination-button-next" className="paginate_button page-item next">
                            <button className="page-link" type="button" onClick={executePaginationNext}>
                              <i className="next"></i>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
};

export default ClientListPage;
