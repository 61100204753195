import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { inputValid, inputInvalid, restartInput} from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { WeightModel } from '../../models/weight.model';
import { WeightService } from '../../services/weight.service';
import { Dropdown } from 'react-bootstrap';
import { weightUnitList, weightUnitValue } from '../../scripts/weight.units.script';
import { listWeightSortedAscending, listWeightSortedDescending } from '../../scripts/order.asc.desc.list.script';

interface AppModalWeightUpdateProps {
  orderAscDesc: string,
  weight: WeightModel | undefined | null,
  weights: WeightModel[] | undefined | null,
  setWeights: Dispatch<SetStateAction<WeightModel[] | undefined | null>>,
  setWeight: Dispatch<SetStateAction<WeightModel | undefined | null>>
};

let errorResponse: Error, serviceResponse: any;

const AppModalWeightUpdate: React.FunctionComponent<AppModalWeightUpdateProps> = ({orderAscDesc, weight, weights, setWeight, setWeights}) => {
  const {lang} = StateLanguage()

  const expressions = {
    number: /^\d{0,10}(\.\d{1,2})?$/,
  }

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [valueWeight, setValueWeight] = useState({value: '', valid: false})
  const [unitWeight, setUnitWeight] = useState({value: '', valid: false})

  const submitWeightUpdate = async () => {
    setLoadIndicator('on')

    if (weights && weight && valueWeight.valid && unitWeight.valid) {
      await WeightService.putWeight(weight.id, valueWeight.value, unitWeight.value).then( (response) => {
        if (response.status === 200) {
          serviceResponse = response.data

          let list = weights.map((item) => {
            if (item.id === serviceResponse.id) {
              return serviceResponse;
            }
            return item
          })

          setWeights((orderAscDesc === "asc") ? listWeightSortedAscending(list) : listWeightSortedDescending(list))

          Swal.fire({
            title: lang.labels.successfullyUpdatedWeight,
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalWeightUpdate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          }) 
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeValueWeight = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setValueWeight({...valueWeight, value: event.target.value})
  }

  const validateValueWeight = () => {
    if (expressions.number.test(valueWeight.value)) {
      setValueWeight({...valueWeight, valid: true})
      inputValid('modal-weight-update-input-value-weight')
    } else {
      setValueWeight({...valueWeight, valid: false})
      inputInvalid('modal-weight-update-input-value-weight')
    }
  }

  const handleChangeUnitWeight = (item: string) => {
    setUnitWeight({ ...unitWeight, value: item, valid: true })
  }

  const executeHideModalWeightUpdate = () => {
    modalHide('modal-weight-update')

    setTimeout( () => {
      restartInput('modal-weight-update-input-value-weight')
    }, 200 )
  }

  useEffect(() => {
    if (weight) {
      setValueWeight({value: weight.value.toString(), valid: true })
      setUnitWeight({value: weight.unity, valid: true })
    }
  }, [weight]);

  return (
    <div id="modal-weight-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-550px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.updateWeight}</h2>
            </div>
          </div>
          <div className="modal-body">
            <div className='row fv-row mb-5'>
              <div className="d-flex justify-content-between">
                <label className="form-label">{lang.labels.value}</label>
                <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
              </div>
              <input id="modal-weight-update-input-value-weight" className="form-control form-control-solid" type="text" name="value-weight" value={valueWeight.value} onChange={handleChangeValueWeight} onKeyUp={validateValueWeight} onBlur={validateValueWeight} />
            </div>
            <div className="row fv-row">
              <div className="d-flex justify-content-between">
                <label className="form-label">{lang.labels.unit}</label>
                <label className="form-label text-gray-600 fs-7 required">{lang.labels.required}</label>
              </div>
              <Dropdown>
                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                  <span className="selection">
                    <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                      <span className="select2-selection__rendered" role="textbox">
                        <span className="select2-selection__placeholder">
                          {unitWeight.valid ? weightUnitValue(unitWeight.value, lang) : lang.labels.selectOption}
                        </span>
                      </span>
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                  <span className="select2-dropdown select2-dropdown--below">
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox">
                        { weightUnitList(lang).map (( (item, index) => { return (
                          <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === unitWeight.value && "select2-results__option--selected"}`} role="option" aria-selected={item.code === unitWeight.value ? "true" : "false"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeUnitWeight(item.code)}>{item.desc}</Dropdown.Item>
                          </li>
                        )}))}
                      </ul>
                    </span>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitWeightUpdate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalWeightUpdate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalWeightUpdate;
