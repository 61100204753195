import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { NavLink, Outlet, useOutletContext } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { UseOutletContext } from '../Main';
import { RefinerModel } from '../../../models/refiner.model';
import { PurityModel } from '../../../models/purity.model';
import { WeightModel } from '../../../models/weight.model';
import { UserModel } from '../../../models/user.model';
import { HeritageOriginModel } from '../../../models/heritage.origin.model';
import { RateModel } from '../../../models/rate.model';
import { IngotTypeService } from '../../../services/ingot.type.service';
import { IngotTypeModel } from '../../../models/ingot.type.model';
import { getError } from '../../../languages/context/response';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { Error } from '../../../models/error.model';
import { PurityService } from '../../../services/purity.service';
import { WeightService } from '../../../services/weight.service';
import { RefinerService } from '../../../services/refiner.service';
import { UserService } from '../../../services/user.service';
import { HeritageOriginService } from '../../../services/heritage.origin.service';
import { RateService } from '../../../services/rate.service';
import { SecurityService } from '../../../services/security.service';
import { CompanySecurityModel } from '../../../models/company.security.model';
import { NotaryModel } from '../../../models/notary.model';
import { NotaryService } from '../../../services/notary.service';

export interface ParameterPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string, branch: string} | null, browse: {target: string, link: string} | null} | null>>,
  users: UserModel[] | undefined | null,
  refineries: RefinerModel[] | undefined | null,
  weights: WeightModel[] | undefined | null,
  purities: PurityModel[] | undefined | null,
  ingotTypes: IngotTypeModel[] | undefined | null,
  securityCompanies: CompanySecurityModel[] | undefined | null,
  rates: RateModel[] | undefined | null,
  heritages: HeritageOriginModel[] | undefined | null,
  notaries: NotaryModel[] | undefined | null,
  setUsers: Dispatch<SetStateAction<UserModel[] | undefined | null>>,
  setRefineries: Dispatch<SetStateAction<RefinerModel[] | undefined | null>>,
  setWeights: Dispatch<SetStateAction<WeightModel[] | undefined | null>>,
  setPurities: Dispatch<SetStateAction<PurityModel[] | undefined | null>>,
  setIngotTypes: Dispatch<SetStateAction<IngotTypeModel[] | undefined | null>>
  setSecurityCompanies: Dispatch<SetStateAction<CompanySecurityModel[] | undefined | null>>
  setRates: Dispatch<SetStateAction<RateModel[] | undefined | null>>,
  setHeritages: Dispatch<SetStateAction<HeritageOriginModel[] | undefined | null>>,
  setNotaries: Dispatch<SetStateAction<NotaryModel[] | undefined | null>>,
};

const ParameterPage: React.FunctionComponent<ParameterPageProps> = () => {
  const {setRoute} = UseOutletContext()
  const {lang} = StateLanguage()

  const [users, setUsers] = useState<UserModel[] | undefined | null>(null)
  const [refineries, setRefineries] = useState<RefinerModel[] | undefined | null>(null)
  const [weights, setWeights] = useState<WeightModel[] | undefined | null>(null)
  const [purities, setPurities] = useState<PurityModel[] | undefined | null>(null)
  const [ingotTypes, setIngotTypes] = useState<IngotTypeModel[] | undefined | null>(null)
  const [securityCompanies, setSecurityCompanies] = useState<CompanySecurityModel[] | undefined | null>(null)
  const [rates, setRates] = useState<RateModel[] | undefined | null>(null)
  const [heritages, setHeritages] = useState<HeritageOriginModel[] | undefined | null>(null)
  const [notaries, setNotaries] = useState<NotaryModel[] | undefined | null>(null)

  const loadUsers = async () => {
    await UserService.getUsers().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setUsers(serviceResponse.users)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadRefineries = async () => {
    await RefinerService.getRefineries().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data

        setRefineries(serviceResponse.refineries)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadWeights = async () => {
    await WeightService.getWeights().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data

        setWeights(serviceResponse.weights)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadPurities = async () => {
    await PurityService.getPurities().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data

        setPurities(serviceResponse.purities)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadIngotTypes = async () => {
    await IngotTypeService.getIngotTypes().then( (response) => {
      if (response.status === 200) {
        let ingotTypesResponse: any = response.data
        setIngotTypes(ingotTypesResponse.ingot_types)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadSecurityCompanies = async () => {
    await SecurityService.getSecurityCompanies().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setSecurityCompanies(serviceResponse.security_companies)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadRates = async () => {
    await RateService.getRates().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setRates(serviceResponse.rates)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadHeritages = async () => {
    await HeritageOriginService.getHeritages().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setHeritages(serviceResponse.heritage_origins)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadNotaries = async () => {
    await NotaryService.getNotaries().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setNotaries(serviceResponse.notaries)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  useEffect( () => {
    loadUsers()
    loadRefineries()
    loadWeights()
    loadPurities()
    loadIngotTypes()
    loadSecurityCompanies()
    loadRates()
    loadHeritages()
    loadNotaries()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="d-flex flex-column gap-5">
      <div className="d-flex flex-wrap flex-stack">
        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-2 fs-4 fw-bold w-100">
          <li className="nav-item">
            <NavLink to="/app/parameter/users" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.users}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/refineries" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.refiners}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/weights" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.weights}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/purities" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.purities}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/ingot/types" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.ingotTypes}</NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to="/app/parameter/safes" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.safes}</NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink to="/app/parameter/security/companies" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.securityCompanies}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/rates" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.commissionRates}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/heritages" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.heritageValues}</NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/app/parameter/notaries" className={({isActive}) => `nav-link text-active-primary pb-2 ${isActive && 'active'}`}>{lang.labels.notaries}</NavLink>
          </li>
        </ul>
      </div>
      <div className="w-100 h-100">
        <Outlet context={{setRoute, users, refineries, weights, purities, ingotTypes, securityCompanies, rates, heritages, notaries, setUsers, setRefineries, setWeights, setPurities, setIngotTypes, setSecurityCompanies, setRates, setHeritages, setNotaries}}></Outlet>
      </div>
    </div>
  );
}

export function UseOutletContextParameter() {
  return useOutletContext<ContexType>()
};

export default ParameterPage;
