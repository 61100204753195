import React, { useEffect } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { StateLanguage } from '../../languages/config/StateLanguage';

export interface AppChartBarInvestmentProps {
  id: string,
  info: { client: string, amount_total: number }[]
};

const AppChartBarInvestment: React.FunctionComponent<AppChartBarInvestmentProps> = ({ id, info }) => {
  const { lang } = StateLanguage();

  useEffect(() => {
    let root = am5.Root.new(id);

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }));

    chart.set("scrollbarY", am5.Scrollbar.new(root, {
      orientation: "vertical",
      marginLeft: 50,
      marginRight: 0
    }));

    let totalInversion = info.reduce((suma, item) => suma + item.amount_total, 0);

    let data = info.map((item) => {
      let porcentaje = ((item.amount_total / totalInversion) * 100).toFixed(2);

      return {
        "client": item.client,
        "total": item.amount_total,
        "percentage": porcentaje
      }
    });

    let yRenderer = am5xy.AxisRendererY.new(root, {
      minGridDistance: 0
    });

    yRenderer.grid.template.set("visible", false);
    yRenderer.grid.template.set("location", 1);

    let yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: "client",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    yAxis.data.setAll(data);

    let xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("strokeOpacity", 0.1);

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0,
        min: 0,
        extraMax: 0.1,
        renderer: xRenderer
      })
    );

    let series1 = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: lang.labels.investmentAmount,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "total",
      categoryYField: "client",
      clustered: false,
      fill: am5.color("#6771dc"),
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: `[bold]{name}[/]\n{categoryY}: {valueX} CHF\n{percentage}% ${lang.labels.ofPortfolio} ({valueX}/${totalInversion.toLocaleString('en-US')} )`
      })
    }));

    series1.columns.template.setAll({
      height: am5.percent(80),
      tooltipX: 0,
      strokeOpacity: 0
    });

    let legend = chart.children.push(am5.Legend.new(root, {
      marginTop: 10,
      centerX: am5.p50,
      x: am5.p50
    }));

    legend.data.setAll(chart.series.values);

    let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomY"
    }));

    cursor.lineX.set("visible", false);

    series1.data.setAll(data);

    series1.appear();

    chart.appear(100, 10);

    return () => { root.dispose() }
  }, [id, info, lang])

  return (
    <div id={id} className="w-100 h-700px"></div>
  )
};

export default AppChartBarInvestment;
