function navTabActive(identifier: string) {
  let element1 = document.getElementById(identifier)
  element1?.classList.add('active')
}

function navTabDesactive(identifier: string) {
  let element1 = document.getElementById(identifier)
  element1?.classList.remove('active')
}

function navTabReload(list_identifier: string[], identifier: string) {
  for (let i = 0; i < list_identifier.length; i++) {
    if (list_identifier[i] === identifier) {
      navTabActive(list_identifier[i])
    } else {
      navTabDesactive(list_identifier[i])
    }
  }
}

export { navTabActive, navTabDesactive, navTabReload }
