import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { getNames, getCode, getName } from '../../../scripts/countries.script'
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { inputValid, inputInvalid } from '../../../types/legend.input.type';
import { Message } from '../../../models/message.model';
import { UseOutletContextSecurity } from './Security';
import { SecurityService } from '../../../services/security.service';
import { extractBase64 } from '../../../scripts/extract.base.64.script';
import { FaExclamationCircle } from 'react-icons/fa';
import { removeTooltip, uploadTooltip } from '../../../types/tooltip.type';
import { CompanySecurityModel } from '../../../models/company.security.model';
import { inputFileSizeInvalid, inputFileTypeInvalid, inputFileTypeSizeValid, restartInputFileTypeSize } from '../../../types/legend.input.file.type';
import { cleanInputFile } from '../../../types/input.file.type';
import imageDefault from '../../../assets/images/default.png';
import imageUpload from '../../../assets/images/upload.png';
import { expressions } from '../../../scripts/regular.expressions.script';

interface SecurityUpdateProps {};

let errorResponse: Error, messageResponse: Message, serviceResponse: any;

const SecurityUpdatePage: React.FunctionComponent<SecurityUpdateProps> = () => {
  const { setRoute } = UseOutletContextSecurity()
  const { lang } = StateLanguage()
  const navigate = useNavigate()
  const param = useParams()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [logo, setLogo] = useState({value: '', file: null as any, valid: false})
  const [name, setName] = useState({value: '', valid: false})
  const [number, setNumber] = useState({value: '', valid: false})
  const [street, setStreet] = useState({value: '', valid: false})
  const [postcode, setPostcode] = useState({value: '', valid: false})
  const [city, setCity] = useState({value: '', valid: false})
  const [country, setCountry] = useState({value: '', valid: false})
  const [phone, setPhone] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: false})
  const [website, setWebsite] = useState({value: '', valid: false})
  const [safesValue, setSafesValue] = useState<string[]>([])
  const [safesValid, setSafesValid] = useState<boolean[]>([])
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})
  const [securityCompany, setSecuritycompany] = useState<CompanySecurityModel | undefined | null>(null)

  const loadSecurityCompany = async (id_security_company: string) => {
    await SecurityService.getSecurityCompany(id_security_company).then( (response) => {
      if (response.status === 200) {
        serviceResponse = response.data

        let tempNumberSafesValue: string[] = []
        let tempNumberSafesValid: boolean[] = []

        for (let number_safe of serviceResponse.number_safes) {
          tempNumberSafesValue.push(number_safe)
          tempNumberSafesValid.push(true)
        }

        setSecuritycompany(serviceResponse)
        setLogo({value: serviceResponse.logo, file: serviceResponse.logo, valid: true})
        setName({value: serviceResponse.name, valid: true})
        setNumber({value: serviceResponse.number, valid: true})
        setStreet({value: serviceResponse.address.street, valid: true})
        setPostcode({value: serviceResponse.address.zip_code, valid: true})
        setCity({value: serviceResponse.address.city, valid: true})
        setCountry({value: serviceResponse.address.country, valid: true})
        setPhone({value: serviceResponse.phone, valid: true})
        setEmail({value: serviceResponse.email, valid: true})
        setWebsite({value: serviceResponse.website, valid: true})
        setSafesValue(tempNumberSafesValue)
        setSafesValid(tempNumberSafesValid)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitSecurityCompanyUpdate = async () => {
    setLoadIndicator('on')

    let validSafes = safesValid.length > 0 && safesValid.every(item => item)

    if (securityCompany && name.valid && number.valid && street.valid && postcode.valid && city.valid && country.valid && phone.valid && email.valid && website.valid && logo.valid && validSafes) {
      await SecurityService.putSecurityCompany(securityCompany.id, name.value, number.value, street.value, postcode.value, city.value, country.value, phone.value, email.value, website.value, logo.file, safesValue).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.codeLanguage),
            text: lang.labels.updatingList,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate(-1)
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  }  

  const onCancel = async () => {
    if (securityCompany) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToCancel,
        text: lang.labels.ifYouAcceptYouMayHaveToFillOutTheFormAgain,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yes,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary'}
      }).then(async (result) => {
        if (result.isConfirmed) {
          navigate(-1)
        }
      })
    }
  }

  const handleChangeLogo = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let archiveCapture = event.target.files[0]
    let archiveType: string[] = ['image/png', 'image/jpeg', 'image/jpg']
    let archiveSize: number = 1048576

    if (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type)))) {
      setLogo({...logo, value: '', file: null, valid: true})
      inputFileTypeInvalid('container-validate-logo-type', 'container-validate-logo-size')
      cleanInputFile()
    } else if (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) {
      setLogo({...logo, value: '', file: null, valid: true})
      inputFileSizeInvalid('container-validate-logo-type', 'container-validate-logo-size')
      cleanInputFile()
    } else {
      extractBase64(archiveCapture).then((image: any) => {
        setLogo({...logo, value: image.base, file: archiveCapture, valid: true})
        inputFileTypeSizeValid('container-validate-logo-type', 'container-validate-logo-size')
      })
    }
  }

  const handleChangeCompanyName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setName({...name, value: event.target.value})
  }

  const handleChangeCompanyBusinessNumber = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setNumber({...number, value: event.target.value})
  }

  const handleChangeCompanyAddress = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeCompanyPostcode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPostcode({...postcode, value: event.target.value})
  }

  const handleChangeCompanyCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeCompanyCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangeCompanyPhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeCompanyEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeCompanyWebsite = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setWebsite({...website, value: event.target.value})
  }

  const handleChangeSafe = (index: number, event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let tempSafesValue = safesValue.map((temp_item, temp_index) => {
      if (index === temp_index) {
        return event.target.value
      } else {
        return temp_item
      }
    })

    setSafesValue(tempSafesValue)
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const handleRemoveLogo = () => {
    setLogo({...logo, value: '', file: null, valid: true})
    restartInputFileTypeSize('container-validate-logo-type', 'container-validate-logo-size')
    removeTooltip()
    cleanInputFile()
  }

  const validateCompanyName = () => {
    if (expressions.text.test(name.value)) {
      setName({...name, valid: true})
      inputValid('input-company-name')
    } else {
      setName({...name, valid: false})
      inputInvalid('input-company-name')
    }
  }

  const validateCompanyBusinessNumber = () => {
    if (expressions.companyNumber.test(number.value)) {
      setNumber({...number, valid: true})
      inputValid('input-company-business-number')
    } else {
      setNumber({...number, valid: false})
      inputInvalid('input-company-business-number')
    }
  }

  const validateCompanyStreet = () => {
    if (expressions.street.test(street.value)) {
      setStreet({...street, valid: true})
      inputValid('input-company-street')
    } else {
      setStreet({...street, valid: false})
      inputInvalid('input-company-street')
    }
  }

  const validateCompanyPostcode = () => {
    if (expressions.postcode.test(postcode.value)) {
      setPostcode({...postcode, valid: true})
      inputValid('input-company-postcode')
    } else {
      setPostcode({...postcode, valid: false})
      inputInvalid('input-company-postcode')
    }
  }

  const validateCompanyCity = () => {
    if (expressions.location.test(city.value)) {
      setCity({...city, valid: true})
      inputValid('input-company-city')
    } else {
      setCity({...city, valid: false})
      inputInvalid('input-company-city')
    }
  }

  const validateCompanyPhone = () => {
    if (expressions.phone.test(phone.value)) {
      setPhone({...phone, valid: true})
      inputValid('input-company-phone')
    } else {
      setPhone({...phone, valid: false})
      inputInvalid('input-company-phone')
    }
  }

  const validateCompanyEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email, valid: true})
      inputValid('input-company-email')
    } else {
      setEmail({...email, valid: false})
      inputInvalid('input-company-email')
    }
  }

  const validateCompanyWebsite = () => {
    if (expressions.website.test(website.value)) {
      setWebsite({...website, valid: true})
      inputValid('input-company-website')
    } else {
      setWebsite({...website, valid: false})
      inputInvalid('input-company-website')
    }
  }

  const validateSafe = (index: number) => {
    if (expressions.number.test(safesValue[index])) {
      let tempSafesValid = safesValid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return true
        } else {
          return temp_item
        }
      })
      setSafesValid(tempSafesValid)
      inputValid('input-safe-' + index)
    } else {
      let tempSafesValid = safesValid.map((temp_item, temp_index) => {
        if (index === temp_index) {
          return false
        } else {
          return temp_item
        }
      })
      setSafesValid(tempSafesValid)
      inputInvalid('input-safe-' + index)
    }
  }

  const executeAddSafe = () => {
    let allow: boolean = true

    for (let i = 0; i < safesValid.length; i++) {
      if (!safesValid[i]) {
        inputInvalid('input-safe-' + i)
        allow = false
      }
    }

    if (allow) {
      setSafesValue([...safesValue, ''])
      setSafesValid([...safesValid, false])

      uploadTooltip()
    }
  }

  const executeRemoveSafe = (index: number) => {
    let tempSafesValue = safesValue.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempSafesValid = safesValid.filter((_temp_item, temp_index) => (temp_index !== index))

    setSafesValue(tempSafesValue)
    setSafesValid(tempSafesValid)

    for (let i = 0; i < tempSafesValue.length; i++) {
      if (expressions.number.test(tempSafesValue[i])) {
        inputValid('input-safe-' + i)
      } else {
        inputInvalid('input-safe-' + i)
      }
    }
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  useEffect( () => {
    if(param.id) {
      loadSecurityCompany(param.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setRoute({main: false, path: {root: lang.labels.security, branch: `${lang.labels.viewSecurityCompany} ${securityCompany ? securityCompany.name : ""}`}, browse: null})

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [securityCompany])

  useEffect( () => {
    setMounted(true)
    uploadTooltip()

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { securityCompany
        ?
        <div className="form">
          <div className="card card-flush mb-5 d-none">
            <div className="card-header">
              <div className="card-title">
                <h3 className="text-uppercase">{lang.labels.pleaseEnterTheFollowingInformationToUpdateASecurityCompany}</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              <p className="fst-italic text-muted mb-10">
                {lang.labels.fieldsMarkedWithAnAsterisk}<span className="text-danger">(*)</span>{lang.labels.areRequired}
              </p>
              <div className="form d-flex flex-column flex-xl-row ">
                <div className="flex-xl-row-auto w-xl-200px me-0 me-xl-10 mb-5">
                  <label className="col-form-label fw-bold fs-6 d-flex align-items-center">
                    {lang.labels.logo}
                    <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.addImageOrDragAndDrop}><FaExclamationCircle /></i>
                  </label>
                  <div className="my-5">
                    <div className="position-relative image-input image-input-outline" data-kt-image-input="true">
                      <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" type="file" name="file" accept=".png, .jpg, .jpeg" onChange={handleChangeLogo} />
                      <span className={`btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white ${(!logo.valid || logo.value.length === 0) && "d-none"}`} data-kt-image-input-action="remove" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.removeImage} onClick={handleRemoveLogo}>
                        <i className="bi bi-x fs-2"></i>
                      </span>
                      <img src={logo.valid && logo.value.length > 0 ? logo.value : imageDefault} className="image-input-wrapper w-200px h-200px" alt="logo" />
                      <div className="position-absolute top-50 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center" style={{ pointerEvents: 'none' }}>
                        <img src={imageUpload} className="w-35px h-35px opacity-50" alt="Upload" />
                      </div>
                    </div>
                  </div>
                  <div id="container-validate-logo-type" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="avatar" data-validator="type">{lang.labels.invalidFileType}</div>
                  </div>
                  <div id="container-validate-logo-size" className="fv-plugins-message-container invalid-feedback d-none">
                    <div data-field="avatar" data-validator="size">{lang.labels.fileSizeNotAllowed}</div>
                  </div>
                </div>
                <div className="d-flex flex-column flex-xl-row-fluid">
                  <div className="mb-5">
                    <label className="col-form-label required fw-bold fs-6">{lang.labels.companyName}</label>
                    <input id="input-company-name" className="form-control form-control-solid" maxLength={70} type="text" name="company-name" value={name.value} onChange={handleChangeCompanyName} onKeyUp={validateCompanyName} onBlur={validateCompanyName} />
                  </div>
                  <div className="row mb-5">
                    <div className="mb-8 mb-xl-0 col-xl-6">
                      <label className="col-form-label required fw-bold fs-6">{lang.labels.businessNumber}</label>
                      <input id="input-company-business-number" className="form-control form-control-solid" type="text" placeholder="CHE-247.022.257" name="company-business-number" value={number.value} onChange={handleChangeCompanyBusinessNumber} onKeyUp={validateCompanyBusinessNumber} onBlur={validateCompanyBusinessNumber} />
                    </div>
                    <div className="col-xl-6">
                      <label className="col-form-label required fw-bold fs-6">{lang.labels.website}</label>
                      <input id="input-company-website" className="form-control form-control-solid" type="text" name="company-website" value={website.value} onChange={handleChangeCompanyWebsite} onKeyUp={validateCompanyWebsite} onBlur={validateCompanyWebsite} />
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="mb-8 mb-xl-0 col-xl-6">
                      <label className="col-form-label required fw-bold fs-6">{lang.labels.email}</label>
                      <input id="input-company-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeCompanyEmail} onKeyUp={validateCompanyEmail} onBlur={validateCompanyEmail} />
                    </div>
                    <div className="col-xl-6">
                      <label className="col-form-label required fw-bold fs-6">{lang.labels.phone}</label>
                      <input id="input-company-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangeCompanyPhone} onKeyUp={validateCompanyPhone} onBlur={validateCompanyPhone} />
                    </div>
                  </div>
                  <div className="mb-5">
                    <label className="col-form-label required">{lang.labels.address}</label>
                    <input id="input-company-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeCompanyAddress} onKeyUp={validateCompanyStreet} onBlur={validateCompanyStreet} />
                  </div>
                  <div className="row mb-5">
                    <div className="mb-5 mb-xl-0 col-xl-3">
                      <label className="col-form-label required">{lang.labels.postcode}</label>
                      <input id="input-company-postcode" className="form-control form-control-solid" type="text" name="postcode" value={postcode.value} onChange={handleChangeCompanyPostcode} onKeyUp={validateCompanyPostcode} onBlur={validateCompanyPostcode} />
                    </div>
                    <div className="mb-5 mb-xl-0 col-xl-4">
                      <label className="col-form-label required">{lang.labels.city}</label>
                      <input id="input-company-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCompanyCity} onKeyUp={validateCompanyCity} onBlur={validateCompanyCity} />
                    </div>
                    <div className="col-xl-5">
                    <label className="col-form-label required">{lang.labels.country}</label>
                      <Dropdown>
                        <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                          <span className="selection">
                            <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                              <span className="select2-selection__rendered" role="textbox">
                                <span className="select2-selection__placeholder">
                                  {country.valid && country.value.length > 0 ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                                </span>
                              </span>
                            </span>
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                          <span className="select2-dropdown select2-dropdown--below">
                            <span className="select2-search select2-search--dropdown">
                              <input className="select2-search__field" type="text" name="country" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                            </span>
                            <span className="select2-results">
                              <ul className="select2-results__options" role="listbox">
                                { searchCountry.list.length > 0
                                  ?
                                  <>
                                    { searchCountry.list.map (( (item, index) => { return (
                                      <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === country.value ? "true" : "false"}>
                                        <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCompanyCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                      </li>
                                    )}))}
                                  </>
                                  :
                                  <li className="select2-results__option select2-results__message" role="alert" aria-live="assertive">{lang.labels.noResultsFound}</li>
                                }
                              </ul>
                            </span>
                          </span>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <div className="card card-flush mb-5">
            <div className="card-header">
              <div className="card-title">
                <h3 className="fw-bolder">{lang.labels.safes}</h3>
              </div>
              <div className="card-toolbar">
                <button className={`btn btn-sm btn-light-primary ${safesValue.length > 0 && "d-none"}`}  type="button" onClick={executeAddSafe}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.add}
                  </div>
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                { safesValue.length > 0 && safesValid.length > 0 &&
                  <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 fw-bold gy-3">
                      <thead>
                        <tr className="border-bottom text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                          <th className="min-w-300px">{lang.labels.value}</th>
                          <th className="w-65px text-end">{lang.labels.actions}</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600">
                        { safesValue.map (( (item, index) => { return (
                          <tr key={index}>
                            <td>
                              <input id={"input-safe-" + index} className="form-control form-control-solid" name="safe" autoComplete="off" value={item} onChange={(event) => handleChangeSafe(index, event)} onKeyUp={() => validateSafe(index)} onBlur={() => validateSafe(index)} />
                            </td>
                            <td className="text-end">
                              <button className="btn btn-icon btn-light-primary w-35px h-35px me-1" type="button" onClick={() => executeRemoveSafe(index)}>
                                <span className="svg-icon svg-icon-2">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="black" />
                                    <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="black" />
                                    <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="black" />
                                  </svg>
                                </span>
                              </button>
                            </td>
                          </tr>
                        )}))}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-end">
                      <button className="btn btn-sm btn-light-primary" type="button" onClick={executeAddSafe}>
                        <div className="d-flex align-items-center">
                          <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                              <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                              <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                              <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                            </svg>
                          </span>
                          {lang.labels.add}
                        </div>
                      </button>
                    </div>
                    
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="card card-flush p-3">
            <div className="d-flex justify-content-end">
              <div className="mb-xl-0 col-12 col-md-6  d-flex pe-6 ps-3">
                <button className="btn btn-primary w-100" type="button" data-kt-indicator={loadIndicator} onClick={submitSecurityCompanyUpdate}>
                  <span className="indicator-label">{lang.labels.save}</span>
                  <span className="indicator-progress">
                    {lang.labels.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button className="btn btn-light-primary w-100 ms-3" type="reset" onClick={onCancel}>
                  {lang.labels.cancel}
                </button>
              </div>
            </div>    
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </div>
  )
}

export default SecurityUpdatePage;
