import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';

export interface SecurityPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string, branch: string} | null, browse: {target: string, link: string} | null} | null>>,
};

const SecurityPage: React.FunctionComponent<SecurityPageProps> = () => {
  const {setRoute} = UseOutletContext()

  return (
    <Outlet context={{setRoute}}></Outlet>
  );
}

export function UseOutletContextSecurity() {
  return useOutletContext<ContexType>()
};

export default SecurityPage;
