import axios from 'axios';

import { ApiResponse } from '../models/api.response.model';
import { Error } from '../models/error.model';
import { GoldPriceHistoryModel } from '../models/gold.price.history.model';
import { appToken } from '../scripts/app.token.script';

export class ApiGold {
  static apiResponse: ApiResponse
  static dataResponse: any

  public static async getPriceGold(): Promise<number> {
    let priceCHFxKG: number = 0

    try {
      const response = await axios.get('https://api.metalpriceapi.com/v1/latest?api_key=f4e70b01a715d405e9bc82f477e66005&base=CHF&currencies=XAU')
      priceCHFxKG = (response.status === 200) ? (1 / response.data.rates.XAU) * 32.150743126506 : 0
    } catch (error) {
      alert(error)
    }

    return priceCHFxKG
  }

  public static async getPriceHistory(): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let response: any | null = {
        gold_prices: []
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/gold.price.history.json').then(resp => { response = resp.data }).catch(err => { alert(err) })

      if (response) {
        this.dataResponse = response

        this.apiResponse = {
          status: 200,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/gold_prices`
      let header: any = { headers: { Authorization: appToken() } }

      await axios.get(url, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }

  public static async postPriceHistory(date: string, price: number): Promise<ApiResponse> {
    if (process.env.REACT_APP_ENVIRONMENT === 'development') {
      let goldPrices: GoldPriceHistoryModel | null = null

      let response: any | null = {
        date: date,
        gold_price: price
      }

      let error: Error | null = {
        code: 9999,
        message: 'Error processing request'
      }

      await axios.get('../../../../../database/gold.price.history.json').then(resp => { goldPrices = resp.data }).catch(err => { alert(err) })

      if (!goldPrices!.gold_prices.some(item => item.date === date)) {
        this.dataResponse = response

        this.apiResponse = {
          status: 201,
          message: 'Success',
          data: this.dataResponse
        }
      } else {
        this.dataResponse = error

        this.apiResponse = {
          status: 400,
          message: 'Bad request',
          data: this.dataResponse
        }
      }

      return this.apiResponse
    } else {
      let url: string = `${process.env.REACT_APP_PORT}/gold_prices`
      let header: any = { headers: { Authorization: appToken() } }
      let data: any = {
        date: date,
        gold_price: price
      }

      await axios.post(url, data, header).then( response => {
        this.apiResponse = {
          status: response.status,
          message: response.statusText,
          data: response.data
        }
      }).catch( error => {
        this.apiResponse = {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data.error
        }
      })

      return this.apiResponse
    }
  }
}
