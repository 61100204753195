export const languageFr = {
  code: "FR",
  labels: {
    sgi: 'S.G.I. Swiss Gold Trading LLC',
    OkGotIt: 'Ok, compris',
    about: 'À propos',
    accepted: 'Accepté',
    account: 'Account',
    actions: 'Actions',
    add: 'Ajouter',
    addAPhotoOfTheCertificateOfAuthenticity: "Ajouter photo du certificat d'authenticité",
    addAPhotoOfTheIngot: 'Ajouter une photo du lingot',
    addASpouse: 'Ajouter un conjoint',
    addAnAllowance: 'Ajouter une allocation',
    addCommission: 'Ajouter taux de commission',
    addCommunication: 'Ajouter communication',
    addDocument: 'Ajouter document',
    addFile: 'Ajouter le fichier',
    addHeritageValue: 'Ajouter une origine de fonds',
    addImage: 'Ajouter une image',
    addImageOrDragAndDrop: 'Ajouter une image, ou glisser-déposer',
    addIngot: 'Ajouter un lingot',
    addIngotType: 'Ajouter type de lingot',
    addIntermediaries: 'Add intermédiaires',
    addIntermediary: 'Ajouter un intermédiaire',
    addInvestment: 'Ajouter investissement',
    addNewInvestment: 'Ajouter un nouvel investissement',
    addNotary: 'Ajouter un notaire',
    addPaymentPlan: 'Ajouter une échéance de paiement',
    addPurity: 'Ajouter pureté',
    addRefiner: 'Ajouter affineur',
    addSecurityCompany: 'Ajouter une société de sécurité',
    addSpouse: 'Ajouter conjoint',
    updateSpouse: 'Mise à jour du conjoint',
    addUser: 'Ajouter utilisateur',
    addWeight: 'Ajouter poids',
    additionalInformation: 'Informations Complémentaires',
    address: 'Adresse',
    admin: 'Administrateur',
    advancePaymentIsRequired: 'Un paiement anticipé est requis',
    advancePaymentOfAuthorizedInstallments: 'Paiement anticipé des échéances autorisées',
    allInLicense: 'Licence globale',
    allocationDetails: "Détails de l'allocation",
    allocations: 'Liste des lingots du Client achetés par S.G.I.',
    allowanceValue: "Valeur de l'allocation",
    allowedFileTypes: 'Types de fichiers autorisés : png, jpg, jpeg.',
    alreadyHaveAnCccount: 'Avez-vous déjà un compte ?',
    amount: 'Montant',
    annual: 'Annuelle',
    annualContractualInstallment: 'Acompte annuel contractuel',
    annualRecurringCommission: 'Commission récurrente annuelle',
    ofAnnualRecurringCommission: 'de commission récurrente annuelle pour',
    areRequired: 'sont requis',
    areYouSureWantToDeleteThisDocument: 'Etes-vous sûr de vouloir supprimer ce document?',
    areYouSureWantToDeleteThisFinancialStatement: 'Êtes-vous sûr de vouloir supprimer cet état financier ?',
    areYouSureWantToDeleteThisGeneralInformation: 'Êtes-vous sûr de vouloir supprimer ces informations générales?',
    areYouSureWantToDeleteThisHeritage: 'Êtes-vous sûr de vouloir supprimer cette origine des fonds?',
    areYouSureWantToDeleteThisIntermediary: 'Etes-vous sûr de vouloir supprimer cet intermédiaire?',
    areYouSureWantToDeleteThisPurity: 'Etes-vous sûr de vouloir supprimer cette pureté?',
    areYouSureWantToDeleteThisRate: 'Êtes-vous sûr de vouloir supprimer ce taux de commission?',
    areYouSureWantToDeleteThisRefiner: 'Voulez-vous vraiment supprimer cet affineur?',
    areYouSureWantToDeleteThisSecurityCompany: 'Êtes-vous sûr de vouloir supprimer cette société de sécurité?',
    areYouSureWantToDeleteThisTypeIngot: 'Etes-vous sûr de vouloir supprimer ce type de lingot?',
    areYouSureWantToDeleteThisWeight: 'Etes-vous sûr de vouloir supprimer ce poids?',
    areYouSureYouWanToDeleteThisIngot: 'Etes-vous sûr de vouloir supprimer ce lingot?',
    areYouSureYouWantToCancel: 'Etes-vous sûr de vouloir annuler?',
    areYouSureYouWantToDeleteThisCommunication: 'Êtes-vous sûr de vouloir supprimer cette communication?',
    areYouSureYouWantToDeleteThisInvestment: 'Êtes-vous sûr de vouloir supprimer cet investissement?',
    areYouSureYouWantToDeleteThisNotary: 'Etes-vous sûr de vouloir supprimer ce notaire?',
    areYouSureYouWantToDeleteThisWithdrawalRequest: 'Êtes-vous sûr de vouloir supprimer cette demande de retrait?',
    areYouSureYouWantToDeleteThisClient: 'Êtes-vous sûr de vouloir supprimer ce client?',
    areYouSureYouWantToPermanentlyDeleteThisClient: 'Êtes-vous sûr de vouloir supprimer définitivement ce client ?',
    asc: 'Croissant',
    auditorReport: 'Rapport Organe de révision',
    auditorsAttestation: 'Attestation Organe de Révision',
    authenticAct: 'Acte authentique par-devant notaire',
    authenticDeedsSignedInFrontOf: 'Actes authentiques signés par-devant Me',
    available: 'Disponible',
    avatar: 'Avatar',
    balanceSheetAndIncomeStatement: 'Bilan et compte de résultat',
    bankInformation: 'Coordonnées bancaires',
    bankStatementConfirmingExistenceOfFunds: 'Preuve de fonds',
    bicSwift: 'BIC / SWIFT',
    businessNumber: "Numéro d'entreprise",
    button: 'button',
    buyNow: 'Achetez maintenant',
    cancel: 'Annuler',
    capitalInvested: 'Capital invest',
    capitalInvestedIsRequired: 'Capital invested is required',
    certificateOfAuthenticity: 'Certificat d’authenticité',
    changeEmail: 'Change Email',
    changeImage: "Modifier l'image",
    changePassword: 'Change Password',
    city: 'Localité/Ville',
    cityIsRequired: 'Ville/Localité requise',
    client: 'Client',
    clientInformation: 'Informations client',
    clientList: 'Liste des clients',
    clientPortal: 'Portail client',
    clientView: 'Fiche client',
    clients: 'Clients',
    comingSoon: 'Bientôt disponible',
    commercialRegisterExtract: 'Extrait Registre de Commerce',
    commision: 'Taux de commission',
    commissionList: 'Liste des Taux de Commission',
    commissionRates: 'Taux de commission',
    commonSafe: 'Coffre-fort commun',
    commonSafeIsRequired: 'Le coffre-fort commun est requis',
    communication: 'Communication',
    communicationTitle: 'Titre de la communication',
    communicationView: 'Vue des communications',
    communications: 'Communications',
    company: 'Entreprise',
    companyInformation: 'Informations sur la société',
    companyName: 'Nom de la société',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirmer le mot de passe',
    contact: 'Contacter',
    continue: 'Continuer',
    correspondence: 'Correspondance',
    country: 'Pays',
    countryIsRequired: 'Le pays est requis',
    countryOfCurrentEmployer: "Pays de l'employeur actuel",
    createANewNotary: 'Créer un notaire',
    createAnAccount: 'Créer un compte',
    createClient: 'Nouveau Client',
    createCommunication: 'Créer communication',
    createFinancialStatement: 'Informations document à ajouter',
    createGeneralInformation: 'Informations document à ajouter',
    createHeritage: 'Créer une origine des fonds',
    createIngot: 'créer un lingot',
    createIntermediary: 'Création d’un intermédiaire',
    createInvestment: 'Nouvel investissement',
    createNewClient: 'Ajouter un client',
    createNotary: 'Créer notaire',
    createPurity: 'Créer pureté',
    createRate: 'Créer un taux de commission',
    createRefiner: 'Créer affineur',
    createSecurityCompany: 'Création d’une société de sécurité',
    createTypeIngot: 'Créer un type de lingot',
    createUser: 'Créer un utilisateur',
    createWeight: 'Créer poids',
    createdBy: 'Créé par',
    currentEmployer: 'Employeur actuel',
    currentPassword: 'Current Password',
    dailyPrice: 'Prix journalier (CH/KG)',
    dashboard: 'Accueil',
    date: 'Date',
    dateIsRequired: 'Date obligatoire',
    dateOfBirth: 'Date de naissance',
    dateOfDemand: 'Date de la demande',
    dateOfTheAuthenticAct: "Date de l'acte authentique",
    dates: 'Dates',
    days: 'jours',
    debtCollectionOfficeExtract: 'Extrait Office des Poursuites',
    delete: 'Supprimer',
    deletePermanently: 'Supprimer déﬁnitivement',
    deleteClient: 'Supprimer le client',
    deleteCommunication: 'Supprimer la communication',
    deleteIngot: 'Supprimer le lingot',
    deleteIntermediary: "Supprimer l'intermédiaire",
    deleteSecurityCompany: 'Supprimer',
    deleteTheRequest: 'Supprimer la demande',
    deletedClients: 'Clients supprimés',
    denied: 'Refusé',
    desc: 'Décroissant',
    description: 'Description',
    detailOfTheIngots: 'Détail des lingots',
    difference: 'Différence',
    digitalAdminPro: 'Digital Admin Pro',
    diploma: 'Diplôme',
    discard: 'Annuler',
    divorced: 'Divorcé',
    document: 'Document',
    documentAddedCuccessfully: 'Document ajouté avec succès',
    documents: 'Documents',
    doesItTakeALongTimeToLoadTheApp: "Le chargement de l'application prend-il beaucoup de temps ?",
    dueDate: 'Échéance',
    eachIngotCanOnlyBeAwardedOnce: "Chaque lingot ne peut être attribué qu'une seule fois, sélectionnez un autre lingot",
    eachIntermediaryCanOnlyBeAssignedOnce: "Chaque intermédiaire ne peut être attribué qu'une seule fois, sélectionnez un autre intermédiaire",
    edit: 'Mettre à jour',
    editAllocation: "Modifier l'allocation",
    editClient: 'Editer client',
    editClientFile: 'Editer fiche client',
    editCommunication: 'Editer communication',
    editIngot: 'Éditer le lingot',
    editIntermediary: "Editer l'intermédiaire",
    editInvestment: "Modifier l'investissement",
    editNotary: 'Mise à jour du notaire',
    editSecurityCompany: 'Mettre à jour',
    elapsedTime: 'Temps écoulé',
    email: 'Email',
    emailAddress: 'Email Address',
    emailAddressIsRequired: "L'adresse e-mail est requise",
    emailIsRequired: 'Email est requis',
    english: 'English',
    enterNewEmailAddress: 'Enter New Email Address',
    enterTheNewPasswordForTheMail: "Saisissez le nouveau mot de passe pour l'email",
    enterYourEmailToResetYourPassword: 'Entrez votre email pour réinitialiser votre mot de passe',
    error1000: 'Jeton non trouvé!',
    error1001: 'Jeton invalide!',
    error1002: "Une erreur s'est produite dans la base de données!",
    error1003: 'Un ou plusieurs champs obligatoires sont vides!',
    error1004: 'Uuid non trouvé!',
    error1005: 'Les mots de passe ne correspondent pas!',
    error1006: "Format non valide pour l'e-mail!",
    error1007: 'Mot de passe erroné!',
    error1008: 'Utilisateur non trouvé!',
    error1009: 'chaîne!',
    error1010: 'Email déjà pris!',
    error1011: 'Doit comporter au moins 8 caractères!',
    error1012: 'Mauvaise façon de commander!',
    error1013: 'La limite de tentative a été dépassée!',
    error1014: 'chaîne!',
    error1015: "Erreur lors de l'envoi de l'e-mail!",
    error1016: 'Le client a déjà été supprimé!',
    error1017: 'Ajouter un document!',
    error1018: 'Le document a déjà été supprimé!',
    error1019: "Le document n'appartient pas au client!",
    error1020: 'Seul 1 document de ce type est autorisé!',
    error1021: 'Chaîne!',
    error1022: 'Chaîne!',
    error1023: 'Nombre maximal de documents pour ce type!',
    error1024: "La communication n'appartient pas au client!",
    error1025: 'La communication a déjà été supprimée!',
    error1026: 'Le type de document est incorrect!',
    error1027: 'Le notaire a déjà été supprimé!',
    error1028: 'Pureté déjà enregistrée!',
    error1029: 'Raffineur déjà enregistré!',
    error1030: 'Poids déjà enregistré!',
    error1031: 'Pureté non enregistrée!',
    error1032: 'Raffineur non enregistré!',
    error1033: 'Poids non enregistré!',
    error1034: 'Lingot déjà enregistré!',
    error1035: 'Le lingot a déjà été supprimé!',
    error1036: 'Commission déjà enregistrée!',
    error1037: "L'investissement a déjà été supprimé!",
    error1038: 'Notaire non enregistré!',
    error1039: 'Société de sécurité non enregistrée!',
    error1040: 'Un ou plusieurs lingots incorrects!',
    error1041: 'Le type de retrait est incorrect!',
    error1042: 'Le statut de retrait est incorrect!',
    error1043: 'Client non trouvé!',
    error1044: 'Investissement non trouvé!',
    error1045: 'Le lingot est attribué à un investissement!',
    error1046: "Le lingot n'est pas disponible!",
    error1047: 'Retrait non trouvé!',
    error1048: 'La société de sécurité a déjà été supprimée!',
    error1049: "L'intermédiaire a déjà été supprimé!",
    error1050: 'Le raffineur a déjà été supprimé!',
    error1051: 'Le poids a déjà été supprimé!',
    error1052: 'La pureté a déjà été supprimée!',
    error1053: 'La commission a déjà été supprimée!',
    error1054: "L'origine du patrimoine a déjà été supprimée!",
    error1055: 'Une ou plusieurs commissions incorrectes!',
    error1056: 'Un ou plusieurs intermédiaires incorrects!',
    error1057: 'Le type de lingot a déjà été supprimé!',
    error1058: 'Le type de lingot est déjà enregistré!',
    error1059: 'Un ou plusieurs lingots ne sont pas disponibles',
    error1060: 'Coffre-fort non enregistré',
    error1061: 'Le mot de passe réel est incorrect',
    error1062: "Un ou plusieurs clients n'ont pas été trouvés",
    error1063: "Format invalide pour l'IBAN",
    error1064: 'Format invalide pour Swift/Bic',
    error1065: 'Format invalide pour le mot de passe',
    error1066: 'La demande de retrait a déjà été supprimée',
    error1067: 'Mois incorrect',
    error1068: 'Sélectionnez une ou plusieurs options pour la demande de retrait',
    error1069: "Un ou plusieurs investissements n'ont pas été trouvés",
    error1070: 'Type de données incorrect',
    error1071: 'Le montant doit être inférieur',
    error1072: 'Solde insuffisant',
    error1073: 'Le client a déjà été supprimé',
    error1074: "Le nombre d'années est incorrect",
    error1075: "Le type de rôle n'existe pas",
    error1076: "Le type de commission n'existe pas",
    error1077: "L'utilisateur a atteint ou dépassé le nombre mensuel de demandes d'API autorisé pour son plan d'abonnement",
    errorOccurredInDatabase: "Une erreur s'est produite dans la base de données!",
    execution: 'Exécution',
    executionScheduledFor: 'Execution prévue le',
    executiveTitle: 'Titre exécutoire original',
    expirationOfTheRightOfRefusal: "Échéance du droit d'emption",
    extraInformation: 'Informations complémentaires',
    extractProsecutionOffice: 'Extrait Office des poursuites',
    fieldOfActivity: 'Domaine d’activité',
    fieldsMarkedWithAnAsterisk: "Champs marqués d'un astérisque",
    file: 'Fichier',
    fileSizeNotAllowed: "La taille des fichiers n'est pas autorisée ! Taille maximale du fichier : 1000 KB",
    files: 'Fichiers',
    filterBy: 'Filtrer par',
    financialCommitments: 'Engagements financiers',
    financialStatements: 'États financiers',
    finma: 'Attestation Affiliation OAR (FINMA)',
    firstName: 'Prénom',
    firstNameAndLastNameFather: 'Nom(s) et prénom(s) du père',
    firstNameIsRequired: 'Le prénom est obligatoire',
    for: 'pour',
    forgotPassword: 'Mot de passe oublié?',
    formA: 'Formulaire A',
    taxComplianceCertificate: 'Attestation conformité fiscale',
    fortuneIncludingRealEstate: 'Fortune nette (dont biens immobiliers)',
    fortuneIsRequired: 'Fortune is requise',
    french: 'Français',
    fullName: 'Nom et prénom',
    futuresSellingPriceOfGold: "Prix de vente à terme de l’or par S.G.I. suivant opération back-to-back (CHF)",
    generalInformations: 'Informations générales',
    goToLoginPage: 'Aller à la page de connexion',
    goToPreviousPage: 'Aller à la page précédente',
    goldFuturesPurchasePrice: "Prix d'achat à terme de l'or du client par S.G.I (CHF)",
    goldPriceLockDate: "Date de blocage du cours de l'or",
    goldPrices: "Cours de l'or",
    gram: 'Gramme',
    grams: 'Grammes',
    heritageValues: 'Origine des fonds',
    heritages: 'Patrimoine',
    iAgree: " J'accepte &",
    iban: 'IBAN',
    identification: "Pièce d'identité",
    identity: 'Identité',
    ifYouAcceptTheChangesMadeWillNotBeUpdated: 'Si vous acceptez, les modifications apportées ne seront pas mises à jour',
    ifYouAcceptYouMayHaveToFillOutTheFormAgain: 'Si vous acceptez, toutes les données du formulaire seront supprimées',
    ifYouWantToTakeOverTheTpouseTata: 'Si vous souhaitez prendre en charge les données du conjoint, vous pouvez cliquer sur le bouton',
    incomeOrPensionReceivedForAnYear: 'Montant du revenu perçu par an',
    information: 'Informations',
    ingot: 'Lingot',
    ingotTypeList: 'Liste des Types de Lingots',
    ingotTypes: 'Types de lingots',
    ingotUpdate: 'lingot de mise à niveau',
    ingotView: 'Vue lingot',
    ingotWeight: 'Poids',
    ingots: 'Lingots',
    interests: 'Intérêts',
    intermediaries: 'Intermédiaires',
    intermediary: 'Intermédiaire',
    intermediaryCommission: 'Commission intermédiaire (CHF)',
    invalidFileType: 'Type de fichier non valide ! Types de fichiers autorisés : jpeg, jpg ou png',
    investment: 'Investissement',
    investmentAmount: "Investissement en or réalisé par les clients",
    investmentView: "Vue d'investissement",
    investments: 'Investissements',
    kilogram: 'Kilogramme',
    kilograms: 'Kilogrammes',
    kyc: 'KYC',
    language: 'Langue',
    languages: 'Langues',
    lastName: 'Nom de famille',
    lastNameIsRequired: 'Le nom de famille est obligatoire',
    lastTaxDeclaration: 'Dernière déclaration fiscale',
    lastTaxNotice: "Dernier avis d'imposition",
    less: 'Moins',
    licenseTerms: 'Conditions de licence',
    listCommunications: 'Liste des communications',
    listOfHeritageValues: 'Liste des Origine des Fonds',
    listOfIngots: 'Liste des lingots',
    listOfIntermediaries: 'Liste des intermédiaires',
    loading: 'Chargement',
    logo: 'Logo',
    maidenNameMother: 'Nom(s) de jeune fille de la mère',
    makeTheDocumentAvailableOnTheClientPortal: 'Rendre le document disponible sur le portail du client',
    maritalStatus: 'État civil',
    maritalStatusIsRequired: "L'état civil est requis",
    married: 'Marié',
    maximum1kCharacters: '1000 caractères maximum',
    maximumFileFormat10MBPerFile: 'Format de fichier maximum 10 Mb par fichier',
    memberIsRequired: 'Le membre est requis',
    memberOfTheBoardOfDirectors: 'Membre du C.A., de la Direction ou toute autre position d’une société cotée',
    message: 'Message',
    messageIsRequired: 'un message est requis',
    messageWillBeSentYourEmailToRecoverAccount: 'Un message vous sera envoyé par email pour récupérer votre compte, veuillez vérifier votre e-mail. Vous serez redirigé vers la page de connexion.',
    million: "million",
    millions: "millions",
    missingValueOfAllocations: 'Valeur manquante des allocations',
    modify: 'Modifier',
    moneyBackGuarantee: 'Garantie de remboursement à 100 %!',
    myProfile: 'Mon profil',
    name: 'Nom',
    nationality: 'Nationalité',
    nationalityIsRequired: 'La nationalité est requise',
    newPassword: 'New Password',
    newServicesWithinTheSameApp: "Nouveaux services au sein d'une même application.",
    no: 'Non',
    noAvailable: 'Non disponible',
    noCancel: 'Non, annuler',
    noMatchingRecordsFound: 'Aucun enregistrement correspondant trouvé',
    noResultFound: 'Aucun résultat trouvé',
    noResultsFound: 'Aucun résultat trouvé',
    noSecurityCompanySelected: 'Aucune société de sécurité sélectionnée',
    nonEsxistOptions: 'Options inexistantes',
    none: 'Aucun',
    noneIngots: 'Aucun lingots',
    notaries: 'Notaires',
    notary: 'Notaire',
    notaryIsRequired: 'un notaire est requis',
    notaryList: 'Liste des Notaires',
    notarySignature: 'Signature notaire (Date)',
    notaryView: 'Notaire ',
    nowYouCanLogIn: 'Vous pouvez maintenant vous connecter avec le nouveau compte créé, vous serez redirigé vers la page de connexion.',
    numberOfIngots: 'Nombre de lingots',
    numberOfParts: 'Nombre de parts',
    ofPortfolio: 'du portefeuille',
    oneOrMoreIngotsHaveNotBeenSelected: "Une ou plusieurs barres n'ont pas été sélectionnées",
    oneOrMoreIntermediariesOrCommissionsHaveNotBeenSelected: "Un ou plusieurs intermédiaires ou commissions n'ont pas été sélectionnés",
    open: 'Ouvert',
    options: 'options',
    originOfHeritageIsRequired: "L'origine du patrimoine est requise",
    originOfHeritageValues: 'Origine des valeurs patrimoniales',
    originalPriceOfGold: "Cours original de l’or (CHF/Kg)",
    originalValuation: 'Valorisation originale',
    originalValuationOfGold: 'Valorisation originale de l’or',
    originalValuationOfGoldPurchased: "Valorisation originale de l’or acheté par S.G.I (CHF)",
    originalValue: 'Valeur originale',
    originalValueIsRequired: "La valeur d'origine est requise",
    other: 'Veuf',
    overview: 'Aperçu',
    pageInProduction: "Page en production, vous pourrez très bientôt accéder à de nouvelles fonctionnalités au sein de l'application.",
    pageNotFound: 'Page introuvable',
    parameters: 'Paramètres',
    password: 'Mot de passe',
    payment: 'Paiement',
    paymentPlan: 'Plan de paiement',
    pensionPerYearIsRequired: 'Revenu ou pension perçue par an is requise',
    pepClose: 'PEP/Proche de PEP',
    permanently: 'en permanence',
    personalSafe: 'Coffre-fort personnel',
    phone: 'Téléphone',
    phoneNumber: 'Numéro de téléphone',
    phoneNumberIsRequired: 'Le numéro de téléphone est requis',
    photoOfTheCertificateOfAuthenticity: "Photo du certificat d'authenticité",
    photoOfTheIngot: 'Photo du lingot',
    placeOfBirth: 'Lieu de naissance',
    pleaseCheckYourEmailInbox: 'Please check your email inbox.',
    pleaseCorrectThePathOfThePageInOrderToAccess: "Veuillez corriger le chemin de la page afin d'accéder à l'application.",
    pleaseEnterTheFollowingInformationToCreateANewIntermedary: 'Veuillez saisir les informations suivantes pour créer un nouvel intermédiaire',
    pleaseEnterTheFollowingInformationToCreateANewSecurityCompany: 'Veuillez saisir les informations suivantes pour créer une nouvelle société de sécurité',
    pleaseEnterTheFollowingInformationToCreateNewClient: 'Veuillez saisir les informations nécessaires à la creation de la fiche client',
    pleaseEnterTheFollowingInformationToUpdateASecurityCompany: 'Veuillez saisir les informations nécessaires  à la mise  à jour de la société de sécurité',
    pleaseEnterTheFollowingInformationToUpdateAnIntermedary: 'Veuillez saisir les informations suivantes pour mettre à jour un intermédiaire',
    pleaseEnterTheInformationYouWantToUpdate: 'Veuillez saisir les informations que vous souhaitez mettre à jour',
    pleaseTryAgainLater: 'Veuillez réessayer plus tard.',
    pleaseWait: 'Veuillez patienter',
    postcode: 'Code postal',
    presentValue: 'Valeur actualisée',
    previousEmployer: 'Précédent employeur ',
    previousPage: 'Page précédente',
    previousProfessionalActivity: 'Fonction précédente',
    professionalActivity: 'Fonction actuelle',
    professionalLiabilityInsurance: 'Assurance RC Professionnelle',
    profileCompleation: 'Profile Compleation',
    profileDetails: "Profil de l'utilisateur",
    profitMargin: 'Marge bénéficiaire pour S.G.I. (CHF)',
    profitMarginForTheCustomer: 'Marge bénéficiaire pour le client (CHF)',
    purchase: 'Achetez',
    purchasePriceOfGold: 'Prix d’achat de l’or par S.G.I',
    purchasePro: 'Achetez Digital Admin Pro',
    purchaseGold: 'Facture d’achat Or Physique',
    purities: 'Puretés',
    purity: 'Pureté',
    purityList: 'Liste des Puretés',
    quantityOfGoldPurchased: "Quantité d'or acheté",
    redirectingToLogin: 'Redirection vers la page de connexion..',
    reference: 'Référence',
    refill: 'Remplir',
    refiner: 'Affineur',
    refinerList: 'Liste des Affineurs',
    refiners: 'Affineurs',
    registeredPartnership: 'Partenariat enregistré',
    reinvestment: 'Réinvestissement',
    reload: 'Recharge',
    remainingSharesOfTheOngot: 'Parts restantes du lingot',
    remainingTime: 'Temps restant',
    removeAll: 'Supprimer tout',
    removeImage: "Supprimer l'image",
    removeLogo: 'Supprimer le logo',
    requestDate: 'Date demande',
    requestFrom: 'Demande du',
    required: 'Requis',
    resetPassword: 'Reset Password',
    return: 'Retour',
    returningToTheWithdrawalRequestPage: 'Retour à la page de demande de retrait',
    role: 'Rôle',
    safeNumber: 'Numéro du coffre-fort',
    safes: 'Coffres-forts',
    salePriceInSgi: 'Prix de vente À S.G.I',
    save: 'Sauvegarder',
    saveChanges: 'Enregistrer les modifications',
    search: 'Rechercher',
    searchClient: 'Recherche de client',
    searchTestClient: 'Rechercher un client test',
    securedPaymentBy: 'Paiement sécurisé par',
    security: 'Sociétés de sécurité',
    securityCompanies: 'Sociétés de sécurité',
    securityCompany: 'Société de sécurité',
    securityCompanyList: 'Liste des Sociétés de Sécurité',
    seeMore: 'Voir plus',
    selectDocumentType: 'Sélectionner le type de document',
    selectDocuments: 'Sélectionner des documents',
    selectFromDocuments: 'Sélectionner parmi les documents',
    selectOneOption: "Sélectionnez l'une des options",
    selectOption: "Sélectionner l'option",
    selectOrDragADocument: 'Sélectionner ou glisser un document',
    selectOrDragDocuments: 'Sélectionner ou faire glisser des documents',
    serialNumber: 'No de série',
    serialNumberIsRequired: 'Le numéro de série est requis',
    settings: 'Paramètres',
    sgiGoldPriceHistory: "Historique du cours de l'or",
    shareOfIngotUsed: 'Quote-part utilisée du lingot',
    sharesOwnedBy: 'Parts possédées par',
    sharesUsed: 'Parts utilisées',
    showTestClients: 'Afficher les clients de test',
    signIn: "Se connecter à l'administrateur numérique",
    signInHere: 'Se connecter ici',
    signInMethod: 'Sign-in Method',
    signOut: 'Déconnexion',
    single: 'Célibataire',
    singleCommission: 'Commission unique',
    ofSingleCommission: 'de commission unique',
    somethingWentWrong: "Quelque chose s'est mal passé!",
    sorryLooksLikeThereAreSomeErrorsTrySolve: "Désolé, il semblerait qu'une erreur ait été détectée. Veuillez la corriger aﬁn de pouvoir sauvegarder.",
    sorryLooksLikeThereAreSomeErrorsTryAgain: "Désolé, il semble qu'une erreur ait été détectée. Veuillez réessayer.",
    sourceOfFunds: 'Origine des fonds',
    spanish: 'Español',
    splitting: 'Fractionnement',
    spouseInformation: 'Informations sur le conjoint',
    identityDocument: 'Pièce d’identité',
    identityDocumentSpouse: "Carte d'identité du cojoint",
    startDateOfBackgroundOn: 'Date de début de fonction',
    storageType: 'Type de stockage',
    street: 'Rue',
    submit: 'Soumettre',
    successful: 'Avec succès!',
    successfullyCreateFinancialStatement: 'État financier créé avec succès',
    successfullyCreatedClient: 'Client créé avec succès!',
    successfullyCreatedCommunication: 'Communication créée avec succès!',
    successfullyCreatedContact: 'Contact créé avec succès!',
    successfullyCreatedDocument: 'Document créé avec succès!',
    successfullyCreatedGeneralInformation: 'Création réussie Informations générales',
    successfullyCreatedHeritage: 'Origine des fonds créée avec succès!',
    successfullyCreatedHeritageOrigin: 'Origine du patrimoine créée avec succès!',
    successfullyCreatedIngot: 'Lingot créé avec succès!',
    successfullyCreatedIntermediary: 'Intermédiaire créé avec succès!',
    successfullyCreatedInvestment: 'Investissement créé avec succès!',
    successfullyCreatedNotary: 'Notaire créé avec succès!',
    successfullyCreatedPurity: 'Pureté créée avec succès',
    successfullyCreatedRate: 'Taux de commission créé avec succès',
    successfullyCreatedRefiner: 'Affineur créé avec succès',
    successfullyCreatedSecurityCompany: 'Société de sécurité créée avec succès!',
    successfullyCreatedTypeIngot: 'Lingot de type créé avec succès',
    successfullyCreatedUser: 'Utilisateur créé avec succès',
    successfullyCreatedWeight: 'Poids créé avec succès',
    successfullyCreatedWithdrawal: 'Retrait créé avec succès!',
    successfullyDeletedClient: 'Client supprimé avec succès!',
    successfullyDeletedCommission: 'Taux de commission supprimé avec succès!',
    successfullyDeletedCommunication: 'Communication supprimée avec succès!',
    successfullyDeletedContact: 'Suppression réussie du contact!',
    successfullyDeletedDocument: 'Document supprimé avec succès!',
    successfullyDeletedFinancialDocument: 'Document financier supprimé avec succès!',
    successfullyDeletedGeneralDocument: 'Document général supprimé avec succès!',
    successfullyDeletedHeritageOrigin: 'Origine des fonds supprimée avec succès!',
    successfullyDeletedIngot: 'Lingot supprimé avec succès!',
    successfullyDeletedIntermediary: "Suppression réussie de l'intermédiaire!",
    successfullyDeletedInvestment: 'Investissement supprimé avec succès!',
    successfullyDeletedNotary: 'Notaire supprimé avec succès!',
    successfullyDeletedPurity: 'Pureté supprimée avec succès!',
    successfullyDeletedRefiner: 'Affineur supprimé avec succès!',
    successfullyDeletedSecurityCompany: 'Société de sécurité supprimée avec succès!',
    successfullyDeletedTypeIngot: 'Type de lingot supprimé avec succès!',
    successfullyDeletedWeight: 'Poids supprimé avec succès!',
    successfullyDeletedWithdrawal: 'Retrait supprimé avec succès!',
    successfullyLoggedIn: 'Vous vous êtes connecté avec succès!',
    successfullyMailSent: 'Successfully mail sent',
    successfullyUpdatedAccount: 'Successfully updated account',
    successfullyUpdatedClient: 'Client mis à jour avec succès!',
    successfullyUpdatedCommunication: 'Communication mise à jour avec succès!',
    successfullyUpdatedContact: 'Le contact a été mis à jour avec succès!',
    successfullyUpdatedDocument: 'Document mis à jour avec succès!',
    successfullyUpdatedFinancialStatement: 'Successfully updated financial statement',
    successfullyUpdatedGeneralInformation: 'Mettre à jour avec succès les informations générales',
    successfullyUpdatedHeritage: 'Origine des fonds mise à jour avec succès',
    successfullyUpdatedIngot: 'Lingot mis à jour avec succès!',
    successfullyUpdatedIntermediary: 'Intermédiare mis à jour avec succès!',
    successfullyUpdatedInvestment: 'Investissement mis à jour avec succès!',
    successfullyUpdatedNotary: 'Notaire mis à jour avec succès!',
    successfullyUpdatedPassword: 'Mise à jour du mot de passe réussie!',
    successfullyUpdatedPurity: 'Pureté mise à jour avec succès',
    successfullyUpdatedRate: 'Taux de commission mis à jour avec succès',
    successfullyUpdatedRefiner: 'Affineur mis à jour avec succès',
    successfullyUpdatedSecurityCompany: 'Entreprise de sécurité mise à jour avec succès!',
    successfullyUpdatedTypeIngot: 'Type de lingot mis à jour avec succès',
    successfullyUpdatedUser: 'Utilisateur modiﬁé avec succès',
    successfullyUpdatedWeight: 'Poids mis à jour avec succès',
    successfullyUpdatedWithdrawalRequestStatus: 'Statut de la demande de retrait mis à jour avec succès',
    successfullyUpdatedWithdrawalStatus: 'Statut de retrait mis à jour avec succès!',
    successfullyUpdatingCommunication: 'Mettre à jour avec succès la communication',
    support: 'Support',
    swissGlobalInvestment: 'Swiss Global Investment SA',
    systemError: 'Erreur système',
    taxpayerNumber: 'Numéro de contribuable NIF (NIF)',
    termsAndConditions: "Conditions d'utilisation.",
    testClient: 'Client test',
    testClientList: 'Liste des clients de test',
    virtualClients: 'Clients virtuels',
    theAppWillBeTranslated: 'The app will be translated into indicated language, except for  information entered in documents.',
    theFileMustBeInImageFormat: 'Le fichier doit être au format .png, .jpg, .jpeg',
    thePageYouLookedNotFound: 'La page que vous avez recherchée est introuvable!',
    thePasswordConfirmationIsRequired: 'La confirmation du mot de passe est requise',
    thePasswordIsAtLeast8Digits: 'Le mot de passe doit au moins contenir 8 chiffres',
    thePasswordIsRequired: 'Le mot de passe est requis',
    thePasswordsAreNotSame: 'Le mot de passe et sa confirmation ne sont pas les mêmes',
    theValueIsNotAValidAdress: "La valeur n'est pas une adresse valide",
    theValueIsNotAValidAnnualContractualInstallment: "La valeur n'est pas un versement contractuel annuel valide",
    theValueIsNotAValidBIC: "La valeur n'est pas un BIC / SWIFT valide",
    theValueIsNotAValidCapitalInvested: 'The value is not a valid capital invested',
    theValueIsNotAValidCity: "La valeur n'est pas une ville valide",
    theValueIsNotAValidCommunicationTitle: "La valeur n'est pas un titre de communication valide",
    theValueIsNotAValidCompanyName: "La valeur n'est pas un nom de société valide",
    theValueIsNotAValidCountry: "La valeur n'est pas un pays valide",
    theValueIsNotAValidCurrentEmployer: "La valeur n'est pas un employeur actuel valide",
    theValueIsNotAValidDiploma: "La valeur n'est pas un diplôme valide",
    theValueIsNotAValidEmail: "La valeur n'est pas un email valide",
    theValueIsNotAValidEmailAddress: "La valeur n'est pas une adresse e-mail valide",
    theValueIsNotAValidFieldOfActivity: "La valeur n'est pas un domaine d'activité valide",
    theValueIsNotAValidFirstAndLastNameFather: "La valeur n'est pas un prénom et un nom valides du père",
    theValueIsNotAValidFirstName: "La valeur n'est pas un prénom valide",
    theValueIsNotAValidGoldFuturesPurchasePrice: "La valeur n'est pas un prix d'achat à terme sur l'or valide",
    theValueIsNotAValidGoldPrices: "La valeur n'est pas un prix de l'or valide",
    theValueIsNotAValidIBAN: "La valeur n'est pas une IBAN valide",
    theValueIsNotAValidLastName: "La valeur n'est pas un nom de famille valide",
    theValueIsNotAValidMaidenMotherName: "La valeur n'est pas un nom de jeune fille valide",
    theValueIsNotAValidMessage: "La valeur n'est pas un message valide",
    theValueIsNotAValidNationality: "La valeur n'est pas une nationalité valide",
    theValueIsNotAValidOriginalValuationOfPurchasedGold: "La valeur n'est pas une valeur d'expertise en or valide",
    theValueIsNotAValidPassword: "La valeur n'est pas un mot de passe valide",
    theValueIsNotAValidPep: "La valeur n'est pas un PEP/Close valide",
    theValueIsNotAValidPhoneNumber: "La valeur n'est pas un numéro de téléphone valide",
    theValueIsNotAValidPlaceOfBirth: "La valeur n'est pas un lieu de naissance valide",
    theValueIsNotAValidPreviousEmployer: "La valeur n'est pas un employeur précédent valide",
    theValueIsNotAValidPreviousProfessionalActivity: "La valeur n'est pas une activité professionnelle antérieure valable",
    theValueIsNotAValidProfesionalActivity: "La valeur n'est pas une activité professionnelle valide",
    theValueIsNotAValidReference: "La valeur n'est pas une référence valide",
    theValueIsNotAValidSafeNumber: "La valeur n'est pas un numéro sûr valide",
    theValueIsNotAValidSerialNumber: "La valeur n'est pas un numéro de série valide",
    theValueIsNotAValidSourceOfFunds: "La valeur n'est pas une source de fonds valide",
    theValueIsNotAValidTaxpayer: "La valeur n'est pas un contribuable valide",
    tin: 'TIN',
    title: 'Titre',
    titleIsRequired: 'le titre est requis',
    to: "à",
    totalAmountUpdatedFinancialCommitments: 'Montant total actualisé des engagements ﬁnanciers de S.G.I.',
    totalAmountInvestmentClients: 'Valorisation originale de l’or acheté par S.G.I.',
    totalValueOfShares: 'Valeur totale des parts',
    totalVolumePhysicalGold: "Volume total d'or physique",
    type: 'Type',
    typeIsrequired: 'Le type est requis',
    typeOfAllocation: "Type d'allocation",
    typeOfAllocationIsRequired: "Le type d'allocation est requis",
    undefined: 'Indéfini!',
    unique: 'Uniquement',
    unit: 'Unité',
    updateDocument: 'Mettre à jour le document',
    updateEmail: 'Update Email',
    updateFinancialStatement: 'Mettre à jour les informations document',
    updateGeneralInformation: 'Mettre à jour les informations document',
    updateHeritage: "Mise à jour de l'origine des founds",
    updateIngot: 'lingot mise à jour',
    updateIntermediary: 'Mise à jour intermédiaire',
    updatePassword: 'Update Password',
    updatePurity: 'Mise à jour de la pureté',
    updateRate: 'Mise à jour du taux de commission',
    updateRefiner: "Mise à jour de l'affineur",
    updateSecurityCompany: 'Mise à jour de la société de sécurité ',
    updateTypeIngot: 'Mettre à jour du type de lingot',
    updateUser: "Mise à jour de l'utilisateur",
    updateWeight: 'Mise à jour du poids',
    updatedInvestment: "Mettre à jour l'investissement",
    updatedInvestmentListReturToPage: "Mise à jour de la liste d'investissement retour à la page!",
    updatedValuationOfGoldPurchased: "Valorisation actualisée de l’or acheté par S.G.I.",
    updatingAccountEmailAddress: 'Updating account email address, returning to page',
    updatingAccountPassword: 'Updating account password, returning to page',
    updatingCommunicationList: 'Mise à jour de la liste des communications',
    updatingCommunicationListReturningToPage: 'Mise à jour de la liste de communications, retour à la page',
    updatingDocumentListReturningToPage: 'Mise à jour de la liste des documents en cours',
    updatingFinancialStatementListReturningToPage: 'Mise à jour de la liste des états financiers, retour à la page',
    updatingGeneralInformationReturningToPage: 'Mise à jour des informations générales, retour à la page',
    updatingIngotListReturningToPage: 'Updating ingot list, returning to page',
    updatingIngotReturningToPage: 'Mise à jour du lingot, retour à la page',
    updatingIntermediaryListReturningToPage: 'Mise à jour de la liste des intermédiaires, retour à la page',
    updatingInvestmentList: "Mise à jour de la liste d'investissement",
    updatingList: 'Mise à jour de la liste en cours',
    updatingNotarieListReturningToPage: 'Mise à jour de la liste des notaires en cours',
    updatingNotaryListReturningToPage: 'Mise à jour de la liste des notaires en cours',
    updatingProfileDetails: 'Updating profile details, returning to page.',
    updatingTheListOfBarsBackToPage: 'Mise à jour de la liste des lingots retour à la page',
    updatingTypesIngotListReturningToPage: 'Mise à jour de la liste des lingots de types, retour à la page',
    updatingUserListReturningToPage: 'Mise à jour de la liste des utilisateurs, retour à la page',
    updatingWithdrawalRequestList: 'Mise à jour de la liste des demandes de retrait',
    use8OrMoreCharacters: 'Utilisez 8 caractères ou plus avec un mélange de lettres (minuscules et majuscules), de chiffres et de symboles.',
    useOfDeposits: 'Utilisation des dépôts',
    useOfDepositsIsRequired: "L'utilisation des dépôts est obligatoire",
    user: 'Utilisateur',
    userList: 'Liste des Utilisateurs',
    userProfile: 'Profil utilisateur',
    users: 'Utilisateurs',
    utilityBill: "Preuve d'adresse",
    valid: 'valide',
    validateAccount: 'Validate account',
    validatedAccountForADifferentUser: 'Validated account for a different user than the one you are logged in, redirecting to login page.',
    validatedAccountForLoggedInUser: 'Validated account for logged in user, redirect to main page.',
    validatedVerifiedForAUserWhoHasNotLoggedIn: 'Validated verified for a user who has not logged in, redirecting to login page.',
    validityOfTheRightOfRefusal: 'Validité du droit d’emption',
    validityOfTheRightOfRefusalIsRequired: 'La validité du droit de refus est requise',
    value: 'Valeur',
    valueAtTimeOfSale: 'Valeur au moment de la vente',
    valueIsNotAValidGoldFuturesPrice: "La valeur n'est pas un prix à terme valide sur l'or",
    valueIsNotAValidOrginalValue: "la valeur n'est pas une valeur originale valide",
    valueIsNotAValidVolumeOfGoldPurchased: "La valeur n'est pas un volume valide d'or acheté",
    valueOfAShare: "Valeur d'une part",
    valueOfAllocations: 'Valeur originale des lingots du client',
    verificationYourAccount: 'Verification Your Account',
    viewClientList: 'Afficher la liste des clients',
    viewCommunications: 'Voir les communications',
    viewIngots: 'Voir les lingots',
    viewIntermediaries: 'Voir les intermédiaires',
    viewIntermediary: "Fiche intermédiaire",
    viewNotaryList: 'Voir liste des notaires',
    viewSecurityCompanies: 'Voir les sociétés de sécurité',
    viewSecurityCompany: 'Société de sécurité ',
    viewUser: "Afficher l'utilisateur",
    viewWithdrawalsRequests: 'Afficher les demandes de retrait',
    volumeOfGoldPurchased: "Volume d’or acheté par S.G.I. (Kg)",
    volumeOfGoldSold: 'Volume d’or vendu (Kg)',
    volumeOfPhysicalGold: 'Volume total d’or physique acheté à terme par S.G.I. en back-to-back',
    waitAMomentToVerifyYourAccess: 'attendez un moment pour vérifier votre accès.',
    website: 'Site web',
    weight: 'Poids',
    weightList: 'Liste des Poids',
    weights: 'Poids',
    willNotBeAbleToReturnUntilYouLogin: 'Vous ne pourrez pas revenir tant que vous ne vous reconnecterez pas',
    with: 'avec',
    withdrawalAmount: 'Montant du retraits',
    withdrawalDemandView: 'Vue de la demande de retrait',
    withdrawalRequest: 'Demande de retrait',
    withdrawalRequestList: 'Liste des demandes de retrait',
    withdrawalRequests: 'Demandes de retrait',
    withdrawalStatus: 'Status du retrait',
    withdrawals: 'Retraits',
    years: 'Ans',
    yes: 'Oui',
    yesDelete: 'Oui, supprimer',
    yesLogOut: 'Oui, confirmer',
    youAreWishingToEnterWithTheFollowingEmailAddress: "Vous souhaitez participer avec l'adresse électronique suivante:",
    youHaveSuccessfullyResetYourPassword: 'Vous avez réinitialisé votre mot de passe avec succès, vous pouvez maintenant vous connecter à nouveau. Vous serez redirigé vers la page de connexion.',
    youHaveSuccessfullySignedOut: 'Vous vous êtes déconnecté avec succès!',
    youMustAcceptTheTermsAndConditions: 'Vous devez accepter les termes et conditions',
    youWantLogOut: 'Souhaitez-vous vous déconnecter?',
    youWillNotBeAbleToUndo: 'Vous ne pourrez pas annuler cette action.',
    yourAccountIsNotVerified: 'Your account is not verified. Please validate your account through your email address, by clicking',
    yourSessionHasBeenClosed: 'Votre session a été clôturée, veuillez vous inscrire!',
    yourSessionHasExpired: 'Votre session a expiré, veuillez vous réinscrire!',
    yourSessionIsActiveCongratulations: 'Votre session est active, félicitations!',
    zipCodeIsRequired: 'Le code postal requis',
    treeStructure: 'Arborescence',
    communicatedTo: 'Transmis à',
    database: 'Database',
    modifyDatabase: 'Modiﬁer en database',
    countryResidence: 'Pays de résidence',
    residentialAddress: 'Adresse de résidence',
    professionalActivityInformation: 'Informations activité professionnelle',
    originAndDeterminationAssetValues: 'Origine et détermination des valeurs patrimoniales',
    currentValuation: 'Valorisation actuelle',
    price: 'Prix',

  }
}