import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import moment, { Moment } from 'moment';
import format from 'date-fns/format';

import { UserService } from '../../services/user.service';
import { ApiGold } from '../../services/api.gold';

import { Error } from '../../models/error.model';
import { UserModel } from '../../models/user.model';
import { GoldPriceHistoryModel } from '../../models/gold.price.history.model';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { authUserLoggedIn } from '../../scripts/auth.user.script';

import AppNavbar from '../../components/Navbar';
import AppHeader from '../../components/Header';
import AppTitle from '../../components/Title';
import AppFooter from '../../components/Footer';
import AppScroll from '../../components/Scroll';
import AppOverlay from '../../components/Overlay';

import AppDropdownUserProfile from '../../components/dropdown/DropdownUserProfile';

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string | null, branch: string | null} | null, browse: {target: string, link: string} | null} | null>>,
  userLoggedIn: UserModel | undefined | null,
  goldPrice: number | undefined | null,
  goldPriceHistory: GoldPriceHistoryModel | undefined | null
};

export interface MainPageProps {
  userLoggedIn: UserModel | undefined | null,
  setUserLoggedIn: Dispatch<SetStateAction<UserModel | undefined | null>>
};

const MainPage: React.FunctionComponent<MainPageProps> = ({userLoggedIn, setUserLoggedIn}) => {
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [scrollVisibility, setScrollVisibility] = useState({header: 'off', top: 'off'})
  const [activeDrawer, setActiveDrawer] = useState({value: 'off', aside: 'off'})
  const [route, setRoute] = useState<{main: boolean, path: {root: string | null, branch: string | null} | null, browse: {target: string, link: string} | null} | null>(null)
  const [goldPrice, setGoldPrice] = useState<number | undefined | null>(null)
  const [goldPriceHistory, setGoldPriceHistory] = useState<GoldPriceHistoryModel | undefined | null>(null)

  const loadUserLoggedIn = async () => {
    await UserService.getUser().then( (response) => {
      if (response.status === 200) {
        let userResponse: UserModel = response.data
        setUserLoggedIn(userResponse)
        loadGoldPriceHistory()
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadGoldPriceHistory = async () => {
    await ApiGold.getPriceHistory().then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        let lastElement: any = serviceResponse.gold_prices[serviceResponse.gold_prices.length-1]
        let dateString: string = `${lastElement.date.split('GMT')[0].replace(' ', 'T').replace(' ', '')}${lastElement.date.split('GMT')[1]}`
        let dateElement: Date = new Date(dateString)
        let dateCurrent: Date = new Date()
        let momentService: Moment = moment(dateElement)
        let momentCurrent: Moment = moment(dateCurrent)
        let diff: number = momentCurrent.diff(momentService, 'day')

        if (diff > 0) {
          uploadGoldPriceHistory(serviceResponse)
        } else {
          setGoldPriceHistory(serviceResponse)
          setGoldPrice(lastElement.gold_price)
        }
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const uploadGoldPriceHistory = async (item: GoldPriceHistoryModel) => {
    ApiGold.getPriceGold().then( async price => {
      let date = format((new Date()), 'yyyy-MM-dd HH:mm:ss zzzz')

      await ApiGold.postPriceHistory(date, price).then( (response) => {
        if (response.status === 201) {
          let serviceResponse: any = response.data
          item.gold_prices.push(serviceResponse)

          setGoldPriceHistory(item)
          setGoldPrice(item.gold_prices[item.gold_prices.length-1].gold_price)
        } else {
          let errorResponse: Error = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    })
  }

  useEffect( () => {
    setMounted(true)
    loadUserLoggedIn()

    if (authUserLoggedIn()) {
      toast.success(lang.labels.yourSessionIsActiveCongratulations)
    } 

    if (Array.from(document.querySelectorAll('div[class="modal-backdrop fade show"]')).length > 0) {
      Array.from(document.querySelectorAll('body[class="modal-open"]')).forEach( (node) => {
        node.removeAttribute('class')
        node.removeAttribute('style')
      })
      Array.from(document.querySelectorAll('div[class="modal-backdrop fade show"]')).forEach( (node) => {
        node.remove()
      })
      Array.from(document.querySelectorAll('div[class="modal fade show"]')).forEach( (node) => {
        node.classList.remove('show')
      })
    }

    window.onpopstate = () => {
      Array.from(document.querySelectorAll('body[class="modal-open"]')).forEach( (node) => {
        node.removeAttribute('class')
        node.removeAttribute('style')
      })
      Array.from(document.querySelectorAll('div[class="modal-backdrop fade show"]')).forEach( (node)  => {
        node.remove()
      })
    }

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled h-100" data-kt-sticky-header={scrollVisibility.header} data-kt-scrolltop={scrollVisibility.top} data-kt-drawer={activeDrawer.value} data-kt-aside-minimize="off">
      <div className="d-flex flex-column flex-root h-100">
        <div className="page d-flex flex-row flex-column-fluid">
          <AppNavbar scrollVisibility={scrollVisibility} activeDrawer={activeDrawer} setScrollVisibility={setScrollVisibility} setActiveDrawer={setActiveDrawer} userLoggedIn={userLoggedIn}></AppNavbar>
          <div className="wrapper d-flex flex-column flex-row-fluid">
            <AppHeader activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer} route={route}></AppHeader>
            <div className="content d-flex flex-column flex-column-fluid">
              <div className="container-xxl">
                <AppTitle route={route}></AppTitle>
                <Outlet context={{setRoute, userLoggedIn, goldPrice, goldPriceHistory}}></Outlet>
              </div>
            </div>
            <AppFooter></AppFooter>
          </div>
        </div>
      </div>
      <AppScroll></AppScroll>
      <AppOverlay activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer}></AppOverlay>
      <AppDropdownUserProfile activeDrawer={activeDrawer} setActiveDrawer={setActiveDrawer} userLoggedIn={userLoggedIn}></AppDropdownUserProfile>
    </div>
  )
};

export function UseOutletContext() {
  return useOutletContext<ContexType>()
};

export default MainPage;
