export function datetimeString(date: string): string {
  let currentDate: Date = new Date()
  let hour: string = currentDate.getHours().toString().padStart(2, '0')
  let minute: string = currentDate.getMinutes().toString().padStart(2, '0')
  let second: string = currentDate.getSeconds().toString().padStart(2, '0')

  return `${date}T${hour}:${minute}:${second}`
} 

export function dateString(date: string): string {
  return (date.split(' ').length === 1) ? `${date}T00:00:00` : (date.split(' ').length === 2) ? `${date.replace(' ', 'T')}` : (/GMT/.test(date)) ? `${date.split('GMT')[0].replace(' ', 'T').replace(' ', '')}${date.split('GMT')[1]}` : `${date.split(' ')[0]}T${date.split(' ')[1]}`
}

export function dateFormat(date: string, code_language: string): string {
  let dateValue: Date = new Date(dateString(date))
  let locale: string = code_language === 'FR' ? 'fr-FR' : 'en-US'
  let options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }

  return dateValue.toLocaleDateString(locale, options);
}

export function dateFormatAbbreviation(date: string): string {
  let dateValue: Date = new Date(dateString(date))
  let day: string = String(dateValue.getDate()).padStart(2, '0')
  let month: string = String(dateValue.getMonth() + 1).padStart(2, '0')
  let year: string = String(dateValue.getFullYear())

  return `${day}/${month}/${year}`
}
