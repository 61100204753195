import { Language } from "../languages/context/language";
import { IngotModel } from "../models/ingot.model";
import { IngotTypeModel } from "../models/ingot.type.model";
import { WeightModel } from "../models/weight.model";
import { weightUnitFormat } from "./weight.units.script";

function fullNameIngot(item: IngotModel, lang: Language): string {
  return `${item.refiner.name} ${item.weight.value} ${weightUnitFormat(item.weight.unity, lang)} ${item.purity.value}, ${item.serial_number}`.toLowerCase()
}

function fullNameIngotType(item: IngotTypeModel, lang: Language): string {
  return `${item.refiner.name} ${item.weight.value} ${weightUnitFormat(item.weight.unity, lang)} ${item.purity.value}`.toLowerCase()
}

function kgToG(item: WeightModel): number {
  return item.unity === 'kg' ? item.value * 1000 : item.value
}

function compareDescription(a: any, b: any, ascendente: boolean): number {
  const compare = a.description.toLowerCase().localeCompare(b.description.toLowerCase())
  return ascendente ? compare : compare * -1
}

function compareName(a: any, b: any, ascendente: boolean): number {
  const compare = a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  return ascendente ? compare : compare * -1
}

function compareFirstNameLastName(a: any, b: any, ascendente: boolean): number {
  let compareFirstName = a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase())
  let compareLastName = compareFirstName === 0 ? a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase()) : compareFirstName
  return ascendente ? compareLastName : compareLastName * -1
}

function compareLastNameFirstName(a: any, b: any, ascendente: boolean): number {
  let compareLastName = a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase())
  let compareFirstName = compareLastName === 0 ? a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase()) : compareLastName
  return ascendente ? compareFirstName : compareFirstName * -1
}

export function listSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => a - b)
}

export function listSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => b - a)
}

export function listValueSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => a.value - b.value)
}

export function listValueSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => b.value - a.value)
}

export function listDescriptionSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareDescription(a, b, true))
}

export function listDescriptionSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareDescription(a, b, false))
}

export function listNameSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareName(a, b, true))
}

export function listNameSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareName(a, b, false))
}

export function listFirstNameLastNameSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareFirstNameLastName(a, b, true))
}

export function listFirstNameLastNameSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareFirstNameLastName(a, b, false))
}

export function listLastNameFirstNameSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareLastNameFirstName(a, b, true))
}

export function listLastNameFirstNameSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => compareLastNameFirstName(a, b, false))
}

export function listIngotTypeSortedAscending(list: any[], lang: Language): any[] {
  return list.slice().sort((a, b) => fullNameIngotType(a, lang).localeCompare(fullNameIngotType(b, lang)))
}

export function listIngotTypeSortedDescending(list: any[], lang: Language): any[] {
  return list.slice().sort((a, b) => fullNameIngotType(b, lang).localeCompare(fullNameIngotType(a, lang)))
}

export function listWeightSortedAscending(list: any[]): any[] {
  return list.slice().sort((a, b) => kgToG(a) - kgToG(b))
}

export function listWeightSortedDescending(list: any[]): any[] {
  return list.slice().sort((a, b) => kgToG(b) - kgToG(a))
}

export function listIngotSortedAscending(list: any[], lang: Language): any[] {
  return list.slice().sort((a, b) => fullNameIngot(a, lang).localeCompare(fullNameIngot(b, lang)))
}