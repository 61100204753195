import React from 'react';

import { StateLanguage } from '../languages/config/StateLanguage';

interface AppTitleProps {
  route: {main: boolean, path: {root: string | null, branch: string | null} | null, browse: {target: string, link: string} | null} | null
};

const AppTitle: React.FunctionComponent<AppTitleProps> = ({route}) => {
  const {lang} = StateLanguage()

  return (
    <div className={route ? "page-title d-flex d-lg-none flex-column align-items-start justify-content-center flex-wrap pb-5 pb-lg-0" : "d-none"}>
        { route && route.path &&
          <>
            <h1 className="text-dark fw-bold my-0 fs-2">{lang.labels.sgi}</h1>
            <ul className="breadcrumb breadcrumb-line text-muted fw-bold fs-base my-1 w-100">
              <li className="text-muted me-3">{lang.labels.treeStructure}:</li>
              { route.path.root && 
                <li className="breadcrumb-item text-dark">{route.path.root}</li>
              }
              { route.path.branch && 
                <li className="breadcrumb-item text-dark">{route.path.branch}</li>
              }
            </ul>
          </>
        }
    </div>
  )
}

export default AppTitle;
