import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { modalShow } from '../../../types/modal.type';
import { UseOutletContextParameter } from './Parameter';
import { RateService } from '../../../services/rate.service';
import { RateModel } from '../../../models/rate.model';
import { listValueSortedAscending, listValueSortedDescending } from '../../../scripts/order.asc.desc.list.script';
import arrowNext from '../../../assets/images/svg/arrow-next.svg';
import AppModalRateCreate from '../../../components/modal/ModalRateCreate';
import AppModalRateUpdate from '../../../components/modal/ModalRateUpdate';

export interface RatesPageProps {};

const RatesPage: React.FunctionComponent<RatesPageProps> = props => {
  const {setRoute, rates, setRates} = UseOutletContextParameter()
  const {lang} = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [orderAscDesc, setOrderAscDesc] = useState("asc")
  const [rate, setRate] = useState<RateModel | undefined | null>(null)

  const submitRatetDelete = async (item: string) => {
    if (rates) {
      Swal.fire({
        title: lang.labels.areYouSureWantToDeleteThisRate,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await RateService.deleteRate(item).then( (response) => {
            if (response.status === 200) {
              let messageResponse: Message = response.data
              let list = rates.filter((rate) => (rate.id !== item))

              setRates(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              let errorResponse: Error = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitRateUpdate = async (item: RateModel) => {
    setRate(item)
    modalShow('modal-rate-update')
  }

  const submitRateCreate = () => {
    modalShow('modal-rate-create')
  }

  const handleChangeOrderAscDesc = (item: string) => {
    if (rates && orderAscDesc !== item) {
      setOrderAscDesc(item)

      switch (item) {
        case 'asc':
          setRates(listValueSortedAscending(rates))
          break;
        case 'desc':
          setRates(listValueSortedDescending(rates))
          break;
      }
    }
  }
  
  useEffect( () => {
    setMounted(true)
    setRoute({main: true, path: {root: lang.labels.database, branch: lang.labels.commision}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <>
      { rates 
        ?
        <div className="card">
          <div className="card-header">
            <div className="card-title">
              <h2>{lang.labels.commissionList}</h2>
            </div>
            <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
              <div className="nav-group nav-group-outline p-1">
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "asc" && "active"}`} onClick={() => handleChangeOrderAscDesc("asc")}>{lang.labels.asc}</button>
                <button className={`btn btn-sm btn-color-gray-500 btn-active btn-active-secondary w-80px px-0 ${orderAscDesc === "desc" && "active"}`} onClick={() => handleChangeOrderAscDesc("desc")}>{lang.labels.desc}</button>
              </div>
              <button className="btn btn-primary" onClick={submitRateCreate}>
                {lang.labels.addCommission}
              </button>
            </div>
          </div>
          <div className="card-body pt-0">
            <div className="dataTables_wrapper">
              <div className="table-responsive">
                <table className="table align-middle table-row-dashed fs-6 gy-3">
                  { rates.length > 0
                    ?
                    <tbody className="text-gray-900">
                      { rates.map (( (item, index) => { return (
                        <tr key={index} className="row-dashed bg-hover-light" onClick={(event) => {event.stopPropagation(); submitRateUpdate(item);}}>
                          <td className="min-w-300px fw-bold ps-3">
                            <div className="d-flex flex-row align-items-center">
                              <span>{item.value.toFixed(2)} %</span>
                              { item.type === "unique" &&
                                <>
                                  <span className="text-lowercase ms-1">{lang.labels.ofSingleCommission}</span>
                                </>
                              }
                              { item.type === "annual" &&
                                <>
                                  <span className="mx-1">{lang.labels.ofAnnualRecurringCommission}</span>
                                  <span className="text-lowercase">{item.years} {lang.labels.years}</span>
                                </>
                              }
                            </div>
                          </td>
                          <td className="w-150px text-end pe-1">
                            <div className="justify-content-end">
                              <button className="btn btn-sm btn-light-primary me-5" type="button" onClick={(event) => {event.stopPropagation(); submitRatetDelete(item.id);}}>
                                {lang.labels.delete}
                              </button>
                              <img className="w-20px" src={arrowNext} alt='Go'/>
                            </div>
                          </td>
                        </tr>
                      )}))}
                    </tbody>
                    :
                    <tbody className="text-gray-600 fw-bold">
                      <tr className="odd">
                        <td valign="top" colSpan={2} className="text-center">
                          {lang.labels.noMatchingRecordsFound}
                        </td>
                      </tr>
                    </tbody>
                  }
                </table>
              </div>
            </div>
          </div>
        </div>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalRateUpdate orderAscDesc={orderAscDesc} rate={rate} rates={rates} setRate={setRate} setRates={setRates}></AppModalRateUpdate>
      <AppModalRateCreate orderAscDesc={orderAscDesc} rates={rates} setRates={setRates}></AppModalRateCreate>
    </>
  )
};

export default RatesPage;
