import { configLanguage } from './ConfigLanguage';

export const initialState = {
  codeLanguage: configLanguage.languageDefault.code,
  labels: configLanguage.languageDefault.labels
}

export type Action = {
  type:string
}

export const languageReducer = (state = initialState, action: Action) => {
  switch(action.type){
    case 'french':
      return { 
        codeLanguage: configLanguage.lang.french.code,
        labels: configLanguage.lang.french.labels
      }
    case 'english':
      return { 
        codeLanguage: configLanguage.lang.english.code,
        labels: configLanguage.lang.english.labels
      }
    default: return state;
  }
}