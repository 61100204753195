import React, { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import imageDocument from '../../assets/images/thumbnail.png';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFThumbnail: React.FC<{ url_pdf: string }> = ({ url_pdf }) => {
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [isMounted, setIsMounted] = useState(true);

  const generateThumbnail = async () => {
    try {
      let pdfDoc = await pdfjs.getDocument(url_pdf).promise;
      let page = await pdfDoc.getPage(1);
      let viewport = page.getViewport({ scale: 1 });
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      if (context) {
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        let renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;

        let image = canvas.toDataURL('image/png');

        if (isMounted) {
          setThumbnail(image);
        }
      }
    } catch (error) {
      console.error('Error generating thumbnail:', error);
    }
  };

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false); 
    };

  }, []);

  useEffect(() => {
    if (url_pdf) {
      generateThumbnail();
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url_pdf]);

  return (
    <div className="card-body p-0">
      {thumbnail ? (
        <img src={thumbnail} alt="thumbnail" className="w-100 h-100" style={{ objectFit: 'cover' }} />
      ) : (
        <img src={imageDocument} alt="PDF thumbnail" className="w-100 h-100" style={{ objectFit: 'cover' }} />
      )}
    </div>
  );
};

export default PDFThumbnail;
