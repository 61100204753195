export const languageEn = {
  code: "EN",
  labels: {
    sgi: 'S.G.I. Swiss Gold Trading LLC',
    OkGotIt: 'Ok, got it',
    about: 'About',
    accepted: 'Accepted',
    account: 'Account',
    actions: 'Actions',
    add: 'Add',
    addAPhotoOfTheCertificateOfAuthenticity: 'Add a photo of the certificate of authenticity',
    addAPhotoOfTheIngot: 'Add a photo of the ingot',
    addASpouse: 'Add a spouse',
    addAnAllowance: 'Add an allowance',
    addCommission: 'Add commission rate',
    addCommunication: 'Add communication',
    addDocument: 'Add Document',
    addFile: 'Add file',
    addHeritageValue: 'Add a origin of funds',
    addImage: 'Add image',
    addImageOrDragAndDrop: 'Add image, or drag and drop',
    addIngot: 'Add ingot',
    addIngotType: 'Add ingot type',
    addIntermediaries: 'Add intermediaries',
    addIntermediary: 'Add intermediary',
    addInvestment: 'Add investment',
    addNewInvestment: 'Add new investment',
    addNotary: 'Add a notary',
    addPaymentPlan: 'Add a payment due date',
    addPurity: 'Add purity',
    addRefiner: 'Add refiner',
    addSecurityCompany: 'Add security company',
    addSpouse: 'Add spouse',
    updateSpouse: 'Update spouse',
    addUser: 'Add User',
    addWeight: 'Add weights',
    additionalInformation: 'Additional information',
    address: 'Address',
    admin: 'Admin',
    advancePaymentIsRequired: 'Advance payment is required',
    advancePaymentOfAuthorizedInstallments: 'Advance payment of authorized installments',
    allInLicense: 'All-in License',
    allocationDetails: 'Allocation Details',
    allocations: 'Liste des lingots du Client achetés par S.G.I.',
    allowanceValue: 'Allowance value',
    allowedFileTypes: 'Allowed file types: png, jpg, jpeg.',
    alreadyHaveAnCccount: 'Already have an account?',
    amount: 'Amount',
    annual: 'Annual',
    annualContractualInstallment: 'Annual contractual installment',
    annualRecurringCommission: 'Annual recurring commission',
    ofAnnualRecurringCommission: 'of annual recurring commission for',
    areRequired: 'are required',
    areYouSureWantToDeleteThisDocument: 'Are you sure want to delete this document?',
    areYouSureWantToDeleteThisFinancialStatement: 'Are you sure want to delete this financial statement?',
    areYouSureWantToDeleteThisGeneralInformation: 'Are you sure want to delete this general information?',
    areYouSureWantToDeleteThisHeritage: 'Are you sure you want to remove this origin of funds?',
    areYouSureWantToDeleteThisIntermediary: 'Are you sure want to delete this intermediary?',
    areYouSureWantToDeleteThisPurity: 'Are you sure want to delete this purity?',
    areYouSureWantToDeleteThisRate: 'Are you sure you want to remove this commission rate?',
    areYouSureWantToDeleteThisRefiner: 'Are you sure want to delete this refiner?',
    areYouSureWantToDeleteThisSecurityCompany: 'Are you sure want to delete this security company?',
    areYouSureWantToDeleteThisTypeIngot: 'Are you sure want to delete this type ingot?',
    areYouSureWantToDeleteThisWeight: 'Are you sure want to delete this weight?',
    areYouSureYouWanToDeleteThisIngot: 'Are you sure you want to delete this lingot?',
    areYouSureYouWantToCancel: 'Are you sure you want to cancel?',
    areYouSureYouWantToDeleteThisCommunication: 'Are you sure you want to delete this communication?',
    areYouSureYouWantToDeleteThisInvestment: 'Are you sure you want to delete this investment?',
    areYouSureYouWantToDeleteThisNotary: 'Are you sure you want to delete this notary?',
    areYouSureYouWantToDeleteThisWithdrawalRequest: 'Are you sure you want to delete this withdrawal request?',
    areYouSureYouWantToDeleteThisClient: 'Are you sure you want to delete this client?',
    areYouSureYouWantToPermanentlyDeleteThisClient: 'Are you sure you want to permanently delete this client?',
    asc: 'Asc',
    auditorReport: 'Auditor report',
    auditorsAttestation: "Auditor's attestation",
    authenticAct: 'Authentic act before a notary',
    authenticDeedsSignedInFrontOf: 'Authentic deeds signed in front of me',
    available: 'Available',
    avatar: 'Avatar',
    balanceSheetAndIncomeStatement: 'Balance sheet and income statement',
    bankInformation: 'Bank information',
    bankStatementConfirmingExistenceOfFunds: 'Proof of funds',
    bicSwift: 'BIC / SWIFT',
    businessNumber: 'Business Number',
    button: 'button',
    buyNow: 'Buy Now',
    cancel: 'Cancel',
    capitalInvested: 'Capital invested',
    capitalInvestedIsRequired: 'Capital invested is required',
    certificateOfAuthenticity: 'Certificate of authenticity',
    changeEmail: 'Change Email',
    changeImage: 'Change image',
    changePassword: 'Change Password',
    city: 'City',
    cityIsRequired: 'City is required',
    client: 'Client',
    clientInformation: 'Client Information',
    clientList: 'Client list',
    clientPortal: 'Portail client',
    clientView: 'Client view',
    clients: 'Clients',
    comingSoon: 'Coming Soon',
    commercialRegisterExtract: 'Commercial Register Extract',
    commision: 'Commission rate',
    commissionList: 'List of Commission Rates',
    commissionRates: 'Commission Rates',
    commonSafe: ' Common safe',
    commonSafeIsRequired: 'Common safe is required',
    communication: 'Communication',
    communicationTitle: 'Communication title',
    communicationView: 'Communication view',
    communications: 'Communications',
    company: 'Company',
    companyInformation: 'Company information',
    companyName: 'Company Name',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    contact: 'Contact',
    continue: 'Continue',
    correspondence: 'Correspondence',
    country: 'Country',
    countryIsRequired: 'Country is required',
    countryOfCurrentEmployer: 'Country of current employer',
    createANewNotary: 'Create a notary',
    createAnAccount: 'Create an Account',
    createClient: 'Create Client',
    createCommunication: 'Create Communication',
    createFinancialStatement: 'Create Financial Statement',
    createGeneralInformation: 'Create General Information',
    createHeritage: 'Create Heritage',
    createIngot: 'create ingot',
    createIntermediary: 'Create of an Intermediary',
    createInvestment: 'Create investment',
    createNewClient: 'Create new client',
    createNotary: 'Create Notary',
    createPurity: 'Create Purity',
    createRate: 'Create a commission rate',
    createRefiner: 'Create Refiner',
    createSecurityCompany: 'Create Security Company',
    createTypeIngot: 'Create a ingot type',
    createUser: 'Create a User',
    createWeight: 'Create Weight',
    createdBy: 'Created by',
    currentEmployer: 'Current employer',
    currentPassword: 'Current Password',
    dailyPrice: 'Daily price (CH/KG)',
    dashboard: 'Welcome',
    date: 'Date',
    dateIsRequired: 'Date is required',
    dateOfBirth: 'Date of birth',
    dateOfDemand: 'Date de la demande',
    dateOfTheAuthenticAct: 'Date of the authentic act',
    dates: 'Dates',
    days: 'days',
    debtCollectionOfficeExtract: 'Debt Collection Office Extract',
    delete: 'Delete',
    deletePermanently: 'Delete permanently',
    deleteClient: 'Delete client',
    deleteCommunication: 'Delete communication',
    deleteIngot: 'Delete lingot',
    deleteIntermediary: 'Delete Intermediary',
    deleteSecurityCompany: 'Delete ',
    deleteTheRequest: 'Delete request',
    deletedClients: 'Deleted clients',
    denied: 'Denied',
    desc: 'Desc',
    description: 'Description',
    detailOfTheIngots: 'Detail of the ingots',
    difference: 'Difference',
    digitalAdminPro: 'Digital Admin Pro',
    diploma: 'Diploma',
    discard: 'Discard',
    divorced: 'Divorced',
    document: 'Document',
    documentAddedCuccessfully: 'Document added successfully',
    documents: 'Documents',
    doesItTakeALongTimeToLoadTheApp: 'Does it take a long time to load the app?',
    dueDate: 'Due date',
    eachIngotCanOnlyBeAwardedOnce: 'Each ingot can only be awarded once, select another ingot',
    eachIntermediaryCanOnlyBeAssignedOnce: 'Each intermediary can only be assigned once, select another intermediary',
    edit: 'Edit',
    editAllocation: 'Edit allocation',
    editClient: 'Edit client',
    editClientFile: 'Edit client file',
    editCommunication: 'Edit communication',
    editIngot: 'Edit lingot',
    editIntermediary: 'Edit Intermediary',
    editInvestment: 'Edit investment',
    editNotary: 'Edit Notary',
    editSecurityCompany: 'Update',
    elapsedTime: 'Elapsed time',
    email: 'Email',
    emailAddress: 'Email Address',
    emailAddressIsRequired: 'Email address is required',
    emailIsRequired: 'Email is required',
    english: 'English',
    enterNewEmailAddress: 'Enter New Email Address',
    enterTheNewPasswordForTheMail: 'Enter the new password for the mail',
    enterYourEmailToResetYourPassword: 'Enter your email to reset your password',
    error1000: 'Token not found!',
    error1001: 'Token invalid!',
    error1002: 'Error ocurred in database!',
    error1003: 'One or more mandatory fields are empty!',
    error1004: 'Uuid not found!',
    error1005: 'Password do not match!',
    error1006: 'Invalid format for Email!',
    error1007: 'Wrong password!',
    error1008: 'User not found!',
    error1009: 'String!',
    error1010: 'Email already taken!',
    error1011: 'Must be at least 8 characters!',
    error1012: 'Incorrect way to order!',
    error1013: 'The attempt limit has been exceeded!',
    error1014: 'String!',
    error1015: 'Error sending email!',
    error1016: 'The client has already been deleted!',
    error1017: 'Add a document!',
    error1018: 'The document has already been deleted!',
    error1019: 'The document does not belong to the client!',
    error1020: 'Only 1 document of this type is permitted!',
    error1021: 'String!',
    error1022: 'String!',
    error1023: 'Maximum number of documents for this type!',
    error1024: 'The communication does not belong to the client!',
    error1025: 'The communication has already been deleted!',
    error1026: 'The document type is incorrect!',
    error1027: 'The notary has already been deleted!',
    error1028: 'Purity already registered!',
    error1029: 'Refiner already registered!',
    error1030: 'Weight already registered!',
    error1031: 'Unregistered purity!',
    error1032: 'Unregistered refiner!',
    error1033: 'Unregistered weight!',
    error1034: 'Ingot already registered!',
    error1035: 'The Ingot has already been deleted!',
    error1036: 'Commission already registered!',
    error1037: 'The Investment has already been deleted!',
    error1038: 'Unregistered notary!',
    error1039: 'Unregistered security company!',
    error1040: 'One o more ingots incorrects!',
    error1041: 'The withdrawal type is incorrect!',
    error1042: 'The withdrawal status is incorrect!',
    error1043: 'Client no found!',
    error1044: 'Investment no found!',
    error1045: 'Th Ingot is assigned to an investment!',
    error1046: 'The Ingot is not available!',
    error1047: 'Withdrawal no found!',
    error1048: 'The Security Company has already been deleted!',
    error1049: 'The Intermediary has already been deleted!',
    error1050: 'The Refiner has already been deleted!',
    error1051: 'The Weight has already been deleted!',
    error1052: 'The Purity has already been deleted!',
    error1053: 'The Commission has already been deleted!',
    error1054: 'The Heritage Origin has already been deleted!',
    error1055: 'One o more commissions incorrects!',
    error1056: 'One o more intermediaries incorrects!',
    error1057: 'The Type Ingot has already been deleted!',
    error1058: 'The Type Ingot already registered!',
    error1059: 'One o more ingots are not available',
    error1060: 'Unregistered safe box',
    error1061: 'The real password is incorrect',
    error1062: 'One or more clients were not found',
    error1063: 'Invalid format for IBAN',
    error1064: 'Invalid format for SWIFT/BIC',
    error1065: 'Invalid format for password',
    error1066: 'The withdrawal request has already been deleted',
    error1067: 'Incorrect month',
    error1068: 'Select one or more options for the withdrawal request',
    error1069: 'One or more investments were not found',
    error1070: 'Wrong data type',
    error1071: 'Amount must be less',
    error1072: 'Insufficient balance',
    error1073: 'The client has already been deleted',
    error1074: 'The wrong number of years',
    error1075: 'The role type does not exist',
    error1076: 'The commission type does not exist',
    error1077: 'User has reached or exceeded the monthly API request allowance for their subscription plan',
    errorOccurredInDatabase: 'Error occurred in database!',
    execution: 'Execution',
    executionScheduledFor: 'Execution scheduled for',
    executiveTitle: 'Original enforceable title',
    expirationOfTheRightOfRefusal: 'Expiration of the right of refusal',
    extraInformation: 'Extra information',
    extractProsecutionOffice: 'Extract prosecution office',
    fieldOfActivity: 'Field of activity',
    fieldsMarkedWithAnAsterisk: 'Fields marked with an asterisk',
    file: 'File',
    fileSizeNotAllowed: 'File size not allowed! Maximum file size: 1000 KB',
    files: 'Files',
    filterBy: 'Filter by',
    financialCommitments: 'Financial commitments',
    financialStatements: 'Financial statements',
    finma: 'Certification of Affiliation to OAR (FINMA)',
    firstName: 'First name',
    firstNameAndLastNameFather: 'First name(s) and last name of father',
    firstNameIsRequired: 'First name is required',
    for: 'for',
    forgotPassword: 'Forgot Password?',
    formA: 'Form A',
    taxComplianceCertificate: 'Tax compliance certificate',
    fortuneIncludingRealEstate: 'Net wealth (including real estate)',
    fortuneIsRequired: 'Fortune is required',
    french: 'French',
    fullName: 'Full Name',
    futuresSellingPriceOfGold: 'Forward sale price of gold by S.G.I. following back-to-back transaction',
    generalInformations: 'General informations',
    goToLoginPage: 'Go to login page',
    goToPreviousPage: 'Go to previous page',
    goldFuturesPurchasePrice: 'Forward purchase price of the client`s gold by S.G.I',
    goldPriceLockDate: 'Gold price lock-in date',
    goldPrices: 'Gold prices',
    gram: 'Gram',
    grams: 'Grams',
    heritageValues: 'Origin of funds',
    heritages: 'Heritage',
    iAgree: ' I Agree &',
    iban: 'IBAN',
    identification: 'Identification',
    identity: 'Identity',
    ifYouAcceptTheChangesMadeWillNotBeUpdated: 'If you accept, the changes made will not be updated',
    ifYouAcceptYouMayHaveToFillOutTheFormAgain: 'If you accept, all form data will be deleted',
    ifYouWantToTakeOverTheTpouseTata: "If you want to take over the spouse's data, you can click the button",
    incomeOrPensionReceivedForAnYear: 'Income or pension received for an year',
    information: 'Information',
    ingot: 'Ingot',
    ingotTypeList: 'List of Types of Ingots',
    ingotTypes: 'Type ingots',
    ingotUpdate: 'Ingot update',
    ingotView: 'Lingot view',
    ingotWeight: 'Ingot weight',
    ingots: 'Ingots',
    interests: 'Interests',
    intermediaries: 'Intermediaries',
    intermediary: 'Intermediary',
    intermediaryCommission: 'Intermediary commission (CHF)',
    invalidFileType: 'Invalid file type! Allowed file types: jpeg, jpg or png',
    investment: 'Investment',
    investmentAmount: 'Gold investment made by clients',
    investmentView: 'Investment view',
    investments: 'Investments',
    kilogram: 'Kilogram',
    kilograms: 'Kilograms',
    kyc: 'KYC',
    language: 'Language',
    languages: 'Languages',
    lastName: 'Last name',
    lastNameIsRequired: 'Last name is required',
    lastTaxDeclaration: 'Last tax declaration',
    lastTaxNotice: 'Last tax notice',
    less: 'Less',
    licenseTerms: 'License Terms',
    listCommunications: 'List of communications',
    listOfHeritageValues: 'List of Origin Funds',
    listOfIngots: 'Ingot list',
    listOfIntermediaries: 'Intermediary list',
    loading: 'Loading...',
    logo: 'Logo',
    maidenNameMother: 'Maiden name(s) of mother',
    makeTheDocumentAvailableOnTheClientPortal: 'Rendre le document disponible sur le portail du client',
    maritalStatus: 'Marital status',
    maritalStatusIsRequired: 'Marital status is required',
    married: 'Married',
    maximum1kCharacters: 'maximum 1000 characters',
    maximumFileFormat10MBPerFile: 'Maximum file format 10 MB per file',
    memberIsRequired: 'Member is required',
    memberOfTheBoardOfDirectors: 'Member of the Board of Directors, Management or any other posi on of a listed company',
    message: 'Message',
    messageIsRequired: 'message is required',
    messageWillBeSentYourEmailToRecoverAccount: 'Message will be sent your email to recover account, please check your email. Will be redirected to login page.',
    million: "million",
    millions: "millions",
    missingValueOfAllocations: 'Missing value of allocations',
    modify: 'Modify',
    moneyBackGuarantee: '100% money back guarantee!',
    myProfile: 'My Profile',
    name: 'Name',
    nationality: 'Nationality',
    nationalityIsRequired: 'Nationality is required',
    newPassword: 'New Password',
    newServicesWithinTheSameApp: 'New services within the same application.',
    no: 'No',
    noAvailable: 'No available',
    noCancel: 'No, cancel',
    noMatchingRecordsFound: 'No matching records found',
    noResultFound: 'No result found',
    noResultsFound: 'No results found',
    noSecurityCompanySelected: 'No security company selected',
    nonEsxistOptions: 'Non esxist options',
    none: 'None',
    noneIngots: 'None ingots',
    notaries: 'Notaries',
    notary: 'Notary',
    notaryIsRequired: 'notary is required',
    notaryList: 'List of Notaries',
    notarySignature: 'notary signature (date)',
    notaryView: 'Notary ',
    nowYouCanLogIn: 'Now you can log in with the new account create, will be redirected to login page.',
    numberOfIngots: 'Number of lingots',
    numberOfParts: 'Number of parts',
    ofPortfolio: 'of the portfolio',
    oneOrMoreIngotsHaveNotBeenSelected: 'One or more ingots have not been selected',
    oneOrMoreIntermediariesOrCommissionsHaveNotBeenSelected: 'One or more intermediaries or commissions have not been selected',
    open: 'Open',
    options: 'options',
    originOfHeritageIsRequired: 'Origin of heritage is required',
    originOfHeritageValues: 'Origin of heritage values',
    originalPriceOfGold: 'Original gold price (CHF/Kg)',
    originalValuation: 'Original valuation',
    originalValuationOfGold: 'Original valuation of gold',
    originalValuationOfGoldPurchased: 'Original valuation of gold purchased by S.G.I',
    originalValue: 'Original value',
    originalValueIsRequired: 'Original value is required',
    other: 'Other',
    overview: 'Overview',
    pageInProduction: 'Page in production, very soon you will be able to access new functionalities within the application.',
    pageNotFound: 'Page Not Found',
    parameters: 'Parameters',
    password: 'Password',
    payment: 'Payment',
    paymentPlan: 'Payment plan',
    pensionPerYearIsRequired: 'Pension per year is required',
    pepClose: 'PEP/Close to PEP',
    permanently: 'permanently',
    personalSafe: 'Personal safe',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    phoneNumberIsRequired: 'Phone number is required',
    photoOfTheCertificateOfAuthenticity: 'Photo of the certificate of authenticity',
    photoOfTheIngot: 'Photo of the ingot',
    placeOfBirth: 'Place of birth',
    pleaseCheckYourEmailInbox: 'Please check your email inbox.',
    pleaseCorrectThePathOfThePageInOrderToAccess: ' Please correct the path of the page in order to access the application.',
    pleaseEnterTheFollowingInformationToCreateANewIntermedary: 'Please enter the following information to create a new intermedary',
    pleaseEnterTheFollowingInformationToCreateANewSecurityCompany: 'Please enter the following information to create a new security company',
    pleaseEnterTheFollowingInformationToCreateNewClient: 'Please enter the following information to create a new client',
    pleaseEnterTheFollowingInformationToUpdateASecurityCompany: 'Please enter the necessary information to update the security company',
    pleaseEnterTheFollowingInformationToUpdateAnIntermedary: 'Please enter the following information to update an intermedary',
    pleaseEnterTheInformationYouWantToUpdate: 'Please enter the information you want to update',
    pleaseTryAgainLater: 'Please try again later.',
    pleaseWait: 'Please wait...',
    postcode: 'Postcode',
    presentValue: 'Present value',
    previousEmployer: 'Previous employer',
    previousPage: 'Previous page',
    previousProfessionalActivity: 'Previous professional activity',
    professionalActivity: 'Professional activity',
    professionalLiabilityInsurance: 'Professional Liability Insurance',
    profileCompleation: 'Profile Compleation',
    profileDetails: 'Profile Details',
    profitMargin: 'Profit margin by S.G.I. (CHF)',
    profitMarginForTheCustomer: 'Profit margin for the customer',
    purchase: 'Purchase',
    purchasePriceOfGold: 'Purchase price of gold',
    purchasePro: 'Purchase Digital Admin Pro',
    purchaseGold: 'Purchase invoice for physical gold',
    purities: 'Purities',
    purity: 'Purity',
    purityList: 'List of Purities',
    quantityOfGoldPurchased: 'Quantity of gold purchased',
    redirectingToLogin: 'Redirecting to login page.',
    reference: 'Reference',
    refill: 'Refill',
    refiner: 'Refiner',
    refinerList: 'List of Refiners',
    refiners: 'Refiners',
    registeredPartnership: 'Registered Partnership',
    reinvestment: 'Reinvestment',
    reload: 'Reload',
    remainingSharesOfTheOngot: 'Remaining shares of the ingot',
    remainingTime: 'Remaining time',
    removeAll: 'Remove all',
    removeImage: 'Remove image',
    removeLogo: ' Remove logo',
    requestDate: 'Request Date',
    requestFrom: 'Request from',
    required: 'Required',
    resetPassword: 'Reset Password',
    return: 'Return',
    returningToTheWithdrawalRequestPage: 'Returning to the withdrawal request page',
    role: 'Rôle',
    safeNumber: 'Safe number',
    safes: 'Safes',
    salePriceInSgi: 'Sale price at S.G.I',
    save: 'Save',
    saveChanges: 'Save Changes',
    search: 'Search...',
    searchClient: 'Search client',
    searchTestClient: 'Search test client',
    securedPaymentBy: 'Secured Payment by',
    security: 'Security Companies',
    securityCompanies: 'Security companies',
    securityCompany: 'Security company',
    securityCompanyList: 'List of Security Company',
    seeMore: 'See more',
    selectDocumentType: 'Select document type',
    selectDocuments: 'Select documents',
    selectFromDocuments: 'Select from documents',
    selectOneOption: 'Select one of the options',
    selectOption: 'Select option...',
    selectOrDragADocument: 'Select or drag a document',
    selectOrDragDocuments: 'Select or drag documents',
    serialNumber: 'Serial number',
    serialNumberIsRequired: 'Serial number is required',
    settings: 'Settings',
    sgiGoldPriceHistory: "Gold Price History",
    shareOfIngotUsed: 'Share of ingot used',
    sharesOwnedBy: 'Shares owned by',
    sharesUsed: 'Shares used',
    showTestClients: 'Show test clients',
    signIn: 'Sign In to Swiss Global Investment',
    signInHere: 'Sign in here',
    signInMethod: 'Sign-in Method',
    signOut: 'Sign Out',
    single: 'Single',
    singleCommission: 'Single commission',
    ofSingleCommission: 'of single commission',
    somethingWentWrong: 'Something went wrong!',
    sorryLooksLikeThereAreSomeErrorsTrySolve: 'Sorry, it looks like an error has been detected. Please correct it so you can save.',
    sorryLooksLikeThereAreSomeErrorsTryAgain: 'Sorry, it looks like an error has been detected. Please try again.',
    sourceOfFunds: 'Source of funds ',
    spanish: 'Spanish',
    splitting: 'Splitting',
    spouseInformation: 'Spouse information',
    identityDocument: 'Identity document',
    identityDocumentSpouse: 'Identity document Spouse',
    startDateOfBackgroundOn: 'Start date of background on',
    storageType: 'Storage type',
    street: 'Street',
    submit: 'Submit',
    successful: 'Successfully!',
    successfullyCreateFinancialStatement: 'Successfully created financial statement',
    successfullyCreatedClient: 'Successfully created client!',
    successfullyCreatedCommunication: 'Successfully created communication!',
    successfullyCreatedContact: 'Successfully created contact!',
    successfullyCreatedDocument: 'Successfully created document!',
    successfullyCreatedGeneralInformation: 'Successfully Created General Information',
    successfullyCreatedHeritage: 'Successfully created origin of funds',
    successfullyCreatedHeritageOrigin: 'Successfully created heritage origin!',
    successfullyCreatedIngot: 'Successfully created ingot!',
    successfullyCreatedIntermediary: 'Successfully created intermediary!',
    successfullyCreatedInvestment: 'Successfully created investment!',
    successfullyCreatedNotary: 'Successfully created notary!',
    successfullyCreatedPurity: 'Successfully created purity',
    successfullyCreatedRate: 'Successfully created commission',
    successfullyCreatedRefiner: 'Successfully created refiner',
    successfullyCreatedSecurityCompany: 'Successfully created security company!',
    successfullyCreatedTypeIngot: 'Successfully created type ingot',
    successfullyCreatedUser: 'Successfully created user',
    successfullyCreatedWeight: 'Successfully created weight',
    successfullyCreatedWithdrawal: 'Successfully created withdrawal!',
    successfullyDeletedClient: 'Successfully deleted client!',
    successfullyDeletedCommission: 'Successfully deleted commission rate!',
    successfullyDeletedCommunication: 'Successfully deleted communication!',
    successfullyDeletedContact: 'Successfully deleted contact!',
    successfullyDeletedDocument: 'Successfully deleted document!',
    successfullyDeletedFinancialDocument: 'Successfully deleted financial document!',
    successfullyDeletedGeneralDocument: 'Successfully deleted general document!',
    successfullyDeletedHeritageOrigin: 'Successfully deleted origin of funds!',
    successfullyDeletedIngot: 'Successfully deleted ingot!',
    successfullyDeletedIntermediary: 'Successfully deleted intermediary!',
    successfullyDeletedInvestment: 'Successfully deleted investment!',
    successfullyDeletedNotary: 'Successfully deleted notary!',
    successfullyDeletedPurity: 'Successfully deleted purity!',
    successfullyDeletedRefiner: 'Successfully deleted refiner!',
    successfullyDeletedSecurityCompany: 'Successfully deleted security company!',
    successfullyDeletedTypeIngot: 'Successfully deleted type ingot!',
    successfullyDeletedWeight: 'Successfully deleted weight!',
    successfullyDeletedWithdrawal: 'Successfully deleted withdrawal!',
    successfullyLoggedIn: 'You have successfully logged in!',
    successfullyMailSent: 'Successfully mail sent',
    successfullyUpdatedAccount: 'Successfully updated account',
    successfullyUpdatedClient: 'Successfully updated client!',
    successfullyUpdatedCommunication: 'Successfully updated communication!',
    successfullyUpdatedContact: 'Successfully updated contact!',
    successfullyUpdatedDocument: 'Successfully updated document!',
    successfullyUpdatedFinancialStatement: 'Successfully updated financial statement',
    successfullyUpdatedGeneralInformation: 'Successfully update general information',
    successfullyUpdatedHeritage: 'Successfully updated origin of funds',
    successfullyUpdatedIngot: 'Successfully updated ingot!',
    successfullyUpdatedIntermediary: 'Successfully updated intermediary!',
    successfullyUpdatedInvestment: 'Successfully updated investment!',
    successfullyUpdatedNotary: 'Successfully updated notary!',
    successfullyUpdatedPassword: 'Successfully updated password!',
    successfullyUpdatedPurity: 'Successfully updated purity',
    successfullyUpdatedRate: 'Successfully updated commission rate',
    successfullyUpdatedRefiner: 'Successfully updated refiner',
    successfullyUpdatedSecurityCompany: 'Successfully updated security company!',
    successfullyUpdatedTypeIngot: 'Successfully updated ingot type',
    successfullyUpdatedUser: 'Successfully updated user',
    successfullyUpdatedWeight: 'Successfully updated weight',
    successfullyUpdatedWithdrawalRequestStatus: 'Successfully updated withdrawal request status',
    successfullyUpdatedWithdrawalStatus: 'Successfully updated withdrawal status!',
    successfullyUpdatingCommunication: 'Successfully updating communication',
    support: 'Support',
    swissGlobalInvestment: 'Swiss Global Investment SA',
    systemError: 'System Error',
    taxpayerNumber: 'Taxpayer Number TIN(NIF)',
    termsAndConditions: 'Terms and conditions',
    testClient: 'Test client',
    testClientList: 'Test client list',
    virtualClients: 'Virtual clients',
    theAppWillBeTranslated: 'The app will be translated into indicated language, except for  information entered in documents.',
    theFileMustBeInImageFormat: 'The file must be in .png, .jpg, .jpeg format',
    thePageYouLookedNotFound: 'The page you looked not found!',
    thePasswordConfirmationIsRequired: 'The password confirmation is required',
    thePasswordIsAtLeast8Digits: 'The password is at least 8 digits',
    thePasswordIsRequired: 'The password is required',
    thePasswordsAreNotSame: 'The password and its confirm are not the same',
    theValueIsNotAValidAdress: 'The value is not a valid address',
    theValueIsNotAValidAnnualContractualInstallment: 'The value is not a valid Annual contractual installment',
    theValueIsNotAValidBIC: 'The value is not a valid BIC / SWIFT',
    theValueIsNotAValidCapitalInvested: 'The value is not a valid capital invested',
    theValueIsNotAValidCity: 'The value is not a valid city',
    theValueIsNotAValidCommunicationTitle: 'The value is not a valid communication title',
    theValueIsNotAValidCompanyName: 'The value is not a valid company name',
    theValueIsNotAValidCountry: 'The value is not a valid country',
    theValueIsNotAValidCurrentEmployer: 'The value is not a valid current employer',
    theValueIsNotAValidDiploma: 'The value is not a valid diploma',
    theValueIsNotAValidEmail: 'The value is not a valid email',
    theValueIsNotAValidEmailAddress: 'The value is not a valid email address',
    theValueIsNotAValidFieldOfActivity: 'The value is not a valid field of activity',
    theValueIsNotAValidFirstAndLastNameFather: 'The value is not a valid first and last name of father',
    theValueIsNotAValidFirstName: 'The value is not a valid first name',
    theValueIsNotAValidGoldFuturesPurchasePrice: 'the value is not a valid gold futures purchase price',
    theValueIsNotAValidGoldPrices: 'The value is not a valid gold prices',
    theValueIsNotAValidIBAN: 'The value is not a valid IBAN',
    theValueIsNotAValidLastName: 'The value is not a valid last name',
    theValueIsNotAValidMaidenMotherName: 'The value is not a valid maiden mother name',
    theValueIsNotAValidMessage: 'The value is not a valid message',
    theValueIsNotAValidNationality: 'The value is not a valid nationality',
    theValueIsNotAValidOriginalValuationOfPurchasedGold: 'The value is not a valid gold appraisal value',
    theValueIsNotAValidPassword: 'The value is not a valid password',
    theValueIsNotAValidPep: 'The value is not a valid PEP/Close',
    theValueIsNotAValidPhoneNumber: 'The value is not a valid phone number',
    theValueIsNotAValidPlaceOfBirth: 'The value is not a valid place of birth',
    theValueIsNotAValidPreviousEmployer: 'The value is not a valid previous employer',
    theValueIsNotAValidPreviousProfessionalActivity: 'The value is not a valid previous professional activity',
    theValueIsNotAValidProfesionalActivity: 'The value is not a valid profesional activity',
    theValueIsNotAValidReference: 'The value is not a valid reference',
    theValueIsNotAValidSafeNumber: 'The value is not a valid safe number',
    theValueIsNotAValidSerialNumber: 'The value is not a valid serial number',
    theValueIsNotAValidSourceOfFunds: 'The value is not a valid source of funds',
    theValueIsNotAValidTaxpayer: 'The value is not a valid taxpayer',
    tin: 'TIN',
    title: 'Title',
    titleIsRequired: 'title is required',
    to: 'to',
    totalAmountUpdatedFinancialCommitments: 'Total updated amount of financial commitments of S.G.I.',
    totalAmountInvestmentClients: 'Original valuation of gold purchased by S.G.I.',
    totalValueOfShares: 'Total value of shares',
    totalVolumePhysicalGold: 'Total volume of physical gold',
    type: 'Type',
    typeIsrequired: 'Type is required',
    typeOfAllocation: 'Type of allocation',
    typeOfAllocationIsRequired: 'Type of allocation is required',
    undefined: 'Undefined!',
    unique: 'Unique',
    unit: 'Unit',
    updateDocument: 'Update document',
    updateEmail: 'Update Email',
    updateFinancialStatement: 'Update Financial Statement',
    updateGeneralInformation: 'Update General Information',
    updateHeritage: 'Updated origin of founds',
    updateIngot: 'Ingot update',
    updateIntermediary: 'Update Intermediary',
    updatePassword: 'Update Password',
    updatePurity: 'Update Purity',
    updateRate: 'Update Commission rate',
    updateRefiner: 'Update Refiner',
    updateSecurityCompany: 'Update Security Company',
    updateTypeIngot: 'Update ingot type',
    updateUser: 'Update User',
    updateWeight: 'Update Weight',
    updatedInvestment: 'Update Investment',
    updatedInvestmentListReturToPage: 'Updated investment list return to page!',
    updatedValuationOfGoldPurchased: 'Updated valuation of gold purchased by S.G.I.',
    updatingAccountEmailAddress: 'Updating account email address, returning to page',
    updatingAccountPassword: 'Updating account password, returning to page',
    updatingCommunicationList: 'Updating communication list',
    updatingCommunicationListReturningToPage: 'Updating communication list returning, to page',
    updatingDocumentListReturningToPage: 'Updating the list of documents in progress',
    updatingFinancialStatementListReturningToPage: 'Updating financial statement list, returning to page',
    updatingGeneralInformationReturningToPage: 'Updating general information, returning to page',
    updatingIngotListReturningToPage: 'Updating ingot list, returning to page',
    updatingIngotReturningToPage: 'Updating ingot, returning to page',
    updatingIntermediaryListReturningToPage: 'Updating intermediary list, returning to page',
    updatingInvestmentList: 'Updating investment list',
    updatingList: 'List update in progress',
    updatingNotarieListReturningToPage: 'Updating the list of notaries in progress',
    updatingNotaryListReturningToPage: 'Updating the list of notaries in progress',
    updatingProfileDetails: 'Updating profile details, returning to page.',
    updatingTheListOfBarsBackToPage: 'Updating the list of ingots return to page',
    updatingTypesIngotListReturningToPage: 'Updating types ingot list, returning to page',
    updatingUserListReturningToPage: 'Updating user list returning to page',
    updatingWithdrawalRequestList: 'Updating withdrawal request list',
    use8OrMoreCharacters: 'Use 8 or more characters with a mix of letters (lowercase and uppercase), numbers & symbols',
    useOfDeposits: 'Use of deposits',
    useOfDepositsIsRequired: 'Use of deposits is required',
    user: 'User',
    userList: 'List of Users',
    userProfile: 'User profile',
    users: 'Users',
    utilityBill: 'Proof of address',
    valid: 'Valid',
    validateAccount: 'Validate account',
    validatedAccountForADifferentUser: 'Validated account for a different user than the one you are logged in, redirecting to login page.',
    validatedAccountForLoggedInUser: 'Validated account for logged in user, redirect to main page.',
    validatedVerifiedForAUserWhoHasNotLoggedIn: 'Validated verified for a user who has not logged in, redirecting to login page.',
    validityOfTheRightOfRefusal: 'Validity of the right of refusal',
    validityOfTheRightOfRefusalIsRequired: 'Validity of the right of refusal is required',
    value: 'Value',
    valueAtTimeOfSale: 'Value at time of sale',
    valueIsNotAValidGoldFuturesPrice: 'value is not a valid gold futures price',
    valueIsNotAValidOrginalValue: 'value is not a valid orginal value',
    valueIsNotAValidVolumeOfGoldPurchased: 'Value is not a valid volume of gold purchased',
    valueOfAShare: 'Value of a share',
    valueOfAllocations: "Original value of customer's bullion",
    verificationYourAccount: 'Verification Your Account',
    viewClientList: 'View client list',
    viewCommunications: 'View Communications',
    viewIngots: 'view Ingots',
    viewIntermediaries: 'View intermediaries',
    viewIntermediary: 'View Intermediary',
    viewNotaryList: 'View notary list',
    viewSecurityCompanies: 'View security companies',
    viewSecurityCompany: 'View Security Company',
    viewUser: 'View User',
    viewWithdrawalsRequests: 'View withdrawals requests',
    volumeOfGoldPurchased: 'Volume of gold purchased by S.G.I. (Kg)',
    volumeOfGoldSold: 'Volume of gold sold (Kg)',
    volumeOfPhysicalGold: 'Total volume of physical gold purchased forward by S.G.I. in back-to-back',
    waitAMomentToVerifyYourAccess: 'wait a moment to verify your access.',
    website: 'Website',
    weight: 'Weight',
    weightList: 'Weight List',
    weights: 'Weights',
    willNotBeAbleToReturnUntilYouLogin: 'Will not be able to return until you login again',
    with: 'with',
    withdrawalAmount: 'Montant du retraits',
    withdrawalDemandView: 'Withdrawal demand view',
    withdrawalRequest: 'Withdrawal request',
    withdrawalRequestList: 'Withdrawal request list',
    withdrawalRequests: 'Withdrawal requests',
    withdrawalStatus: 'Withdrawal status',
    withdrawals: 'Withdrawals',
    years: 'Years',
    yes: 'Yes',
    yesDelete: 'Yes, delete',
    yesLogOut: 'Yes, log out',
    youAreWishingToEnterWithTheFollowingEmailAddress: 'You are wishing to enter with the following email address:',
    youHaveSuccessfullyResetYourPassword: 'You have successfully reset your password, now you can log in again. Will be redirected to login page.',
    youHaveSuccessfullySignedOut: 'You have successfully signed out!',
    youMustAcceptTheTermsAndConditions: 'You must accept the terms and conditions',
    youWantLogOut: 'Do you want to log out??',
    youWillNotBeAbleToUndo: 'You will not be able to undo this action.',
    yourAccountIsNotVerified: 'Your account is not verified. Please validate your account through your email address, by clicking',
    yourSessionHasBeenClosed: 'Your session has been closed, please register !!',
    yourSessionHasExpired: 'Your session has expired, please register again !!',
    yourSessionIsActiveCongratulations: 'Your session is active, congratulations!!',
    zipCodeIsRequired: 'ZIP code is required',
    treeStructure: 'Tree structure',
    communicatedTo: 'Communicated to',
    database: 'Database',
    modifyDatabase: 'Modify in database',
    countryResidence: 'Country of residence',
    residentialAddress: 'Residential address',
    professionalActivityInformation: 'Professional activity information',
    originAndDeterminationAssetValues: 'Origin and determination of asset values',
    currentValuation: 'Current valuation',
    price: 'Price',

  }
}