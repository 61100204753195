import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError, getMessage } from '../../languages/context/response';
import { AuthenticationService } from '../../services/authentication.service';
import { Error } from '../../models/error.model';
import { Message } from '../../models/message.model';
import { inputValidRequiredIcon, inputInvalidRequiredIcon } from '../../types/legend.input.type';
import { expressions } from '../../scripts/regular.expressions.script';

export interface RecoverPageProps {};

let errorResponse: Error, messageResponse: Message;

const RecoverPage: React.FunctionComponent<RecoverPageProps> = props => {
  const {lang} = StateLanguage()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [loadIndicator, setLoadIndicator] = useState('off')
  const [email, setEmail] = useState({value: '', valid: false})

  const submitRecover = async (event: React.ChangeEvent <HTMLFormElement>) => {
    event.preventDefault()
    setLoadIndicator('on')

    if (email.valid === true) {
      await AuthenticationService.recover(email.value).then( (response) => {
        if (response.status === 200) {
          messageResponse = response.data

          Swal.fire({
            title: getMessage(messageResponse.message, lang.codeLanguage),
            text: lang.labels.messageWillBeSentYourEmailToRecoverAccount,
            icon: 'success',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: {confirmButton:'h-100 btn btn-primary'},
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            navigate('/auth/login')
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        inputInvalidRequiredIcon(email.value, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
        setLoadIndicator('off')
      })
    }
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const validateEmail = () => {
    if (expressions.email.test(email.value)) {
      setEmail({...email , valid: true})
      inputValidRequiredIcon('input-email', 'container-validate-email-valid', 'container-validate-email-required')
    } else {
      setEmail({...email , valid: false})
      inputInvalidRequiredIcon(email.value, 'input-email', 'container-validate-email-valid', 'container-validate-email-required')
    }
  }

  useEffect( () => {
    setMounted(true)

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
      <form className="form w-100" noValidate onSubmit={submitRecover}>
        <div className="text-center mb-8">
          <h1 className="text-dark mb-3">{lang.labels.forgotPassword}</h1>
          <div className="text-gray-400 fw-bold fs-5">{lang.labels.enterYourEmailToResetYourPassword}</div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">{lang.labels.email}</label>
          <input id="input-email" className="form-control form-control-lg form-control-solid" type="email" name="email" autoComplete="off" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
          <div id="container-validate-email-valid" className="fv-plugins-message-container invalid-feedback d-none">
            <div data-field="email" data-validator="emailAddress">{lang.labels.theValueIsNotAValidEmailAddress}</div>
          </div>
          <div id="container-validate-email-required" className="fv-plugins-message-container invalid-feedback d-none">
            <div data-field="email" data-validator="notEmpty">{lang.labels.emailAddressIsRequired}</div>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center">
          <button className="btn btn-lg btn-primary fw-bolder me-4" type="submit" data-kt-indicator={loadIndicator}>
            <span className="indicator-label">{lang.labels.submit}</span>
            <span className="indicator-progress">
              {lang.labels.pleaseWait}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          <Link to="/auth/login" className="btn btn-lg btn-light-primary fw-bolder">{lang.labels.cancel}</Link>
        </div>
      </form>
    </div>
  )
};

export default RecoverPage;
