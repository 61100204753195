import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { FaExclamationCircle } from 'react-icons/fa';

import { StateLanguage } from '../../languages/config/StateLanguage';
import { getError } from '../../languages/context/response';
import { Error } from '../../models/error.model';
import { ClientModel } from '../../models/client.model';
import { inputValid, inputInvalid, restartInput } from '../../types/legend.input.type';
import { modalHide } from '../../types/modal.type';
import { CommunicationService } from '../../services/communication.service';
import { CommunicationModel } from '../../models/communication.model';
import { cleanInputFile } from '../../types/input.file.type';
import { uploadTooltip } from '../../types/tooltip.type';
import { expressions } from '../../scripts/regular.expressions.script';

interface AppModalCommunicationCreateProps {
  client: ClientModel | undefined | null,
  communications: CommunicationModel[] | undefined | null,
  setCommunications: Dispatch<SetStateAction<CommunicationModel[] | undefined | null>>
};

let errorResponse: Error, serviceResponse: any;

const AppModalCommunicationCreate: React.FunctionComponent<AppModalCommunicationCreateProps> = ({client, communications, setCommunications}) => {
  const {lang} = StateLanguage()

  const [loadIndicator, setLoadIndicator] = useState('off')
  const [loadFiles, setLoadFiles] = useState('off')
  const [title, setTitle] = useState({value: '', valid: false})
  const [message, setMessage] = useState({value: '', valid: false})
  const [archivesValue, setArchivesValue] = useState<{name: string, size: number, type: string, file: any}[]>([])
  const [archivesValid, setArchivesValid] = useState<{file: boolean, message: string}[]>([])

  const submitCommunicationCreate = async () => {
    setLoadIndicator('on')

    let validArchives = archivesValid.length === 0 || archivesValid.every(item => item.file)

    if (client && communications && title.valid && message.valid && validArchives) {
      let fileList = archivesValue.map(archive => archive.file)

      await CommunicationService.postCommunication([client.id], title.value, message.value, fileList).then( (response) => {
        if (response.status === 201) {
          serviceResponse = response.data
          setCommunications([serviceResponse[0], ...communications])

          Swal.fire({
            title: lang.labels.successfullyCreatedCommunication,
            text: lang.labels.updatingCommunicationListReturningToPage,
            icon: 'success',
            showConfirmButton: false,
            timer: 1800
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
            executeHideModalCommunicationCreate()
          })
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions).then( () => {
            setLoadIndicator('off')
          })
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions).then( () => {
        setLoadIndicator('off')
      })
    }
  } 

  const handleChangeTitle = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTitle({...title, value: event.target.value})
  }

  const handleChangeMessage = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setMessage({...message, value: event.target.value})
  }

  const handleChangeFiles = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLoadFiles('on')

    let archiveValueList: {name: string, size: number, type: string, file: any}[] = []
    let archiveValidList: {file: boolean, message: string}[] = []
    let archivesCapture: any = event.target.files
    let archiveType: string[] = ['application/pdf']
    let archiveSize: number = 10485760

    for (let archiveCapture of archivesCapture) {

      if (!archivesValue.some((existingArchive) => existingArchive.name === archiveCapture.name)) {
        let archiveValue: {name: string, size: number, type: string, file: any} = {
          name: archiveCapture.name,
          size: Number((archiveCapture.size / 1048576).toFixed(2)),
          type: archiveCapture.type,
          file: archiveCapture,
        }

        let archiveValid: {file: boolean, message: string} = 
        (!(archiveType === undefined || (archiveType && archiveType.includes(archiveCapture.type))))
          ? {file: false, message: lang.labels.invalidFileType}
          : (!((archiveSize !== 0 && archiveCapture.size <= archiveSize) || archiveSize === 0)) 
            ? {file: false, message: lang.labels.fileSizeNotAllowed}
            : {file: true, message: lang.labels.valid}

        archiveValueList.push(archiveValue)
        archiveValidList.push(archiveValid)
      }
    }

    setArchivesValue([...archivesValue, ...archiveValueList]);
    setArchivesValid([...archivesValid, ...archiveValidList]);
    setLoadFiles('off')
  }

  const handleRemoveFile = (index: number) => {
    let tempUploadIdentificationsValue = archivesValue.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempUploadIdentificationsValid = archivesValid.filter((_temp_item, temp_index) => (temp_index !== index))

    setArchivesValue(tempUploadIdentificationsValue)
    setArchivesValid(tempUploadIdentificationsValid)
    cleanInputFile()
  }

  const validateTitle = () => {
    if (expressions.text.test(title.value)) {
      setTitle({...title, valid: true})
      inputValid('modal-communication-create-input-title')
    } else {
      setTitle({...title, valid: false})
      inputInvalid('modal-communication-create-input-title')
    }
  }

  const validateMessage = () => {
    if (expressions.textsize.test(message.value)) {
      setMessage({...message, valid: true})
    } else {
      setMessage({...message, valid: false})
    }
  }

  const executeHideModalCommunicationCreate = () => {
    modalHide('modal-communication-create')

    setTimeout( () => {
      restartInput('modal-communication-create-input-title')

      setTitle({value: "", valid: false})
      setMessage({value: "", valid: false})
      setArchivesValue([])
      setArchivesValid([])

      cleanInputFile()
    }, 200 )
  }

  useEffect( () => {
    uploadTooltip()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id="modal-communication-create" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog mw-700px">
        <div className="modal-content">
          <div className="modal-header">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.addCommunication}</h2>
            </div>
          </div>
          <div className="modal-body">
            { client
              ?
              <div className="col">
                <div className="col-xl-12 mb-5">
                  <label className="form-label">{lang.labels.client}:</label>
                  <label className="fs-5 fw-bold ms-3">{client.first_name} {client.last_name}</label>
                </div>
                <div className="col-xl-12 mb-5">
                  <label className="form-label required">{lang.labels.communicationTitle}</label>
                  <input id="modal-communication-create-input-title" className="form-control form-control-solid" type="text" name="title" value={title.value} onChange={handleChangeTitle} onKeyUp={validateTitle} onBlur={validateTitle} />
                </div>
                <div className="col-xl-12 mb-5">
                  <label className="form-label required">{lang.labels.message}</label>
                  <textarea className="form-control form-control-solid" rows={5} name="message" autoComplete="off" value={message.value} onChange={handleChangeMessage} onKeyUp={validateMessage} onBlur={validateMessage} />
                </div>
                <div className="col-xl-12">
                  <label className="form-label d-flex align-items-center">
                    {lang.labels.files}
                    <i className="ms-2 fs-7" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title={lang.labels.maximumFileFormat10MBPerFile}><FaExclamationCircle /></i>
                  </label>
                  <div className="dropzone dropzone-queue cursor-pointer-none">
                    <div className="dropzone-panel">
                      <div className="position-relative btn btn-outline bg-gray-100 h-55px d-flex align-items-center" data-kt-indicator={loadFiles}>
                        <input className="position-absolute opacity-0 w-100 h-100 top-0 bottom-0 start-0 end-0 cursor-pointer" multiple type="file" name="file" accept=".pdf" onChange={handleChangeFiles} />
                        <span className="svg-icon svg-icon-1 me-3">
                          <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" stroke="#000000">
                            <path d="M17 9.00195C19.175 9.01406 20.3529 9.11051 21.1213 9.8789C22 10.7576 22 12.1718 22 15.0002V16.0002C22 18.8286 22 20.2429 21.1213 21.1215C20.2426 22.0002 18.8284 22.0002 16 22.0002H8C5.17157 22.0002 3.75736 22.0002 2.87868 21.1215C2 20.2429 2 18.8286 2 16.0002L2 15.0002C2 12.1718 2 10.7576 2.87868 9.87889C3.64706 9.11051 4.82497 9.01406 7 9.00195" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round"></path>
                            <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#aaaebe" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg>
                        </span>
                        <span className="indicator-label text-muted">{lang.labels.selectOrDragDocuments}</span>
                        <span className="indicator-progress text-muted">
                          {lang.labels.pleaseWait}
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      </div>
                    </div>
                    <div className="dropzone-items">
                      { archivesValue.map (( (item, index) => { return (
                        <div key={index} className="dropzone-item dz-image-preview">
                          <div className="dropzone-file">
                            <div className="dropzone-filename d-flex align-items-center text-dark">
                              <span className="svg-icon svg-icon-2 me-3">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM16 13H13V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V13H8C7.4 13 7 13.4 7 14C7 14.6 7.4 15 8 15H11V18C11 18.6 11.4 19 12 19C12.6 19 13 18.6 13 18V15H16C16.6 15 17 14.6 17 14C17 13.4 16.6 13 16 13Z" fill="black"/>
                                  <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="black"/>
                                </svg>
                              </span>
                              <span>{item.name}</span>
                              <span className="mx-3">-</span>
                              <span>{item.size} MB</span>
                            </div>
                            { !archivesValid[index].file &&
                              <div className="dropzone-error">{archivesValid[index].message}</div>
                            }
                          </div>
                          <div className="dropzone-toolbar">
                            <span className="dropzone-delete" onClick={() => handleRemoveFile(index)}>
                              <i className="bi bi-x fs-1"></i>
                            </span>
                          </div>
                        </div>
                      )}))}
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" data-kt-indicator={loadIndicator} onClick={submitCommunicationCreate}>
              <span className="indicator-label">{lang.labels.save}</span>
              <span className="indicator-progress">
                {lang.labels.pleaseWait}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
            <button className="btn btn-light-primary ms-3" type="reset" onClick={executeHideModalCommunicationCreate}>
              {lang.labels.cancel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default AppModalCommunicationCreate;
