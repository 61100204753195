import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { Message } from '../../../models/message.model';
import { IngotModel } from '../../../models/ingot.model';
import { UseOutletContextIngot } from './Ingot';
import { IngotService } from '../../../services/ingot.service';
import { moneyFormat } from '../../../scripts/money.format.script';
import { imageIngot } from '../../../scripts/image.object.script';
import { weightUnitFormat } from '../../../scripts/weight.units.script';

export interface IngotViewPageProps {};

let errorResponse: Error, messageResponse: Message, ingotResponse: IngotModel;

const IngotViewPage: React.FunctionComponent<IngotViewPageProps> = props => {
  const {setRoute} = UseOutletContextIngot()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [ingot, setIngot] = useState<IngotModel| undefined | null>(null)

  const loadIngot = async (id_ingot: string) => {
    await IngotService.getIngot(id_ingot).then( (response) => {
      if (response.status === 200) {
        ingotResponse = response.data
        setIngot(ingotResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitIngotDelete = async () => {
    if (ingot) {
      Swal.fire({
        title: lang.labels.areYouSureYouWanToDeleteThisIngot,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
            await IngotService.deleteIngot(ingot.id).then( (response) => {
              if (response.status === 200) {
                messageResponse = response.data

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.codeLanguage),
                  text: lang.labels.updatingIngotListReturningToPage,
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions).then( () => {
                  navigate("/app/ingot/list")
                })
              } else {
                errorResponse = response.data

                Swal.fire({
                  title: getError(errorResponse.code, lang.codeLanguage),
                  text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                  icon: 'error',
                  buttonsStyling: !1,
                  confirmButtonText: lang.labels.OkGotIt,
                  customClass: { confirmButton:'h-100 btn btn-primary' }
                } as SweetAlertOptions)
              }
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          }
      })
    }
  }

  useEffect( () => {
    if(param.id) {
      loadIngot(param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.ingots, branch: lang.labels.ingotView}, browse: null})

    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { ingot
        ?
        <>
          <div className="card overflow-hidden border border-gray-300 mb-10">
            <div className="card-header py-5"> 
              <div className="card-title">
                <div className="d-sm-flex align-items-center mb-5 mb-sm-0">
                  <a href={ingot.photo_ingot} className="symbol symbol-100px mb-3 mb-sm-0" target="_blank" rel="noopener noreferrer">
                    <img src={imageIngot(ingot.photo_ingot)} alt="photography"/>
                  </a>
                  <div className="ms-5 d-flex flex-column justify-content-center">
                    <span className="fw-bold fs-5 text-gray-600 text-uppercase">
                      {ingot.available ? lang.labels.available : lang.labels.noAvailable}
                    </span>
                    <span className="fw-bolder text-gray-800 fs-1">
                      {ingot.serial_number}
                    </span>
                    <span className="fw-bolder text-gray-700">
                      {ingot.refiner.name} {ingot.weight.value} {weightUnitFormat(ingot.weight.unity, lang)} {ingot.purity.value}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
                <Link to={"/app/ingot/update/" + ingot.id} className="btn btn-primary">{lang.labels.editIngot}</Link>
                <button className="btn btn-light-primary" type="button" onClick={submitIngotDelete}>{lang.labels.deleteIngot}</button>
              </div>
            </div>
          </div>
          <div className="bg-transparent mb-5">
            <h3 className="fw-bold">{lang.labels.information}</h3>
          </div>
          <div className="card card-flush bg-transparent">
            <div className="card-body p-0">
              <div className="row d-flex flex-wrap">
                <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5">
                  <div className="d-flex flex-column h-100 p-5 border rounded-3 bg-white border border-gray-300">
                    <p className="fw-bolder mb-3 fs-6">{lang.labels.originalValue}</p>
                    <span className="fw-bolder lh-2 fs-2x">{moneyFormat(ingot.original_value)}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5">
                  <div className="d-flex flex-column h-100 p-5 border rounded-3 bg-white border border-gray-300">
                    <p className="fw-bolder mb-3 fs-6">{lang.labels.refiner}</p>
                    <span className="fw-bolder lh-2 fs-2x">{ingot.refiner.name}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5">
                  <div className="d-flex flex-column h-100 p-5 border rounded-3 bg-white border border-gray-300">
                    <p className="fw-bolder mb-3 fs-6">{lang.labels.ingotWeight}</p>
                    <span className="fw-bolder lh-2 fs-2x">{ingot.weight.value} {ingot.weight.unity}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-5">
                  <div className="d-flex flex-column h-100 p-5 border rounded-3 bg-white border border-gray-300">
                    <p className="fw-bolder mb-3 fs-6">{lang.labels.purity}</p>
                    <span className="fw-bolder lh-2 fs-2x">{ingot.purity.value}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          { ingot.investment &&
            <div className="card overflow-hidden border border-gray-300 mb-5">
              <h4 className="p-5 fw-bold">{lang.labels.allocationDetails}</h4>
              <Link to={"/app/investment/view/" + ingot.investment.client.id + "/" + ingot.investment.id} className="row bg-hover-light rounded-3 mt-0 mb-10 mx-3 p-3">
                <div className="col-12 col-sm-4 px-3">
                  <div className="d-flex flex-column mb-3 mb-sm-0">
                    <span className="fw-bolder fs-8 text-muted">{lang.labels.volumeOfGoldPurchased}</span>
                    <span className="fw-bolder fs-6 text-dark">{ingot.investment.gold_volume_purchased}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 px-3">
                  <div className="d-flex flex-column mb-3 mb-sm-0">
                    <span className="fw-bolder fs-8 text-muted">{lang.labels.originalPriceOfGold}</span>
                    <span className="fw-bolder fs-6 text-dark">{moneyFormat(ingot.investment.original_price_gold)}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 px-3">
                  <div className="d-flex flex-column mb-3 mb-sm-0">
                    <span className="fw-bolder fs-8 text-muted">{lang.labels.client}</span>
                    <span className="fw-bolder fs-6 text-dark">{ingot.investment.client.first_name} {ingot.investment.client.last_name}</span>
                  </div>
                </div>
              </Link>
            </div>
          }
          { ingot.photo_certificate.length > 0 &&
            <div className="card card-flush bg-transparent"> 
              <div className="card-body p-0">
                <div className="d-flex justify-content-center h-100 p-5 border rounded-3 bg-white border border-gray-300">
                  <div className="d-flex flex-column">
                    <a href={ingot.photo_certificate} className="symbol symbol-200px" target="_blank" rel="noopener noreferrer">
                      <img src={ingot.photo_certificate} alt="photography"/>
                    </a>
                    <div className="mt-5 fs-6 fw-bold text-gray-900 text-center">
                      {lang.labels.certificateOfAuthenticity}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }    
    </div>
  )
};

export default IngotViewPage;
