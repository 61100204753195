import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { modalShow } from '../../../types/modal.type';
import { collapsible } from '../../../types/collapsible.type';
import { UseOutletContextClient } from './Client';
import { ClientModel } from '../../../models/client.model';
import { ClientService } from '../../../services/client.service';
import { CommunicationModel } from '../../../models/communication.model';
import { Message } from '../../../models/message.model';
import { Error } from '../../../models/error.model';
import { getName } from '../../../scripts/countries.script';
import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError, getMessage } from '../../../languages/context/response';
import { CommunicationService } from '../../../services/communication.service';
import { InvestmentService } from '../../../services/investment.service';
import { DocumentService } from '../../../services/document.service';
import { WithdrawalRequestService } from '../../../services/withdrawal.request.service';
import { DocumentModel } from '../../../models/document.model';
import { dateFormat } from '../../../scripts/date.format.script';
import { WithdrawalRequestModel } from '../../../models/withdrawal.request.model';
import { moneyFormat } from '../../../scripts/money.format.script';
import { InvestmentModel } from '../../../models/investment.model';
import { civilStatusValue } from '../../../scripts/civil.status.script';
import { pensionPerYearValue } from '../../../scripts/pension.per.year.script';
import { fortuneValue } from '../../../scripts/fortune.script';
import { documentTypeList } from '../../../scripts/document.type.script';
import { ibanFormat } from '../../../scripts/iban.format.script';
import { bicFormat } from '../../../scripts/bic.format.script';
import { HeritageOriginModel } from '../../../models/heritage.origin.model';
import AppModalInvestmentUpdate from '../../../components/modal/ModalInvestmentUpdate';
import DocumentClientComponent from './document/DocumentListView';
import AppModalCommunicationCreate from '../../../components/modal/ModalCommunicationCreate';
import AppModalDocumentCreate from '../../../components/modal/ModalDocumentCreate';
import AppModalSpouseView from '../../../components/modal/ModalSpouseView';

export interface ClientViewPageProps {};

let errorResponse: Error, messageResponse: Message, clientResponse: ClientModel, investmentsResponse: InvestmentModel[], communicationsResponse: CommunicationModel[], withdrawalRequestsResponse: WithdrawalRequestModel[], documentsResponse: DocumentModel[], investmentResponse: any;

const ClientViewPage: React.FunctionComponent<ClientViewPageProps> = props => {
  const {setRoute, notaries, ingots, rates, intermediaries, securityCompanies} = UseOutletContextClient()
  const {lang} = StateLanguage()
  const param = useParams()
  const navigate = useNavigate()

  const [mounted, setMounted] = useState(false)
  const [expanded, setExpanded] = useState({heritages: false, funds: false})
  const [isHovered, setIsHovered] = useState(false)
  const [client, setClient] = useState<ClientModel | undefined | null>(null)
  const [investments, setInvestments] = useState<InvestmentModel[] | undefined | null>(null)
  const [communications, setCommunications] = useState<CommunicationModel[] | undefined | null>(null)
  const [withdrawalRequests, setWithdrawalRequests] = useState<WithdrawalRequestModel[] | undefined | null>(null)
  const [documents, setDocuments] = useState<DocumentModel[] | undefined | null>(null)
  const [searchCommunications, setSearchCommunications] = useState({text: {value: '', valid: false}, list: [] as CommunicationModel[]})
  const [searchDocuments, setSearchDocuments] = useState({text: {value: '', valid: false}, list: [] as DocumentModel[]})
  const [investment, setInvestment] = useState<InvestmentModel | undefined | null>(null)

  const loadClient = async (id_client: string) => {
    await ClientService.getClient(id_client).then( (response) => {
      if (response.status === 200) {
        clientResponse = response.data
        setClient(clientResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadInvestments = async (id_client: string) => {
    await InvestmentService.getInvestments(id_client).then( (response) => {
      if (response.status === 200) {
        investmentsResponse = response.data.investments
        setInvestments(investmentsResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadCommunications = async (id_client: string) => {
    await CommunicationService.getClientCommunications(id_client).then( (response) => {
      if (response.status === 200) {
        communicationsResponse = response.data.communications
        setCommunications(communicationsResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadWithdrawalRequests = async (id_client: string) => {
    await WithdrawalRequestService.getClientWithdrawalRequests(id_client).then( (response) => {
      if (response.status === 200) {
        withdrawalRequestsResponse = response.data.withdrawal_requests
        setWithdrawalRequests(withdrawalRequestsResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const loadDocuments = async (id_client: string) => {
    await DocumentService.getDocuments(id_client).then( (response) => {
      if (response.status === 200) {
        documentsResponse = response.data.documents
        setDocuments(documentsResponse)
      } else {
        errorResponse = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const submitClientDelete = async () => {
    if (client) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisClient,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
            await ClientService.deleteClient(client.id, false).then( (response) => {
              if (response.status === 200) {
                messageResponse = response.data

                Swal.fire({
                  title: getMessage(messageResponse.message, lang.codeLanguage),
                  text: "Updating client list",
                  icon: 'success',
                  showConfirmButton: false,
                  timer: 1800
                } as SweetAlertOptions).then( () => {
                  navigate('/app/client/list')
                })
              } else {
                errorResponse = response.data

                Swal.fire({
                  title: getError(errorResponse.code, lang.codeLanguage),
                  text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                  icon: 'error',
                  buttonsStyling: !1,
                  confirmButtonText: lang.labels.OkGotIt,
                  customClass: { confirmButton:'h-100 btn btn-primary' }
                } as SweetAlertOptions)
              }
            }).catch( (error) => {
              console.error(error)
              window.location.href = '/error'
            })
          }
      })
    }
  }

  const submitInvestmentUpdate = async (id_investment: string) => {
    if (client) {
      await InvestmentService.getInvestment(client.id, id_investment).then( (response) => {
        if (response.status === 200) {
          investmentResponse = response.data
          setInvestment(investmentResponse)
          modalShow('modal-investment-update')
        } else {
          errorResponse = response.data

          Swal.fire({
            title: getError(errorResponse.code, lang.codeLanguage),
            text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
            icon: 'error',
            buttonsStyling: !1,
            confirmButtonText: lang.labels.OkGotIt,
            customClass: { confirmButton:'h-100 btn btn-primary' }
          } as SweetAlertOptions)
        }
      }).catch( (error) => {
        console.error(error)
        window.location.href = '/error'
      })
    }
  }

  const submitInvestmentDelete = async (id_investment: string) => {
    if (client && investments) {
      Swal.fire({
        title: lang.labels.areYouSureYouWantToDeleteThisInvestment,
        text: lang.labels.youWillNotBeAbleToUndo,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: lang.labels.yesDelete,
        cancelButtonText: lang.labels.noCancel,
        customClass: {confirmButton:'btn btn-primary', cancelButton:'btn btn-secondary' }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await InvestmentService.deleteInvestment(client.id, id_investment).then( (response) => {
            if (response.status === 200) {
              messageResponse = response.data

              let list = investments.filter((item) => (item.id !== id_investment))
              setInvestments(list)

              Swal.fire({
                title: getMessage(messageResponse.message, lang.codeLanguage),
                text: lang.labels.updatingInvestmentList,
                icon: 'success',
                showConfirmButton: false,
                timer: 1800
              } as SweetAlertOptions)
            } else {
              errorResponse = response.data

              Swal.fire({
                title: getError(errorResponse.code, lang.codeLanguage),
                text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
                icon: 'error',
                buttonsStyling: !1,
                confirmButtonText: lang.labels.OkGotIt,
                customClass: { confirmButton:'h-100 btn btn-primary' }
              } as SweetAlertOptions)
            }
          }).catch( (error) => {
            console.error(error)
            window.location.href = '/error'
          })
        }
      })
    }
  }

  const submitCommunicationCreate = () => {
    modalShow('modal-communication-create')
  }

  const submitDocumentCreate = () => {
    modalShow('modal-document-create')
  }

  const handleChangeSearchCommunication = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let validity: boolean = false
    let list: CommunicationModel[] = []

    if (communications && text.length > 0) {
      for (let communication of communications) {
        let title: string = communication.title.toLowerCase()
        if (title.indexOf(text.toLowerCase()) !== -1) {
          list.push(communication)
        }
      }
      validity = true
    } else if (communications && text.length === 0) {
      validity = false
      list = communications
    }

    setSearchCommunications({...searchCommunications, text: {valid: validity, value: text}, list: list})
  }

  const handleChangeSearchDocuments = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let validity: boolean = false
    let list: DocumentModel[] = []

    if (documents && text.length > 0) {
      for (let document of documents) {
        let title: string = document.name.toLowerCase()
        if (title.indexOf(text.toLowerCase()) !== -1) {
          list.push(document)
        }
      }
      validity = true
    } else if (documents && text.length === 0) {
      validity = false
      list = documents
    }

    setSearchDocuments({...searchDocuments, text: {valid: validity, value: text}, list: list})
  }

  const executeShowModalSpouseView = () => {
    modalShow('modal-spouse-view')
  }

  const executeCollapsible = () => {
    collapsible('button-collapsible-details', 'container-collapsible-details')
  }

  const executeToggleExpandedHeritages = () => {
    setExpanded({...expanded, heritages: !expanded.heritages})
  }

  const executeToggleExpandedFunds = () => {
    setExpanded({...expanded, funds: !expanded.funds})
  }

  const executeMouseEnter = () => {
    setIsHovered(true)
  }

  const executeMouseLeave = () => {
    setIsHovered(false)
  }

  function stringHeritageOrigin(listHeritageOrigin: HeritageOriginModel[]): string {
    return listHeritageOrigin.map(item => item.description).join(' | ')
  }

  useEffect( () => {
    if (communications) {
      setSearchCommunications({...searchCommunications, list: communications})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communications])

  useEffect( () => {
    if (documents) {
      setSearchDocuments({...searchDocuments, list: documents})
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  useEffect( () => {
    if(param.id) {
      loadClient(param.id)
      loadInvestments(param.id)
      loadCommunications(param.id)
      loadWithdrawalRequests(param.id)
      loadDocuments(param.id)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param])

  useEffect( () => {
    setMounted(true)
    setRoute({main: false, path: {root: lang.labels.clients, branch: lang.labels.clientView}, browse: null})
    
    return () => setMounted(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <div className="w-100 h-100">
      { client && investments && communications && withdrawalRequests && documents
        ?
        <>
          <div className="card mb-8">
            <div className="card-header py-5">
              <div className="card-title">
                <div className="d-sm-flex align-items-center">
                  <div className="symbol symbol-100px symbol-circle mb-3 mb-sm-0">
                    <div className="symbol-label fs-1 bg-light-primary text-primary">
                      {!client.test_client ? `${client.first_name.charAt(0)}${client.last_name.charAt(0)}` : "TEST"}
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-center ms-5">
                    <span className="fw-bolder text-uppercase">
                      { !client.test_client
                        ?
                        <small className="text-info">{lang.labels.client}</small> 
                        :
                        <small className="text-danger">{lang.labels.testClient}</small> 
                      }
                    </span>
                    <span className="fs-3 text-gray-800 fw-bolder">
                      {client.last_name} {client.first_name}
                    </span>
                    <span className="fs-6 text-gray-600 fw-bold">
                      {client.email.length > 0 && client.email}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-toolbar flex-row-fluid justify-content-end gap-3">
                <Link to={"/app/client/update/" + client.id} className="btn btn-primary">{lang.labels.editClientFile}</Link>
                <button className="btn btn-light-primary bd-highlight" type="button" onClick={submitClientDelete}>{lang.labels.deleteClient}</button>
              </div>
            </div>
            <div className="card-body">
              <div className="card">
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.lastName}</span>
                    <span className="fs-6">{client.last_name}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.firstName}</span>
                    <span className="fs-6">{client.first_name}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.dateOfBirth}</span>
                    <span className="fs-6">{client.birthdate.length > 0 ? dateFormat(client.birthdate, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.phone}</span>
                    <span className="fs-6">{client.phone.length > 0 ? client.phone : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.email}</span>
                    <span className="fs-6">{client.email.length > 0 ? client.email : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.address}</span>
                    <span className="fs-6">{client.address.street.length > 0 ? client.address.street : lang.labels.none}</span>
                  </div>
                </div>
              </div>
              <div id="container-collapsible-details" className="collapse">
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.city}</span>
                    <span className="fs-6">{client.address.city.length > 0 ? client.address.city : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.iban}</span>
                    <span className="fs-6">{client.bank_info.iban.length > 0 ? ibanFormat(client.bank_info.iban) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.bicSwift}</span>
                    <span className="fs-6">{client.bank_info.swift_bic.length > 0 ? bicFormat(client.bank_info.swift_bic) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.country}</span>
                    <span className="fs-6">{client.address.country.length > 0 ? getName(client.address.country, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.taxpayerNumber}</span>
                    <span className="fs-6">{client.taxpayer_number.length > 0 ? client.taxpayer_number : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.pepClose}</span>
                    <span className="fs-6 text-uppercase">{client.pep_family ? lang.labels.yes : lang.labels.no}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.nationality}</span>
                    <span className="fs-6">{client.nationality.length > 0 ? getName(client.nationality, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.maritalStatus}</span>
                    <span className="fs-6">{client.civil_status.length > 0 ? civilStatusValue(client.civil_status, lang) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.professionalActivity}</span>
                    <span className="fs-6">{client.current_professional_info.professional_activity.length > 0 ? client.current_professional_info.professional_activity : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.firstNameAndLastNameFather}</span>
                    <span className="fs-6">{client.parents_info.father.length > 0 ? client.parents_info.father : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.maidenNameMother}</span>
                    <span className="fs-6">{client.parents_info.mother.length > 0 ? client.parents_info.mother : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.startDateOfBackgroundOn}</span>
                    <span className="fs-6">{client.current_professional_info.function_start_date.length > 0 ? dateFormat(client.current_professional_info.function_start_date, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.currentEmployer}</span>
                    <span className="fs-6">{client.current_professional_info.current_employer.length > 0 ? client.current_professional_info.current_employer : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.countryOfCurrentEmployer}</span>
                    <span className="fs-6">{client.current_professional_info.country_current_employer.length > 0 ? getName(client.current_professional_info.country_current_employer, lang.codeLanguage) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.fieldOfActivity}</span>
                    <span className="fs-6">{client.current_professional_info.activity_area.length > 0 ? client.current_professional_info.activity_area : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.previousProfessionalActivity}</span>
                    <span className="fs-6">{client.previus_professional_experencie.previus_profesional_activity.length > 0 ? client.previus_professional_experencie.previus_profesional_activity : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.previousEmployer}</span>
                    <span className="fs-6">{client.previus_professional_experencie.previus_employer.length > 0 ? client.previus_professional_experencie.previus_employer : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.diploma}</span>
                    <span className="fs-6">{client.diploma.length > 0 ? client.diploma : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.memberOfTheBoardOfDirectors}</span>
                    <span className="fs-6 text-uppercase">{client.board_member ? lang.labels.yes : lang.labels.no}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.incomeOrPensionReceivedForAnYear}</span>
                    <span className="fs-6">{client.financial_info.annual_income_or_pension.length > 0 ? pensionPerYearValue(lang, client.financial_info.annual_income_or_pension) : lang.labels.none}</span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.fortuneIncludingRealEstate}</span>
                    <span className="fs-6">{client.financial_info.fortune.length > 0 ? fortuneValue(lang, client.financial_info.fortune) : lang.labels.none}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.originOfHeritageValues}</span>
                    <span className="fs-6">
                      { client.financial_info.origin_of_patrimonial_assets.length > 0
                        ?
                        <>
                          { stringHeritageOrigin(client.financial_info.origin_of_patrimonial_assets).length > 120
                            ?
                            <>
                              { expanded.heritages
                                ?
                                <>
                                  {stringHeritageOrigin(client.financial_info.origin_of_patrimonial_assets)}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedHeritages}>{lang.labels.less}</label>
                                </>
                                :
                                <>
                                  {stringHeritageOrigin(client.financial_info.origin_of_patrimonial_assets).slice(0, 120)}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedHeritages}>{lang.labels.seeMore}...</label>
                                </>
                                }
                            </>
                            :
                            stringHeritageOrigin(client.financial_info.origin_of_patrimonial_assets)
                          }
                        </>
                        :
                        lang.labels.none
                      }
                    </span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.sourceOfFunds}</span>
                    <span className="fs-6">
                      { client.financial_info.source_of_funds.length > 0
                        ?
                        <>
                          { client.financial_info.source_of_funds.length > 120
                            ?
                            <>
                              { expanded.funds
                                ?
                                <>
                                  {client.financial_info.source_of_funds}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedFunds}>{lang.labels.less}</label>
                                </>
                                :
                                <>
                                  {client.financial_info.source_of_funds.slice(0, 120)}
                                  <label className="ms-2 fw-bold text-primary text-lowercase cursor-pointer" onClick={executeToggleExpandedFunds}>{lang.labels.seeMore}...</label>
                                </>
                                }
                            </>
                            :
                            client.financial_info.source_of_funds
                          }
                        </>
                        :
                        lang.labels.none
                      }
                    </span>
                  </div>
                  <div className="col-md-4 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted">{lang.labels.testClient}</span>
                    <span className={`fs-6 fw-bold text-uppercase ${client.test_client ? 'text-danger' : 'text-success'}`}>
                      { client.test_client ? lang.labels.yes : lang.labels.no }
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-stack fs-4 pt-3">
                <div id="button-collapsible-details" className="d-flex justify-content-center w-100 rotate collapsible collapsed" data-bs-toggle="collapse" role="button" onClick={executeCollapsible}>
                  <span className="rotate-180">
                    <span className="svg-icon svg-icon-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="black" />
                      </svg>
                    </span>
                  </span>
                </div>
              </div>
              { client.spouse &&
                <>
                  <div className="separator my-5"></div>
                  <h6 className="mb-3">{lang.labels.spouseInformation}</h6>
                  <div className="row">
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                      <span className="text-muted fs-8">{lang.labels.lastName}</span>
                      <span className="fs-7">{client.spouse.last_name}</span>
                    </div>
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                      <span className="text-muted fs-8">{lang.labels.firstName}</span>
                      <span className="fs-7">{client.spouse.first_name}</span>
                    </div>
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                      <span className="text-muted fs-8">{lang.labels.dateOfBirth}</span>
                      <span className="fs-7">{client.spouse.birthdate.length > 0 ? dateFormat(client.spouse.birthdate, lang.codeLanguage) : lang.labels.none}</span>
                    </div>
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                      <span className="text-muted fs-8">{lang.labels.placeOfBirth}</span>
                      <span className="fs-7">{client.spouse.birthplace.length > 0 ? client.spouse.birthplace : lang.labels.none}</span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted fs-8">{lang.labels.nationality}</span>
                    <span className="fs-7">{client.spouse.nationality.length > 0 ? getName(client.spouse.nationality, lang.codeLanguage) : lang.labels.none}</span>
                    </div>
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                    <span className="text-muted fs-8">{lang.labels.phone}</span>
                    <span className="fs-7">{client.spouse.phone.length > 0 ? client.spouse.phone : lang.labels.none}</span>
                    </div>
                    <div className="col-md-3 mb-3 d-flex flex-row flex-md-column justify-content-between justify-content-md-start border-bottom border-md-0">
                      <span className="text-muted fs-8">{lang.labels.email}</span>
                      <span className="fs-7">{client.spouse.email.length > 0 ? client.spouse.email : lang.labels.none}</span>
                    </div>
                    <div className="col-md-3 d-flex align-items-center border-bottom border-md-0">
                      <label className="fs-6 fw-bolder text-primary cursor-pointer" onClick={executeShowModalSpouseView}>{lang.labels.seeMore}...</label>
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
          <div className="card mb-8">
            <div className="card-header border-0">
              <div className="card-title">
                <h3>{lang.labels.investments}</h3>
              </div>
              <div className="card-toolbar">
                <Link to={"/app/investment/create/" + client.id} className="btn btn-primary">{lang.labels.addInvestment}</Link>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-4">
                    <thead>
                      <tr className="text-muted fw-bolder fs-7 text-uppercase">
                        <th className="min-w-100px text-start">{lang.labels.notarySignature}</th>
                        <th className="min-w-100px text-end">{lang.labels.volumeOfGoldSold}</th>
                        <th className="min-w-100px text-end">{lang.labels.originalValuationOfGold}</th>
                        <th className="min-w-100px text-end">{lang.labels.purchasePriceOfGold}</th>
                        <th className="w-225px text-end"></th>
                      </tr>
                    </thead>
                    { investments.length > 0
                      ?
                      <tbody className="text-gray-600 fw-bold fs-6">
                        { investments.map (( (item, index) => { return (
                          <tr key={index} className="row-dashed bg-hover-light" onClick={() => {navigate(`/app/investment/view/${client.id}/${item.id}`)}} onMouseEnter={executeMouseEnter} onMouseLeave={executeMouseLeave}>
                            <td className="text-start">
                              <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-1">
                                  <svg fill="#000000" width="64px" height="64px" viewBox="-1.7 0 20.4 20.4" xmlns="http://www.w3.org/2000/svg" className="cf-icon-svg">
                                    <g id="SVGRepo_bgCarrier"></g>
                                    <g id="SVGRepo_tracerCarrier"></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zm-4.844 1.754a2.249 2.249 0 0 0-.556-1.477l-.001-.002a3.02 3.02 0 0 0-.835-.665l-.003-.002a3.498 3.498 0 0 0-.866-.313H9.31a3.78 3.78 0 0 0-.795-.083 2.849 2.849 0 0 1-.475-.037 1.8 1.8 0 0 1-.494-.158l-.002-.001a1.17 1.17 0 0 1-.371-.298L7.172 9a.733.733 0 0 1-.175-.44.749.749 0 0 1 .421-.63 2.157 2.157 0 0 1 1.11-.297 2.283 2.283 0 0 1 .391.066l.049.01a2.479 2.479 0 0 1 .473.166 1.33 1.33 0 0 1 .381.261.792.792 0 1 0 1.118-1.12 2.902 2.902 0 0 0-.85-.585 3.996 3.996 0 0 0-.785-.268h-.001l-.008-.002v-.786a.792.792 0 1 0-1.583 0v.763a3.557 3.557 0 0 0-1.14.454 2.328 2.328 0 0 0-1.159 1.967 2.296 2.296 0 0 0 .529 1.44 2.724 2.724 0 0 0 .894.717 3.342 3.342 0 0 0 .942.305 4.398 4.398 0 0 0 .736.059 2.202 2.202 0 0 1 .46.046 1.927 1.927 0 0 1 .467.168 1.431 1.431 0 0 1 .382.308.674.674 0 0 1 .165.436c0 .097 0 .324-.385.573a2.182 2.182 0 0 1-1.132.314 3.515 3.515 0 0 1-.494-.06 2.381 2.381 0 0 1-.459-.148h-.001a.953.953 0 0 1-.356-.274.792.792 0 1 0-1.197 1.037 2.516 2.516 0 0 0 .967.708 3.799 3.799 0 0 0 .774.237h.007v.783a.792.792 0 1 0 1.583 0v-.79a3.581 3.581 0 0 0 1.17-.479 2.215 2.215 0 0 0 1.107-1.9z"></path>
                                    </g>
                                  </svg>
                                </span>
                                <span className="text-gray-900 ms-2">{dateFormat(item.authentic_act_date, lang.codeLanguage)}</span>
                              </div>
                            </td>
                            <td className="text-center">{item.gold_volume_purchased}</td>
                            <td className="text-end">{moneyFormat(item.original_valuation_gold_purchased)}</td>
                            <td className="text-end">{moneyFormat(item.gold_purchase_price)}</td>
                            <td className="text-end py-0">
                              { isHovered &&
                                <>
                                  <button className="btn btn-sm btn-light-primary" onClick={(e) => {e.stopPropagation(); submitInvestmentUpdate(item.id);}}>{lang.labels.modify}</button>
                                  <button className="btn btn-sm btn-light-primary ms-2" onClick={(e) => {e.stopPropagation(); submitInvestmentDelete(item.id);}}>{lang.labels.delete}</button>
                                </>
                              }
                            </td>
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={5} className="text-center">
                            {lang.labels.noMatchingRecordsFound}
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-8">
            <div className="card-header border-0">
              <div className="card-title">
                <h3>{lang.labels.withdrawalRequests}</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-4">
                    <thead>
                      <tr className="text-muted fw-bolder fs-7 text-uppercase">
                        <th className="min-w-200px text-start">{lang.labels.requestFrom}</th>
                        <th className="min-w-200px text-start">{lang.labels.executionScheduledFor}</th>
                        <th className="min-w-200px text-end">{lang.labels.withdrawalAmount}</th>
                      </tr>
                    </thead>
                    { withdrawalRequests.length > 0
                      ?          
                        <tbody className="text-gray-600 fw-bold fs-6">
                          { withdrawalRequests.map(( (item, index) => { return (
                            <tr key={index} className="row-dashed bg-hover-light" onClick={() => {navigate(`/app/withdrawal/view/${client.id}/${item.id}`)}}>
                              <td className="text-start">{dateFormat(item.created_at, lang.codeLanguage)}</td>
                              <td className="text-start">{dateFormat(item.execution_date, lang.codeLanguage)}</td>
                              <td className="text-end text-gray-900">{moneyFormat(item.amount)}</td>
                            </tr>
                          )}))}
                        </tbody>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={3} className="text-center">
                            {lang.labels.noMatchingRecordsFound}
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-8">
            <div className="card-header border-0">
              <div className="card-title">
                <h3>{lang.labels.communications}</h3>
              </div>
              <div className="card-toolbar gap-3">
                <div className="d-flex align-items-center position-relative">
                  <span className="svg-icon svg-icon-1 position-absolute ms-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                    </svg>
                  </span>
                  <input className="form-control form-control-solid w-200px w-md-300px ps-14" maxLength={200} type="text" placeholder={lang.labels.search} onChange={handleChangeSearchCommunication} />
                </div>
                <button className="btn btn-primary" onClick={() => submitCommunicationCreate()}>{lang.labels.addCommunication}</button>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="dataTables_wrapper">
                <div className="table-responsive">
                  <table className="table align-middle table-row-dashed gy-4">
                    <thead>
                      <tr className="text-muted fw-bolder fs-7 text-uppercase">
                        <th className="min-w-100px text-start">{lang.labels.title}</th>
                        <th className="min-w-200px text-start">{lang.labels.message}</th>
                        <th className="min-w-100px text-start">{lang.labels.date}</th>
                      </tr>
                    </thead>
                    { searchCommunications.list.length > 0
                      ?
                      <tbody className="text-gray-600 fw-bold fs-6">
                        { searchCommunications.list.map (( (item, index) => { return (
                          <tr key={index} className="row-dashed bg-hover-light" onClick={() => {navigate(`/app/communication/view/${client.id}/${item.id}`)}}>
                            <td className="text-start">
                              <div className="d-flex align-items-start">
                                <span className="svg-icon svg-icon-2">
                                  <svg  fill="#000000" width="64px" height="64px" viewBox="0 0 32 32" style={{fillRule: 'evenodd',clipRule: 'evenodd',strokeLinejoin: 'round',strokeMiterlimit: 2}} version="1.1"  xmlns="http://www.w3.org/2000/svg" >
                                    <g id="SVGRepo_bgCarrier" ></g>
                                    <g id="SVGRepo_tracerCarrier"></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <path d="M31,10c0,-1.326 -0.527,-2.598 -1.464,-3.536c-0.938,-0.937 -2.21,-1.464 -3.536,-1.464c-5.322,0 -14.678,0 -20,0c-1.326,-0 -2.598,0.527 -3.536,1.464c-0.937,0.938 -1.464,2.21 -1.464,3.536c0,3.486 0,8.514 0,12c-0,1.326 0.527,2.598 1.464,3.536c0.938,0.937 2.21,1.464 3.536,1.464c5.322,-0 14.678,-0 20,-0c1.326,0 2.598,-0.527 3.536,-1.464c0.937,-0.938 1.464,-2.21 1.464,-3.536c0,-3.486 0,-8.514 0,-12Zm-26.556,-0.221c-0,-0 5.145,4.237 8.372,6.894c1.849,1.523 4.519,1.52 6.365,-0.007c3.237,-2.677 8.413,-6.959 8.413,-6.959c0.425,-0.352 0.485,-0.983 0.133,-1.408c-0.351,-0.425 -0.982,-0.485 -1.408,-0.133c0,-0 -5.176,4.281 -8.412,6.959c-1.108,0.916 -2.71,0.918 -3.82,0.004c0,0 -8.372,-6.894 -8.372,-6.894c-0.426,-0.351 -1.056,-0.29 -1.407,0.136c-0.351,0.426 -0.29,1.057 0.136,1.408Z"></path>
                                      <g id="Icon"></g>
                                    </g>
                                  </svg>
                                </span>
                                <span className="text-gray-900 ms-2">{item.title}</span>
                              </div>
                            </td>
                            <td className="text-start">{ item.message.length > 40 ? `${item.message.slice(0,40)}...` : item.message }</td>
                            <td className="text-start">{dateFormat(item.created_at, lang.codeLanguage)}</td>
                          </tr>
                        )}))}
                      </tbody>
                      :
                      <tbody className="text-gray-600 fw-bold">
                        <tr className="odd">
                          <td valign="top" colSpan={3} className="text-center">
                            {lang.labels.noMatchingRecordsFound}
                          </td>
                        </tr>
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-8">
            <div className="card-header border-0">
              <div className="card-title">
                <h3>{lang.labels.documents}</h3>
              </div>
              <div className="card-toolbar gap-3">
                <div className="d-flex align-items-center position-relative">
                  <span className="svg-icon svg-icon-1 position-absolute ms-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black" />
                      <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black" />
                    </svg>
                  </span>
                  <input className="form-control form-control-solid w-200px w-md-300px ps-14" maxLength={200} type="text" placeholder={lang.labels.search} onChange={handleChangeSearchDocuments} />
                </div>
                <button className="btn btn-primary" type="button" onClick={submitDocumentCreate}>
                  <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="black" />
                        <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="black" />
                        <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="black" />
                      </svg>
                    </span>
                    {lang.labels.addDocument}
                  </div>
                </button>
              </div>
            </div>
            <div className="card-body pt-0">
              { searchDocuments.list.length > 0
                ?
                <>
                  { documentTypeList(lang).map (( (item, index) => { return (
                    <DocumentClientComponent key={index} type={item.code} searchDocuments={searchDocuments} client={client} documents={documents} setDocuments={setDocuments} />
                  )}))}
                </>
                :
                <div className="p-10 text-gray-600 text-center fw-bold">
                  {lang.labels.noMatchingRecordsFound}
                </div>
              }
            </div>
          </div>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      <AppModalInvestmentUpdate investment={investment} notary={null} investments={investments}  ingots={ingots} rates={rates} intermediaries={intermediaries} securityCompanies={securityCompanies} notaries={notaries} setNotary={null} setInvestments={setInvestments}></AppModalInvestmentUpdate>
      <AppModalCommunicationCreate client={client} communications={communications} setCommunications={setCommunications}></AppModalCommunicationCreate >
      <AppModalDocumentCreate client={client} documents={documents} setDocuments={setDocuments}></AppModalDocumentCreate>
      <AppModalSpouseView spouse={client?.spouse}></AppModalSpouseView>
    </div>
  )
};

export default ClientViewPage;
