import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';
import { getNames, getCode, getName } from '../../scripts/countries.script'
import { format } from 'date-fns';
import { StateLanguage } from '../../languages/config/StateLanguage';
import { inputValid, inputInvalid, restartInput } from '../../types/legend.input.type';
import { pensionPerYearList, pensionPerYearValue } from '../../scripts/pension.per.year.script';
import { fortuneList, fortuneValue } from '../../scripts/fortune.script';
import { modalHide } from '../../types/modal.type';
import { HeritageOriginModel } from '../../models/heritage.origin.model';
import { SpouseModel } from '../../models/spouse.model';
import { ClientModel } from '../../models/client.model';

interface AppModalSpouseUpdateProps {
  client: ClientModel | undefined | null,
  heritages: HeritageOriginModel[] | undefined | null,
  setSpouse: Dispatch<SetStateAction<SpouseModel | undefined | null>>
};

const AppModalSpouseUpdate: React.FunctionComponent<AppModalSpouseUpdateProps> = ({client, heritages, setSpouse}) => {
  const { lang } = StateLanguage()

  const expressions = {
    longText: /^[\s\S]{1,1000}$/,
    text: /^[A-Za-zÀ-ÿ0-9\s\-_!@#$%^&*(){}[\]<>?¿¡+=.,;:'"|\\\\/´¨`´áéíóúüñÁÉÍÓÚÜÑÄäËëÏïÖöÜüÿ]+$/,
    name: /^[A-Za-zÀ-ÿ0-9\s\-_]{1,500}$/,
    phone: /^\+?[0-9]{1,4}[ -]*\(?[0-9]{1,4}\)?[ -]*[0-9\- ]{4,10}$/,
    email: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    street: /^[A-Za-zÀ-ÿ0-9'\\.\-\s\\,]{1,200}$/,
    location: /^[a-zA-ZÀ-ÿ]+(?:[\s-][a-zA-ZÀ-ÿ]+)*$/,
    nif: /^([A-Z]{3}-\d{3}\.\d{3}\.\d{3} [A-Z]{4})|([A-Z]{3}-\d{3}\.\d{3}\.\d{3})|(\d{3}\.\d{3}\.\d{3}(?:\.\d{2})?(?: [A-Z]{4})?)$/,
    zip: /^\d{3,5}([-]|\s*)?(\d{3,5})?$/,
    iban: /^([A-Z]{2})\s*(\d{2})(\s*(\d{4})){1,2}\s*(\d{2})?(\s*(\d{4})){1,3}\s*([A-Z0-9]{1,10})$/,
    bic: /^([A-Z]{4}\s?[A-Z]{2}\s?[A-Z0-9]{2}\s?([A-Z0-9]{0,3})?)$/
  }

  const [firstName, setFirstName] = useState({value: '', valid: false})
  const [lastName, setLastName] = useState({value: '', valid: false})
  const [email, setEmail] = useState({value: '', valid: true})
  const [phone, setPhone] = useState({value: '', valid: true})
  const [placeOfBirth, setPlaceOfBirth] = useState({value: '', valid: true})
  const [street, setStreet] = useState({value: '', valid: true})
  const [city, setCity] = useState({value: '', valid: true})
  const [iban, setIban] = useState({value: '', valid: true})
  const [bic, setBic] = useState({value: '', valid: true})
  const [sourceOfFunds, setSourceOfFunds] = useState({value: '', valid: true})
  const [pep, setPep] = useState({value: false, valid: true})
  const [taxpayer, setTaxpayer] = useState({value: '', valid: true})
  const [fieldOfActivity, setFieldOfActivity] = useState({value: '', valid: true})
  const [pensionPerYear, setPensionPerYear] = useState({value: '', valid: true})
  const [fortune, setFortune] = useState({value: '', valid: true})
  const [nationality, setNationality] = useState({value: '', valid: true})
  const [countryEmployer, setCountryEmployer] = useState({value: '', valid: true})
  const [professionalActivity, setProfessionalActivity] = useState({value: '', valid: true})
  const [previousProfessionalActivity, setPreviousProfessionalActivity] = useState({value: '', valid: true})
  const [previousEmployer, setPreviousEmployer] = useState({value: '', valid: true})
  const [diploma, setDiploma] = useState({value: '', valid: true})
  const [member, setMember] = useState({value: false, valid: true})
  const [fatherName, setFatherName] = useState({value: '', valid: true})
  const [motherName, setMotherName] = useState({value: '', valid: true})
  const [dateOfBirth, setDateOfBirth] = useState({value: '', valid: true})
  const [dateOfBackground, setDateOfBackground] = useState({value: '', valid: true})
  const [country, setCountry] = useState({value: '', valid: true})
  const [currentEmployer, setCurrentEmployer] = useState({value: '', valid: true})
  const [zipCode, setZipCode] = useState({value: '', valid: true})
  const [heritageOrigins, setHeritageOrigins] = useState({value: [] as string[], object: [] as HeritageOriginModel[], valid: [] as boolean[]})
  const [searchCountry, setSearchCountry] = useState<{text: string, list: string[]}>({text: '', list: []})

  const saveSpouce = () => {
    if (lastName.valid && firstName.valid && email.valid && phone.valid && dateOfBirth.valid && placeOfBirth.valid && street.valid && city.valid && country.valid && zipCode.valid && taxpayer.valid && pep.valid && nationality.valid && fatherName.valid && motherName.valid && iban.valid && bic.valid && professionalActivity.valid && dateOfBackground.valid && currentEmployer.valid && countryEmployer.valid && fieldOfActivity.valid && previousProfessionalActivity.valid && previousEmployer.valid && pensionPerYear.valid && fortune.valid && heritageOrigins.valid && sourceOfFunds.valid && diploma.valid && member.valid) {
      let spouseData: SpouseModel = { 
        last_name: firstName.value,
        first_name: lastName.value,
        email: email.value,
        phone: phone.value,
        birthdate: dateOfBirth.value,
        birthplace: placeOfBirth.value,
        diploma: diploma.value,
        board_member: member.value,
        address: {
          street: street.value,
          city: city.value,
          zip_code: zipCode.value,
          country: country.value
        },
        taxpayer_number: taxpayer.value,
        pep_family: pep.value,
        nationality: nationality.value,
        father: fatherName.value,
        mother: motherName.value,
        iban: iban.value,
        swift_bic: bic.value,
        professional_activity: professionalActivity.value,
        function_start_date: dateOfBackground.value,
        current_employer: currentEmployer.value,
        country_current_employer: countryEmployer.value,
        activity_area: fieldOfActivity.value,
        previus_profesional_activity: previousProfessionalActivity.value,
        previus_employer: previousEmployer.value,
        annual_income_or_pension: pensionPerYear.value,
        fortune: fortune.value,
        source_of_funds: sourceOfFunds.value,
        origin_of_patrimonial_assets: heritageOrigins.object
      }

      setSpouse(spouseData)
      executeHideModalSpouseUpdate()
    } else {
      Swal.fire({
        text: lang.labels.sorryLooksLikeThereAreSomeErrorsTrySolve,
        icon: 'error',
        showConfirmButton: false,
        timer: 1800
      } as SweetAlertOptions)
    }
  }

  const cancelSpouce = () => {
    setSpouse(null)
    executeHideModalSpouseUpdate()
  }

  const handleChangeFirstName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFirstName({...firstName, value: event.target.value})
  }

  const handleChangeLastName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setLastName({...lastName, value: event.target.value})
  }

  const handleChangeEmail = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setEmail({...email, value: event.target.value})
  }

  const handleChangeFieldOfActivity= (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFieldOfActivity({...fieldOfActivity, value: event.target.value})
  }

  const handleChangeFatherName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setFatherName({...fatherName, value: event.target.value})
  }

  const handleChangeMotherName = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setMotherName({...motherName, value: event.target.value})
  }

  const handleChangeZipCode = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setZipCode({...zipCode, value: event.target.value})
  }

  const handleChangePlaceOfBirth = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPlaceOfBirth({...placeOfBirth, value: event.target.value})
  }

  const handleChangeCurrentEmployer = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCurrentEmployer({...currentEmployer, value: event.target.value})
  }

  const handleChangeProfessionalActivity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setProfessionalActivity({...professionalActivity, value: event.target.value})
  }

  const handleChangePreviusProfessionalActivity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPreviousProfessionalActivity({...previousProfessionalActivity, value: event.target.value})
  }

  const handleChangePreviusEmployer = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPreviousEmployer({...previousEmployer, value: event.target.value})
  }

  const handleChangeDiploma = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDiploma({...diploma, value: event.target.value})
  }

  const handleChangeTaxpayer = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setTaxpayer({...taxpayer, value: event.target.value})
  }

  const handleChangePhone = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setPhone({...phone, value: event.target.value})
  }

  const handleChangeStreet = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setStreet({...street, value: event.target.value})
  }

  const handleChangeCity = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setCity({...city, value: event.target.value})
  }

  const handleChangeIban = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setIban({...iban, value: event.target.value})
  }

  const handleChangeBic = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setBic({...bic, value: event.target.value})
  }

  const handleChangeSourceOfFunds = (event: React.ChangeEvent <HTMLFormElement | HTMLTextAreaElement>) => {
    setSourceOfFunds({...sourceOfFunds, value: event.target.value})
  }

  const handleChangeDateOfBirth = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDateOfBirth({...dateOfBirth, value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleDateOfBackground = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    setDateOfBackground({...dateOfBackground, value: event.target.value, valid: event.target.value.length > 0})
  }

  const handleChangeMember = (item: boolean) => {
    if (document.querySelector('input[name="member"]:checked')) {
      setMember({...member, value: item, valid: true})
    }
  }

  const handleChangePep = (item: boolean) => {
    if (document.querySelector('input[name="pep"]:checked')) {
      setPep({...pep, value: item, valid: true})
    }
  }

  const handleChangePensionPerYear = (item: string) => {
    setPensionPerYear({...pensionPerYear, value: item, valid: true})
  }

  const handleChangeFortune = (item: string) => {
    setFortune({...fortune, value: item, valid: true})
  }

  const handleChangeOriginOfHeritage = (item: HeritageOriginModel) => {
    setHeritageOrigins({value: [...heritageOrigins.value, item.id], object: [...heritageOrigins.object, item], valid: [...heritageOrigins.valid, true]})
  }

  const handleChangeNationality = (item: string) => {
    setNationality({...nationality, value: item, valid: true})
  }

  const handleChangeCountry  = (item: string) => {
    setCountry({...country, value: item, valid: true})
  }

  const handleChangeCountryEmployer = (item: string) => {
    setCountryEmployer({...countryEmployer, value: item, valid: true})
  }

  const handleChangeSearchCountry = (event: React.ChangeEvent <HTMLFormElement | HTMLInputElement>) => {
    let text: string = event.target.value
    let list: string[] = []

    if (text.length > 0) {
      for (let country of getNames(lang.codeLanguage)) {
        if (country.toLowerCase().indexOf(text.toLowerCase()) !== -1) {
          list.push(country)
        }
      }
    } else {
      list = getNames(lang.codeLanguage)
    }

    setSearchCountry({...searchCountry, text: text, list: list})
  }

  const handleRemoveHeritage = (index: number) => {
    let tempHeritageOriginsValue = heritageOrigins.value.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempHeritageOriginsObject = heritageOrigins.object.filter((_temp_item, temp_index) => (temp_index !== index))
    let tempHeritageOriginsValid = heritageOrigins.valid.filter((_temp_item, temp_index) => (temp_index !== index))

    setHeritageOrigins({value: tempHeritageOriginsValue, object: tempHeritageOriginsObject, valid: tempHeritageOriginsValid})
  }

  const validateFirstName = () => {
    if (expressions.name.test(firstName.value)) {
      setFirstName({...firstName, valid: true})
      inputValid('modal-spouse-update-input-first-name')
    } else {
      setFirstName({...firstName, valid: false})
      inputInvalid('modal-spouse-update-input-first-name')
    }
  }

  const validateLastName = () => {
    if (expressions.name.test(lastName.value)) {
      setLastName({...lastName, valid: true})
      inputValid('modal-spouse-update-input-last-name')
    } else {
      setLastName({...lastName, valid: false})
      inputInvalid('modal-spouse-update-input-last-name')
    }
  }

  const validateEmail = () => {
    if (email.value.length > 0) {
      if (expressions.email.test(email.value)) {
        setEmail({...email, valid: true})
        inputValid('modal-spouse-update-input-email')
      } else {
        setEmail({...email, valid: false})
        inputInvalid('modal-spouse-update-input-email')
      }
    } else if (email.value.length === 0) {
      setEmail({...email, valid: true})
      restartInput('modal-spouse-update-input-email')
    }
  }

  const validateZipCode = () => {
    if (zipCode.value.length > 0) {
      if (expressions.zip.test(zipCode.value)) {
        setZipCode({...zipCode, valid: true})
        inputValid('modal-spouse-update-input-zip-code')
      } else {
        setZipCode({...zipCode, valid: false})
        inputInvalid('modal-spouse-update-input-zip-code')
      }
    } else if (zipCode.value.length === 0) {
      setZipCode({...zipCode, valid: true})
      restartInput('modal-spouse-update-input-zip-code')
    }
  }

  const validatePlaceOfBirth = () => {
    if (placeOfBirth.value.length > 0) {
      if (expressions.text.test(placeOfBirth.value)) {
        setPlaceOfBirth({...placeOfBirth, valid: true})
        inputValid('modal-spouse-update-input-place-of-birth')
      } else {
        setPlaceOfBirth({...placeOfBirth, valid: false})
        inputInvalid('modal-spouse-update-input-place-of-birth')
      }
    } else if (placeOfBirth.value.length === 0) {
      setPlaceOfBirth({...placeOfBirth, valid: true})
      restartInput('modal-spouse-update-input-place-of-birth')
    }
  }

  const validateTaxpayer = () => {
    if (taxpayer.value.length > 0) {
      if (expressions.nif.test(taxpayer.value)) {
        setTaxpayer({...taxpayer, valid: true})
        inputValid('modal-spouse-update-input-taxpayer')
      } else {
        setTaxpayer({...taxpayer, valid: false})
        inputInvalid('modal-spouse-update-input-taxpayer')
      }
    } else if (taxpayer.value.length === 0) {
      setTaxpayer({...taxpayer, valid: true})
      restartInput('modal-spouse-update-input-taxpayer')
    }
  }

  const validatePhone = () => {
    if (phone.value.length > 0) {
      if (expressions.phone.test(phone.value)) {
        setPhone({...phone, valid: true})
        inputValid('modal-spouse-update-input-phone')
      } else {
        setPhone({...phone, valid: false})
        inputInvalid('modal-spouse-update-input-phone')
      }
    } else if (phone.value.length === 0) {
      setPhone({...phone, valid: true})
      restartInput('modal-spouse-update-input-phone')
    }
  }

  const validateFatherName = () => {
    if (fatherName.value.length > 0) {
      if (expressions.text.test(fatherName.value)) {
        setFatherName({...fatherName, valid: true})
        inputValid('modal-spouse-update-input-father-name')
      } else {
        setFatherName({...fatherName, valid: false})
        inputInvalid('modal-spouse-update-input-father-name')
      }
    } else if (fatherName.value.length === 0) {
      setFatherName({...fatherName, valid: true})
      restartInput('modal-spouse-update-input-father-name')
    }
  }

  const validateMotherName = () => {
    if (motherName.value.length > 0) {
      if (expressions.text.test(motherName.value)) {
        setMotherName({...motherName, valid: true})
        inputValid('modal-spouse-update-input-mother-name')
      } else {
        setMotherName({...motherName, valid: false})
        inputInvalid('modal-spouse-update-input-mother-name')
      }
    } else if (motherName.value.length === 0) {
      setMotherName({...motherName, valid: true})
      restartInput('modal-spouse-update-input-mother-name')
    }
  }

  const validateCurrentEmployer = () => {
    if (currentEmployer.value.length > 0) {
      if (expressions.text.test(currentEmployer.value)) {
        setCurrentEmployer({...currentEmployer, valid: true})
        inputValid('modal-spouse-update-input-current-employer')
      } else {
        setCurrentEmployer({...currentEmployer, valid: false})
        inputInvalid('modal-spouse-update-input-current-employer')
      }
    } else if (currentEmployer.value.length === 0) {
      setCurrentEmployer({...currentEmployer, valid: true})
      restartInput('modal-spouse-update-input-current-employer')
    }
  }

  const validateFieldOfActivity = () => {
    if (fieldOfActivity.value.length > 0) {
      if (expressions.text.test(fieldOfActivity.value)) {
        setFieldOfActivity({...fieldOfActivity, valid: true})
        inputValid('modal-spouse-update-input-field-of-activity')
      } else {
        setFieldOfActivity({...fieldOfActivity, valid: false})
        inputInvalid('modal-spouse-update-input-field-of-activity')
      }
    } else if (fieldOfActivity.value.length === 0) {
      setFieldOfActivity({...fieldOfActivity, valid: true})
      restartInput('modal-spouse-update-input-field-of-activity')
    }
  }

  const validateStreet = () => {
    if (street.value.length > 0) {
      if (expressions.street.test(street.value)) {
        setStreet({...street, valid: true})
        inputValid('modal-spouse-update-input-street')
      } else {
        setStreet({...street, valid: false})
        inputInvalid('modal-spouse-update-input-street')
      }
    } else if (street.value.length === 0) {
      setStreet({...street, valid: true})
      restartInput('modal-spouse-update-input-street')
    }
  }

  const validateProfessionalActivity = () => {
    if (professionalActivity.value.length > 0) {
      if (expressions.text.test(professionalActivity.value)) {
        setProfessionalActivity({...professionalActivity, valid: true})
        inputValid('modal-spouse-update-input-professional-activity')
      } else {
        setProfessionalActivity({...professionalActivity, valid: false})
        inputInvalid('modal-spouse-update-input-professional-activity')
      }
    } else if (professionalActivity.value.length === 0) {
      setProfessionalActivity({...professionalActivity, valid: true})
      restartInput('modal-spouse-update-input-professional-activity')
    }
  }

  const validatePreviousProfessionalActivity = () => {
    if (previousProfessionalActivity.value.length > 0) {
      if (expressions.text.test(previousProfessionalActivity.value)) {
        setPreviousProfessionalActivity({...previousProfessionalActivity, valid: true})
        inputValid('modal-spouse-update-input-previous-professional-activity')
      } else {
        setPreviousProfessionalActivity({...previousProfessionalActivity, valid: false})
        inputInvalid('modal-spouse-update-input-previous-professional-activity')
      }
    } else if (previousProfessionalActivity.value.length === 0) {
      setPreviousProfessionalActivity({...previousProfessionalActivity, valid: true})
      restartInput('modal-spouse-update-input-previous-professional-activity')
    }
  }

  const validatePreviousEmployer = () => {
    if (previousEmployer.value.length > 0) {
      if (expressions.text.test(previousEmployer.value)) {
        setPreviousEmployer({...previousEmployer, valid: true})
        inputValid('modal-spouse-update-input-previous-employer')
      } else {
        setPreviousEmployer({...previousEmployer, valid: false})
        inputInvalid('modal-spouse-update-input-previous-employer')
      }
    } else if (previousEmployer.value.length === 0) {
      setPreviousEmployer({...previousEmployer, valid: true})
      restartInput('modal-spouse-update-input-previous-employer')
    }
  }

  const validateDiploma = () => {
    if (diploma.value.length > 0) {
      if (expressions.text.test(diploma.value)) {
        setDiploma({...diploma, valid: true})
        inputValid('modal-spouse-update-input-diploma')
      } else {
        setDiploma({...diploma, valid: false})
        inputInvalid('modal-spouse-update-input-diploma')
      }
    } else if (diploma.value.length === 0) {
      setDiploma({...diploma, valid: true})
      restartInput('modal-spouse-update-input-diploma')
    }
  }

  const validateCity = () => {
    if (city.value.length > 0) {
      if (expressions.location.test(city.value)) {
        setCity({...city, valid: true})
        inputValid('modal-spouse-update-input-city')
      } else {
        setCity({...city, valid: false})
        inputInvalid('modal-spouse-update-input-city')
      }
    } else if (city.value.length === 0) {
      setCity({...city, valid: true})
      restartInput('modal-spouse-update-input-city')
    }
  }

  const validateIban = () => {
    if (iban.value.length > 0) {
      if (expressions.iban.test(iban.value)) {
        setIban({...iban, valid: true})
        inputValid('modal-spouse-update-input-iban')
      } else {
        setIban({...iban, valid: false})
        inputInvalid('modal-spouse-update-input-iban')
      }
    } else if (iban.value.length === 0) {
      setIban({...iban, valid: true})
      restartInput('modal-spouse-update-input-iban')
    }
  }

  const validateBic = () => {
    if (bic.value.length > 0) {
      if (expressions.bic.test(bic.value)) {
        setBic({...bic, valid: true})
        inputValid('modal-spouse-update-input-bic')
      } else {
        setBic({...bic, valid: false})
        inputInvalid('modal-spouse-update-input-bic')
      }
    } else if (bic.value.length === 0) {
      setBic({...bic, valid: true})
      restartInput('modal-spouse-update-input-bic')
    }
  }

  const validateSourceOfFunds = () => {
    if (expressions.longText.test(sourceOfFunds.value)) {
      setSourceOfFunds({...sourceOfFunds, valid: true})
    } else {
      setSourceOfFunds({...sourceOfFunds, valid: false})
    }
  }

  function maxDate(): string {
    return format((new Date()), 'yyyy-MM-dd')
  }

  function uploadDropdown() {
    setSearchCountry({...searchCountry, text: '', list: getNames(lang.codeLanguage)})
  }

  const executeHideModalSpouseUpdate = () => {
    modalHide('modal-spouse-update')

    setTimeout( () => {
      restartInput('modal-spouse-update-input-first-name')
      restartInput('modal-spouse-update-input-last-name')
      restartInput('modal-spouse-update-input-email')
      restartInput('modal-spouse-update-input-phone')
      restartInput('modal-spouse-update-input-place-of-birth')
      restartInput('modal-spouse-update-input-father-name')
      restartInput('modal-spouse-update-input-mother-name')
      restartInput('modal-spouse-update-input-taxpayer')
      restartInput('modal-spouse-update-input-diploma')
      restartInput('modal-spouse-update-input-street')
      restartInput('modal-spouse-update-input-city')
      restartInput('modal-spouse-update-input-zip-code')
      restartInput('modal-spouse-update-input-iban')
      restartInput('modal-spouse-update-input-bic')
      restartInput('modal-spouse-update-input-field-of-activity')
      restartInput('modal-spouse-update-input-professional-activity')
      restartInput('modal-spouse-update-input-current-employer')
      restartInput('modal-spouse-update-input-previous-professional-activity')
      restartInput('modal-spouse-update-input-previous-employer')
    }, 200 )
  }


  useEffect(() => {
    if (client && client.spouse) {
      setFirstName({value: client.spouse.first_name, valid: true})
      setLastName({value: client.spouse.last_name, valid: true})
      setEmail({value: client.spouse.email, valid: true})
      setPhone({value: client.spouse.phone, valid: true})
      setPlaceOfBirth({value: client.spouse.birthplace, valid: true})
      setStreet({value: client.spouse.address.street, valid: true})
      setCity({value: client.spouse.address.city, valid: true})
      setIban({value: client.spouse.iban, valid: true})
      setBic({value: client.spouse.swift_bic, valid: true})
      setSourceOfFunds({value: client.spouse.source_of_funds, valid: true})
      setPep({value: client.spouse.pep_family, valid: true})
      setTaxpayer({value: client.spouse.taxpayer_number, valid: true})
      setFieldOfActivity({value: client.spouse.activity_area, valid: true})
      setPensionPerYear({value: client.spouse.annual_income_or_pension, valid: true})
      setFortune({value: client.spouse.fortune, valid: true})
      setNationality({value: client.spouse.nationality, valid: true})
      setCountryEmployer({value: client.spouse.country_current_employer, valid: true})
      setProfessionalActivity({value: client.spouse.professional_activity, valid: true})
      setPreviousProfessionalActivity({value: client.spouse.previus_profesional_activity, valid: true})
      setPreviousEmployer({value: client.spouse.previus_employer, valid: true})
      setDiploma({value: client.spouse.diploma, valid: true})
      setMember({value: client.spouse.board_member, valid: true})
      setFatherName({value: client.spouse.father, valid: true})
      setMotherName({value: client.spouse.mother, valid: true})
      setDateOfBirth({value: client.spouse.birthdate, valid: true})
      setDateOfBackground({value: client.spouse.function_start_date, valid: true})
      setCountry({value: client.spouse.address.country, valid: true})
      setCurrentEmployer({value: client.spouse.current_employer, valid: true})
      setZipCode({value: client.spouse.address.zip_code, valid: true})
      setHeritageOrigins({value: [], object: [], valid: []})
      setHeritageOrigins({value: client.spouse.origin_of_patrimonial_assets.map(item => {return item.id}), object: client.spouse.origin_of_patrimonial_assets, valid: client.spouse.origin_of_patrimonial_assets.map(_ => {return true})})
    }
  }, [client]);

  return (
    <div id="modal-spouse-update" className="modal fade" tabIndex={-1} aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between">
            <div className="card-title d-flex flex-column align-items-center w-100">
              <h2 className="fw-bolder text-uppercase">{lang.labels.updateSpouse}</h2>
            </div>
          </div>
          <div className="modal-body">
            { client && heritages 
              ?
              <div className="form">
                <div className="card mb-10">
                  <div className="card-body py-0">
                    <h5 className="mb-2">{lang.labels.clientInformation}</h5>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label required fw-bold fs-6">{lang.labels.lastName}</label>
                        <input id="modal-spouse-update-input-last-name" className="form-control form-control-solid" type="text" name="last-name" value={lastName.value} onChange={handleChangeLastName} onKeyUp={validateLastName} onBlur={validateLastName} />
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label required fw-bold fs-6">{lang.labels.firstName}</label>
                        <input id="modal-spouse-update-input-first-name" className="form-control form-control-solid" type="text" name="first-name" value={firstName.value} onChange={handleChangeFirstName} onKeyUp={validateFirstName} onBlur={validateFirstName} />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.email}</label>
                        <input id="modal-spouse-update-input-email" className="form-control form-control-solid" type="text" name="email" value={email.value} onChange={handleChangeEmail} onKeyUp={validateEmail} onBlur={validateEmail} />
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.phone}</label>
                        <input id="modal-spouse-update-input-phone" className="form-control form-control-solid" type="text" name="phone" value={phone.value} onChange={handleChangePhone} onKeyUp={validatePhone} onBlur={validatePhone} />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-4">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.nationality}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {nationality.valid && nationality.value.length > 0 ? getName(nationality.value, lang.codeLanguage) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-search select2-search--dropdown">
                                <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                              </span>
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { searchCountry.list.map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === nationality.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === nationality.value ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeNationality(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="mb-2 mb-xl-0 col-xl-4">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.dateOfBirth}</label>
                        <input className="form-control form-control-solid pe-2" type="date" name="dateOfBirth" max={maxDate()} value={dateOfBirth.value} onChange={handleChangeDateOfBirth} />
                      </div>
                      <div className="col-xl-4">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.placeOfBirth}</label>
                        <input id="modal-spouse-update-input-place-of-birth" className="form-control form-control-solid" type="text" name="place-of-birth" value={placeOfBirth.value} onChange={handleChangePlaceOfBirth} onKeyUp={validatePlaceOfBirth} onBlur={validatePlaceOfBirth} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.firstNameAndLastNameFather}</label>
                        <input id="modal-spouse-update-input-father-name" className="form-control form-control-solid" type="text" name="father-name" value={fatherName.value} onChange={handleChangeFatherName} onKeyUp={validateFatherName} onBlur={validateFatherName} />
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.maidenNameMother}</label>
                        <input id="modal-spouse-update-input-mother-name" className="form-control form-control-solid" type="text" name="motherName" value={motherName.value} onChange={handleChangeMotherName} onKeyUp={validateMotherName} onBlur={validateMotherName} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-10">
                  <div className="card-body py-0">
                    <h5 className="mb-2">{lang.labels.extraInformation}</h5>
                    <div className="row fv-row">
                      <div className="mb-2 col-12">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.pepClose}</label>
                        <div className="fv-row d-flex overflow-hidden my-3">
                          <div className="d-flex align-items-center cursor-pointer me-5">
                            <label className="fw-bolder fs-6 me-2">{lang.labels.yes}</label>
                            <span className="form-check form-check-custom form-check-solid">
                              <input className="form-check-input" type="radio" name="pep" defaultChecked={pep.value} onChange={() => handleChangePep(true)} />
                            </span>
                          </div>
                          <div className="d-flex align-items-center cursor-pointer">
                            <label className="fw-bolder fs-6 me-2">{lang.labels.no}</label>
                            <span className="form-check form-check-custom form-check-solid">
                              <input className="form-check-input" type="radio" name="pep" defaultChecked={!pep.value} onChange={() => handleChangePep(false)} />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="mb-2 col-12">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.taxpayerNumber}</label>
                        <input id="modal-spouse-update-input-taxpayer" className="form-control form-control-solid" type="text" name="taxpayer" value={taxpayer.value} onChange={handleChangeTaxpayer} onKeyUp={validateTaxpayer} onBlur={validateTaxpayer} />
                      </div>
                      <div className="col-12">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.diploma}</label>
                        <input id="modal-spouse-update-input-diploma" className="form-control form-control-solid" type="text" name="diploma" value={diploma.value} onChange={handleChangeDiploma} onKeyUp={validateDiploma} onBlur={validateDiploma} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-10">
                  <div className="card-body py-0">
                    <h5 className="mb-2">{lang.labels.residentialAddress}</h5>
                    <div className="row fv-row">
                      <div className="mb-2 col-12">
                        <label className="col-form-label fs-6">{lang.labels.street}</label>
                        <input id="modal-spouse-update-input-street" className="form-control form-control-solid" type="text" name="street" value={street.value} onChange={handleChangeStreet} onKeyUp={validateStreet} onBlur={validateStreet} />
                      </div>
                      <div className="row mb-2">
                        <div className="mb-2 mb-xl-0 col-xl-9">
                          <label className="col-form-label fs-6">{lang.labels.city}</label>
                          <input id="modal-spouse-update-input-city" className="form-control form-control-solid" type="text" name="city" value={city.value} onChange={handleChangeCity} onKeyUp={validateCity} onBlur={validateCity} />
                        </div>
                        <div className="col-xl-3">
                          <label className="col-form-label fs-6">{lang.labels.postcode}</label>
                          <input id="modal-spouse-update-input-zip-code" className="form-control form-control-solid" type="text" name="zipCode" value={zipCode.value} onChange={handleChangeZipCode} onKeyUp={validateZipCode} onBlur={validateZipCode} />
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.country}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {country.valid && country.value.length > 0 ? getName(country.value, lang.codeLanguage) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-search select2-search--dropdown">
                                <input className="select2-search__field" type="text" name="contact" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                              </span>
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { searchCountry.list.map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === country.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === country.value ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountry(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-10">
                  <div className="card-body py-0">
                    <h5 className="mb-2">{lang.labels.bankInformation}</h5>
                    <div className="row fv-row">
                      <div className="mb-2 col-12">
                        <label className="col-form-label fs-6">{lang.labels.iban}</label>
                        <input id="modal-spouse-update-input-iban" className="form-control form-control-solid" type="text" name="iban" value={iban.value} onChange={handleChangeIban} onKeyUp={validateIban} onBlur={validateIban} />
                      </div>
                      <div className="col-12">
                        <label className="col-form-label fs-6">{lang.labels.bicSwift}</label>
                        <input id="modal-spouse-update-input-bic" className="form-control form-control-solid" type="text" name="bic" value={bic.value} onChange={handleChangeBic} onKeyUp={validateBic} onBlur={validateBic} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-10">
                  <div className="card-body py-0">
                    <h5 className="mb-2">{lang.labels.professionalActivityInformation}</h5>
                    <div className="row mb-2">
                      <div className="col-12">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.fieldOfActivity}</label>
                        <input id="modal-spouse-update-input-field-of-activity" className="form-control form-control-solid" type="text" name="FieldOfActivity" value={fieldOfActivity.value} onChange={handleChangeFieldOfActivity} onKeyUp={validateFieldOfActivity} onBlur={validateFieldOfActivity} />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.professionalActivity}</label>
                        <input id="modal-spouse-update-input-professional-activity" className="form-control form-control-solid" type="text" name="professional-activity" value={professionalActivity.value} onChange={handleChangeProfessionalActivity} onKeyUp={validateProfessionalActivity} onBlur={validateProfessionalActivity} />
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.currentEmployer}</label>
                        <input id="modal-spouse-update-input-current-employer" className="form-control form-control-solid" type="text" name="currentEmployer" value={currentEmployer.value} onChange={handleChangeCurrentEmployer} onKeyUp={validateCurrentEmployer} onBlur={validateCurrentEmployer} />
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.startDateOfBackgroundOn}</label>
                        <input className="form-control form-control-solid pe-2" type="date" name="dateOfBackground" max={maxDate()} value={dateOfBackground.value} onChange={handleDateOfBackground} />
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.countryOfCurrentEmployer}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0" onFocus={uploadDropdown}>
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid" aria-disabled="false">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {countryEmployer.valid && countryEmployer.value.length > 0 ? getName(countryEmployer.value, lang.codeLanguage) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100" onLoad={uploadDropdown}>
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-search select2-search--dropdown">
                                <input className="select2-search__field" type="text" name="client" value={searchCountry.text} onChange={handleChangeSearchCountry} />
                              </span>
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { searchCountry.list.map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${getCode(item, lang.codeLanguage) === countryEmployer.value && "select2-results__option--selected"}`} role="option" aria-selected={getCode(item, lang.codeLanguage) === countryEmployer.value ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeCountryEmployer(getCode(item, lang.codeLanguage))}>{item}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.previousProfessionalActivity}</label>
                        <input id="modal-spouse-update-input-previous-professional-activity" className="form-control form-control-solid" type="text" name="previousProfessionalActivity" value={previousProfessionalActivity.value} onChange={handleChangePreviusProfessionalActivity} onKeyUp={validatePreviousProfessionalActivity} onBlur={validatePreviousProfessionalActivity} />
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.previousEmployer}</label>
                        <input id="modal-spouse-update-input-previous-employer" className="form-control form-control-solid" type="text" name="previousEmployer" value={previousEmployer.value} onChange={handleChangePreviusEmployer} onKeyUp={validatePreviousEmployer} onBlur={validatePreviousEmployer} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.incomeOrPensionReceivedForAnYear}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {pensionPerYear.valid && pensionPerYear.value.length > 0 ? pensionPerYearValue(lang, pensionPerYear.value) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { pensionPerYearList(lang).map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === pensionPerYear.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === pensionPerYear.value ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangePensionPerYear(item.code)}>{item.desc}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.memberOfTheBoardOfDirectors}</label>
                        <div className="fv-row d-flex overflow-hidden my-3">
                          <div className="d-flex align-items-center cursor-pointer me-5">
                            <label className="fw-bolder fs-6 me-2">{lang.labels.yes}</label>
                            <span className="form-check form-check-custom form-check-solid">
                              <input className="form-check-input" type="radio" name="member" defaultChecked={member.value} onChange={() => handleChangeMember(true)} />
                            </span>
                          </div>
                          <div className="d-flex align-items-center cursor-pointer">
                            <label className="fw-bolder fs-6 me-2">{lang.labels.no}</label>
                            <span className="form-check form-check-custom form-check-solid">
                              <input className="form-check-input" type="radio" name="member" defaultChecked={!member.value} onChange={() => handleChangeMember(false)} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mb-10">
                  <div className="card-body py-0">
                    <h5 className="mb-2">{lang.labels.originAndDeterminationAssetValues}</h5>
                    <div className="row mb-2">
                      <div className="mb-2 mb-xl-0 col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.originOfHeritageValues}</label>
                        <Dropdown>
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { heritages.map((item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${heritageOrigins.value.some(sub_item => sub_item === item.id) && "d-none"}`} role="option" aria-selected={heritageOrigins.value.some(sub_item => sub_item === item.id) ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeOriginOfHeritage(item)}>{item.description}</Dropdown.Item>
                                    </li>
                                  )})}
                                  <li className={`select2-results__option select2-results__option--selectable ${!heritages.every(item => heritageOrigins.value.some(sub_item => sub_item === item.id)) && "d-none"}`} role="option" aria-selected="false">
                                    <Dropdown.Item bsPrefix="select2-results__option__text">{lang.labels.nonEsxistOptions}</Dropdown.Item>
                                  </li>
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                        { heritageOrigins.value.length > 0 &&
                          <div className="d-print-none card-rounded border border-dashed border-gray-300 bg-gray-100 w-100 mt-3">
                            { heritageOrigins.object.map((item, index) => { return (
                              <label key={index} className="badge badge-secondary d-inline-flex align-items-center m-3">
                                <span className="overflow-hidden d-inline-block me-2">
                                  <div className="d-flex align-items-center mw-500px h-20px">
                                    <div className="text-truncate">
                                      {item.description}
                                    </div>
                                  </div>
                                </span>
                                <span className="btn btn-icon svg-icon svg-icon-3 h-20px w-20px" onClick={() => handleRemoveHeritage(index)}>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.3" d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z" fill="black"/>
                                    <path d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z" fill="black"/>
                                  </svg>
                                </span>
                              </label>
                            )})}
                          </div>
                        }
                      </div>
                      <div className="col-xl-6">
                        <label className="col-form-label fw-bold fs-6">{lang.labels.fortuneIncludingRealEstate}</label>
                        <Dropdown>  
                          <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                            <span className="selection">
                              <span className="select2-selection select2-selection--single form-select form-select-solid">
                                <span className="select2-selection__rendered" role="textbox">
                                  <span className="select2-selection__placeholder">
                                    {fortune.valid && fortune.value.length > 0 ? fortuneValue(lang, fortune.value) : lang.labels.selectOption}
                                  </span>
                                </span>
                              </span>
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                            <span className="select2-dropdown select2-dropdown--below">
                              <span className="select2-results">
                                <ul className="select2-results__options" role="listbox">
                                  { fortuneList(lang).map (( (item, index) => { return (
                                    <li key={index} className={`select2-results__option select2-results__option--selectable ${item.code === fortune.value &&"select2-results__option--selected"}`} role="option" aria-selected={item.code === fortune.value ? "true" : "false"}>
                                      <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeFortune(item.code)}>{item.desc}</Dropdown.Item>
                                    </li>
                                  )}))}
                                </ul>
                              </span>
                            </span>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <label className="col-form-label fw-bold fs-6">
                          {lang.labels.sourceOfFunds}
                          <span className="text-muted fst-italic fs-7 ms-1">({lang.labels.maximum1kCharacters})</span>
                        </label>
                        <textarea className="form-control form-control-solid resize-none" rows={8} name="sourceOfFunds" maxLength={1000} autoComplete="off" value={sourceOfFunds.value} onFocus={handleChangeSourceOfFunds} onChange={handleChangeSourceOfFunds} onKeyUp={validateSourceOfFunds} onBlur={validateSourceOfFunds} />
                        <div className={`d-flex justify-content-end ${sourceOfFunds.value.length === 1000 ? "text-danger" : "text-muted"} mt-2 ms-2"`}>{sourceOfFunds.value.length}/1000 {sourceOfFunds.value.length === 1000 && "The character limit has been reached"}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="w-100 h-200px">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">{lang.labels.loading}</span>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="modal-footer flex-center">
            <button className="btn btn-primary" type="button" onClick={saveSpouce}>{lang.labels.save}</button>
            <button className="btn btn-light-primary  ms-3" type="reset" onClick={cancelSpouce}>{lang.labels.cancel}</button>
          </div>
        </div>
      </div>
    </div>
    
  )
};

export default AppModalSpouseUpdate;
