import React, { useEffect, useState } from 'react';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { Dropdown } from 'react-bootstrap';

import { StateLanguage } from '../../../languages/config/StateLanguage';
import { getError } from '../../../languages/context/response';
import { Error } from '../../../models/error.model';
import { SecurityService } from '../../../services/security.service';
import { UseOutletContextParameter } from './Parameter';
import { CompanySecurityModel } from '../../../models/company.security.model';

export interface SafesPageProps { };

const SafesPage: React.FunctionComponent<SafesPageProps> = props => {
  const { setRoute, securityCompanies } = UseOutletContextParameter()
  const { lang } = StateLanguage()

  const [mounted, setMounted] = useState(false)
  const [securityCompany, setSecurityCompany] = useState({value: '', object: null as any, valid: false})
  const [safes, setSafes] = useState<string[] | undefined | null>(null)

  const loadSecurityCompanySafes = async (id_security_company: string) => {
    await SecurityService.getSecurityCompanySafes(id_security_company).then( (response) => {
      if (response.status === 200) {
        let serviceResponse: any = response.data
        setSafes(serviceResponse.number_safes)
      } else {
        let errorResponse: Error = response.data

        Swal.fire({
          title: getError(errorResponse.code, lang.codeLanguage),
          text: lang.labels.sorryLooksLikeThereAreSomeErrorsTryAgain,
          icon: 'error',
          buttonsStyling: !1,
          confirmButtonText: lang.labels.OkGotIt,
          customClass: { confirmButton:'h-100 btn btn-primary' }
        } as SweetAlertOptions)
      }
    }).catch( (error) => {
      console.error(error)
      window.location.href = '/error'
    })
  }

  const handleChangeSecurityComany = (item: CompanySecurityModel) => {
    setSecurityCompany({...securityCompany, value: item.id , object: item, valid: true})
    setSafes(null)

    if (item) {
      loadSecurityCompanySafes(item.id)
    }
  }

  const handleClearSecurityCompany = (e: any) => {
    setSecurityCompany({...securityCompany, value: '', object: null, valid: false })
    setSafes(null)
    e.stopPropagation()
  }

  useEffect(() => {
    setMounted(true)
    setRoute({main: true, path: { root: lang.labels.database, branch: lang.labels.safes }, browse: null })

    return () => setMounted(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!mounted) return null

  return (
    <>
      { securityCompanies
        ?
        <>
          <div className="card card-flush mb-5 mb-lg-8">
            <div className="card-header">
              <div className="card-title">
                <h3>{lang.labels.securityCompanyList}</h3>
              </div>
            </div>
            <div className="card-body pt-0">
              <Dropdown>
                <Dropdown.Toggle variant="select2 select2-container select2-container--bootstrap5 select2-container--below select2-container--focus select2-container--open w-100 p-0">
                  <span className="selection">
                    <span className="select2-selection select2-selection--single form-select form-select-solid d-flex justify-content-between">
                      <span className="select2-selection__rendered" role="textbox">
                        <span className="select2-selection__placeholder">
                          { securityCompany.valid ?  securityCompany.object.name : lang.labels.selectOption }
                        </span>
                      </span>
                      { securityCompany.valid &&
                        <span className="svg-icon svg-icon-primary svg-icon-2" onClick={handleClearSecurityCompany}>
                          <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="Vector" d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16" stroke="#9297aa" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                          </svg>
                        </span>
                      }
                    </span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu variant="select2-container select2-container--bootstrap5 select2-container--open w-100">
                  <span className="select2-dropdown select2-dropdown--below">
                    <span className="select2-results">
                      <ul className="select2-results__options" role="listbox">
                        { securityCompanies.map((item, index) => { return (
                          <li key={index} className={`select2-results__option select2-results__option--selectable ${ item.id === securityCompany.value &&"select2-results__option--selected" }`} role="option" aria-selected={item.id === securityCompany.value ? "true" : "false"}>
                            <Dropdown.Item bsPrefix="select2-results__option__text" onClick={() => handleChangeSecurityComany(item)}>{item.name}</Dropdown.Item>
                          </li>
                        )})}
                      </ul>
                    </span>
                  </span>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="card card-flush">
            <div className="card-header">
              <div className="card-title">
                <h4>{lang.labels.safes}</h4>
              </div>
            </div>
            <div className="card-body pt-0">
              { securityCompany.valid
                ?
                <>
                  { safes
                    ?
                    <>
                      { safes.length > 0
                        ?
                        <div className="d-flex flex-wrap">
                          { safes.map (( (item, index) => { return (
                            <div key={index} className="d-flex align-items-center fs-5 w-200px h-40px ">
                              <span className="bullet bg-primary me-3"></span>
                              <span>{item}</span>
                            </div>
                          )}))}
                        </div>
                        :
                        <p className="text-center fs-5">
                          {lang.labels.noMatchingRecordsFound}
                        </p>
                      }
                    </>
                    :
                    <div className="d-flex justify-content-center align-items-center w-100 h-100 my-5">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">{lang.labels.loading}</span>
                      </div>
                    </div>
                  }
                </>
                :
                <p className="text-center fs-5">
                  {lang.labels.noSecurityCompanySelected}
                </p>
              }
            </div>
          </div>
        </>
        :
        <div className="page-preloader d-flex justify-content-center align-items-center">
          <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
    </>
  )
};

export default SafesPage;
