import React, { Dispatch, SetStateAction } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { UseOutletContext } from '../Main';

export interface IntermediaryPageProps {};

type ContexType = {
  setRoute: Dispatch<SetStateAction<{main: boolean, path: {root: string, branch: string} | null, browse: {target: string, link: string} | null} | null>>,
};

const IntermediaryPage: React.FunctionComponent<IntermediaryPageProps> = () => {
  const {setRoute} = UseOutletContext()

  return (
    <Outlet context={{setRoute}}></Outlet>
  );
}

export function UseOutletContextIntermediary() {
  return useOutletContext<ContexType>()
};

export default IntermediaryPage;
