import { Language } from "../languages/context/language"

export function civilStatusList(lang: Language): {code: string, desc: string}[] {
  return [
    { code: 'single', desc: lang.labels.single },
    { code: 'married', desc: lang.labels.married },
    { code: 'divorced', desc: lang.labels.divorced },
    { code: 'registered_partnership', desc: lang.labels.registeredPartnership },
    { code: 'other', desc: lang.labels.other },
  ]
}

export function civilStatusValue(code: string, lang: Language): string {
  let element = civilStatusList(lang).find((item) => item.code === code)

  return element ? element.desc : code
}